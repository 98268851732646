const generatePropChangeActionCreator = (type) => (payloadName) => {
  const actionCreator = (input, isRelatedQuote) => {
    const action = {
      type: `INSTRUCT_LENDER_${type}`,
      isRelatedQuote,
    };

    action[payloadName] = input;

    return action;
  };

  return {
    type: `INSTRUCT_LENDER_${type}`,
    action: actionCreator,
  };
};

export const UPDATE_EXISTING_LENDER = generatePropChangeActionCreator(
  "UPDATE_EXISTING_LENDER"
)("newExistingLender");

export const UPDATE_EXISTING_MORTGAGE_NUMBER = generatePropChangeActionCreator(
  "UPDATE_EXISTING_MORTGAGE_NUMBER"
)("newExistingMortgageNumber");

export const UPDATE_NEW_LENDER = generatePropChangeActionCreator(
  "UPDATE_NEW_LENDER"
)("newLender");

export const VALIDATE_LENDER_INFO = generatePropChangeActionCreator(
  "VALIDATE_LENDER_INFO"
)("quoteType");
