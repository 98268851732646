import React from "react";
import Form from "react-bootstrap/Form";
import PropTypes from "prop-types";
import css from "./index.module.css";
import commonCss from "../../styles.module.css";

const LabelledCheckboxInput = ({
  id,
  datatestid,
  label,
  onChange,
  errorMessages = [],
  value = "",
  className,
}) => {
  
  return (
    <div className={css.container}>
      <Form.Check
        id={id}
        type="checkbox"
        data-testid={`${datatestid}-input`}
        onChange={onChange}
        value={value}
        label={label}
        className={className}
      />
      {errorMessages?.map((e, idx) => (
        <div key={idx} className={commonCss.error}>{`* ${e}`}</div>
      ))}
    </div>
  );
};

LabelledCheckboxInput.propTypes = {
  id: PropTypes.string,
  datatestid: PropTypes.string,
  errorMessages: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  className: PropTypes.string
};

export default LabelledCheckboxInput;
