import React, { useState } from 'react';
import { Modal, Form, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../components/button/button';
import css from '../../dashboard.module.css';
import { getUserAction } from '../../../../redux/actions/emulateActions/emulate';
import { AuthContext } from '../../../../utils/authProvider';
import EmulateResult from './EmulateResult';
import { EmulateContext } from '../EmulateContext';

const initialState = {
  loading: false,
  error: false,
  resultDisplay: false,
  userInput: '',
  validated: false,
};

export default function EmulateModal() {
  const { modalShow, setModalShow } = React.useContext(EmulateContext);
  const { getAccessToken } = React.useContext(AuthContext);
  const dispatch = useDispatch();

  const [state, setState] = useState(initialState);
  const { loading, error, resultDisplay, userInput, validated } = state;

  const userToEmulate = useSelector((store) => store.emulateUser.user);
  const resultsLoaded = useSelector((store) => store.emulateUser.finishedLoadingUser);

  React.useEffect(() => {
    if (resultsLoaded) {
      if (userToEmulate && userToEmulate.userFound) {
        setState({
          ...state,
          resultDisplay: true,
          error: false,
          loading: false,
        });
      } else {
        setState({
          ...state,
          resultDisplay: false,
          error: true,
          loading: false,
        })
      }
    }
  }, [resultsLoaded]);

  React.useEffect(() => {
    if (!modalShow) {
      setState({...initialState});
    }
  }, [modalShow]);

  const handleInputChange = (evt) => {
    setState({
      ...state,
      userInput: evt.target.value,
      error: false,
    });
  };

  const handleSearchForUser = (evt) => {
    evt.preventDefault();
    const form = evt.currentTarget;
    if (form.checkValidity() === false) {
      evt.stopPropagation();
      setState({
        ...state,
        error: false,
        validated: true,
      });
    } else {
      setState({
        ...state,
        resultDisplay: false,
        error: false,
        loading: true,
        validated: true,
      });
      getAccessToken().then(() => {
        dispatch(getUserAction(userInput));
      });
    }
  };

  return (
    <Modal
      onHide={() => setModalShow(false)}
      show={modalShow}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      contentClassName={css.modalContent}
    >
      <Modal.Header closeButton className={css.modalHeader}>
        <Modal.Title id="contained-modal-title-vcenter">
          EMULATE USER
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Please give the username of the account you want to emulate
        </p>
        <Form noValidate validated={validated} onSubmit={handleSearchForUser}>
          <Form.Row>
            <Col xs="auto" className={css.emulateModalLabel}>
              <Form.Label htmlFor="emulateFormInput">
                Broker username
              </Form.Label>
              <Form.Control
                required
                className="mb-2"
                id="emulateFormInput"
                placeholder="Username"
                value={userInput}
                onChange={handleInputChange}
              />
              <Form.Control.Feedback type="invalid">
                Please provide a username
              </Form.Control.Feedback>
              {error && (
                <div className={css.feedback}>User not found</div>
              )}
            </Col>
            <Col xs="auto" className={css.emulateModalAction}>
              <div className={css.emulateButtonWrapper}>
                <Button
                  id="find-emulate-user"
                  variant="secondary"
                  datatestid="find-emulate-user"
                  type="submit"
                  size="lg"
                  text="FIND"
                  btnClass={css.emulateModalFindButton}
                  isLoading={loading}
                  disabled={loading}
                />
              </div>
            </Col>
          </Form.Row>
        </Form>
        <EmulateResult showResult={resultDisplay} />
      </Modal.Body>
    </Modal>
  );
}
