import React from 'react'
import PropTypes from 'prop-types'
import css from './index.module.css'
import classnames from 'classnames'

const GenericText = ({copy}) => {
    return <div className={css.printCopy}>
        { 
            copy.map((item, i) => {
                return (
                    <React.Fragment key={`terms-${i}`}>
                        {item.title && item.title.text && <h2 data-generic-title className={classnames({[css[item.title.type]]: item.title.type, [css.h2]: !item.title.type})} dangerouslySetInnerHTML={{__html: item.title.text}} />}
                        {item.text.map((text, idx) => <p data-generic-paragraph key={`p-${idx}`} className={css.p} dangerouslySetInnerHTML={{__html: text}} />)}
                    </React.Fragment>
                )
            })
        }
    </div>
}

GenericText.propTypes = {
    copy: PropTypes.array
}

export default GenericText