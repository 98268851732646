import React, { useState, useEffect } from "react";
import { Route } from "react-router-dom";
import { AuthConsumer } from "./authProvider";
import { GenericModal } from "../modals"
import { useDispatch } from "react-redux";
import { stopEmulatingUserSaga } from "../redux/actions/emulateActions/emulate";

const CheckAuthenticated = (props) => {
    const { isAuthenticated, signinRedirect } = props;
    const dispatch = useDispatch();
    const [signedIn, setSignedIn] = useState(false);

    useEffect(() => {
        async function init() {            
            if ((await isAuthenticated())) {
                setSignedIn(true);
            } else {
                dispatch(stopEmulatingUserSaga());
                signinRedirect();
            }
        }
        init();
    }, []);

    return (
        <>
            {signedIn
                ? props.children
                : <GenericModal isBusy={true} loadingText="Loading..." />
            }
        </>
    );
}

const PrivateRoute = ({ component, ...rest }) => {
    const renderFn = (Component) => (props) => (
        <AuthConsumer>
            {({ isAuthenticated, signinRedirect, getAccessToken }) => {
                return (
                    <CheckAuthenticated isAuthenticated={isAuthenticated} signinRedirect={signinRedirect}>
                        <Component getAccessToken={getAccessToken} {...props} />
                    </CheckAuthenticated>
                );
            }}
        </AuthConsumer>
    );

    return <Route {...rest} component={renderFn(component)} />;
};

export default PrivateRoute;