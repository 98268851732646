import React from 'react';
import PropTypes from 'prop-types';
import { isFeatureEnabled } from '../../utils/features';

const FeatureToggler = ({ children, featureName }) => {
  return (
    <>
      {isFeatureEnabled(featureName) && (
        <>
          { children }
        </>
      )}
    </>
  );
};

FeatureToggler.propTypes = {
  children: PropTypes.node.isRequired,
  featureName: PropTypes.node.isRequired,
};

export default FeatureToggler;
