import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Accordion, Row, Col } from "react-bootstrap";
import AuthCheck from "../../components/authcheck/authCheck";
import authState from "../../components/authcheck/authState";
import Spinner from "../../components/spinner";
import CaseSummaryCard from "./components/CaseSummaryCard";
import AccordionQuoteBreakdownItem from './components/AccordionQuoteBreakdownItem';
import AccordionLawFirmItem from './components/AccordionLawFirmItem';
import AccordionCaseActivity from './components/AccordionCaseActivity';

import css from "./index.module.css";
import AccordionItemWrapper from "../../components/AccordionItems/AccordionItemWrapper";
import { useSelectApi } from "../../hooks/selectApi";
import Layout from '../../layout';

const CaseDetail = () => {
  const { caseId } = useParams();
  const { getCase } = useSelectApi();

  const [isLoading, setIsLoading] = useState(true);
  const [instructedCase, setInstructedCase] = useState();

  useEffect(() => {
    async function retrieveCase() {
      const result = await getCase({ caseId });
      setInstructedCase(result);
      setIsLoading(false);
    }

    retrieveCase();
  }, [caseId])


  if (isLoading) {
    return (
      <Layout classname={css.viewCaseSummaryContainer}>
        <AuthCheck requiredAuthState={authState.AUTHENTICATED} />
        <Row>
          <Col md={1} xs={1}></Col>
          <Col md={10} xs={10} className={css.loading}>
            <Spinner variant={css.spinner} /> Loading case details...
          </Col>
          <Col md={1} xs={1}></Col>
        </Row>
      </Layout>
    );
  }
  else if (instructedCase) {
    return (
      <Layout classname={css.viewCaseSummaryContainer}>
        <AuthCheck requiredAuthState={authState.AUTHENTICATED} />
        <Row>
          <Col md={1} xs={1}></Col>
          <Col md={10} xs={10} className={css.pageHeader}>
            VIEW YOUR CASE PROGRESS
          </Col>
          <Col md={1} xs={1}></Col>
        </Row>
        <Row>
          <Col md={1} xs={1}></Col>
          <Col md={10} xs={10}>
            <CaseSummaryCard oneCase={instructedCase} />
          </Col>
          <Col md={1} xs={1}></Col>
        </Row>

        <Row>
          <Col md={1} xs={1}></Col>
          <Col md={10} xs={10}>
            <Accordion className={css.accordion}>
              <AccordionItemWrapper
                id="quoteBreakdown"
                headerText="QUOTE BREAKDOWN"
                eventKey="0"
                variant="caseDetail"
              >
                <AccordionQuoteBreakdownItem
                  oneCase={instructedCase}
                  eventKey="0"
                />
              </AccordionItemWrapper>
              <AccordionItemWrapper
                id="lawFirmDetails"
                headerText="LAW FIRM DETAILS"
                eventKey="1"
                variant="caseDetail"
              >
                <AccordionLawFirmItem
                  oneCase={instructedCase}
                  eventKey="1"
                />
              </AccordionItemWrapper>
              <AccordionItemWrapper
                id="caseActivity"
                headerText="CASE ACTIVITY"
                eventKey="2"
                variant="caseDetail"
              >
                <AccordionCaseActivity
                  oneCase={instructedCase}
                  eventKey="2"
                />
              </AccordionItemWrapper>
            </Accordion>
          </Col>
          <Col md={1} xs={1}></Col>
        </Row>
      </Layout>
    );
  }
  else {
    return (
      <Layout classname={css.viewCaseSummaryContainer}>
        <AuthCheck requiredAuthState={authState.AUTHENTICATED} />
        <Row>
          <Col md={1} xs={1}></Col>
          <Col md={10} xs={10} className={css.pageHeader}>
            VIEW YOUR CASE PROGRESS
          </Col>
          <Col md={1} xs={1}></Col>
        </Row>
        <Row>
          <Col md={1} xs={1}></Col>
          <Col md={10} xs={10} className={css.noDetails}>
            No details can be found for this case.
          </Col>
          <Col md={1} xs={1}></Col>
        </Row>
      </Layout>
    );
  }
};

export default CaseDetail;
