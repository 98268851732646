import React from "react";
import Form from "react-bootstrap/Form";
import css from "./dropdownselector.module.css";
import { useSelector } from "react-redux";
import store from "../../../redux/store";
import classnames from "classnames";
import PropTypes from "prop-types";

const DropdownSelector = ({
  id,
  datatestid,
  options,
  groupname,
  persistAction,
  selector,
  errorMessage,
  storePath,
  selectedState,
}) => {
  const dropdownValue = useSelector(selector);
  const error = useSelector(errorMessage);
  const setValue = (value) => store.dispatch(persistAction(value, storePath));
  return (
    <>
      <Form>
        <Form.Group className={css.flexcontainer}>
          <div className={classnames(css.flexitem, "dropdown")}>
            <Form.Control
              as="select"
              placeholder="Please select"
              onChange={(e) => setValue(e.currentTarget.value)}
              value={dropdownValue || options[0]}
            >
              {options.map((dropdown, idx) => (
                <option
                  className={classnames(
                    css.dropdownfield,
                    "btn btn-outline-secondary btn-lg btn-block dropdown-toggle text-left"
                  )}
                  id={`${id}-${dropdown.value}`}
                  data-testid={`${datatestid}-${dropdown.value}`}
                  key={idx}
                  type="dropdown"
                  variant="secondary"
                  name={groupname}
                  value={dropdown.value}
                >
                  {dropdown.name}
                </option>
              ))}
            </Form.Control>
          </div>
        </Form.Group>
      </Form>
      <span className={css.error}> {error}</span>
    </>
  );
};

DropdownSelector.propTypes = {
  id: PropTypes.string.isRequired,
  datatestid: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
  groupname: PropTypes.string,
  persistAction: PropTypes.func,
  selector: PropTypes.func,
  errorMessage: PropTypes.func,
};

export default DropdownSelector;
