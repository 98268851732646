import { put, takeLatest, fork } from "redux-saga/effects";
import { NOTIFICATION_SHOW } from "../../actions/site-wide-actions"
import { stopEmulatingUser as notification } from "../../../data/notifications"
import { SITE_ERROR, STOP_EMULATING_USER_SAGA, STOP_EMULATING_USER, RESET_STORE } from "../../actions/types";
import { removeEmulateHeader } from "../../../utils/axiosHeaders";

function* stopEmulatingUser() {
  try {
    removeEmulateHeader();
    yield put({ type: RESET_STORE });
    yield put({ type: STOP_EMULATING_USER });
  } catch (e) {
    yield put({ type: SITE_ERROR, payload: true });
    yield put(NOTIFICATION_SHOW.action({ show: true, message: notification.negative, type: "negative" }));
  }
}

function* saga() {
  yield takeLatest(STOP_EMULATING_USER_SAGA, stopEmulatingUser);
}

const stopEmulatingUserSaga = fork(saga);

export { stopEmulatingUserSaga as default, stopEmulatingUser, saga };
