import React from "react";
import PropTypes from "prop-types";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import NumberFormat from "react-number-format";
import { useSelector, useDispatch } from "react-redux";
import css from "./currencyInput.module.css";

const CurrencyInput = ({
  id,
  datatestid,
  cssclass,
  placeholder,
  persistaction,
  errorMessage,
  isallowed,
  format,
  value,
  storePath,
  onChange = () => {},
}) => {
  const dispatch = useDispatch();
  const setValue = (value) => {
    if (!persistaction) return;
    if (value.length <= 0) return;
    dispatch(persistaction(value, storePath));
  };
  const error = useSelector(errorMessage);

  return (
    <>
      <div className={css.flexcontainer}>
        <div className={css.flexitem}>
          <InputGroup size="lg">
            <InputGroup.Prepend>
              <InputGroup.Text className={cssclass}>&#xa3;</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              as={NumberFormat}
              id={id}
              className={cssclass}
              data-testid={datatestid}
              placeholder={placeholder}
              decimalScale={2}
              thousandSeparator={true}
              value={value}
              aria-label="CurrencyInput"
              onBlur={(e) => setValue(e.currentTarget.value)}
              isAllowed={isallowed}
              format={format}
              onChange={onChange}
            />
          </InputGroup>
        </div>
      </div>
      <span className={css.error}> {error}</span>
    </>
  );
};

CurrencyInput.propTypes = {
  id: PropTypes.string.isRequired,
  datatestid: PropTypes.string.isRequired,
  cssclass: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  persistaction: PropTypes.func,
  errorMessage: PropTypes.func,
  isallowed: PropTypes.func,
  format: PropTypes.string,
  defaultvalue: PropTypes.number,
};

export default CurrencyInput;
