import React, { forwardRef, useState } from "react";
import PropTypes from "prop-types";
import Dropdown from "react-bootstrap/Dropdown";
import FormControl from "react-bootstrap/FormControl";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import css from "./searchable-dropdown.module.css";
import commonCss from "../../common/styles.module.css";
import { Search } from "react-bootstrap-icons";

// eslint-disable-next-line react/display-name
const CustomToggle = forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    className={css.customToggle}
  >
    {children}
  </a>
));

CustomToggle.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
};

// eslint-disable-next-line react/display-name
const CustomMenu = forwardRef(
  ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    const [value, setValue] = useState("");

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <FormControl
          autoFocus
          className={css.filterInput}
          placeholder="Type to filter..."
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            (child) =>
              !value || child.props.children.toLowerCase().startsWith(value.toLowerCase())
          )}
        </ul>
      </div>
    );
  }
);

CustomMenu.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
  "aria-labelledby": PropTypes.string,
};

const SearchableDropdown = ({
  id,
  label,
  value,
  list,
  onChange,
  placeholder,
  menuClassName,
  errorMessages,
  disabled = false
}) => {
  return (
    <div className={css.container}>
      <Form.Label htmlFor={id} className={css.label}>
        {label}
      </Form.Label>
      <Dropdown className={css.dropdown}>
        <Dropdown.Toggle as={CustomToggle} id={id}>
          <InputGroup>
            <InputGroup.Prepend className={css.toggleButton}>
              <Search />
            </InputGroup.Prepend>
            <div className={css.toggleButtonLabel}>
              {list.find((i) => i.value === value)?.name ?? placeholder}
            </div>
          </InputGroup>
        </Dropdown.Toggle>

        <Dropdown.Menu as={CustomMenu} className={menuClassName}>
          {list.map((i) => (
            <Dropdown.Item
              key={i.value}
              value={i.value}
              onClick={() => onChange(i.value)}
              disabled={disabled}
            >
              {i.name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      {errorMessages?.map((e, idx) => 
        {if(e !== null) 
        return <div
          key={idx}
          className={`${commonCss.error} ${css.error}`}
        >{`* ${e}`}</div>
        })}
    </div>
  );
};

SearchableDropdown.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      name: PropTypes.string,
    })
  ),
  onChange: PropTypes.func,
  label: PropTypes.string,
  menuClassName: PropTypes.string,
  placeholder: PropTypes.string,
  errorMessages: PropTypes.arrayOf(PropTypes.string),
};

export default SearchableDropdown;
