import React from "react";

// presentation
import classnames from "classnames";
import css from "../quote.module.css";
import { Row, Col } from "react-bootstrap";

//components
import CurrencyInput from "../../../components/currencyinput/currencyinput";

const Price = ({
  title,
  id,
  datatestid,
  placeholder,
  persistaction,
  errorMessage,
  format,
  value,
  storePath,
  onChange,
}) => {
  return (
    <>
      <Row className={css.label}>
        <Col
          md={12}
          xs={12}
          className={classnames(css.leftalign, css.leftpad2x)}
        >
          {title}
        </Col>
      </Row>
      <Row>
        <Col
          md={12}
          xs={12}
          className={classnames(css.leftalign, css.leftpad2x)}
        >
          <CurrencyInput
            id={id}
            datatestid={datatestid}
            cssclass={css.input}
            placeholder={placeholder}
            persistaction={persistaction}
            errorMessage={errorMessage}
            storePath={storePath}
            value={value}
            isallowed={(value) => {
              const { floatValue } = value;
              return floatValue == null || Number.isInteger(floatValue);
            }}
            format={format}
            onChange={onChange}
          />
        </Col>
      </Row>
    </>
  );
};

export default Price;
