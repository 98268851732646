import React, { useState } from "react";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useHistory } from "react-router-dom";

import GenericModal from "../../../../modals/GenericModal/GenericModal";
import css from "./save-quote-modal.module.css";
import LabelledTextInput from "../../../common/components/labelled-text-input";
import LabelledTextarea from "../../../common/components/labelled-textarea";
import ValidationGroup from "../../../../components/validation-group";
import Address from "../../../../components/address";
import _ from "lodash";
import { useSelectApi } from "../../../../hooks/selectApi";
import { mapAddress } from "../../../../mappers/quote";
import { isBlank } from "../../../../utils/jsutils";

const SaveQuoteModal = ({
  show,
  onHide,
  quoteIds,
  quoteGroupId,
  insuranceReference,
  insurancePremium,
  actionButtonText = "SAVE QUOTE",
}) => {
  const defaultValue = { value: '', validationMessage: '' };
  const [firstName, setFirstName] = useState(_.cloneDeep(defaultValue));
  const [surname, setSurname] = useState(_.cloneDeep(defaultValue));
  const [correspondenceAddress, setCorrespondenceAddress] = useState();
  const [quoteName, setQuoteName] = useState(_.cloneDeep(defaultValue));
  const [notes, setNotes] = useState(_.cloneDeep(defaultValue));
  const [submitted, setSubmitted] = useState(false);
  const [validated, setValidated] = useState(false);

  const { saveQuote } = useSelectApi();
  const history = useHistory();

  const updateField = (get, set, value) => set({ ...get, validationMessage: null, value });
  const updateFieldValidation = (get, set, value) => set({ ...get, validationMessage: value });

  const validate = () => {
    setValidated(true);
    let isValid = true;

    if (isBlank(firstName?.value)) {
      updateFieldValidation(firstName, setFirstName, "You must supply a first name");
      isValid = false;
    }

    if (isBlank(surname?.value)) {
      updateFieldValidation(surname, setSurname, "You must supply a surname");
      isValid = false;
    }

    if (!correspondenceAddress.isValid) isValid = false;

    return isValid;
  }

  const validationHandler = (isValid) => setCorrespondenceAddress({ ...correspondenceAddress, isValid });

  const handleSubmit = async () => {
    if (!validate()) {
      setSubmitted(false);
      return;
    }

    setSubmitted(true);
    let success = true;

    success = await saveQuote({
      quoteId: quoteIds[0],
      quoteGroupId,
      firstName: firstName?.value,
      surname: surname?.value,
      correspondenceAddress: mapAddress(correspondenceAddress),
      quoteName: quoteName?.value,
      notes: notes?.value,
      insuranceReference,
      insurancePremium
    });

    if (success && quoteIds.length > 1) {
      success = await saveQuote({
        quoteId: quoteIds[1],
        quoteGroupId,
        firstName: firstName?.value,
        surname: surname?.value,
        correspondenceAddress: mapAddress(correspondenceAddress),
        quoteName: quoteName?.value,
        notes: notes?.value,
        insuranceReference,
        insurancePremium
      });
    }

    if (success) history.push('/view-quotes');
    else setSubmitted(false);
  }

  return (
    <GenericModal
      show={show}
      onHide={onHide}
      heading="SAVE QUOTE"
      contentClassName={css["dialog-content"]}
      dialogClassName={css["dialog"]}
      secondaryButtonText="CANCEL"
      primaryButtonText={actionButtonText}
      isActionLoading={submitted}
      onClickValue={() => handleSubmit()}
    >
      <Row className={css["dialog-body"]}>
        <Col>
          <Row>
            <p className={css["text-primary"]}>
              {
                "Add your customer's details to save the quote. You can also give it a name to help you find it later."
              }
            </p>
          </Row>
          <Row>
            <Form className={css["save-quote-form"]}>
              <Form.Group controlId="customerFirstName">
                <LabelledTextInput
                  label="Customer first name"
                  placeholder="First name"
                  onChange={(value) => updateField(firstName, setFirstName, value)}
                  value={firstName?.value}
                  errorMessages={firstName?.validationMessage ? [firstName.validationMessage] : []}
                />
              </Form.Group>
              <Form.Group controlId="customerSurname">
                <LabelledTextInput
                  label="Customer surname"
                  placeholder="Surname"
                  onChange={(value) => updateField(surname, setSurname, value)}
                  value={surname?.value}
                  errorMessages={surname?.validationMessage ? [surname.validationMessage] : []}
                />
              </Form.Group>
              <ValidationGroup submitted={validated} validationHandler={validationHandler}>
                <Address
                  label="Correspondence address"
                  onChange={(value) => { setCorrespondenceAddress(value) }}
                  initialState={correspondenceAddress?.value}
                />
              </ValidationGroup>
              <Form.Group controlId="quoteName">
                <LabelledTextInput
                  label="Quote name"
                  placeholder="Quote name"
                  infoText="(optional - if no name is given we’ll title it surname + postcode)"
                  datatestid="quoteName"
                  onChange={(value) => updateField(quoteName, setQuoteName, value)}
                  value={quoteName?.value}
                />
              </Form.Group>
              <Form.Group controlId="notes">
                <LabelledTextarea
                  label="Notes"
                  placeholder="Add your note"
                  infoText="(optional)"
                  datatestid="notes"
                  onChange={(value) => updateField(notes, setNotes, value)}
                  value={notes?.value}
                />
              </Form.Group>
            </Form>
          </Row>
        </Col>
      </Row>
    </GenericModal>
  );
};

SaveQuoteModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  caseType: PropTypes.string,
  quoteIds: PropTypes.arrayOf(PropTypes.string),
  quoteGroupId: PropTypes.string,
  storePath: PropTypes.string,
  actionButtonText: PropTypes.string
};

export default SaveQuoteModal;
