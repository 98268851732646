import React, { useContext, useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import css from "./preferences.module.css";
import AuthCheck from "../../components/authcheck/authCheck";
import authState from "../../components/authcheck/authState";
import PreferenceCard from "../../components/preferencecard/preferencecard";
import PricingCard from "../../components/preferencecard/content/pricingcard/pricingcard";
import ContactCard from "../../components/preferencecard/content/contactcard/contactcard";
import JurisdictionCard from "../../components/preferencecard/content/jurisdictioncard/jurisdictioncard";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "../../components/button/button";
import classnames from "classnames";
import { ReactComponent as ManagePricing } from "../../components/shared/imgs/manage-pricing-icon.svg";
import { ReactComponent as UinsureLogo } from "../../components/shared/imgs/uinsure-logo.svg";
import { ReactComponent as ManageContactDetails } from "../../components/shared/imgs/manage-contact-details-icon.svg";
import { ReactComponent as ManageRegion } from "../../components/shared/imgs/manage-region-icon.svg";
import User from "../../components/user/user";
import { AuthContext } from "../../utils/authProvider";
import { validatePreferences } from "../../redux/actions/actions";
import PropTypes from "prop-types";
import store from "../../redux/store";
import UinsureOptionsCard from "../../components/preferencecard/content/uinsureoptionscard/uinsureoptionscard";
import SortOrderCard from "../../components/preferencecard/content/sortordercard/sortordercard";
import LinkUinsureModal from "./linkUinsureModal";
import Spinner from "../../components/spinner";
import Layout from '../../layout';

const Preferences = ({ getAccessToken }) => {
  const { getUser } = useContext(AuthContext);

  const handleHideLinkUinsureModal = () => {
    // clearValidationErrors()
    setShowLinkUinsureModal(false)
  }

  const handleLinkUinsureModal = () => {
    setShowLinkUinsureModal(true)
    // urlType.current = 1
  }

  let [isBroker, setisBroker] = useState(false);
  const [showLinkUinsureModal, setShowLinkUinsureModal] = useState(false)

  const uinsureConnected = useSelector((store) => store.preferences.uinsure.uinsureLinked?.value) ?? false;
  const isEmulating = useSelector((store) => store.emulateUser.startEmulating);
  const isUinsureBlocked = useSelector((store) => store.preferences.uinsure?.blockedByNetwork)
  const loading = useSelector((store) => !store.preferences?.loaded?.value);

  useLayoutEffect(() => {
    async function init() {
      let user = await getUser();

      if (user.profile.roles.includes("BROKER_USER.PAGE"))
        setisBroker(true);
    }
    init();
  }, []);
  
  if (!isEmulating && !isBroker) {
    return <Layout>
    <AuthCheck requiredAuthState={authState.AUTHENTICATED} />
    <Row>
      <Col md={1} xs={1}></Col>
      <Col md={10} xs={10} className={css.loading}>
        No preferences to load
      </Col>
      <Col md={1} xs={1}></Col>
    </Row>
  </Layout>
  }
  if (loading) {
    return (
      <Layout>
        <AuthCheck requiredAuthState={authState.AUTHENTICATED} />
        <Row>
          <Col md={1} xs={1}></Col>
          <Col md={10} xs={10} className={css.loading}>
            <Spinner variant={css.spinner} /> Loading your preferences...
          </Col>
          <Col md={1} xs={1}></Col>
        </Row>
      </Layout>
    )
  }

  return (
    <Layout>
      <AuthCheck requiredAuthState={authState.AUTHENTICATED} />
      <Row className={css.bottompad2x}>
        <Col xl={1} xs={1}></Col>
        <Col xl={10} xs={10}>
          <Row>
            <Col xl={6} xs={12}>
              <div className={css.title}>
                HELLO<User id="user" datatestid="user" userClass={css.user} />,
                LET&lsquo;S FLEX!
              </div>
            </Col>
            <Col xl={6} xs={12}></Col>
          </Row>
          <Row>
            <Col xl={6} xs={12}>
              <div className={css.subtitle}>
                What would you like to customise?
              </div>
            </Col>
            <Col xl={6} xs={12}></Col>
          </Row>
          {isBroker && (
            <Row className={css.bottompad2x}>
              <Col xl={6} xs={12} className={css.leftalign}>
                <PreferenceCard
                  id="pricing-card"
                  datatestid="pricing-card"
                  title="YOUR PRICING"
                  image={
                    <ManagePricing
                      className={classnames(css.flexitem, css.cardimage)}
                    />
                  }
                  content={
                    <PricingCard
                      id="pricing-card-content"
                      datatestid="pricing-card-content"
                      inputclass={css.input}
                      errorclass={css.error}
                      placeholder="Amount"
                      label="Default referral fee (£300 maximum)"
                      errormessage={() => ""}
                    />
                  }
                />
              </Col>
              <Col xl={6} xs={12} className={css.leftalign}>
                <PreferenceCard
                  id="contact-card"
                  datatestid="contact-card"
                  title="YOUR CONTACT DETAILS"
                  image={
                    <ManageContactDetails
                      className={classnames(css.flexitem, css.cardimage)}
                    />
                  }
                  content={
                    <ContactCard
                      id="contact-card-content"
                      datatestid="contact-card-content"
                      inputclass={css.input}
                      placeholder={["Email Address", "Mobile Number"]}
                      errorclass={css.error}
                    />
                  }
                />
              </Col>
            </Row>
          )}
          {isBroker && (
            <Row className={css.bottompad2x}>
              <Col xl={6} xs={12} className={css.leftalign}>
                <PreferenceCard
                  id="region-card"
                  datatestid="region-card"
                  title="YOUR DEFAULT REGION FOR NEW QUOTES"
                  image={
                    <ManageRegion
                      className={classnames(css.flexitem, css.cardimage)}
                    />
                  }
                  content={
                    <JurisdictionCard
                      id="jurisdiction-card-content"
                      datatestid="jurisdiction-card-content"
                    />
                  }
                />
              </Col>
            </Row>
          )}
        </Col>
        <Col xl={1} xs={1}></Col>
      </Row>

      {isBroker && (
        <Row className={classnames(css.bottompad4x, css.yourlawfirms)}>
          <Col xl={1} xs={1}></Col>
          <Col xl={10} xs={10}>
            <Row>
              <Col md={12} xs={12} className={css.lawfirmstitle}>
                YOUR LAW FIRMS
              </Col>
            </Row>
            <Row>
              <Col md={12} xs={12}>
                <div className={css.lawfirmstext}>
                  Select the default sort order (this is the order in which your
                  law firms will be displayed)
                </div>
                <Row>
                  <Col md="auto">
                    <SortOrderCard id="sortorder" datatestid="sortorder" />
                  </Col>
                  <Col md="auto" className={css.toppad15x}>
                    <p>This feature will be coming soon</p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col xl={1} xs={1}></Col>
        </Row>
      )}
      {isBroker && (
        <Row className={css.bottompad2x}>
          <Col xl={1} xs={1}></Col>
          <Col xl={10} xs={10}>
            <Row className={classnames(css.leftalign)}>
              <Col md={8} xs={12} className={css.uinsuretitle}>
                INTERESTED IN GETTING A HOME INSURANCE QUOTE TOO?
              </Col>
              <Col md={2} xs={12}></Col>
              <Col md={2} xs={12} className={css.uinsurelogo}>
                <UinsureLogo />
              </Col>
            </Row>

            {isUinsureBlocked && (
              <Row className={classnames(css.leftalign, css.bottompad1x)}>
                <Col md={12} xs={12} className={css.error}>
                  Your network does not permit access to this feature
                </Col>
              </Row>
            )}

            <Row className={classnames(css.leftalign, css.bottompad1x)}>
              <Col md={12} xs={12} className={css.uinsuretext}>
                LMS have partnered with Uinsure to provide home insurance quotes
                for your customer.
              </Col>
            </Row>

            <Row className={classnames(css.leftalign, css.bottompad2x)}>
              <Col md={12} xs={12} className={css.uinsuretext}>
                If this is something you would like to offer, set your
                preferences below:
              </Col>
            </Row>

            <Row className={classnames(css.leftalign, css.bottompad2x)}>
              <Col md={12} xs={12} className={css.uinsuretext}>
                Don&apos;t have an account?  <a href="https://uinsure.co.uk/register" rel="noreferrer" target="_blank">Register with Uinsure</a>
              </Col>
            </Row>

            <Row className={classnames(css.leftalign, css.bottompad2x)}>
              <Col md={12} xs={12} className={css.uinsuretext}>
                <Button
                  id="LinkUinsure"
                  datatestid="LinkUinsure"
                  type="button"
                  btnClass={css.linkuinsure}
                  size="lg"
                  variant="primary"
                  onClick={() => handleLinkUinsureModal()}
                  text={uinsureConnected ? "UPDATE YOUR UINSURE ACCOUNT" : "CONNECT YOUR UINSURE ACCOUNT"}
                  disabled={isUinsureBlocked}
                />
                <LinkUinsureModal
                  getAccessToken={getAccessToken}
                  show={showLinkUinsureModal}
                  onHide={() => handleHideLinkUinsureModal()}
                  actionButtonText="LINK ACCOUNT"
                />
              </Col>
            </Row>

            <Row className={css.leftalign}>
              <Col md={12} xs={12} className={css.uinsuretext}>
                <UinsureOptionsCard
                  id="uinsureoptions"
                  connected={uinsureConnected}
                  datatestid="uinsureoptions"
                  disabled={isUinsureBlocked}
                />
              </Col>
            </Row>
          </Col>
          <Col xl={1} xs={1}></Col>
        </Row>
      )}
      <Row className={css.bottompad2x}>
        <Col xl={1} xs={1}></Col>
        <Col xl={10} xs={10} className={css.inlineflex}>
          <Button
            id="save_preferences"
            datatestid="save_preferences"
            btnClass={css.savepreferences}
            onClick={() => {
              getAccessToken().then(() => {
                store.dispatch(validatePreferences());
              });
            }}
            type="button"
            text="SAVE PREFERENCES"
            disabled={isEmulating}
          />
        </Col>
        <Col xl={1} xs={1}></Col>
      </Row>
    </Layout>
  );
};

Preferences.propTypes = {
  getAccessToken: PropTypes.func.isRequired,
};

export default Preferences;
