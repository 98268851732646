import { useLayoutEffect, useContext } from 'react';
import { AuthContext } from "../../utils/authProvider";
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types';
import authState from './authState';

const AuthCheck = ({requiredAuthState}) => {
   
    const { isAuthenticated, persistRequestedUri, getAccessToken } = useContext(AuthContext); 
    const history = useHistory(); 

    useLayoutEffect(() => {
        async function init() {           
            let authenticated = await isAuthenticated();            
    
            if (!authenticated && requiredAuthState == authState.AUTHENTICATED) {
                persistRequestedUri();
                getAccessToken();
            }

            if (authenticated && requiredAuthState == authState.UNAUTHENTICATED)
                history.push("/dashboard");      
        }
        init();
      }, []);

    return null;    
};

AuthCheck.propTypes = {
    requiredAuthState: PropTypes.string
  };



export default AuthCheck;
