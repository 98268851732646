import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../GenericModal/GenericModal'

const ErrorModal = ({ errorMessages, onErrorOkClick }) => {
    if (errorMessages.length === 0) {
        return null;
    }

    return (
        <Modal>
            <div >
                <h1>Cannot load config</h1>
            </div>
        </Modal>
    );
};

ErrorModal.propTypes = {
    errorMessages: PropTypes.arrayOf(PropTypes.string),
    onErrorOkClick: PropTypes.func
};

ErrorModal.defaultProps = {
    errorMessages: [],
};


export default ErrorModal;
