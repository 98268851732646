import React from "react";
import { AuthConsumer } from "../utils/authProvider";

const RenewOidc = () => (
    <AuthConsumer>
        {({ signinSilentCallback }) => {
            signinSilentCallback();
            return <></>;
        }}
    </AuthConsumer>
);

export default RenewOidc;