import { all } from 'redux-saga/effects';
import validationSaga from './validationSaga/validationSaga';
import preferenceSaga from './preferenceSaga/preferenceSaga';
import quoteSaga from './quoteSaga/quoteSaga';
import instructQuoteSaga from './instructQuote';
import { getEmulateUser, startEmulatingUser, stopEmulatingUser } from './emulateSagas';
import { getUinsureQuoteSaga, getUinsureRedirectSaga, linkUinsureSaga, sendUinsureLeadSaga, updateUinsureClientSaga } from './uinsureSagas';
import lendersSaga from './lendersSaga/lendersSaga';

const sagas = [
  validationSaga,
  quoteSaga,
  preferenceSaga,
  instructQuoteSaga,
  linkUinsureSaga,
  getUinsureQuoteSaga,
  getUinsureRedirectSaga,
  sendUinsureLeadSaga,
  updateUinsureClientSaga,
  getEmulateUser,
  startEmulatingUser,
  stopEmulatingUser,
  lendersSaga,
];

function* rootSaga() {
  yield all(sagas);
}

export default rootSaga;
