import PropTypes from "prop-types";
import css from "./index.module.css";
import React, { useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { Completed, Pending, UInsureFull } from "../../components/shared/imgs";
import classnames from "classnames";
import Button from "../../components/button/button";
import commonCss from "../../pages/common/styles.module.css";
import { getUinsureRedirect } from "../../redux/actions/actions";
import { AuthContext } from "../../utils/authProvider";


const InsuranceConfirmation = ({
  customerName
}) => {
  const quoteReference = useSelector((store) => store.uinsure?.selectedQuote);
  const quoteType = useSelector((store) => store.instruct.insurance?.processType?.value);
  const busy = useSelector((store) => store.site.siteBusy)

  const { getAccessToken } = useContext(AuthContext);
  const dispatch = useDispatch();

  const handleCompleteClick = () => {
    getAccessToken().then(() => {
      dispatch(getUinsureRedirect());
    })
  }

  const handleRedirectClick = () => {
    window.open('https://quotes.uinsure.co.uk/', '_blank');
  }

  const Banner = () => {
    if (quoteType === "SendLead") {
      return (
        <>
          <Col xs={2} sm={2} xl={1}>
            <span className={css.icon}>
              <Completed data-testid="icon-completed" />
            </span>
          </Col>
          <Col xs={8} className={classnames(css.leftalign)}>
            <p className={classnames(css.flex, css.center, css.contentText)}>Your Home Insurance quote for {customerName} has been sent to Uinsure.
              <br />Your referral reference is {quoteReference}
              <br /><br />All Uinsure quotes are automatically saved in your Uinsure portal and can be accessed later.</p>
          </Col>
          <Col className={classnames(css.flex, css.center)}>
            <Button
              id="InstructGeneralInsurance"
              datatestid="track-insurance"
              type="button"
              btnClass={commonCss.button}
              size="lg"
              variant="primary"
              onClick={handleRedirectClick}
              text="Track Progress"
            />
          </Col>
        </>
      )
    } else {
      return (
        <>
          <Col xs={2} sm={2} xl={1}>
            <span className={css.icon}>
              <Pending data-testid="icon-pending" />
            </span>
          </Col>
          <Col xs={6} className={classnames(css.leftalign)}>
            <p className={classnames(css.flex, css.center, css.contentText)}>Your Home Insurance quote for {customerName} has been saved in your Uinsure account.
              <br /><br />Your quote reference is {quoteReference}</p>
          </Col>
          <Col xs={{ offset: 2 }} className={classnames(css.flex, css.center)}>
            <Button
              id="InstructGeneralInsurance"
              datatestid="complete-insurance"
              type="button"
              btnClass={classnames(commonCss.button, css.alignright)}
              size="lg"
              variant="primary"
              onClick={handleCompleteClick}
              text="COMPLETE"
              isLoading={busy}
              disabled={busy}
            />
          </Col>
        </>
      )
    }
  }

  const Logo = () => {
    return (
      <UInsureFull className={css.logo} />
    )
  }

  const ContactDetails = () => {
    return (
      <>
        <div className={css.contentText}>
          <p>www.uinsure.co.uk</p>
          <p>0344 844 3844</p>
          <p>Our lines are open Monday - Friday 9.00am - 5.00pm</p>
        </div>
      </>
    )
  }

  return (
    <Row data-testid="gi-row">
      <Col xs={{ span: 10, offset: 1 }}>
        <Card.Header className={css.box}>
          <Row>
            <Banner />
          </Row>
        </Card.Header>
        <Card.Body>
          <Row>
            <Logo />
          </Row>
          <Row className={css.toppad2x}>
            <Col>
              <ContactDetails />
            </Col>
          </Row>
        </Card.Body>
      </Col>
    </Row>
  )
};

InsuranceConfirmation.propTypes = {
  customerName: PropTypes.string.isRequired
};

export default InsuranceConfirmation;
