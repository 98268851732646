import React, { useContext } from 'react';
import axios, { AxiosError } from 'axios';
import { useHistory } from 'react-router-dom';
import { AuthContext } from "../utils/authProvider";
import { useConfiguration } from '../context/configuration-context';
import { ISelectApiOptions } from '../types/select-api/ISelectApiOptions';
import { useNotifications } from '../hooks/notifications';
import * as ResponseTypes from '../types/select-api/ResponseTypes';
import _ from 'lodash';

interface IHeaders extends Record<string, string> {
  Authorization: string,
}

interface IRequestConfig {
  headers: IHeaders,
}

export default function useSelectApi(options: ISelectApiOptions | undefined = undefined) {
  const { apiBaseAddress } = useConfiguration();
  const history = useHistory();
  const { getAccessToken } = useContext(AuthContext);
  const { showError } = useNotifications();

  if (!apiBaseAddress) {
    console.error('ERROR: Cannot set API base address');
  }

  const authenticate = async (): Promise<IRequestConfig> => {
    const accessToken = await getAccessToken();
    return {
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : '',
      },
    };
  };

  const handleUnauthorised = () => {
    if (!options?.suppressErrorRedirect) {
      history.push('/no-auth');
    }
  };

  interface ISelectApiError {
    title?: string,
    detail?: string,
  }

  const showErrorMessage = (e: AxiosError<ISelectApiError>) => {
    if (!options?.suppressErrorMessages) {
      if (e.response?.data?.detail) {
        showError(e.response.data.detail);
      } else {
        showError('An error occurred');
      }
    }
  };

  const execute = async <T>(fn: (cfg: IRequestConfig) => Promise<T>): Promise<T | boolean> => {
    try {
      const config = await authenticate();
      return await fn(config);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e?.response?.status === 401) {
          handleUnauthorised();
        } else {
          showErrorMessage(e);
        }
      } else {
        showError('An error occurred');
      }
    }

    return false;
  };

  const searchAddress = async (
    searchTerm: string,
  ): Promise<ResponseTypes.IAddressSearchResultItem[] | boolean> => execute(
    async (cfg): Promise<ResponseTypes.IAddressSearchResultItem[]> => {
      const url = `${apiBaseAddress}/addresses?searchTerm=${searchTerm}`;
      const { data } = await axios.get<ResponseTypes.IAddressSearchResultItem[]>(url, cfg);
      return data;
    },
  );

  const drillDownAddress = async (
    containerId: string,
  ): Promise<ResponseTypes.IAddressSearchResultItem[] | boolean> => execute(
    async (cfg): Promise<ResponseTypes.IAddressSearchResultItem[]> => {
      const url = `${apiBaseAddress}/addresses?containerid=${containerId}`;
      const { data } = await axios.get<ResponseTypes.IAddressSearchResultItem[]>(url, cfg);
      return data;
    },
  );

  const lookupAddress = async (
    addressId: string,
  ): Promise<ResponseTypes.IAddressGetResult | boolean> => execute(
    async (cfg): Promise<ResponseTypes.IAddressGetResult> => {
      const url = `${apiBaseAddress}/addresses/${addressId}`;
      const { data } = await axios.get<ResponseTypes.IAddressGetResult>(url, cfg);
      return data;
    },
  );

  return {
    searchAddress,
    drillDownAddress,
    lookupAddress,
  };
}
