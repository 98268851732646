import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import Button from '../../../components/button/button';
import css from '../dashboard.module.css';
import { EmulateUser } from '../../../components/shared/imgs';
import { ReactComponent as IconArrow } from '../../../components/shared/imgs/link-closed.svg';
import EmulateModal from './components/EmulateModal';
import { EmulateProvider } from './EmulateContext';

const EmulatePanel = () => {
  const [modalShow, setModalShow] = useState(false);
  return (
    <Row>
      <Col md="auto">
        <EmulateUser className={css.brokerIcon} />
      </Col>
      <Col md="auto">
        <p>Want to emulate a broker user?</p>
        <Button
          id="emulate-broker"
          datatestid="emulate-broker"
          btnClass={css.emulateButton}
          onClick={() => setModalShow(true)}
          type="button"
          text="CHOOSE A BROKER"
          icon={IconArrow}
          variant="link-arrow"
        />
      </Col>
      <EmulateProvider value={{ modalShow, setModalShow }}>
        <EmulateModal />
      </EmulateProvider>
    </Row>
  );
};

export default EmulatePanel;
