import { put, takeLatest, fork, select } from "redux-saga/effects";
import { NOTIFICATION_SHOW } from "../../actions/site-wide-actions"
import { startEmulatingUser as notification } from "../../../data/notifications"
import { SITE_ERROR, START_EMULATING_USER_SAGA, START_EMULATING_USER, RESET_STORE, GET_USER_SETTINGS } from "../../actions/types";
import { setEmulateHeader } from "../../../utils/axiosHeaders";

function* startEmulatingUser() {
  try {
    const emulatedUser = yield select((s) => s.emulateUser);
    const { username } = emulatedUser.user;
    setEmulateHeader(username);
    yield put({ type: RESET_STORE });
    yield put({ type: GET_USER_SETTINGS, payload: username })
    yield put({ type: START_EMULATING_USER, payload: true});
  } catch (e) {
    yield put({ type: SITE_ERROR, payload: true });
    yield put(NOTIFICATION_SHOW.action({ show: true, message: notification.negative, type: "negative" }));
  }
}

function* saga() {
  yield takeLatest(START_EMULATING_USER_SAGA, startEmulatingUser);
}

const startEmulatingUserSaga = fork(saga);

export { startEmulatingUserSaga as default, startEmulatingUser, saga };
