import React from "react";
import PropTypes from "prop-types";

import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";

import { Completed, Pending } from "../../../../components/shared/imgs";
import css from "./section-accordion.module.css";

const SectionAccordion = ({
  children,
  completed,
  completedIconClassName,
  heading,
  show = false,
}) => {
  return (
    <div className={css.accordionContainer}>
      <Accordion className={css.accordion}>
        <Card.Header className={css.accordionHeader}>
          <div className={css.headingAndIconContainer}>
            <div className={css.completedIcon}>
              {completed ? (
                <Completed
                  data-testid="Completed"
                  className={completedIconClassName}
                />
              ) : (
                <Pending data-testid="Pending" />
              )}
            </div>
            {heading}
          </div>
        </Card.Header>
        <Accordion.Collapse eventKey="0" in={show}>
          <Card.Body className={css.accordionBody}>{children}</Card.Body>
        </Accordion.Collapse>
      </Accordion>
    </div>
  );
};

SectionAccordion.propTypes = {
  children: PropTypes.node,
  completed: PropTypes.bool,
  completedIconClassName: PropTypes.string,
  heading: PropTypes.string,
  show: PropTypes.bool,
};

export default SectionAccordion;
