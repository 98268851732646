import * as actions from "../../../actions/instruct-actions/conveyance-address";

const initialState = {}

export default (state = initialState, action) => {
  const isRelatedQuote = action.isRelatedQuote
  const baseProp = isRelatedQuote ? "relatedQuote" : "quote"
 
  switch (action.type) {
    case actions.UPDATE_CONVEYED_ADDRESS.type:
      return {
          ...state,
          [baseProp]: action.payload
      }
  }

  return state;
};
