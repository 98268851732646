import { SITE_BUSY, SITE_ERROR } from "../actions/types";
import * as siteWideActions from "../actions/site-wide-actions";

const initialState = {
  siteBusy: false,
  siteError: false,
  notification: {
    message: "",
    show: false,
    type: ""
  }
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SITE_BUSY:
      return {
        ...state,
        siteBusy: action.payload,
      };

    case siteWideActions.SITE_BUSY.type:
      return {
        ...state,
        siteBusy: action.newBusyValue,
      };

    case siteWideActions.SITE_ERROR.type:
      return {
        ...state,
        lastSiteError: action.newError,
      };

    case SITE_ERROR:
      return {
        ...state,
        siteError: action.payload
      }
      
    case siteWideActions.NOTIFICATION_SHOW.type:
        return {
          ...state,
          notification: action.notificationShow
        }

    default:
      return state;
  }
}
