import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import css from "./referralfeeupdate.module.css";
import classnames from "classnames";
import ReferralFeeBespokePrice from "../referralfeebespokeprice/referralfeebespokeprice";
import { useSelector, useDispatch } from "react-redux";
import { getCheckedState } from "../../../utils/jsutils";

// components
import CheckboxSelector from "../../../components/selectors/checkbox/checkboxselector";

//data
import { setAsDefaultReferralFee } from "../../../components/shared/data";

// actions
import {
  storeQuoteUpdateDefaultReferralFee,
  validateBespokeReferralFee,
} from "../../../redux/actions/actions";

const ReferralFeeUpdate = ({ id, datatestid, getNewReferralFee }) => {
  const dispatch = useDispatch();
  const caseType = useSelector((store) => store.quote.type.value);
  const storePath = useSelector((store) => store.quote.storePath);
  const isSetDefaultReferralFeeChecked = getCheckedState(caseType, "updateDefaultReferralFee");

  const bespokeReferralFee = useSelector(
    (store) => store.quote[caseType].bespokeReferralFee.value
  );
  const defaultReferralFee = useSelector(
    (store) => store.preferences.defaultReferralFee.value
  );
  const referralFee = bespokeReferralFee
    ? bespokeReferralFee
    : defaultReferralFee;
  const [referralFeeValue, setReferralFeeValue] = useState(referralFee);

  useEffect(() => {
    getNewReferralFee(referralFeeValue);
  }, []);

  useEffect(() => {
    dispatch(validateBespokeReferralFee(referralFeeValue));
  }, [referralFeeValue]);

  const handleChange = (e) => {
    getNewReferralFee(e.target.value);
    setReferralFeeValue(e.target.value);
  };

  return (
    <>
      <div id={id} datatestid={datatestid}>
        <div className={css.modalText}>(Minimum £0, maximum £300)</div>

        <div className={classnames(css.modalText, css.spacer)}>
          <ReferralFeeBespokePrice
            title="Your fee"
            id="currency-input"
            datatestid={`currency-input-${datatestid}`}
            placeholder="Amount"
            storePath={storePath}
            value={referralFeeValue}
            onChange={(e) => handleChange(e)}
            errorclass={css.error}
            errorMessage={(store) =>
              store.quote[caseType].bespokeReferralFee.validation.message
            }
          />
        </div>
        <div className={css.modalText}>
          <CheckboxSelector
            id="set-fee-as-default"
            datatestid="set-fee-as-default"
            checkboxes={setAsDefaultReferralFee}
            persistaction={storeQuoteUpdateDefaultReferralFee}
            storePath={storePath}
            checkedState={isSetDefaultReferralFeeChecked}
          />
        </div>
      </div>
    </>
  );
};

ReferralFeeUpdate.propTypes = {
  id: PropTypes.string.isRequired,
  datatestid: PropTypes.string.isRequired,
  getNewReferralFee: PropTypes.func.isRequired,
};

export default ReferralFeeUpdate;
