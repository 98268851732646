import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import AppSwitcher from '../components/app-switcher';

const Layout = ({ children, classname }) => (
  <Container fluid className={classname}>
    <AppSwitcher />
    {children}
  </Container>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  classname: PropTypes.string,
};

Layout.defaultProps = {
  classname: null,
};

export default Layout;
