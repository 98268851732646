import React from "react";
import { AuthConsumer } from "../utils/authProvider";

const SigninOidc = () => (
    <AuthConsumer>
        {({ signinRedirectCallback }) => {
            signinRedirectCallback();
            return <h1>Redirecting...</h1>;
        }}
    </AuthConsumer>
);

export default SigninOidc