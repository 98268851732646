import React, { useEffect, useState } from "react";
import css from "./index.module.css";
import AddressSearch from "./search";
import ManualEntry from "./manualEntry";
import AddressNotFound from "./notfound";
import validate from "./validator"
import { Form } from "react-bootstrap";

const defaultState = {
    data: {
        houseName: '',
        houseNumber: '',
        streetName: '',
        districtName: '',
        postTown: '',
        county: '',
        postCode: '',
        country: null
    },
    validation: {
        houseName: true,
        houseNumber: true,
        streetName: true,
        districtName: true,
        postTown: true,
        county: true,
        postCode: true,
        country: true
    }
}

const Address = ({ id = 'addr', label = 'Property Address', onChange, options, submitted, validationHandler, initialState = null, className }) => {
    const [useManual, setUseManual] = useState(false);
    const [state, setState] = useState(defaultState);
    const [addressValid, setAddressValid] = useState(false);

    useEffect(() => {
        if (initialState) setState(
            { 
                ...defaultState, 
                data: {
                    houseName: initialState.houseName ?? '',
                    houseNumber: initialState.houseNumber ?? '',
                    streetName: initialState.streetName ?? '',
                    districtName: initialState.districtName ?? '',
                    postTown: initialState.postTown ?? '',
                    county: initialState.county ?? '',
                    postCode: initialState.postCode ?? '',
                    country: initialState.country,
                } });
    }, [])

    useEffect(() => {
        onChange(state.data);
        const isValid = validate(state, options ?? {})
        setAddressValid(isValid);
        if (validationHandler) validationHandler(isValid);

    }, [state]);

    return <div className={className}>
        <Form.Group>
            <label className={css.label}>{label}</label>
            <AddressSearch id={id} state={state} setState={setState} showValidationMessages={submitted && !useManual && !addressValid} />
            <AddressNotFound id={id} onChange={setUseManual} />
            {useManual && (
                <ManualEntry id={id} state={state} setState={setState} showValidationMessages={submitted} cssclass={css.flexitem} />
            )}
        </Form.Group>
    </div>
}

export default Address;