import * as types from "../actions/types";
  
  const initialState = {
    quoteRequestBusy: false,
    sendQuoteRequestBusy: false,
    linkUinsureRequestBusy: false,
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case types.QUOTE_REQUEST_BUSY:
        return {
          ...state,
          quoteRequestBusy: action.payload
        }
      case types.SEND_QUOTE_REQUEST_BUSY:
          return {
            ...state,
            sendQuoteRequestBusy: action.payload
          }
      case types.LINK_UINSURE_REQUEST_BUSY:
        return {
          ...state,
          linkUinsureRequestBusy: action.payload
        }
      default:
        return state;
    }
  }
