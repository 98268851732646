import _ from "lodash";
import {
  STORE_QUOTE_TYPE,
  STORE_QUOTE_REGION,
  STORE_QUOTE_PROPERTY_ADDRESS,
  STORE_QUOTE_PROPERTY_TYPE,
  STORE_QUOTE_CUSTOMER_LOCATION,
  STORE_QUOTE_TRANSACTION_AMOUNT,
  STORE_QUOTE_TENURE,
  STORE_QUOTE_MORTGAGE,
  STORE_QUOTE_OBTAIN_MORTGAGE,
  STORE_QUOTE_LTD_COMPANY,
  STORE_QUOTE_FIRST_TIME_BUYER,
  STORE_QUOTE_FIRST_REGISTRATION_TRAIT,
  STORE_QUOTE_TRANSFER_REQUIRED,
  STORE_QUOTE_MAIN_RESIDENCE,
  STORE_QUOTE_OTHER_RESIDENCE,
  STORE_QUOTE_OWNERS,
  STORE_QUOTE_OWNERS_AFTER_TRANSFER,
  STORE_QUOTE_NEW_LENDER,
  STORE_QUOTE_TRAITS,
  STORE_QUOTE_ARMED_FORCES_TRAIT,
  STORE_QUOTE_AUCTION_TRAIT,
  STORE_QUOTE_DEED_OF_POSTPONEMENT_TRAIT,
  STORE_QUOTE_EXPATS_TRAIT,
  STORE_QUOTE_NUMBER_OF_EXPATS,
  STORE_QUOTE_GIFTED_DEPOSIT_TRAIT,
  STORE_QUOTE_NUMBER_OF_GIFTED_DEPOSITS,
  STORE_QUOTE_GIFTED_EQUITY_TRAIT,
  STORE_QUOTE_HELP_TO_BUY_TRAIT,
  STORE_QUOTE_NUMBER_OF_HELP_TO_BUY,
  STORE_QUOTE_HELP_TO_BUY_LOAN_TRAIT,
  STORE_QUOTE_HMO_TRAIT,
  STORE_QUOTE_STUDENT_PROPERTY,
  STORE_QUOTE_JOINT_PROPRIETOR_TRAIT,
  STORE_QUOTE_SOLE_PROPRIETOR_TRAIT,
  STORE_QUOTE_NEW_BUILD_TRAIT,
  STORE_QUOTE_RIGHT_TO_BUY_TRAIT,
  STORE_QUOTE_SHARED_OWNERSHIP_TRAIT,
  STORE_QUOTE_BESPOKE_REFERRAL_FEE,
  STORE_QUOTE_UPDATE_DEFAULT_REFERRAL_FEE,
  STORE_QUOTE_BRIDGING_FINANCE,
  STORE_QUOTE_BRIDGING_FINANCE_REPRESENTATION,
  QUOTE_VALIDATION_FAILURE,
  BESPOKE_REFERRAL_FEE_VALIDATION_FAILURE,
  STORE_DATE_OF_BIRTH,
  QUOTES_RECEIVED,
  STORE_QUOTE_YEAR_BUILT,
  STORE_QUOTE_NUMBER_OF_BEDROOMS,
  SET_QUOTE_EDITING,
  SET_SELECTED_QUOTES
} from "../actions/types";
import { jurisdictionsWithNone } from "../../components/shared/data";
import { QuoteType } from "../../data/quote-type";

const defaultValue = { value: null, validation: { message: null } };
const dobValue = { value: [] };

const type = { value: QuoteType.SALE, validation: { message: null } };
const jurisdiction = {
  value: jurisdictionsWithNone.ENGLAND.value,
  validation: { message: null },
};
const propertyAddress = _.cloneDeep(defaultValue);
const propertyType = _.cloneDeep(defaultValue);
const customerLocation = { ..._.cloneDeep(defaultValue), value: "GBR" };
const transactionAmount = _.cloneDeep(defaultValue);
const tenure = _.cloneDeep(defaultValue);
const mortgage = _.cloneDeep(defaultValue);
const obtainmortgage = _.cloneDeep(defaultValue);
const ltdcompany = _.cloneDeep(defaultValue);
const firsttimebuyer = _.cloneDeep(defaultValue);
const transferrequired = _.cloneDeep(defaultValue);
const mainresidence = _.cloneDeep(defaultValue);
const otherresidence = _.cloneDeep(defaultValue);
const newLender = _.cloneDeep(defaultValue);
const owners = _.cloneDeep(defaultValue);
const ownersaftertransfer = _.cloneDeep(defaultValue);
const armedforcestrait = _.cloneDeep(defaultValue);
const auctiontrait = _.cloneDeep(defaultValue);
const deedofpostponementtrait = _.cloneDeep(defaultValue);
const expatstrait = _.cloneDeep(defaultValue);
const numberofexpats = _.cloneDeep(defaultValue);
const gifteddeposittrait = _.cloneDeep(defaultValue);
const numberofgifteddeposits = _.cloneDeep(defaultValue);
const giftedequitytrait = _.cloneDeep(defaultValue);
const helptobuytrait = _.cloneDeep(defaultValue);
const numberofhelptobuy = _.cloneDeep(defaultValue);
const helptobuyloantrait = _.cloneDeep(defaultValue);
const hmotrait = _.cloneDeep(defaultValue);
const studentproperty = _.cloneDeep(defaultValue);
const jointproprietortrait = _.cloneDeep(defaultValue);
const soleproprietortrait = _.cloneDeep(defaultValue);
const newbuildtrait = _.cloneDeep(defaultValue);
const righttobuytrait = _.cloneDeep(defaultValue);
const sharedownershiptrait = _.cloneDeep(defaultValue);
const firstregistrationtrait = _.cloneDeep(defaultValue);
const bespokeReferralFee = _.cloneDeep(defaultValue);
const updateDefaultReferralFee = _.cloneDeep(defaultValue);
const quotesReceived = null;
const DateOfBirth = _.cloneDeep(dobValue);
const yearBuilt = _.cloneDeep(defaultValue);
const numberOfBedrooms = _.cloneDeep(defaultValue);
const bridgingfinance = _.cloneDeep(defaultValue);
const bridgingfinancerepresentation = _.cloneDeep(defaultValue);

const saleValues = {
  jurisdiction,
  tenure,
  mortgage,
  owners,
  expatstrait,
  numberofexpats,
  newLender,
  giftedequitytrait,
  helptobuytrait,
  hmotrait,
  numberofhelptobuy,
  studentproperty,
  sharedownershiptrait,
  auctiontrait,
  propertyType,
  customerLocation,
  propertyAddress,
  transactionAmount,
  bespokeReferralFee,
  updateDefaultReferralFee,
  DateOfBirth,
  helptobuyloantrait,
  ltdcompany,
  firstregistrationtrait,
};

const purchaseValues = {
  jurisdiction,
  tenure,
  ltdcompany,
  obtainmortgage,
  mainresidence,
  firsttimebuyer,
  owners,
  expatstrait,
  numberofexpats,
  newLender,
  armedforcestrait,
  auctiontrait,
  deedofpostponementtrait,
  gifteddeposittrait,
  giftedequitytrait,
  helptobuytrait,
  hmotrait,
  numberofhelptobuy,
  studentproperty,
  helptobuyloantrait,
  numberofgifteddeposits,
  jointproprietortrait,
  soleproprietortrait,
  newbuildtrait,
  righttobuytrait,
  sharedownershiptrait,
  firstregistrationtrait,
  propertyType,
  customerLocation,
  otherresidence,
  propertyAddress,
  transactionAmount,
  bespokeReferralFee,
  updateDefaultReferralFee,
  DateOfBirth,
  yearBuilt,
  numberOfBedrooms,
  bridgingfinance,
  bridgingfinancerepresentation,
};

const remortgageValues = {
  jurisdiction,
  tenure,
  ltdcompany,
  transferrequired,
  mainresidence,
  owners,
  expatstrait,
  numberofexpats,
  newLender,
  deedofpostponementtrait,
  helptobuytrait,
  hmotrait,
  studentproperty,
  numberofhelptobuy,
  jointproprietortrait,
  soleproprietortrait,
  sharedownershiptrait,
  propertyType,
  customerLocation,
  mortgage,
  otherresidence,
  propertyAddress,
  ownersaftertransfer,
  transactionAmount,
  bespokeReferralFee,
  updateDefaultReferralFee,
  DateOfBirth,
  yearBuilt,
  numberOfBedrooms,
  helptobuyloantrait,
  gifteddeposittrait,
  numberofgifteddeposits,
};

const transferOfEquityValues = {
  jurisdiction,
  tenure,
  owners,
  propertyType,
  customerLocation,
  propertyAddress,
  ownersaftertransfer,
  transactionAmount,
  bespokeReferralFee,
  updateDefaultReferralFee,
  DateOfBirth,
  yearBuilt,
  numberOfBedrooms
};

const saleAndPurchaseSale = _.cloneDeep(saleValues);
delete saleAndPurchaseSale.jurisdiction;
delete saleAndPurchaseSale.bespokeReferralFee;
delete saleAndPurchaseSale.updateDefaultReferralFee;
delete saleAndPurchaseSale.newLender;
const saleAndPurchasePurchase = _.cloneDeep(purchaseValues);
delete saleAndPurchasePurchase.jurisdiction;
delete saleAndPurchasePurchase.bespokeReferralFee;
delete saleAndPurchasePurchase.updateDefaultReferralFee;

const saleAndPurchaseValues = {
  jurisdiction,
  bespokeReferralFee,
  updateDefaultReferralFee,
  sale: saleAndPurchaseSale,
  purchase: saleAndPurchasePurchase,
};

export const initialState = {
  type,
  sale: _.cloneDeep(saleValues),
  purchase: _.cloneDeep(purchaseValues),
  saleAndPurchase: _.cloneDeep(saleAndPurchaseValues),
  remortgage: _.cloneDeep(remortgageValues),
  transferOfEquity: _.cloneDeep(transferOfEquityValues),
  quotesReceived,
};

function updateObject(oldValue, payload) {
  let newPayload = payload;
  if (typeof payload === "object") {
    newPayload = _.cloneDeep(payload);
  }

  return {
    ...oldValue,
    value: newPayload,
    validation: { message: null },
  };
}

const setState = (state, payload, key, storePath) => {
  const statePathArr = storePath ? storePath.split(".") : [];
  const statePath = [...statePathArr];
  if (statePath.length > 1) {
    return {
      ...state[statePath[0]],
      [statePath[1]]: {
        ...state[statePath[0]][statePath[1]],
        [key]: updateObject(state[statePath[0]][statePath[1]][key], payload),
      },
    };
  }
  return {
    ...state[getCaseType(state)],
    [key]: updateObject(state[getCaseType(state)][key], payload),
  };
};

const getCaseType = (state) => state.type.value;

export default function (state = initialState, action) {
  switch (action.type) {
    case STORE_QUOTE_TYPE:
      return {
        ...state,
        type: updateObject(state.type, action.payload),
      };

    case STORE_QUOTE_REGION:
      return {
        ...state,
        [getCaseType(state)]: setState(
          state,
          action.payload,
          "jurisdiction",
          action.storePath
        ),
      };

    case STORE_QUOTE_PROPERTY_ADDRESS:
      return {
        ...state,
        [getCaseType(state)]: setState(
          state,
          action.payload === "" ? null : action.payload,
          "propertyAddress",
          action.storePath
        ),
      };

    case STORE_QUOTE_PROPERTY_TYPE:
      return {
        ...state,
        [getCaseType(state)]: setState(
          state,
          action.payload,
          "propertyType",
          action.storePath
        ),
      };

    case STORE_QUOTE_CUSTOMER_LOCATION:
      return {
        ...state,
        [getCaseType(state)]: setState(
          state,
          action.payload,
          "customerLocation",
          action.storePath
        ),
      };

    case STORE_QUOTE_TRANSACTION_AMOUNT:
      return {
        ...state,
        [getCaseType(state)]: setState(
          state,
          action.payload === "" ? null : action.payload.replace(",", ""),
          "transactionAmount",
          action.storePath
        ),
      };

    case STORE_QUOTE_TENURE:
      return {
        ...state,
        [getCaseType(state)]: setState(
          state,
          action.payload,
          "tenure",
          action.storePath
        ),
      };

    case STORE_QUOTE_MORTGAGE:
      return {
        ...state,
        [getCaseType(state)]: setState(
          state,
          action.payload,
          "mortgage",
          action.storePath
        ),
      };

    case STORE_QUOTE_OBTAIN_MORTGAGE:
      return {
        ...state,
        [getCaseType(state)]: setState(
          state,
          action.payload,
          "obtainmortgage",
          action.storePath
        ),
      };

    case STORE_QUOTE_LTD_COMPANY:
      return {
        ...state,
        [getCaseType(state)]: setState(
          state,
          action.payload,
          "ltdcompany",
          action.storePath
        ),
      };

    case STORE_QUOTE_FIRST_TIME_BUYER:
      return {
        ...state,
        [getCaseType(state)]: setState(
          state,
          action.payload,
          "firsttimebuyer",
          action.storePath
        ),
      };

    case STORE_QUOTE_FIRST_REGISTRATION_TRAIT:
      return {
        ...state,
        [getCaseType(state)]: setState(
          state,
          action.payload,
          "firstregistrationtrait",
          action.storePath
        ),
      };

    case STORE_QUOTE_TRANSFER_REQUIRED:
      return {
        ...state,
        [getCaseType(state)]: setState(
          state,
          action.payload,
          "transferrequired",
          action.storePath
        ),
      };

    case STORE_QUOTE_MAIN_RESIDENCE:
      return {
        ...state,
        [getCaseType(state)]: setState(
          state,
          action.payload,
          "mainresidence",
          action.storePath
        ),
      };

    case STORE_QUOTE_OTHER_RESIDENCE:
      return {
        ...state,
        [getCaseType(state)]: setState(
          state,
          action.payload,
          "otherresidence",
          action.storePath
        ),
      };

    case STORE_QUOTE_OWNERS:
      return {
        ...state,
        [getCaseType(state)]: setState(
          state,
          action.payload,
          "owners",
          action.storePath
        ),
      };

    case STORE_QUOTE_OWNERS_AFTER_TRANSFER:
      return {
        ...state,
        [getCaseType(state)]: setState(
          state,
          action.payload,
          "ownersaftertransfer",
          action.storePath
        ),
      };

    case STORE_QUOTE_NEW_LENDER:
      return {
        ...state,
        [getCaseType(state)]: setState(
          state,
          action.payload,
          "newLender",
          action.storePath
        ),
      };

    case STORE_QUOTE_TRAITS:
      return {
        ...state,
        [getCaseType(state)]: setState(
          state,
          action.payload.map((trait) => {
            return {
              name: trait.name,
              value: trait.value,
              checked: trait.checked,
            };
          }),
          "owners",
          action.storePath
        ),
      };

    case STORE_QUOTE_ARMED_FORCES_TRAIT:
      return {
        ...state,
        [getCaseType(state)]: setState(
          state,
          action.payload,
          "armedforcestrait",
          action.storePath
        ),
      };

    case STORE_QUOTE_AUCTION_TRAIT:
      return {
        ...state,
        [getCaseType(state)]: setState(
          state,
          action.payload,
          "auctiontrait",
          action.storePath
        ),
      };

    case STORE_QUOTE_DEED_OF_POSTPONEMENT_TRAIT:
      return {
        ...state,
        [getCaseType(state)]: setState(
          state,
          action.payload,
          "deedofpostponementtrait",
          action.storePath
        ),
      };

    case STORE_QUOTE_EXPATS_TRAIT:
      return {
        ...state,
        [getCaseType(state)]: setState(
          state,
          action.payload,
          "expatstrait",
          action.storePath
        ),
      };

    case STORE_QUOTE_NUMBER_OF_EXPATS:
      return {
        ...state,
        [getCaseType(state)]: setState(
          state,
          action.payload,
          "numberofexpats",
          action.storePath
        ),
      };

    case STORE_QUOTE_GIFTED_DEPOSIT_TRAIT:
      return {
        ...state,
        [getCaseType(state)]: setState(
          state,
          action.payload,
          "gifteddeposittrait",
          action.storePath
        ),
      };

    case STORE_QUOTE_NUMBER_OF_GIFTED_DEPOSITS:
      return {
        ...state,
        [getCaseType(state)]: setState(
          state,
          action.payload,
          "numberofgifteddeposits",
          action.storePath
        ),
      };

    case STORE_QUOTE_GIFTED_EQUITY_TRAIT:
      return {
        ...state,
        [getCaseType(state)]: setState(
          state,
          action.payload,
          "giftedequitytrait",
          action.storePath
        ),
      };

    case STORE_QUOTE_HELP_TO_BUY_TRAIT:
      return {
        ...state,
        [getCaseType(state)]: setState(
          state,
          action.payload,
          "helptobuytrait",
          action.storePath
        ),
      };

    case STORE_QUOTE_NUMBER_OF_HELP_TO_BUY:
      return {
        ...state,
        [getCaseType(state)]: setState(
          state,
          action.payload,
          "numberofhelptobuy",
          action.storePath
        ),
      };

    case STORE_QUOTE_HELP_TO_BUY_LOAN_TRAIT:
      return {
        ...state,
        [getCaseType(state)]: setState(
          state,
          action.payload,
          "helptobuyloantrait",
          action.storePath
        ),
      };

    case STORE_QUOTE_HMO_TRAIT:
      return {
        ...state,
        [getCaseType(state)]: setState(
          state,
          action.payload,
          "hmotrait",
          action.storePath
        ),
      };

    case STORE_QUOTE_STUDENT_PROPERTY:
      return {
        ...state,
        [getCaseType(state)]: setState(
          state,
          action.payload,
          "studentproperty",
          action.storePath
        ),
      };

    case STORE_QUOTE_JOINT_PROPRIETOR_TRAIT:
      return {
        ...state,
        [getCaseType(state)]: setState(
          state,
          action.payload,
          "jointproprietortrait",
          action.storePath
        ),
      };

    case STORE_QUOTE_SOLE_PROPRIETOR_TRAIT:
      return {
        ...state,
        [getCaseType(state)]: setState(
          state,
          action.payload,
          "soleproprietortrait",
          action.storePath
        ),
      };
      
    case STORE_QUOTE_NEW_BUILD_TRAIT:
      return {
        ...state,
        [getCaseType(state)]: setState(
          state,
          action.payload,
          "newbuildtrait",
          action.storePath
        ),
      };

    case STORE_QUOTE_RIGHT_TO_BUY_TRAIT:
      return {
        ...state,
        [getCaseType(state)]: setState(
          state,
          action.payload,
          "righttobuytrait",
          action.storePath
        ),
      };

    case STORE_QUOTE_SHARED_OWNERSHIP_TRAIT:
      return {
        ...state,
        [getCaseType(state)]: setState(
          state,
          action.payload,
          "sharedownershiptrait",
          action.storePath
        ),
      };

    case STORE_QUOTE_BESPOKE_REFERRAL_FEE:
      return {
        ...state,
        [getCaseType(state)]: setState(
          state,
          action.payload,
          "bespokeReferralFee",
          action.storePath
        ),
      };

    case STORE_QUOTE_UPDATE_DEFAULT_REFERRAL_FEE:
      return {
        ...state,
        [getCaseType(state)]: setState(
          state,
          action.payload,
          "updateDefaultReferralFee",
          action.storePath
        ),
      };

    case STORE_QUOTE_BRIDGING_FINANCE:
      return {
        ...state,
        [getCaseType(state)]: setState(
          state,
          action.payload,
          "bridgingfinance",
          action.storePath
        ),
      };

    case STORE_QUOTE_BRIDGING_FINANCE_REPRESENTATION:
      return {
        ...state,
        [getCaseType(state)]: setState(
          state,
          action.payload,
          "bridgingfinancerepresentation",
          action.storePath
        ),
      };

    case QUOTES_RECEIVED:
      return {
        ...state,
        quotesReceived: action.payload,
      };

    case QUOTE_VALIDATION_FAILURE:
      return {
        ...state,
        [action.caseType]: {
          ...[action.caseType],
          ...action.payload,
        },
      };

    case BESPOKE_REFERRAL_FEE_VALIDATION_FAILURE:
      return {
        ...state,
        [action.caseType]: {
          ...[action.caseType],
          ...action.payload,
        },
      };

    case STORE_DATE_OF_BIRTH:
      return {
        ...state,
        [getCaseType(state)]: setState(
          state,
          action.payload,
          "DateOfBirth",
          action.storePath
        ),
      };

      case STORE_QUOTE_YEAR_BUILT:
        return {
          ...state,
          [getCaseType(state)]: setState(
            state,
            action.payload,
            "yearBuilt",
            action.storePath
          ),
        };

        case STORE_QUOTE_NUMBER_OF_BEDROOMS:
        return {
          ...state,
          [getCaseType(state)]: setState(
            state,
            action.payload,
            "numberOfBedrooms",
            action.storePath
          ),
        };

        case SET_QUOTE_EDITING:
          return {
            ...state,
            editing: true
          }

        case SET_SELECTED_QUOTES:
          return {
            ...state,
            selectedQuotes: action.quoteIds
          }

    default:
      return state;
  }
}
