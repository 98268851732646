import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { Toast } from "react-bootstrap"
import css from "./index.module.css"
import classnames from "classnames"

import * as siteWideActions from "../../redux/actions/site-wide-actions";

const CustomToast = () => {
    const dispatch = useDispatch()
    const { type, message, show } = useSelector(store => store.site.notification)
    const handleToastClose = () => {
        dispatch(siteWideActions.NOTIFICATION_SHOW.action({show:false}))
    }
    return (
        <Toast className={classnames(css[type], css.toast)} show={show} onClose={handleToastClose} delay={5000} autohide>
            <Toast.Header className={classnames(css.header, css[`header--${type}`])}>Notification - {type === "positive" ? "Success" : "Error"} - {message}</Toast.Header>
        </Toast>
    )
}

export default CustomToast