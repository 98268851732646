import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Toast, Container, Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import css from './index.module.css';
import Button from '../button/button';
import { stopEmulatingUserSaga } from '../../redux/actions/emulateActions/emulate';
import { setEmulateHeader } from '../../utils/axiosHeaders';

export default function EmulateNotification() {
  const dispatch = useDispatch();
  const history = useHistory();
  const userToEmulate = useSelector((store) => store.emulateUser.user);
  const emulate = useSelector((store) => store.emulateUser.startEmulating);

  const handleStopEmulating = () => {
    history.push('/dashboard');
    dispatch(stopEmulatingUserSaga());
  }

  if (emulate) {
    const { username, companyName, localCompanyName } = userToEmulate;
    // persist header on refresh
    setEmulateHeader(username);
    return (
      <Toast className={css.toast}>
        <Toast.Header className={css.header} closeButton={false}>
          <Container fluid>
            <Row className={css.notificationRow}>
              <Col id="emulation-message" xs="auto">
                {`YOU ARE CURRENTLY ACTING AS ${username} FROM ${companyName} AT ${localCompanyName}`}
              </Col>
              <Col xs="auto">
                <Button
                  id="emulate-stop-button"
                  datatestid="emulate-stop-button"
                  variant="primary"
                  btnClass={css.emulateStopButton}
                  onClick={handleStopEmulating}
                  text="STOP"
                  type="button"
                />
              </Col>
            </Row>
          </Container>
        </Toast.Header>
      </Toast>
    );
  }
  return null;
}