import React from "react";
import PropTypes from "prop-types";
import css from './index.module.css';
import ReactTable from 'react-bootstrap/Table';

const Table = ({ data, noData }) => {
    const getHeaders = () => {
        const firstRecord = data[0];
        return Object.keys(firstRecord);
    }

    const TableRows = () => {
        const headers = getHeaders();
        return data.map((i, idx) => 
            <tr key={idx}>
            { headers.map((header, idx) =>
                 <td key={idx}>{i[header]}</td>
            ) }
            </tr>
        );
    }

    const TableBody = () => (
        <tbody>
            <TableRows />
        </tbody>
    )

    const TableHeader = () => (
        <thead className={css.header}>
            <tr className={css.header}>
            { getHeaders().map((item, idx) => <th key={idx}>{item}</th>) }
            </tr>
        </thead>
    )

    if (!data || data.length < 1) {
        return noData ? <p data-testid="no-data">{noData}</p> : null;
    }

    return (
        <div>
            <ReactTable data-testid="result-table" className={css.table}>
                <TableHeader />
                <TableBody />
            </ReactTable>
        </div>
    );
}

Table.propTypes = {
    data: PropTypes.array,
    noData: PropTypes.string
  };
  
  export default Table;