const generateActionCreator = (type) => (payloadName) => {
  const actionCreator = (input) => {
    const action = {
      type: `SITE_WIDE_${type}`,
    };

    action[payloadName] = input;

    return action;
  };

  return {
    type: `SITE_WIDE_${type}`,
    action: actionCreator,
  };
};

export const SITE_BUSY = generateActionCreator("SITE_BUSY")("newBusyValue");

export const SITE_ERROR = generateActionCreator("SITE_ERROR")("newError");

export const NOTIFICATION_SHOW = generateActionCreator("NOTIFICATION_SHOW")("notificationShow");
