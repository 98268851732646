import React from 'react';
import PropTypes from 'prop-types';
import css from './index.module.css';
import { Container, Row, Col } from 'react-bootstrap';
import Totals from './components/totals';
import FeesTable from './components/feestable';
import { generateNotes, removeDuplicateObjectFromArray } from '../../utils/quoteBreakdownUtils';
import NoteTable from './components/noteTable';
import { isBlank, sumReceivedQuotes, toQuoteTypeDisplay } from '../../utils/jsutils';

const QuoteBreakdown = ({ quoteGroup, quotes }) => {
  const combinedTotals = sumReceivedQuotes(quotes);
  let notes = [];
  quotes?.forEach((quote) => {
    generateNotes(quote.costBreakdown).forEach((item) => notes.push(item));
    notes = removeDuplicateObjectFromArray(notes, 'symbol');
  });

  return (
    <Container fluid className={css.viewQuoteContainer}>
      <Row>
        <Col>
          <div className={css.vatText}>
            All prices are subject to VAT unless otherwise stated.
          </div>
        </Col>
      </Row>
      <div className={css.feesContainer}>
        {quoteGroup.notes && !isBlank(quoteGroup.notes) &&
          <NoteTable note={quoteGroup.notes} />
        }
      </div>
      {quotes.map((quote) => {
        return <>
          <div className={css.quoteType}>
            {toQuoteTypeDisplay(quote?.quoteType)?.toUpperCase()}
          </div>
          <FeesTable id={quote.quoteType} fees={quote.costBreakdown.sort((a, b) => a.displayOrder - b.displayOrder)} notes={generateNotes(quote.costBreakdown)} />
        </>
      })}
      <Totals
        subTotal={combinedTotals.subTotal}
        vatTotal={combinedTotals.vat}
        totalCost={combinedTotals.total}
      />
      <Row>
        <Col>
          {notes.map((n, idx) => (
            <div className={css.noteFooter} key={idx}>
              {`${n.symbol} ${n.noteDescription}`}
            </div>
          ))}
        </Col>
      </Row>
    </Container>
  )
}

QuoteBreakdown.propTypes = {
  quoteGroup: PropTypes.shape({
    notes: PropTypes.string
  }).isRequired,
  quotes: PropTypes.arrayOf(PropTypes.shape({
    costBreakdown: PropTypes.arrayOf(PropTypes.shape({
      feeType: PropTypes.string.isRequired,
      isVatChargeable: PropTypes.bool.isRequired,
      name: PropTypes.string.isRequired,
      notes: PropTypes.string.isRequired,
    })).isRequired,
    costSummary: PropTypes.shape({
      disbursements: PropTypes.number.isRequired,
      governmentFees: PropTypes.number.isRequired,
      legalFees: PropTypes.number.isRequired,
      specialDisbursements: PropTypes.number.isRequired,
      subtotalCost: PropTypes.number.isRequired,
      totalCost: PropTypes.number.isRequired,
      vat: PropTypes.number.isRequired
    }).isRequired,
    quoteType: PropTypes.string.isRequired
  }))
}

export default QuoteBreakdown;