import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";

import formatter from "../../../../utils/formatter";
import css from "./feestable.module.css";

const FeesTable = ({ fees, notes, id }) => {
  const display = (fee) => {
    const notesToDisplay = fee.notes.split(', ');
    const filteredArray = notes.filter((item) => notesToDisplay.includes(item.note));
    return filteredArray.map((item) => item.symbol).join(' ');
  }

  const [transformedFees, setTransformedFees] = useState({});

  useEffect(() => {
    let transformedFees = {};
    fees.map((item) => {
      const existing = transformedFees[item.feeType];
      if (existing) existing.push(item);
      else transformedFees[item.feeType] = [item]
    })
    setTransformedFees(transformedFees);
  }, []);
  
  return (
    <div>
      {Object.keys(transformedFees).map((item) => {
        const feeType = transformedFees[item];

        return (<Row key={`${item}`}>
          <Col>
            <Row>
              <Col>
                <div className={css.tableHeader}>
                  <h2 className={css.tableHeaderText}>{formatter.formatFeeType(item)}</h2>
                </div>
              </Col>
            </Row>
            <Row key={`${item}`}>
              <Col>
                <div className={css.tableBody}>
                  <Table id={`fee-table-${id}`} borderless>
                    <tbody>
                      {feeType.sort((a, b) => a.displayOrder - b.displayOrder).map(
                        (f, idx) =>
                          !f.value || (
                            <tr key={`${item}-${idx}`}>
                              <td>{f.name}{notes && <sup> {display(f)}</sup>}</td>
                              <td className={css.tableValueColumn}>
                                {formatter.formatCurrency(f.value)}
                              </td>
                            </tr>
                          )
                      )}
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>)
      })}
    </div>
  );
};

FeesTable.propTypes = {
  fees: PropTypes.array,
  notes: PropTypes.arrayOf(PropTypes.shape({
    symbol: PropTypes.string,
    note: PropTypes.string,
  })),
};

export default FeesTable;
