import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import DropdownSelectorLayout from "../../pages/quote/components/dropdown-selector-layout";
import getCountries from "@lms/react-reference-data";
import { AuthContext } from "../../utils/authProvider";
import { arrayHasItems } from "@lms/react-common-helpers/arrays";

const uri = window?.config?.referenceDataUrl;

const CustomerLocation = ({
  quoteType,
  persistAction,
  storePath,
  selector,
  errorMessage,
  selectedState,
}) => {
  const { getAccessToken } = useContext(AuthContext);

  const [countryOptions, setCountryOptions] = useState(null);

  useEffect(() => {
    const fetchCountries = async () => {
      const data = await getCountries({
        getAccessToken: getAccessToken,
        uri: uri,
      });

      const mappedResults = data
        .filter((item) => item.alpha3Code)
        .map((item) => ({ name: item.name, value: item.alpha3Code }));

      setCountryOptions(mappedResults);
    };

    fetchCountries();
  }, []);

  if (!arrayHasItems(countryOptions)) return null;

  return (
    <DropdownSelectorLayout
      title="Customer Location"
      id={`customer-location-${quoteType}`}
      datasetid={`customer-location-${quoteType}`}
      options={countryOptions}
      groupname="customer-location"
      persistAction={persistAction}
      storePath={storePath}
      selector={selector}
      errorMessage={errorMessage}
      selectedState={selectedState}
    />
  );
};

CustomerLocation.propTypes = {
  quoteType: PropTypes.string.isRequired,
  persistAction: PropTypes.func.isRequired,
  storePath: PropTypes.string,
  selector: PropTypes.func.isRequired,
  errorMessage: PropTypes.func.isRequired,
  selectedState: PropTypes.string.isRequired,
};

export default CustomerLocation;
