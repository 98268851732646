import { combineReducers } from "redux";
import instructProcessReducer from "./instruct-process/reducer";
import instructCustomer from "./customer/reducer";
import instructLender from "./lender/reducer";
import insuranceReducer from "./insurance/reducer";
import additionalInfoReducer from "./additional-info/reducer"
import casePropertyReducer from "./case-property/reducer";

export default combineReducers({
  customers: instructCustomer,
  lender: instructLender,
  instructProcessState: instructProcessReducer,
  insurance: insuranceReducer,
  additionalInfo: additionalInfoReducer,
  propertyAddress: casePropertyReducer
});
