const mapNoteToDisplayString = (note) => {
  switch (note) {
    case 'NotSubjectToVat':
      return 'Not subject to VAT';
    case 'SubjectToIPT':
      return 'Insurance Premium Tax (IPT) at the appropriate rate (where applicable).';
    case 'SubjectToSupportingEcot':
      return 'If the law firm is unable to register the property electronically, the land registry fee quoted will increase';
    default:
      return '';
  }
}

const generateNotes = (fees) => {
  let notesToDisplay = [];
  let countAsterisks = 0;
  let countDaggers = 0;
  fees?.forEach((fee) => {
      const { notes } = fee;
      if (notes !== 'None') {
        const arrayOfNotes = notes.split(', ');
        const moreThenOneNote = arrayOfNotes.length > 1;
        if (moreThenOneNote) {
          // this handles 2 notes, check either of the notes exist in the array
          const alreadyExists = notesToDisplay.filter((item) => arrayOfNotes.includes(item.note));
          if (alreadyExists.length === 1) {
            // we need a dagger, asterisk has already been taken care of
            const noteThatDoesntExist = arrayOfNotes.find((item) => item !== alreadyExists[0].note);
            countDaggers += 1;
            return notesToDisplay.push({ symbol: '\u2020'.repeat(countDaggers), note: noteThatDoesntExist, noteDescription: mapNoteToDisplayString(noteThatDoesntExist) });
          } else if (alreadyExists.length === 2) {
            // both have been added separately as asterisks, change the symbol of the second one to a dagger (NB: this works for 3 different notes, but for more than that, will need a rethink)
            countDaggers += 1;
            countAsterisks -= 1;
            const secondNote = alreadyExists[1];
            const indexToUpdate = notesToDisplay.findIndex((item) => item.note === secondNote);
            return notesToDisplay[indexToUpdate].symbol = '\u2020'.repeat(countDaggers);
          } else {
            // neither of them exist, push to the array an asterisk and a dagger
            countDaggers += 1;
            countAsterisks += 1;
            notesToDisplay.push({ symbol: '*'.repeat(countAsterisks), note: arrayOfNotes[0], noteDescription: mapNoteToDisplayString(arrayOfNotes[0]) })
            return notesToDisplay.push({ symbol: '\u2020'.repeat(countDaggers), note: arrayOfNotes[1], noteDescription: mapNoteToDisplayString(arrayOfNotes[1]) });
          }
        }
        // just the one note, check it doesn't exist before pushing
        if (!notesToDisplay.find((item) => item.note === notes)) {
          countAsterisks += 1;
          return notesToDisplay.push({ symbol: '*'.repeat(countAsterisks), note: notes, noteDescription: mapNoteToDisplayString(notes) });
        }
      }
  });
  return notesToDisplay;
}

const removeDuplicateObjectFromArray = (array, key) => {
  var check = new Set();
  return array.filter(obj => !check.has(obj[key]) && check.add(obj[key]));
}

export { generateNotes, removeDuplicateObjectFromArray };
