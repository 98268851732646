import { call, put, takeLatest, fork, select } from 'redux-saga/effects';
import * as types from '../../actions/types';
import { getQuoteRequest } from '../../../mappers/quote';
import postRequest from '../../../services/postRequest';
import showError from "../utils/error";
import { QuoteType } from '../../../data/quote-type';

const getEndpoint = (caseType) => {
  const base = 'quotes';

  switch(caseType) {
    case QuoteType.SALE:
      return `${base}/sale`
    case QuoteType.PURCHASE:
      return `${base}/purchase`;
      case QuoteType.REMORTGAGE:
      return `${base}/remortgage`;
      case QuoteType.TRANSFER_OF_EQUITY:
      return `${base}/transfer-of-equity`;
    default:
      return '';
  }
}

function* submitQuoteRequest(action) {
  const history = action.history

  function forwardTo(location) {
    if (!history.send) return history.replace(location, '', location)
    history.push(location)
  }

  try {
    yield put({ type: types.QUOTE_REQUEST_BUSY, payload: false });

    const caseType = yield select((s) => s.quote.type.value)
    const preferences = yield select((s) => s.preferences);

    var request = yield getQuoteRequest();

    let quoteResponse;
    let success;
    let quoteGroupId;

    if (caseType === QuoteType.SALE_AND_PURCHASE) {
      const saleEndpoint = getEndpoint(QuoteType.SALE);
      const purchaseEndpoint = getEndpoint(QuoteType.PURCHASE);

      const saleRes = yield call(
        postRequest,
        `${window.config.apiBaseUrl}/${saleEndpoint}`,
        request[0]
      );

      const { data: saleData } = saleRes;
      ({ id: quoteGroupId } = saleData);

      request[1].quoteGroupId = quoteGroupId;

      const purchaseRes = yield call(
        postRequest,
        `${window.config.apiBaseUrl}/${purchaseEndpoint}`,
        request[1]
      );

      success = saleRes.status === 200 && purchaseRes.status === 200;

      const { data: purchaseData } = purchaseRes;
      if (success) quoteResponse = { 
        quoteGroupId: purchaseData.id,
        quotes: purchaseData.conveyancyQuotes,
        dateTimeGenerated: purchaseData.createdOn
      };
    }
    else {
      const endpoint = getEndpoint(caseType);

      const res = yield call(
        postRequest,
        `${window.config.apiBaseUrl}/${endpoint}`,
        request
      );

      const { status, data } = res;
      success = status === 200;
      ({ id: quoteGroupId } = data);
      if (success) quoteResponse = { 
        quoteGroupId: data.id,
        quotes: data.conveyancyQuotes,
        dateTimeGenerated: data.createdOn
      };
    }

    if (success) {
      yield put({ type: types.QUOTES_RECEIVED, payload: quoteResponse });
      if (caseType !== QuoteType.SALE && preferences.uinsure.quotingType.value === "AlwaysIncludeGeneralInsuranceQuote") {
        yield put({ type: types.GET_UINSURE_QUOTE, quoteGroupId: quoteGroupId });
      }
      yield call(forwardTo, '/quoteresults')
      yield put({ type: types.QUOTE_REQUEST_BUSY, payload: false });
      yield put({ type: types.SITE_ERROR, payload: false });


    } else {
      yield put({ type: types.SITE_ERROR, payload: true });
      yield put({ type: types.QUOTE_REQUEST_BUSY, payload: false });
      yield showError(res);
    }


  } catch (e) {
    yield put({ type: types.SITE_ERROR, payload: true });
    yield put({ type: types.QUOTE_REQUEST_BUSY, payload: false });
    yield showError(e);
  }
}

function* saga() {
  yield takeLatest(types.QUOTE_VALIDATION_SUCCESS, submitQuoteRequest);
}

const settingsSaga = fork(saga);

export { settingsSaga as default, submitQuoteRequest, saga };
