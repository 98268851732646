import React from "react";
import PropTypes from "prop-types";
import Jurisdiction from "../../../selectors/jurisdiction/jurisdiction";
import { storePreferenceJurisdiction } from "../../../../redux/actions/actions";

const JurisdictionCard = ({ id, datatestid }) => {
  return (
    <fieldset>
      <div id={id} data-testid={datatestid}>
        <Jurisdiction
          id={`${id}-jurisdiction`}
          datatestid={`${datatestid}-jurisdiction`}
          persistAction={storePreferenceJurisdiction}
          storelocation={(store) => store.preferences.jurisdiction.value}
          isPreferenceScreen={true}
        />
      </div>
    </fieldset>
  );
};

JurisdictionCard.propTypes = {
  id: PropTypes.string.isRequired,
  datatestid: PropTypes.string.isRequired,
};

export default JurisdictionCard;
