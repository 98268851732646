import axios from "axios";

export function setAuthHeader(token) {
  axios.defaults.headers.common["Authorization"] = token
    ? "Bearer " + token
    : "";
}

export function setEmulateHeader(username) {
  axios.defaults.headers.common["emulateusername"] = username;
}

export function removeEmulateHeader() {
  delete axios.defaults.headers.common["emulateusername"];
}