import React from "react";
import { useSelector } from "react-redux";
import * as logos from "../../../components/shared/imgs/network-logos/index"
import Logo from "../../../components/logo/logo";
import classnames from "classnames";
import css from "../header.module.css";


export default function NetworkLogoCheck() {

    const networkStr = useSelector(
        (store) => store.preferences?.network?.value ?? ''
    );
    const network = networkStr.replace(/ /g, '');
    const networkName = network.replace(/[^a-zA-Z ]/g, '');

    return (
      <div className={css.flex}>
        <Logo
            name={networkName}
            className={classnames(css.flexitem, css.networklogo)}
            logos={logos}
            logotype="network"
            id="logo"
            data-testid="logo"
        />
        </div>
    );

}