import { useDispatch } from "react-redux";
import * as siteWideActions from "../redux/actions/site-wide-actions"

export function useNotifications() {
    const dispatch = useDispatch();

    const showNotification = (type, message) => {
        dispatch(
            siteWideActions.NOTIFICATION_SHOW.action(
                {
                    show: true,
                    message,
                    type
                }
            ));
    }

    const showError = (message) => showNotification('negative', message)
    const showSuccess = (message) => showNotification('positive', message);

    return {
        showSuccess,
        showError
    }
}