import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import css from '../quote.module.css';
import { getJurisdiction, getQuote } from '../../../utils/jsutils';
import {
  storeQuoteTenure,
  storeQuoteOwners,
  storeQuotePropertyType,
  storeQuoteCustomerLocation,
  storeQuoteOwnersAfterTransfer,
  storeQuotePropertyAddress,
  storeQuoteTransactionAmount,
  storeDateOfBirth,
  storeQuoteNumberOfBedrooms,
  storeQuoteYearBuilt,
} from '../../../redux/actions/actions';
import {
  propertyOwners,
  propertyType,
  giYearBuilt,
  numberOfBedrooms,
} from '../../../components/shared/data';
import Price from '../components/price';
import DropdownSelectorLayout from '../components/dropdown-selector-layout';
import ButtonSelectorLayout from '../components/button-selector-layout';
import Address from '../../../components/address';
import ValidationGroup from '../../../components/validation-group';
import DateInput from '../../../components/dateInput';
import { QuoteType } from '../../../data/quote-type';
import Tenure from '../components/tenure';
import { jurisdictions } from '../../../components/shared/data';
import CustomerLocation from '../../../components/customerLocation';

const TransferOfEquity = ({
  caseType = QuoteType.TRANSFER_OF_EQUITY,
  storePath = null,
  submitted,
  validationHandler,
}) => {
  const dispatch = useDispatch();

  const region = useSelector((store) => getJurisdiction(store, caseType, storePath));
  const getCurrentQuote = (store) => getQuote(store, caseType, storePath);
  const customerLocationState = () => useSelector((store) => getCurrentQuote(store).customerLocation.value);
  const homeOwnersCount = useSelector((store) => store.quote[caseType].ownersaftertransfer.value);
  const giPreference = useSelector((store) => store.preferences.uinsure?.quotingType?.value);
  const propertyAddress = useSelector((store) => store.quote[caseType].propertyAddress?.value);

  const showGIFields = giPreference === 'AlwaysIncludeGeneralInsuranceQuote' || giPreference === 'PassOnLeadWithoutQuote';

  return (
    <>
      <div className={css.toppad2x} />
      <ValidationGroup submitted={submitted} validationHandler={validationHandler}>
        <Address
          className={css.addressContainer}
          onChange={(e) => dispatch(storeQuotePropertyAddress(e))}
          initialState={propertyAddress}
        />
      </ValidationGroup>

      <Price
        title="Consideration Amount"
        id="currency-input"
        datatestid="currency-input"
        placeholder="Amount"
        persistaction={storeQuoteTransactionAmount}
        errorMessage={(store) => store.quote[caseType].transactionAmount.validation.message}
        value={useSelector((store) => store.quote[caseType].transactionAmount.value)}
      />

      <Tenure 
        region={region}
        persistAction={storeQuoteTenure}
        storePath={storePath}
        selector={(store) => getCurrentQuote(store).tenure.value}
        errorMessage={(store) =>
          getCurrentQuote(store).tenure.validation.message
        }  
      />

      {(region === jurisdictions.SCOTLAND.value || showGIFields) && (
        <DropdownSelectorLayout
          title="Type of Property"
          id="property-type"
          datatestid="property-type"
          options={propertyType}
          groupname="propertytype"
          persistAction={storeQuotePropertyType}
          selector={(store) => store.quote[caseType].propertyType.value}
          errorMessage={(store) => store.quote[caseType].propertyType.validation.message}
        />
      )}

      {showGIFields && (
        <DropdownSelectorLayout
          title="Number of Bedrooms"
          id="number-bedrooms"
          datatestid="number-bedrooms"
          options={numberOfBedrooms}
          groupname="NumberBedrooms"
          persistAction={storeQuoteNumberOfBedrooms}
          storePath={storePath}
          selector={(store) => store.quote[caseType].numberOfBedrooms.value}
          errorMessage={(store) => store.quote[caseType].numberOfBedrooms.validation.message}
        />
      )}

      {showGIFields && (
        <DropdownSelectorLayout
          title="Year Built"
          id="year-built"
          datatestid="year-built"
          options={giYearBuilt}
          groupname="yearBuilt"
          persistAction={storeQuoteYearBuilt}
          storePath={storePath}
          selector={(store) => store.quote[caseType].yearBuilt.value}
          errorMessage={(store) => store.quote[caseType].yearBuilt.validation.message}
        />
      )}

      <ButtonSelectorLayout
        title="How many people own the property now?"
        id="owners"
        datatestid="owners"
        buttons={propertyOwners}
        groupname="owners"
        persistAction={storeQuoteOwners}
        selector={(store) => store.quote[caseType].owners.value}
        errorMessage={(store) => store.quote[caseType].owners.validation.message}
      />

      <ButtonSelectorLayout
        title="How many people own the property after this transfer?"
        id="ownersaftertransfer"
        datatestid="ownersaftertransfer"
        buttons={propertyOwners}
        groupname="ownersaftertransfer"
        persistAction={storeQuoteOwnersAfterTransfer}
        selector={(store) => store.quote[caseType].ownersaftertransfer.value}
        errorMessage={(store) => store.quote[caseType].ownersaftertransfer.validation.message}
      />

      {showGIFields && (
        <DateInput
          id="dob"
          datatestid="dob"
          collectionValues={(store) => store.quote[caseType].DateOfBirth.value}
          storeModel={(store) => store.quote[caseType]}
          persistAction={storeDateOfBirth}
          storePath={storePath}
          homeOwnersCount={homeOwnersCount}
          errorclass={css.error}
        />
      )}

      <CustomerLocation 
        quoteType="transfer-of-equity"
        persistAction={storeQuoteCustomerLocation}
        storePath={storePath}
        selector={(store) => getCurrentQuote(store).customerLocation.value}
        errorMessage={(store) => getCurrentQuote(store).customerLocation.validation.message}
        selectedState={customerLocationState}
      />

    </>
  );
};

TransferOfEquity.propTypes = {
  caseType: PropTypes.string,
  storePath: PropTypes.string,
  submitted: PropTypes.bool,
  validationHandler: PropTypes.func.isRequired,
};

TransferOfEquity.defaultProps = {
  caseType: QuoteType.TRANSFER_OF_EQUITY,
  storePath: null,
  submitted: false,
};

export default TransferOfEquity;
