import React from "react";
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { resetQuoteData } from "../../../redux/actions/actions" 
import Button from "../../../components/button/button"
import css from "../dashboard.module.css"
import { ReactComponent as NewQuoteIcon } from "../../../components/shared/imgs/new-quote-icon.svg"
import { ReactComponent as IconArrow } from "../../../components/shared/imgs/link-closed.svg"

const NewQuote = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const handleClick = (location) => {
    dispatch(resetQuoteData())
    history.push(location)
  }
  return (
    <>
      <div className={css.newQuoteCard}>
        <div className={css.actionSectionContent}>
          <div className={css.actionSectionIcon}>
            <NewQuoteIcon />
          </div>
          <div className={css.actionSectionText}>
            Got a new client who needs conveyancing?
          </div>
          <div className={css.actionSectionLink}>
            <Button
              id="new_quote"
              datatestid="new-quote"
              btnClass={css.actionSectionLinkText}
              onClick={() => handleClick("/quote")}
              type="button"
              text="START A NEW QUOTE"
              icon={IconArrow}
              variant="link-arrow"
            />
          </div>
        </div>
        <div className={css.newQuoteBottomBorder}></div>
      </div>
    </>
  );
};

export default NewQuote;
