import { call, put, takeLatest, fork, select } from "redux-saga/effects";
import * as types from "../../actions/types"
import { getClients, getPropertyDetails, getReferences, isEligible } from '../utils/uinsure';

import postRequest from "../../../services/postRequest"
import { storeUinsureSelectedQuote } from "../../actions/actions";

function* sendUinsureLead() {
    yield put({ type: types.SITE_BUSY, payload: true });

    try {
        if (yield !isEligible()) {
            return;
        }

        const clients = yield getClients();
        const propertyDetails = yield getPropertyDetails();
        const references = getReferences();
        const quoteGroupId = yield select((store) => store.instruct.instructProcessState?.savedQuote?.data?.quoteGroupId);

        const uinsureRequest = {
            ...references,
            client: clients[0],
            ...propertyDetails
        }

        const { data: insuranceResponse } = yield call(postRequest, `${window.config.apiBaseUrl}/quotes/${quoteGroupId}/insurance/callback`, uinsureRequest);
        const { data } = insuranceResponse;
        const { quoteReference } = data;

        yield put(storeUinsureSelectedQuote({ quote: quoteReference }));
        
    }
    catch(e) {;
        // Sending a lead is a background operation.
        // If an error happens, we don't want to stop the user, or surface the error to them.
    }
    
    yield put({ type: types.SITE_BUSY, payload: false });
}

function* saga() {
    yield takeLatest(types.UINSURE_SEND_LEAD, sendUinsureLead);
}

const sendUInsureLeadSaga = fork(saga);

export { sendUInsureLeadSaga as default, sendUinsureLead, saga };