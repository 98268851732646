import * as instructQuoteActions from "../../../actions/instruct-actions/instruct-process";
import * as getSavedQuoteActions from "../../../actions/get-saved-quotes-actions";

const initialState = {
  processing: false
};

export default (state = initialState, action = {}) => {
  switch (action.type) {

    case getSavedQuoteActions.SET_RETRIEVED_SAVED_QUOTE.type:
      return {
        ...state,
        savedQuote: action.retrievedQuote,
      };
    
    case instructQuoteActions.INSTRUCT_REQUEST_BUSY.type: 
      return {
        ...state,
        processing: action.payload
      }

    case instructQuoteActions.SAVE_INSTRUCT_RESPONSE.type:
      return {
        ...state,
        instruction: action.payload
      } 

    default:
      return state;
  }
};
