import { select } from "redux-saga/effects";
import { QuoteType } from "../../../data/quote-type";
import { isBlank } from "../../../utils/jsutils";

const getPhone = (client) => {
    if (client.mobile)  return client.mobile;
    if (client.homePhoneNumber) return client.homePhoneNumber
    if (client.workPhoneNumber) return client.workPhoneNumber;
    return null
}

const getCustomerType = ({firstTimeBuyer}) => {
    if (firstTimeBuyer) return "FirstTimeBuyer";    
    return "Homemover";
}

function* getConveyancingQuote() {
    const caseType = yield getConveyancingCaseType();

    const quote = caseType === QuoteType.SALE_AND_PURCHASE
        ? yield select((s) => s.instruct?.instructProcessState?.savedQuote?.data?.relatedQuote)
        : yield select((s) => s.instruct?.instructProcessState?.savedQuote?.data)

    return {
        ...quote,
    }
}

function* getDatesOfBirth() {
    const caseType = yield getConveyancingCaseType();
    let clients;
    if (caseType === QuoteType.SALE_AND_PURCHASE) {
        clients = yield select((s) => s.instruct.customers.relatedQuote);
    } else {
        clients = yield select((s) => s.instruct.customers.quote);
    }
    const a = yield select((s) => s.instruct);

    return clients.map(client => client.dateOfBirth);
}



function* getCustomers() {
    const caseType = yield getConveyancingCaseType();
    return caseType === QuoteType.SALE_AND_PURCHASE
        ? yield select((s) => s.instruct?.customers?.relatedQuote)
        : yield select((s) => s.instruct?.customers?.quote);
}

function* getConveyancingCaseType() {
    return yield select((s) => s.quote.type.value);
}

export function* getClients() {
    const customers = yield getCustomers();
    const datesOfBirth = yield getDatesOfBirth();

    var clients = customers.map((x, idx) => ({
        title: x.title,
        forenames: x.firstName,
        surname: x.surname,
        dateOfBirth: datesOfBirth[idx] ?? "2000-12-20T00:00:00",
        telephoneNumber: getPhone(x),
        emailAddress: x.email
    }))

    return clients;
}

export function* getPropertyDetails() {
    const quote = yield getConveyancingQuote();

    const {
        postCode,
        houseName,
        houseNumber,
        streetName,
        postTown,
        districtName,
        county } = yield select((s) => s.instruct.propertyAddress.relatedQuote ?? s.instruct.propertyAddress.quote);

    const { numberOfBedrooms, yearBuilt, propertyType } = quote

    return {
        riskAddressHouseNameNumber: houseName || houseNumber,
        riskAddressStreetName: streetName,
        riskAddressTownCity: postTown,
        riskAddressDistrict: districtName,
        riskAddressCounty: county,
        riskAddressPostcode: postCode,
        yearBuilt: yearBuilt,
        numberOfBedrooms: numberOfBedrooms,
        riskAddressPropertyType: propertyType,
        customerType: getCustomerType(quote)
    }
}

export function getReferences() {
    return {
        riskDataId: "lms-test",
        lmsReference: "lms-test"
    }
}

export function* getSelectedQuoteReference() {
    return yield select(s => s.uinsure.selectedQuote);
}

export function* isEligible() {
    const formattedCaseType = yield getConveyancingCaseType();
    const conveyancingQuote = yield getConveyancingQuote();

    return !conveyancingQuote.includeBuyToLet && formattedCaseType !== QuoteType.SALE;
}