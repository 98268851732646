import React, { useLayoutEffect, useEffect, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import css from './dashboard.module.css';
import { AuthContext } from '../../utils/authProvider';
import AuthCheck from '../../components/authcheck/authCheck';
import authState from '../../components/authcheck/authState';
import NewQuote from './actions/new-quote';
import SavedQuotes from './actions/saved-quotes';
import CaseProgress from './actions/case-progress';
import ControlPanel from './control-panel/control-panel';
import Pricing from './pricing/pricing';
import User from '../../components/user/user';
import NewUserPreferenceMessage from '../../components/newuserpreferencemessage';
import EmulatePanel from './emulate/EmulatePanel';
import Layout from '../../layout';

const DashBoard = () => {
  const { getUser, getAccessToken } = useContext(AuthContext);

  const [isBroker, setisBroker] = useState(false);
  const [isLawfirm, setisLawfirm] = useState(false);
  const [isNewUser, setisNewUser] = useState(false);
  const [canEmulateUser, setCanEmulateUser] = useState(false);

  const newUserCheck = useSelector(
    (store) => store.preferences.lastUpdated.value
  );

  const preferencesReceived = useSelector(
    (store) => store.preferences.loaded.value
  );

  const handleClose = () => {
    window.sessionStorage.setItem('preferenceSettingDeferred', 'true')
    setisNewUser(false);
  };

  const preferencesDeferred = window.sessionStorage.getItem('preferenceSettingDeferred');

  useEffect(() => {
    if (preferencesDeferred) {
      setisNewUser(false);
    }
    else if (newUserCheck === null && preferencesReceived) {
      setisNewUser(true);
    }
    else {
      setisNewUser(false);
    }
  }, [newUserCheck, preferencesReceived]);

  useLayoutEffect(() => {
    async function init() {
      await getAccessToken();
      let user = await getUser();

      if (user.profile.roles.includes("BROKER_USER.PAGE"))
        setisBroker(true);

      if (user.profile.roles.includes("LAW_FIRM_USER.PAGE"))
        setisLawfirm(true);

      if (user.profile.roles.includes('EMULATE_USER.PAGE')) {
        setCanEmulateUser(true);
      }
    }
    init();
  }, []);

  return (
    <Layout>
      <AuthCheck requiredAuthState={authState.AUTHENTICATED} />
      <Row>
        <Col md={12} xs={12}>
          <NewUserPreferenceMessage
            id="preferencemessagemodal"
            datatestid="preferencemessagemodal"
            show={isNewUser}
            onHide={handleClose}
            getAccessToken={getAccessToken}
          />
        </Col>
      </Row>

      <Row className={css.bottompad2x}>
        <Col md={1} xs={1}></Col>
        <Col md={10} xs={10}>
          <Row>
            <Col md={6} xs={12}>
              <div className={css.welcomeUser}>
                HELLO<User id="user" datatestid="user" userClass={css.user} />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className={css.welcomeText}>
                Welcome, what would you like to do today?
              </div>
            </Col>
            <Col md={6} xs={12}></Col>
          </Row>

          {canEmulateUser && (
            <Row className={css.bottompad2x}>
              <Col md={12} xs={12}>
                <div className={css.emulateSection}>
                  <EmulatePanel />
                </div>
              </Col>
            </Row>
          )}

          {isBroker && (
            <Row className={css.bottompad2x}>
              <Col md={4} xs={12} className={css.customGutter}>
                <div className={css.actionSection}>
                  <NewQuote />
                </div>
              </Col>
              <Col md={4} xs={12} className={css.customGutter}>
                <div className={css.actionSection}>
                  <SavedQuotes />
                </div>
              </Col>
              <Col md={4} xs={12} className={css.customGutter}>
                <div className={css.actionSection}>
                  <CaseProgress />
                </div>
              </Col>
            </Row>
          )}

          {isLawfirm && (
            <Row className={css.bottompad2x}>
              <Col md={12} xs={12}>
                <div className={css.pricingSection}>
                  <Pricing />
                  <div className={css.prefsSectionContent}>
                    <div>Content TBC</div>
                  </div>
                </div>
              </Col>
            </Row>
          )}

          <Row className={css.bottompad2x}>
            <Col md={12} xs={12}>
              <div className={css.welcomeText}>YOUR PREFERENCES</div>
            </Col>
          </Row>

          <Row className={css.bottompad2x}>
            <Col md={12} xs={12}>
              <div className={css.prefsSection}>
                <ControlPanel />
              </div>
            </Col>
          </Row>
        </Col>
        <Col md={1} xs={1}></Col>
      </Row>
    </Layout>
  );
};

export default DashBoard;
