import React, { useEffect, useState, useContext } from "react"
import FormControl from "react-bootstrap/FormControl";
import css from "../index.module.css"
import classnames from "classnames";
import SearchableDropdown from "../../../pages/instructQuote/searchable-dropdown";
import getCountries from "@lms/react-reference-data";
import _ from "lodash";
import { AuthContext } from "../../../utils/authProvider";

const uri = window?.config?.referenceDataUrl;

const ManualEntry = ({ id, state, setState, showValidationMessages }) => {
  const { getAccessToken } = useContext(AuthContext);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [countryLoading, setCountryLoading] = useState(false);

  const handleOnChange = (e, key) => updateState(key, e.currentTarget.value);

  const updateState = (key, value) => {
    const newState = _.cloneDeep(state);
    newState.data[key] = value;
    setState(newState);
  }

  useEffect(() => {
    if (state?.data?.country && countries) {
      var findCountry = countries.find(item => item.name === state.data.country);
      if (findCountry && findCountry.value !== selectedCountry) {
        setSelectedCountry(findCountry.value);
      }
    }
  }, [state, countries])

  useEffect(() => {
    if (countries) {
      var findCountry = countries.find(item => item.value === selectedCountry);
      if (findCountry && findCountry.name !== state.country) {
        updateState('country', findCountry.name);
      }
    }
  }, [selectedCountry, countries])

  useEffect(() => {
    async function load() {
      setCountryLoading(true);
      var response = await getCountries({ getAccessToken: getAccessToken, uri: uri });
      var map = response.map(item => ({ name: item.name, value: item.alpha2Code }));
      if (response) setCountries(map);
      setCountryLoading(false);
    }

    load();
  }, []);

  return <div className={css.manualcontainer}>
    <div className={classnames(css.flexcontainer, css.subsection)}>
      <div className={css.flexitem}>
        <div>
          <p>Enter the property address details below:</p>
          House name
          <FormControl
            onChange={(e) => handleOnChange(e, 'houseName')}
            id={`${id}-house-name`}
            placeholder="Enter house name"
            value={state.data.houseName}
          />
          { showValidationMessages && state.validation.houseName && (
            <p className={css.validationError}>{state.validation.houseName}</p>
          )}
        </div>

        <div className={css.childManualInput}>
          House number
          <FormControl
            onChange={(e) => handleOnChange(e, 'houseNumber')}
            id={`${id}-house-number`}
            placeholder="Enter house number"
            value={state.data.houseNumber}
          />
          { showValidationMessages && state.validation.houseNumber && (
            <p className={css.validationError}>{state.validation.houseNumber}</p>
          )}
        </div>

        <div className={css.childManualInput}>
          Street name
          <FormControl
            onChange={(e) => handleOnChange(e, 'streetName')}
            id={`${id}-street-name`}
            placeholder="Enter street name"
            value={state.data.streetName}
          />
          { showValidationMessages && state.validation.streetName && (
            <p className={css.validationError}>{state.validation.streetName}</p>
          )}
        </div>

        <div className={css.childManualInput}>
          District (optional)
          <FormControl
            onChange={(e) => handleOnChange(e, 'districtName')}
            id={`${id}-district-name`}
            placeholder="Enter district"
            value={state.data.districtName}
          />
          { showValidationMessages && state.validation.districtName && (
            <p className={css.validationError}>{state.validation.districtName}</p>
          )}
        </div>

        <div className={css.childManualInput}>
          Town
          <FormControl
            onChange={(e) => handleOnChange(e, 'postTown')}
            id={`${id}-town`}
            placeholder="Enter town"
            value={state.data.postTown}
          />
          { showValidationMessages && state.validation.postTown && (
            <p className={css.validationError}>{state.validation.postTown}</p>
          )}
        </div>

        <div className={css.childManualInput}>
          County (optional)
          <FormControl
            onChange={(e) => handleOnChange(e, 'county')}
            id={`${id}-county`}
            placeholder="Enter county"
            value={state.data.county}
          />
          { showValidationMessages && state.validation.county && (
            <p className={css.validationError}>{state.validation.county}</p>
          )}
        </div>

        <div className={css.childManualInput}>
          Postcode
          <FormControl
            onChange={(e) => handleOnChange(e, 'postCode')}
            id={`${id}-postcode`}
            placeholder="Enter postcode"
            value={state.data.postCode}
          />
          { showValidationMessages && state.validation.postCode && (
            <p className={css.validationError}>{state.validation.postCode}</p>
          )}
        </div>
        
        <div className={css.childManualInput}>
          <SearchableDropdown
            id={`${id}-country`}
            label="Country"
            placeholder={countryLoading ? "Loading..." : "Please select a country..."}
            value={selectedCountry}
            onChange={setSelectedCountry}
            list={countries}
            menuClassName={css.countryDropdown}
          />
          { showValidationMessages && state.validation.country && (
            <p className={css.validationError}>{state.validation.country}</p>
          )}
        </div>
      </div>
    </div>
  </div>
}

export default ManualEntry;