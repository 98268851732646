import { POPULATE_EMULATE_USER, FINISHED_GETTING_USER, START_EMULATING_USER, STOP_EMULATING_USER } from '../../actions/types';

const initialState = {
    finishedLoadingUser: false,
    user: null,
    startEmulating: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case POPULATE_EMULATE_USER:
            return {
                ...state,
                user: action.payload
            }
        case FINISHED_GETTING_USER:
            return {
                ...state,
                finishedLoadingUser: action.payload
            }
        case START_EMULATING_USER:
            return {
                ...state,
                startEmulating: action.payload
            }
        case STOP_EMULATING_USER:
            return initialState;
        default:
            return state;
    }
}
