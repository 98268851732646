import React from "react";
import PropTypes from "prop-types";
import Card from "react-bootstrap/Card";
import classnames from "classnames";
import css from "./preferencecard.module.css";

const PreferenceCard = ({ id, datatestid, image, title, content }) => (
  <Card id={id} data-testid={datatestid} className={css.card}>
    <Card.Header className={classnames(css.cardheader, css.flexcontainer)}>
      {image}
      <span className={classnames(css.leftpad2x, css.flexitem)}>{title}</span>
    </Card.Header>
    <Card.Body>{content}</Card.Body>
  </Card>
);

PreferenceCard.propTypes = {
  id: PropTypes.string.isRequired,
  datatestid: PropTypes.string.isRequired,
  image: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.element.isRequired,
};

export default PreferenceCard;
