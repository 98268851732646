import React, { useState } from "react";
import classnames from "classnames";
import css from "./casetype.module.css";
import ComponentSelector from "../selectors/component/componentselector";
import { useSelector } from "react-redux";
import store from "../../redux/store";
import { storeQuoteType } from "../../redux/actions/actions";
import {
  Sale,
  SaleSelected,
  PurchaseSelected,
  Purchase,
  SalePurchaseSelected,
  SalePurchase,
  RemortgageSelected,
  Remortgage,
  ToeSelected,
  Toe,
} from "../shared/imgs/index";
import { QuoteType, QuoteTypeDisplay } from "../../data/quote-type";

const CaseType = () => {
  const storeType = useSelector((store) => store.quote.type.value);

  const handleHistoryClick = (state) => store.dispatch(storeQuoteType(state));

  return (
    <div className={classnames(css.hiddenradio, css.flexcontainer)}>
      <ComponentSelector
        id="case_type_sale"
        datatestid="case_type_sale"
        value={QuoteType.SALE}
        label={QuoteTypeDisplay.SALE}
        caseTypeClass={css.flexitem}
        state={storeType}
        onChange={handleHistoryClick}
        selected={<SaleSelected className={css.radioImage} />}
        unselected={<Sale className={css.radioImage} />}
      />

      <span className={css.spacer}></span>

      <ComponentSelector
        id="case_type_purchase"
        datatestid="case_type_purchase"
        value={QuoteType.PURCHASE}
        label={QuoteTypeDisplay.PURCHASE}
        caseTypeClass={css.flexitem}
        state={storeType}
        onChange={handleHistoryClick}
        selected={<PurchaseSelected className={css.radioImage} />}
        unselected={<Purchase className={css.radioImage} />}
      />

      <span className={css.spacer}></span>

      <ComponentSelector
        id="case_type_sale_purchase"
        datatestid="case_type_sale_purchase"
        value={QuoteType.SALE_AND_PURCHASE}
        label={QuoteTypeDisplay.SALE_AND_PURCHASE}
        caseTypeClass={css.flexitem}
        state={storeType}
        onChange={handleHistoryClick}
        selected={<SalePurchaseSelected className={css.radioImage} />}
        unselected={<SalePurchase className={css.radioImage} />}
      />

      <span className={css.spacer}></span>

      <ComponentSelector
        id="case_type_remortgage"
        datatestid="case_type_remortgage"
        value={QuoteType.REMORTGAGE}
        label={QuoteTypeDisplay.REMORTGAGE}
        caseTypeClass={css.flexitem}
        state={storeType}
        onChange={handleHistoryClick}
        selected={<RemortgageSelected className={css.radioImage} />}
        unselected={<Remortgage className={css.radioImage} />}
      />

      <span className={css.spacer}></span>

      <ComponentSelector
        id="case_type_toe"
        datatestid="case_type_toe"
        value={QuoteType.TRANSFER_OF_EQUITY}
        label={QuoteTypeDisplay.TRANSFER_OF_EQUITY}
        caseTypeClass={css.flexitem}
        state={storeType}
        onChange={handleHistoryClick}
        selected={<ToeSelected className={css.radioImage} />}
        unselected={<Toe className={css.radioImage} />}
      />
    </div>
  );
};

export default CaseType;
