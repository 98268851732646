import React from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import Button from "../../components/button/button";
import css from "./Modal.module.css";

const GenericModal = ({
  show,
  onHide,
  heading,
  bodyContent,
  secondaryButtonText,
  primaryButtonText,
  onClickValue,
  children,
  contentClassName,
  dialogClassName,
  isActionLoading,
}) => (
  <Modal
    show={show}
    onHide={onHide}
    contentClassName={contentClassName}
    dialogClassName={dialogClassName}
  >
    <Modal.Header
      className={css.modalHeaderContainer}
      closeButton
      closeLabel="Cancel"
    >
      <Modal.Title className={css.modalHeader}>{heading}</Modal.Title>
    </Modal.Header>
    <Modal.Body className={css.modalText}>{bodyContent || children}</Modal.Body>
    <Modal.Footer className={css.modalFooter}>
      {secondaryButtonText &&
        <Button
          id="modal-secondary-button"
          datatestid="modal-secondary-button"
          variant="secondary"
          btnClass={css.secondarybutton}
          onClick={onHide}
          size="lg"
          text={secondaryButtonText}
          type="button"
        />
      }
      {primaryButtonText &&
        <Button
          id="modal-primary-button"
          datatestid="modal-primary-button"
          variant="primary"
          btnClass={css.primarybutton}
          onClick={onClickValue}
          size="lg"
          isLoading={isActionLoading}
          disabled={isActionLoading}
          text={primaryButtonText}
          type="button"
        />

      }
    </Modal.Footer>
  </Modal>
);

GenericModal.propTypes = {
  bodyContent: PropTypes.node,
  children: PropTypes.node,
  contentClassName: PropTypes.string,
  dialogClassName: PropTypes.string,
  heading: PropTypes.string.isRequired,
  onClickValue: PropTypes.func,
  onHide: PropTypes.func.isRequired,
  primaryButtonText: PropTypes.string,
  secondaryButtonText: PropTypes.string,
  show: PropTypes.bool.isRequired,
  isActionLoading: PropTypes.bool
};

export default GenericModal;
