import React, { useContext } from 'react';
import { Form, Container, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../components/button/button';
import EmulateResultRow from './EmulateResultRow';
import css from '../../dashboard.module.css';
import { EmulateContext } from '../EmulateContext';
import { startEmulatingUserSaga } from '../../../../redux/actions/emulateActions/emulate';

export default function EmulateResult(props) {
  const { showResult } = props;
  const { setModalShow } = useContext(EmulateContext);
  const dispatch = useDispatch();
  const userToEmulate = useSelector((store) => store.emulateUser.user);

  const handleChooseBroker = () => {
    dispatch(startEmulatingUserSaga());
    setModalShow(false);
  }

  if (showResult) {
    return (
      <>
        <Form className={css.emulateResultForm}>
          <EmulateResultRow id="formPlaintextBroker" label="Broker company name:" value={userToEmulate.companyName} />
          <EmulateResultRow id="formPlaintextLocal" label="Broker name:" value={`${userToEmulate.forenames} ${userToEmulate.surname}`} />
        </Form>
        <p>Click to confirm if this is correct or re-enter the broker information.</p>
        <Container className={css.actionButtonsContainer}>
          <Row>
            <Col xs="auto" className={css.actionButtons}>
              <Button
                id="modal-secondary-button"
                datatestid="modal-secondary-button"
                variant="secondary"
                btnClass={css.emulateModalSecondaryButton}
                onClick={() => setModalShow(false)}
                size="lg"
                text="CANCEL"
                type="button"
              />
            </Col>
            <Col xs="auto" className={css.actionButtons}>
              <Button
                id="modal-primary-button"
                datatestid="modal-primary-button"
                variant="primary"
                btnClass={css.emulateModalPrimaryButton}
                onClick={handleChooseBroker}
                size="lg"
                text="CHOOSE BROKER"
                type="button"
              />
            </Col>
          </Row>
        </Container>
      </>
    );
  }
  return null;
}

EmulateResult.propTypes = {
  showResult: PropTypes.bool.isRequired,
}