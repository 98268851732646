import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { AccordionContext } from 'react-bootstrap';
import { LinkClosed, LinkOpen } from '../shared/imgs';

function AccordionIconToggle({ eventKey }) {
    const currentEventKey = useContext(AccordionContext);
    const isCurrentEventKey = currentEventKey === eventKey;

    if (isCurrentEventKey) {
        return <LinkOpen style={{ width: '20px', marginLeft: '70px' }} />;
    }
    return <LinkClosed style={{ width: '20px', marginLeft: '70px' }} />;
}

AccordionIconToggle.propTypes = {
    eventKey: PropTypes.string.isRequired,
}

export default AccordionIconToggle;