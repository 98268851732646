import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Accordion } from "react-bootstrap";
import css from '../index.module.css';
import Table from '../../../components/table';
import { Button } from "react-bootstrap";
import classnames from 'classnames';

export default function AccordionCaseActivity(props) {
  const [filter, setFilter] = useState(null);
  const [data, setData] = useState([]);
  const [noDataMessage, setNoDataMessage] = useState("");

  const { oneCase, eventKey } = props;
  const { casePlanEntries, caseNotes } = oneCase;

  const formatDateTime = (datetime) => {
    if (!datetime) return null;
    const date = new Date(datetime);
    return date.toLocaleString();
  }

  const filterStates = {
    All: null,
    Steps: 'Case step',
    Notes: 'Case note'
  }

  const getAllUpdates = () => {
    const steps = casePlanEntries.filter((item) => item.actualEndDateTime).map((item) => {
      return {
        Date: formatDateTime(item.actualEndDateTime),
        Type: 'Case step',
        Details: item.taskDescription,
        Timestamp: Date.parse(item.actualEndDateTime)
      }
    });
  
    const notes = caseNotes.map((item) => {
      return {
        Date: formatDateTime(item.createdDateTime),
        Type: 'Case note',
        Details: item.bodyText,
        Timestamp: Date.parse(item.createdDateTime)
      }
    });

    const res = [
      ...notes,
      ...steps
    ];
    
    res.sort((a, b) => b.Timestamp - a.Timestamp)

    res.forEach((item) => delete item.Timestamp);

    return res;
  }

  const getSteps = () => {
    return casePlanEntries.map((item) => {
      return {
        Date: formatDateTime(item.actualEndDateTime),
        Name: item.taskName,
        Description: item.taskDescription,
        Status: item.status
      }
    });
  }

  const getNotes = () => {  
    return caseNotes.map((item) => {
      return {
        Date: formatDateTime(item.createdDateTime),
        Type: item.noteDescription,
        Details: item.bodyText,
        User: `${item.forenames} ${item.surname}`
      }
    });
  }

  useEffect(() => {
    filterActivity();
  }, [filter])

  useEffect(() => {
    if (filter === filterStates.Steps) setNoDataMessage("No case steps are available for this case.");
    else if (filter === filterStates.Notes) setNoDataMessage("No notes have been added to this case.");
    else setNoDataMessage("There is no activity for this case yet.");
  }, [filter])

  const filterActivity = () => {
    if (filter === filterStates.Steps) setData(getSteps());
    else if (filter === filterStates.Notes) setData(getNotes());
    else setData(getAllUpdates);
  }

  const handleAllUpdatesClick = () => {
    setFilter(filterStates.All);
  }

  const handleCaseStepClick = () => {
    setFilter(filterStates.Steps);
  }

  const handleCaseNotesClick = () => {
    setFilter(filterStates.Notes);
  }

  const Filter = () => (
    <div className={css.filter}>
      <Button
        variant="button"
        type="button"
        onClick={handleAllUpdatesClick}
        className={classnames(css.filterButton, filter === filterStates.All ? css.activeButton : '')}
      >
        All updates
      </Button>
      <Button
        variant="button"
        type="button"
        onClick={handleCaseStepClick}
        className={classnames(css.filterButton, filter === filterStates.Steps ? css.activeButton : '')}
      >
        Case steps
      </Button>
      <Button
        variant="button"
        type="button"
        onClick={handleCaseNotesClick}
        className={classnames(css.filterButton, filter === filterStates.Notes ? css.activeButton : '')}
      >
        Case notes
      </Button>
    </div>
  );

  return (
    <Accordion.Collapse eventKey={eventKey}>
      <div className={css.sectionContent}>
        <Filter />
        <Table data={data} noData={noDataMessage} />
      </div>
    </Accordion.Collapse>
  );
}

AccordionCaseActivity.propTypes = {
  oneCase: PropTypes.shape({
    lawFirmName: PropTypes.string,
    lawFirmBranchAddress: PropTypes.string,
    lawFirmBranchPhone:PropTypes.string,
    lawFirmBranchEmail: PropTypes.string,
    lawFirmFeeEarner: PropTypes.string,
  }).isRequired,
  eventKey: PropTypes.string.isRequired,
}