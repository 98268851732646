import { call, put, takeLatest, fork, select } from "redux-saga/effects";
import * as types from "../../actions/types"
import * as notifications from "../../../data/notifications"
import * as siteWideActions from "../../actions/site-wide-actions"
import { uinsureLinkComplete } from "../../actions/actions";

import postRequest from "../../../services/postRequest"

function* clearError() {
    yield put({
        type: types.STORE_UINSURE_LINK_ERROR,
        payload: ""
    })
}

function* error() {
    yield put({
        type: types.STORE_UINSURE_LINK_ERROR,
        payload: "We couldn't verify your UInsure account. Please check your input and try again"
    })
}

function* linkUinsure(e) {
    yield put({ type: types.SITE_BUSY, payload: true });
    yield put({ type: types.LINK_UINSURE_REQUEST_BUSY, payload: true });
    yield clearError();

    const request = {
        emailAddress: e.payload.emailAddress,
        password: e.payload.password
    };

    try {
        const res = yield call(postRequest, `${window.config.apiBaseUrl}/insurance`, request);

        const { status } = res;

        if (status === 202) {
            yield put(siteWideActions.NOTIFICATION_SHOW.action({show: true, message: notifications.linkUinsure.positive, type: "positive"}))
            yield put(uinsureLinkComplete(true));
        } else {
            yield error();            
        }

    } catch {
        yield error()
    }

    yield put({ type: types.SITE_BUSY, payload: false });
    yield put({ type: types.LINK_UINSURE_REQUEST_BUSY, payload: false });
}

function* saga() {
    yield takeLatest(types.LINK_UINSURE, linkUinsure);
}

const linkUinsureSaga = fork(saga);

export { linkUinsureSaga as default, linkUinsure, saga };