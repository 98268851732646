import React from 'react'
import PropTypes from 'prop-types'
import css from './index.module.css'
import classnames from 'classnames'

const Spinner = ({
    color="currentColor", 
    variant,
    datatestid,
}) => {
    return (
        <div className={classnames({[variant]: variant})} data-testid={datatestid}>
            <div className={css.spinner}>
                <svg
                    viewBox="0 0 32 32"
                    aria-hidden="true"
                    focusable="false"
                    >
                        <circle
                            cx="16"
                            cy="16"
                            r="14.5"
                            fill="none"
                            stroke={color}
                            strokeWidth="3"
                            strokeMiterlimit="10"
                        />
                </svg>
            </div>
        </div>
    )
}

Spinner.propTypes = {
    color: PropTypes.string,
    variant: PropTypes.string,
    datatestid: PropTypes.string,
}

export default Spinner