import { call, put, takeLatest, fork, select } from "redux-saga/effects";
import { SITE_ERROR, SITE_BUSY, FINISHED_GETTING_USER, POPULATE_EMULATE_USER, GET_EMULATE_USER } from "../../actions/types";
import getRequest from '../../../services/getRequest';
import showError from "../utils/error";

function* getEmulateUser(action) {
  try {
    yield put({ type: SITE_BUSY, payload: true });
    yield put({ type: FINISHED_GETTING_USER, payload: false });

    const res = yield call(
      getRequest,
      `${window.config.apiBaseUrl}/users/${action.payload}`
    );

    const { status, data } = res;

    if (status === 200) {
      yield put({ type: SITE_ERROR, payload: false });
      yield put({ type: POPULATE_EMULATE_USER, payload: data });
    } else {
      yield put({ type: SITE_ERROR, payload: true });
      yield showError(res);
    }
    yield put({ type: FINISHED_GETTING_USER, payload: true });
    yield put({ type: SITE_BUSY, payload: false });
  } catch (e) {
    yield showError(e);
    yield put({ type: SITE_ERROR, payload: true });
    yield put({ type: SITE_BUSY, payload: false });
    yield put({ type: FINISHED_GETTING_USER, payload: true });
  }
}

function* saga() {
  yield takeLatest(GET_EMULATE_USER, getEmulateUser);
}

const getEmulateUserSaga = fork(saga);

export { getEmulateUserSaga as default, getEmulateUser, saga };
