import React from "react";
import PropTypes from "prop-types";

const ComponentSelector = ({
  id,
  datatestid,
  value,
  label,
  caseTypeClass,
  selected,
  unselected,
  onChange,
  state,
}) => (
  <div className={caseTypeClass} id={id} datatestid={id}>
    <label>
      <input
        id={id}
        data-testid={datatestid}
        type="radio"
        name="caseType"
        value={value}
        onChange={() => onChange(value)}
      />
      {state == value ? selected : unselected}
    </label>
    {label ?? value}
  </div>
);

ComponentSelector.propTypes = {
  id: PropTypes.string.isRequired,
  datatestid: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  caseTypeClass: PropTypes.string.isRequired,
  selected: PropTypes.element.isRequired,
  unselected: PropTypes.element.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ComponentSelector;
