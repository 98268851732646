import { put, takeLatest, fork, select, call } from 'redux-saga/effects';
import * as types from '../../actions/types';
import * as siteWideActions from '../../actions/site-wide-actions';
import * as notifications from '../../../data/notifications';
import postRequest from '../../../services/postRequest';
import getRequest from '../../../services/getRequest';
import showError from "../utils/error";

function* getAllPreferences() {
  try {
    const preferences = yield select((s) => s.preferences);
    if (preferences.loaded.value) return;

    yield put({ type: types.SITE_BUSY, payload: true });
    
    const res = yield call(getRequest, `${window.config.apiBaseUrl}/preferences`);

    const { data: payload, status } = res;
    if (status === 200) {
      const loadedPreferences = {
        ...payload,
        loaded: true,
      };
      yield put({
        type: types.SET_PREFERENCES,
        payload: loadedPreferences,
      });
      yield put({ type: types.SITE_ERROR, payload: false });
    } else {
      yield showError(res);
      yield put({ type: types.SITE_ERROR, payload: true });
    }

    yield put({ type: types.SITE_BUSY, payload: false });
  } catch (e) {
    yield showError(e);
    yield put({ type: types.SITE_ERROR, payload: true });
  }
}

function* saveAllPreferences() {
  try {
    yield put({ type: types.SITE_BUSY, payload: true });

    const preferences = yield select((s) => s.preferences);

    var request = {
      UinsurePreferences: {
        quotingType: preferences.uinsure.quotingType.value
      },
      defaultReferralFee: preferences.defaultReferralFee.value,
      jurisdiction: preferences.jurisdiction.value,
      receiveCaseUpdates: preferences.updateType.value,
      preferredPricingModel: preferences.preferredPricingModel.value,
      preferredSortOrder: preferences.preferredSortOrder.value,
    };

    const res = yield call(
      postRequest,
      `${window.config.apiBaseUrl}/preferences`,
      request
    );

    const { status, data: payload } = res;

    if (status === 200) {
      yield put({ type: types.SITE_BUSY, payload: false });
      yield put({ type: types.SITE_ERROR, payload: false });
      yield put({ type: types.STORE_PREFERENCE_LAST_UPDATED, payload: new Date() });

      const loadedPreferences = {
        ...payload,
        loaded: true,
      };

      yield put({ type: types.SET_PREFERENCES, payload: loadedPreferences });
      yield put(siteWideActions.NOTIFICATION_SHOW.action({ show: true, message: notifications.preferences.positive, type: 'positive' }))
    } else {
      yield put({ type: types.SITE_ERROR, payload: true });
      yield put(siteWideActions.NOTIFICATION_SHOW.action({ show: true, message: notifications.preferences.negative, type: 'negative' }))
    }
  } catch (e) {
    yield put({ type: types.SITE_ERROR, payload: true });
    yield put(siteWideActions.NOTIFICATION_SHOW.action({ show: true, message: notifications.preferences.negative, type: 'negative' }))
  }
}

function* saga() {
  yield takeLatest(types.PREFERENCES_VALIDATION_SUCCESS, saveAllPreferences);
  yield takeLatest(types.GET_PREFERENCES, getAllPreferences);
  yield takeLatest(types.GET_USER_SETTINGS, getAllPreferences);
}

const settingsSaga = fork(saga);

export { settingsSaga as default, saveAllPreferences, saga };
