import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

const connectionString = window?.config?.appInsightsConnectionString;
const hasConnectionString = connectionString !== undefined;

const reactPlugin = new ReactPlugin();

const appInsights = new ApplicationInsights({
  config: {
    connectionString: hasConnectionString ? connectionString : '',
    extensions: [reactPlugin],
    extensionConfig: {},
    enableAutoRouteTracking: true,
    disableAjaxTracking: false,
    autoTrackPageVisitTime: true,
    enableCorsCorrelation: false,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    correlationHeaderExcludedDomains: [],
  },
});

if (hasConnectionString) {
  appInsights.loadAppInsights();

  appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => {
    const e = { ...envelope };
    e.tags = envelope.tags || [];
    e.tags['ai.cloud.role'] = 'frontend-select-ui';
  });
}

export { reactPlugin, appInsights };
