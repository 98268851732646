import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const User = ({ id, datatestid, userClass }) => {
  const preferences = useSelector((store) => store.preferences);
  const emulatedUsername = useSelector((store) => store.emulateUser.user);
  const isEmulating = useSelector((store) => store.emulateUser.startEmulating);
  const { forenames } = preferences;
  const [user, setUser] = useState(null);

  useEffect(() => {
    async function init() {
        let user;
        if (forenames) {
          user = forenames;
        }
        if (isEmulating) {
          const { forenames: emulateForenames } = emulatedUsername;
          user = emulateForenames
        }
        setUser(user);
    }
    init();
  }, [forenames, isEmulating]);

  if (user) {
    return (
      <span id={id} className={userClass} data-testid={datatestid}>
        {user}
      </span>
    );
  }
  return null;
};

User.propTypes = {
  id: PropTypes.string.isRequired,
  datatestid: PropTypes.string.isRequired,
  userClass: PropTypes.string.isRequired,
};

export default User;
