import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import GenericModal from "../../../../modals/GenericModal/GenericModal";
import css from './index.module.css';
import LabelledTextInput from "../../../common/components/labelled-text-input";
import LabelledTextarea from "../../../common/components/labelled-textarea";
import { useSelector } from "react-redux";
import { useSelectApi } from "../../../../hooks/selectApi";
import { useNotifications } from "../../../../hooks/notifications";
import * as notifications from '../../../../data/notifications';
import { isBlank } from "../../../../utils/jsutils";
import ValidationGroup from "../../../../components/validation-group";
import Address from "../../../../components/address";
import _ from "lodash";
import { mapAddress } from "../../../../mappers/quote";

const SendQuoteModal = ({
  show,
  onHide,
  actionButtonText = "SEND QUOTE",
  quoteIds,
  quoteGroupId,
  insuranceReference,
  insurancePremium,
  requiresSave = false

}) => {
  const { sendQuotes, saveQuote } = useSelectApi();
  const { showSuccess, showError } = useNotifications();

  const defaultValue = { value: '', validationMessage: '' };
  const brokerEmailAddress = useSelector(store => store.preferences?.contactEmail?.value);
  const [emailAddress, setEmailAddress] = useState(_.cloneDeep(defaultValue));
  const [firstName, setFirstName] = useState(_.cloneDeep(defaultValue));
  const [surname, setSurname] = useState(_.cloneDeep(defaultValue));
  const [address, setAddress] = useState();
  const [message, setMessage] = useState(_.cloneDeep(defaultValue));
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formValidated, setFormValidated] = useState(false);

  const updateField = (get, set, value) => set({ ...get, validationMessage: null, value });
  const updateFieldValidation = (get, set, value) => set({ ...get, validationMessage: value });

  const reset = () => {
    setEmailAddress(_.cloneDeep(defaultValue));
    setMessage(_.cloneDeep(defaultValue));
    setFirstName(_.cloneDeep(defaultValue));
    setSurname(_.cloneDeep(defaultValue));
    setAddress(null);
  }

  const handleHide = () => {
    reset();
    onHide();
  }

  const validationHandler = (isValid) => setAddress({ ...address, isValid });

  const validate = () => {
    setFormValidated(true);
    let isValid = true;

    if (isBlank(emailAddress?.value)) {
      updateFieldValidation(emailAddress, setEmailAddress, "Please enter an email address")
      isValid = false;
    }

    if (requiresSave) {
      if (isBlank(firstName?.value)) {
        updateFieldValidation(firstName, setFirstName, "Please enter a first name");
        isValid = false;
      }
      if (isBlank(surname?.value)) {
        updateFieldValidation(surname, setSurname, "Please enter a surname");
        isValid = false;
      }

      isValid = isValid && address.isValid;
    }

    return isValid;
  }

  const updateQuotes = async () => {
    let success = true;

    success = await saveQuote({
      quoteId: quoteIds[0],
      quoteGroupId,
      firstName: firstName?.value,
      surname: surname?.value,
      correspondenceAddress: mapAddress(address),
      insuranceReference,
      insurancePremium
    });

    if (success && quoteIds.length > 1) {
      success = await saveQuote({
        quoteId: quoteIds[1],
        quoteGroupId,
        firstName: firstName?.value,
        surname: surname?.value,
        correspondenceAddress: mapAddress(address),
        insuranceReference,
        insurancePremium
      });
    }

    return success;
  }

  const handleSubmit = async () => {
    if (!validate()) return;

    setFormSubmitted(true);

    let success = true;
    if (requiresSave) success = await updateQuotes();

    if (success) success = await sendQuotes({
      emailAddress: emailAddress?.value,
      message: message?.value,
      quoteGroupId,
      quoteIds
    });

    setFormSubmitted(false);

    if (success) {
      showSuccess(notifications.sendQuote.positive);
      handleHide();
    }
    else showError(notifications.sendQuote.negative);
  }

  return (
    <GenericModal
      show={show}
      onHide={handleHide}
      heading="SEND QUOTE"
      contentClassName={css["dialog-content"]}
      dialogClassName={css["dialog"]}
      secondaryButtonText="CANCEL"
      primaryButtonText={actionButtonText}
      isActionLoading={formSubmitted}
      onClickValue={() => handleSubmit()}
    >
      <Row className={css["dialog-body"]}>
        <Col>
          <Row>
            <p className={css["text-primary"]}>Add your customer’s details to send the quote to a customer as a PDF file.</p>
          </Row>
          <Row>
            <Form className={css["save-quote-form"]}>
              {(requiresSave &&
                <>
                  <Form.Group controlId="customerFirstName">
                    <LabelledTextInput
                      label="Customer first name"
                      placeholder="First name"
                      onChange={(e) => updateField(firstName, setFirstName, e)}
                      value={firstName?.value}
                      errorMessages={firstName?.validationMessage ? [firstName?.validationMessage] : []}
                    />
                  </Form.Group>
                  <Form.Group controlId="customerSurname">
                    <LabelledTextInput
                      label="Customer surname"
                      placeholder="Surname"
                      onChange={(e) => updateField(surname, setSurname, e)}
                      value={surname?.value}
                      errorMessages={surname?.validationMessage ? [surname?.validationMessage] : []}
                    />
                  </Form.Group>
                  <ValidationGroup submitted={formValidated} validationHandler={validationHandler}>
                    <Address
                      label="Correspondence address"
                      onChange={setAddress}
                      initialState={address}
                    />
                  </ValidationGroup>
                </>
              )}
              <Form.Group controlId="emailAddress">
                <LabelledTextInput
                  label="Recipient email address"
                  placeholder="example@lms.com"
                  datatestid="emailAddress"
                  type="email"
                  onChange={(e) => updateField(emailAddress, setEmailAddress, e)}
                  value={emailAddress?.value}
                  errorMessages={emailAddress?.validationMessage ? [emailAddress?.validationMessage] : []}
                />
              </Form.Group>
              <Form.Group controlId="emailContent">
                <LabelledTextarea
                  label="Email content"
                  infoText="(optional)"
                  placeholder="Add your message"
                  datatestid="emailContent"
                  onChange={(e) => updateField(message, setMessage, e)}
                  value={message?.value}
                />
              </Form.Group>
            </Form>
            {brokerEmailAddress && (
              <p>This quote will be emailed to you at {brokerEmailAddress}</p>
            )}
          </Row>
        </Col>
      </Row>
    </GenericModal>
  );
};

SendQuoteModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  caseType: PropTypes.string,
  actionButtonText: PropTypes.string,
  quoteGroupId: PropTypes.string,
  quoteIds: PropTypes.arrayOf(PropTypes.string)
};

export default SendQuoteModal;
