import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Form from "react-bootstrap/Form";
import css from "./checkboxselector.module.css";
import PropTypes from "prop-types";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { QuestionCircleFill } from "react-bootstrap-icons";

const CheckboxSelector = ({
  checkboxes,
  persistaction,
  datatestid,
  storePath,
  checkedState=false,
  checkboxref,
  checkboxclickevent=() => {},
  disabled=false
}) => {
  const dispatch = useDispatch()
  const [currentValues, setCurrentValue] = useState([{...checkboxes[0]}]);
  const [checked, setChecked] = useState(checkedState)

  const setValue = (id, isChecked) => {
    currentValues[id].checked = isChecked;
    setChecked(isChecked)
    setCurrentValue(currentValues);
    checkboxclickevent(isChecked)
    dispatch(persistaction(currentValues, storePath));
  };

  useEffect(() =>{
    setChecked(checkedState)
  }, [checkedState])

  return (
      <Form.Group className={css.flexcontainer}>
        {currentValues.map((checkbox, index) => {
          const id = `${datatestid}-${checkbox.value}`;
          return (
            <div key={checkbox.value} className={css.flexitem}>
              <Form.Check
                className={css.checkbox}
                data-testid={id}
                key={checkbox.value}
                id={id}
                type="checkbox"
                label={checkbox.name}
                onChange={(e) => setValue(index, e.target.checked)}
                checked={checked}
                ref={checkboxref}
                disabled={disabled}
              />

              {checkbox.overlay === true && (
                <OverlayTrigger
                  key="top"
                  placement="top"
                  overlay={
                    <Popover id="popover-basic">
                      <Popover.Content>{checkbox.overlaytext}</Popover.Content>
                    </Popover>
                  }
                >
                  <QuestionCircleFill className={css.infoIcon} />
                </OverlayTrigger>
              )}
            </div>
          );
        })}
      </Form.Group>
  );
};

CheckboxSelector.propTypes = {
  checkboxes: PropTypes.arrayOf(PropTypes.object),
  persistaction: PropTypes.func.isRequired,
  datatestid: PropTypes.string.isRequired,
  storePath: PropTypes.string,
  checkedState: PropTypes.bool,
  checkboxref: PropTypes.object,
  checkboxclickevent: PropTypes.func
};

export default CheckboxSelector;
