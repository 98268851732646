const generateCustomerPropChangeAction = (type) => (payloadName) => {
  const actionCreator = (idx, value, isRelatedQuote) => {
    const action = {
      type: `INSTRUCT_CUSTOMER_${type}`,
      idx,
      isRelatedQuote: isRelatedQuote ? true : false 
    };

    action[payloadName] = value;

    return action;
  };

  return {
    type: `INSTRUCT_CUSTOMER_${type}`,
    action: actionCreator,
  };
};

const generateEventAction = (type) => ({
  type: `INSTRUCT_CUSTOMER_${type}`,
  action: (idx, isRelatedQuote) => ({
    type: `INSTRUCT_CUSTOMER_${type}`,
    idx,
    isRelatedQuote: isRelatedQuote ? true : false
  }),
});

export const UPDATE_TITLE = generateCustomerPropChangeAction("UPDATE_TITLE")(
  "newTitle"
);

export const UPDATE_FIRST_NAME = generateCustomerPropChangeAction(
  "UPDATE_FIRST_NAME"
)("newFirstName");

export const UPDATE_SURNAME = generateCustomerPropChangeAction(
  "UPDATE_SURNAME"
)("newSurname");

export const UPDATE_ADDRESS = generateCustomerPropChangeAction(
  "UPDATE_ADDRESS"
)("newAddress");

export const UPDATE_DATE_OF_BIRTH = generateCustomerPropChangeAction(
  "UPDATE_DATE_OF_BIRTH"
)("newDateOfBirth");

export const POPULATE_AUTOMATIC_ADDRESS = generateCustomerPropChangeAction(
  "POPULATE_AUTOMATIC_ADDRESS"
)("populateAutomaticAddress");

export const UPDATE_AUTOMATIC_ADDRESS = generateCustomerPropChangeAction(
  "UPDATE_AUTOMATIC_ADDRESS"
)("newAutomaticAddress");

export const UPDATE_AUTOMATIC_ADDRESS_LABEL = generateCustomerPropChangeAction(
  "UPDATE_AUTOMATIC_ADDRESS_LABEL"
)("newAutomaticAddressLabel");

export const UPDATE_MANUAL_HOUSE_NAME = generateCustomerPropChangeAction(
  "UPDATE_MANUAL_HOUSE_NAME"
)("newManualHouseName");

export const UPDATE_MANUAL_HOUSE_NUMBER = generateCustomerPropChangeAction(
  "UPDATE_MANUAL_HOUSE_NUMBER"
)("newManualHouseNumber");

export const UPDATE_MANUAL_STREET_NAME = generateCustomerPropChangeAction(
  "UPDATE_MANUAL_STREET_NAME"
)("newManualStreetName");

export const UPDATE_MANUAL_DISTRICT = generateCustomerPropChangeAction(
  "UPDATE_MANUAL_DISTRICT"
)("newManualDistrict");

export const UPDATE_MANUAL_POST_TOWN = generateCustomerPropChangeAction(
  "UPDATE_MANUAL_POST_TOWN"
)("newManualPostTown");

export const UPDATE_MANUAL_COUNTY = generateCustomerPropChangeAction(
  "UPDATE_MANUAL_COUNTY"
)("newManualCounty");

export const UPDATE_MANUAL_POSTCODE = generateCustomerPropChangeAction(
  "UPDATE_MANUAL_POSTCODE"
)("newManualPostcode");

export const UPDATE_MANUAL_COUNTRY = generateCustomerPropChangeAction(
  "UPDATE_MANUAL_COUNTRY"
)("newManualCountry");

export const UPDATE_USED_MANUAL_ADDRESS_ENTRY = generateCustomerPropChangeAction(
  "UPDATE_USED_MANUAL_ADDRESS_ENTRY"
)("newUsedManualAddressEntry");

export const UPDATE_EMAIL = generateCustomerPropChangeAction("UPDATE_EMAIL")(
  "newEmail"
);

export const UPDATE_MOBILE_NUMBER_CALLING_CODE = generateCustomerPropChangeAction(
  "UPDATE_MOBILE_NUMBER_CALLING_CODE"
)("newMobileCallingCode");

export const UPDATE_MOBILE_NUMBER = generateCustomerPropChangeAction(
  "UPDATE_MOBILE_NUMBER"
)("newMobile");

export const UPDATE_WORK_NUMBER_CALLING_CODE = generateCustomerPropChangeAction(
  "UPDATE_WORK_NUMBER_CALLING_CODE"
)("newWorkPhoneNumberCallingCode");

export const UPDATE_WORK_NUMBER = generateCustomerPropChangeAction(
  "UPDATE_WORK_NUMBER"
)("newWorkPhoneNumber");

export const UPDATE_HOME_NUMBER_CALLING_CODE = generateCustomerPropChangeAction(
  "UPDATE_HOME_NUMBER_CALLING_CODE"
)("newHomePhoneNumberCallingCode");

export const UPDATE_HOME_NUMBER = generateCustomerPropChangeAction(
  "UPDATE_HOME_NUMBER"
)("newHomePhoneNumber");

export const POPULATE_RELATED_AUTOMATIC_ADDRESS = generateCustomerPropChangeAction(
  "POPULATE_RELATED_AUTOMATIC_ADDRESS"
)("populateAutomaticAddress");

export const UPDATE_RELATED_TITLE = generateCustomerPropChangeAction(
  "UPDATE_RELATED_TITLE"
)("newTitle");

export const UPDATE_RELATED_FIRST_NAME = generateCustomerPropChangeAction(
  "UPDATE_RELATED_FIRST_NAME"
)("newFirstName");

export const UPDATE_RELATED_SURNAME = generateCustomerPropChangeAction(
  "UPDATE_RELATED_SURNAME"
)("newSurname");

export const SUBMIT_CUSTOMER = generateCustomerPropChangeAction("SUBMIT_CUSTOMER")("customer");

export const UNSUBMIT_CUSTOMER = generateCustomerPropChangeAction("UNSUBMIT_CUSTOMER")("unsubmitCustomer");

export const ADD_NEW_CUSTOMER = generateEventAction("ADD_NEW_CUSTOMER");

export const REMOVE_CUSTOMER = generateCustomerPropChangeAction("REMOVE_CUSTOMER")("removeCustomer");

export const VALIDATE_CUSTOMERS_SECTION = generateEventAction(
  "VALIDATE_CUSTOMERS_SECTION"
);
