import React from "react";

// presentation
import classnames from "classnames";
import css from "../quote.module.css";
import { Row, Col } from "react-bootstrap";

const CheckboxApply = ({ children, isTOEActive = "" }) => {
  return (
    <>
      <Row className={css.label}>
        <Col
          md={12}
          xs={12}
          className={classnames(css.leftalign, css.leftpad2x)}
        >
          Do any of these apply?
        </Col>
      </Row>

      <Row>
        <Col md={12} xs={12} className={classnames(css.traitsCardVisible)}>
          {children}
        </Col>
      </Row>
    </>
  );
};

export default CheckboxApply;
