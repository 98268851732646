import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from "react-router"
import PropTypes from "prop-types"
import { Row, Col } from "react-bootstrap"
import css from "./index.module.css"
import Button from "../../components/button/button"
import GiResultsTable from "../../components/giResultsTable";
import Layout from '../../layout';

import { getUinsureQuote, storeUinsureSelectedQuote } from "../../redux/actions/actions"

const GeneralInsuranceQuoteResults = ({ getAccessToken }) => {
    const history = useHistory()
    const dispatch = useDispatch()
    const [selectedQuote, setSelectedQuote] = useState()
    const [isDisabled, setIsDisabled] = useState(true)
    const isLoading = useSelector((store) => store.uinsure.isLoading);
    const giQuote = useSelector((store) => store.uinsure);
    const quoteGroupId = useSelector((store) => store.quote.quotesReceived.quoteGroupId);
    const handleSubmit = async (e) => {
        e.preventDefault()
        if (selectedQuote) {
            await dispatch(storeUinsureSelectedQuote(selectedQuote))
        }
        history.push(`/view-quote`)

    }

    useEffect(() => {
        getAccessToken().then(() => {
            dispatch(getUinsureQuote({ quoteGroupId }))
        })
    }, [])

    useEffect(() => {
        if (giQuote.error) {
            setIsDisabled(false);
        }
    }, [giQuote])

    const handleQuoteSelected = (quote) => {
        setSelectedQuote(quote)
        setIsDisabled(false)
    }

    return (
        <Layout classname={css.container}>
            <Row>
                <Col md={1} xs={1}></Col>
                <Col md={10} xs={10}>
                    <GiResultsTable
                        data={giQuote.quote}
                        onQuoteSelect={(quote, premium) => handleQuoteSelected({quote, premium})}
                        isLoading={isLoading}
                        error={giQuote.error}
                        errorMessage={giQuote.errorMessage}
                    />
                    <Row>
                        <Col>
                            <div className={css.actionContainer}>
                                <Button
                                    id="ginextbuton"
                                    datatestid="ginextbutton"
                                    text="Next"
                                    type="button"
                                    onClick={(e) => handleSubmit(e)}
                                    disabled={isDisabled}
                                />
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col md={1} xs={1}></Col>
            </Row>
        </Layout>
    )
}

GeneralInsuranceQuoteResults.propTypes = {
    getAccessToken: PropTypes.func.isRequired,
    pathSlugs: PropTypes.shape({
        params: PropTypes.shape({
            quoteType: PropTypes.string.isRequired,
            productId: PropTypes.string.isRequired,
            officeId: PropTypes.string.isRequired,
        }),
    }),
}

export default GeneralInsuranceQuoteResults