import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import classnames from 'classnames';
import css from '../quote.module.css';
import { getCheckedState, getJurisdiction, getQuote } from '../../../utils/jsutils';
import {
  storeQuoteTenure,
  storeQuoteLtdCompany,
  storeQuoteTransferRequired,
  storeQuoteMainResidence,
  storeQuoteOwners,
  storeQuoteOwnersAfterTransfer,
  storeQuoteExpatsTrait,
  storeQuoteNumberOfExpats,
  storeQuoteNewLender,
  storeQuoteDeedOfPostponementTrait,
  storeQuoteJointProprietorTrait,
  storeQuoteSoleProprietorTrait,
  storeQuoteSharedOwnershipTrait,
  storeQuotePropertyType,
  storeQuoteCustomerLocation,
  storeQuoteMortgage,
  storeQuoteOtherResidence,
  storeQuotePropertyAddress,
  storeQuoteTransactionAmount,
  storeDateOfBirth,
  storeQuoteNumberOfBedrooms,
  storeQuoteYearBuilt,
  storeQuoteHelpToBuyLoanTrait,
  storeQuoteStudentProperty,
  storeQuoteHMOTrait,
  storeQuoteGiftedDepositTrait,
  storeQuoteNumberOfGiftedDeposits
} from '../../../redux/actions/actions';
import {
  yesno,
  propertyOwners,
  expatsTrait,
  additionalTraitValues,
  deedOfPostponementTrait,
  giftedDepositTrait,
  jointProprietorTrait,
  soleProprietorTrait,
  sharedOwnershipTrait,
  propertyType,
  otherresidences,
  giYearBuilt,
  numberOfBedrooms,
  helpToBuyLoanRedemptionTrait,
  hmoTrait,
} from '../../../components/shared/data';
import Price from '../components/price';
import DropdownSelectorLayout from '../components/dropdown-selector-layout';
import DropdownSelectorSubLayout from '../components/dropdown-selector-sub-layout';
import ButtonSelectorLayout from '../components/button-selector-layout';
import CheckboxApply from '../components/checkbox-apply';
import CheckboxSelector from '../../../components/selectors/checkbox/checkboxselector';
import DateInput from '../../../components/dateInput';
import SearchableDropdown from '../../instructQuote/searchable-dropdown';
import ButtonSelectorSubLayout from '../components/button-selector-sub-layout';
import ValidationGroup from '../../../components/validation-group';
import Address from '../../../components/address';
import { QuoteType } from '../../../data/quote-type';
import Tenure from '../components/tenure';
import { jurisdictions } from "../../../components/shared/data";
import CustomerLocation from '../../../components/customerLocation';

const Remortgage = ({
  caseType = QuoteType.REMORTGAGE,
  storePath = null,
  submitted,
  validationHandler,
}) => {
  const dispatch = useDispatch();
  const region = useSelector((store) => getJurisdiction(store, caseType, storePath));
  const getCurrentQuote = (store) => getQuote(store, caseType, storePath);
  const customerLocationState = () => useSelector((store) => getCurrentQuote(store).customerLocation.value);

  const isMainResidence = useSelector(
    (store) => store.quote.remortgage.mainresidence.value,
  );
  const isGiftedDepositChecked = getCheckedState(caseType, 'gifteddeposittrait');
  const isExpatsChecked = getCheckedState(caseType, 'expatstrait');
  const isHelpToBuyLoanChecked = getCheckedState(caseType, 'helptobuyloantrait');
  const isHMOChecked = getCheckedState(caseType, 'hmotrait');
  const isDeedOfPostponementChecked = getCheckedState(
    caseType,
    'deedofpostponementtrait',
  );
  const isJointProprietorChecked = getCheckedState(
    caseType,
    'jointproprietortrait',
  );
  const isSoleProprietorChecked = getCheckedState(
    caseType,
    'soleproprietortrait',
  );
  const isSharedOwnershipChecked = getCheckedState(
    caseType,
    'sharedownershiptrait',
  );

  const isTransferRequired = useSelector(
    (store) => store.quote.remortgage.transferrequired.value,
  );

  const homeOwnersCount = useSelector(
    (store) => store.quote[caseType].owners.value,
  );
  const giPreference = useSelector((store) => store.preferences.uinsure?.quotingType?.value);

  const showGIFields = giPreference === 'AlwaysIncludeGeneralInsuranceQuote' || giPreference === 'PassOnLeadWithoutQuote';

  useEffect(() => {
    // reset redux store
    if (!isExpatsChecked) {
      dispatch(storeQuoteNumberOfExpats(null, storePath));
    }
    if (!isGiftedDepositChecked) {
      dispatch(storeQuoteNumberOfGiftedDeposits(null, storePath));
    }
  }, [isExpatsChecked, isGiftedDepositChecked, showGIFields]);

  useEffect(() => {
    // If Joint Proprietor is checked set Sole Proprietor to unchecked
    if (isJointProprietorChecked) {
      dispatch(storeQuoteSoleProprietorTrait(null, storePath));
    }
  }, [isJointProprietorChecked]);

  useEffect(() => {
    // If Sole Proprietor is checked set Joint Proprietor to unchecked
    if (isSoleProprietorChecked) {
      dispatch(storeQuoteJointProprietorTrait(null, storePath));
    }
  }, [isSoleProprietorChecked]);

  useEffect(() => {
    // If Transfer Required set to 'No' then reset Owners After Transfer
    if (isTransferRequired === '2') {
      dispatch(storeQuoteOwnersAfterTransfer(null, storePath));
    }
  }, [isTransferRequired]);

  const lenders = useSelector((store) => store.lenders)?.data;

  useEffect(() => {
    if (isMainResidence === '1') {
      dispatch(storeQuoteOtherResidence(null, storePath));
    }
  }, [isMainResidence]);

  const lenderVal = useSelector(
    (store) => store.quote[caseType].newLender.value,
  );
  const lenderState = lenders.find((l) => l.value === lenderVal)?.value;

  const propertyAddress = useSelector((store) => store.quote[caseType].propertyAddress?.value);

  return (
    <>
      <div className={css.toppad2x} />
      <ValidationGroup submitted={submitted} validationHandler={validationHandler}>
        <Address
          className={css.addressContainer}
          onChange={(e) => dispatch(storeQuotePropertyAddress(e))}
          initialState={propertyAddress}
        />
      </ValidationGroup>

      <Price
        title="Mortgage Amount"
        id="currency-input"
        datatestid="currency-input"
        placeholder="Amount"
        persistaction={storeQuoteTransactionAmount}
        errorMessage={(store) => store.quote.remortgage.transactionAmount.validation.message}
        value={useSelector(
          (store) => store.quote[caseType].transactionAmount.value,
        )}
      />

      <Tenure 
        region={region}
        persistAction={storeQuoteTenure}
        storePath={storePath}
        selector={(store) => getCurrentQuote(store).tenure.value}
        errorMessage={(store) =>
          getCurrentQuote(store).tenure.validation.message
        }  
      />

      <ButtonSelectorLayout
        title="Is there a current mortgage to be redeemed?"
        id="mortgage"
        datatestid="mortgage"
        buttons={yesno}
        groupname="mortgage"
        persistAction={storeQuoteMortgage}
        selector={(store) => store.quote.remortgage.mortgage.value}
        errorMessage={(store) => store.quote.remortgage.mortgage.validation.message}
      />

      <ButtonSelectorLayout
        title="Is the property being remortgaged in a limited company name?"
        id="limitedcompany"
        datatestid="ltdcompany"
        buttons={yesno}
        groupname="ltdcompany"
        persistAction={storeQuoteLtdCompany}
        selector={(store) => store.quote.remortgage.ltdcompany.value}
        errorMessage={(store) => store.quote.remortgage.ltdcompany.validation.message}
      />

      <ButtonSelectorLayout
        title="Is this the customer's main residence?"
        id="mainresidence"
        datatestid="mainresidence"
        buttons={yesno}
        groupname="mainresidence"
        persistAction={storeQuoteMainResidence}
        selector={(store) => store.quote.remortgage.mainresidence.value}
        errorMessage={(store) => store.quote.remortgage.mainresidence.validation.message}
      />

      {isMainResidence === '2' && (
        <ButtonSelectorLayout
          title="If not the main residence, is the property?"
          id="otherresidence"
          datatestid="otherresidence"
          buttons={otherresidences}
          groupname="otherresidence"
          persistAction={storeQuoteOtherResidence}
          selector={(store) => store.quote.remortgage.otherresidence.value}
          errorMessage={(store) => store.quote.remortgage.otherresidence.validation.message}
        />
      )}

      <ButtonSelectorLayout
        title="Is a Transfer of Equity required?"
        id="transferrequired"
        datatestid="transferrequired"
        buttons={yesno}
        groupname="transferrequired"
        persistAction={storeQuoteTransferRequired}
        selector={(store) => store.quote.remortgage.transferrequired.value}
        errorMessage={(store) => store.quote.remortgage.transferrequired.validation.message}
      />

      {(region === jurisdictions.SCOTLAND.value || showGIFields) && (
        <DropdownSelectorLayout
          title="Type of Property"
          id="property-type"
          datatestid="property-type"
          options={propertyType}
          groupname="propertytype"
          persistAction={storeQuotePropertyType}
          selector={(store) => store.quote.remortgage.propertyType.value}
          errorMessage={(store) => store.quote.remortgage.propertyType.validation.message}
        />
      )}

      {showGIFields && (
        <DropdownSelectorLayout
          title="Number of Bedrooms"
          id="number-bedrooms"
          datatestid="number-bedrooms"
          options={numberOfBedrooms}
          groupname="NumberBedrooms"
          persistAction={storeQuoteNumberOfBedrooms}
          storePath={storePath}
          selector={(store) => store.quote[caseType].numberOfBedrooms.value}
          errorMessage={(store) => store.quote[caseType].numberOfBedrooms.validation.message}
        />
      )}

      {showGIFields && (
        <DropdownSelectorLayout
          title="Year Built"
          id="year-built"
          datatestid="year-built"
          options={giYearBuilt}
          groupname="yearBuilt"
          persistAction={storeQuoteYearBuilt}
          storePath={storePath}
          selector={(store) => store.quote[caseType].yearBuilt.value}
          errorMessage={(store) => store.quote[caseType].yearBuilt.validation.message}
        />
      )}

      <ButtonSelectorLayout
        title="How many people own the property?"
        id="owners"
        datatestid="owners"
        buttons={propertyOwners}
        groupname="owners"
        persistAction={storeQuoteOwners}
        selector={(store) => store.quote.remortgage.owners.value}
        errorMessage={(store) => store.quote.remortgage.owners.validation.message}
      />

      {showGIFields && (
        <DateInput
          id="dob"
          datatestid="dob"
          collectionValues={(store) => store.quote[caseType].DateOfBirth.value}
          storeModel={(store) => store.quote[caseType]}
          persistAction={storeDateOfBirth}
          storePath={storePath}
          homeOwnersCount={homeOwnersCount}
          errorclass={css.error}
        />
      )}

      {isTransferRequired === '1' && (
        <ButtonSelectorLayout
          title="How many people will own the property after this transaction?"
          id="ownersaftertransfer"
          datatestid="ownersaftertransfer"
          buttons={propertyOwners}
          groupname="ownersaftertransfer"
          persistAction={storeQuoteOwnersAfterTransfer}
          selector={(store) => store.quote.remortgage.ownersaftertransfer.value}
          errorMessage={(store) => store.quote.remortgage.ownersaftertransfer.validation.message}
        />
      )}

      <Row>
        <Col
          md={12}
          xs={12}
          className={classnames(
            css.leftalign,
            css.leftpad2x,
            css.tempSpacing,
            css.label,
          )}
        >
          <SearchableDropdown
            id="existingLenderDropdown-remortgage"
            label="Name of new lender"
            placeholder="Please select new lender..."
            value={lenderState}
            onChange={(newExistingLender) => {
              const lender = lenders.find(
                (l) => l.value === newExistingLender,
              )?.value;
              dispatch(storeQuoteNewLender(lender, storePath));
            }}
            list={lenders}
            menuClassName={css.lenderDropDown}
            errorMessages={[
              useSelector(
                (store) => store.quote[caseType].newLender.validation.message,
              ),
            ]}
          />
        </Col>
      </Row>

      <CustomerLocation 
        quoteType="remortgage"
        persistAction={storeQuoteCustomerLocation}
        storePath={storePath}
        selector={(store) => getCurrentQuote(store).customerLocation.value}
        errorMessage={(store) => getCurrentQuote(store).customerLocation.validation.message}
        selectedState={customerLocationState}
      />

      <CheckboxApply>
        <CheckboxSelector
          id="deedofpostponementtrait"
          datatestid="deedofpostponementtrait"
          checkboxes={deedOfPostponementTrait}
          persistaction={storeQuoteDeedOfPostponementTrait}
          checkedState={isDeedOfPostponementChecked}
        />
        <CheckboxSelector
          id="expatstrait"
          datatestid="expatstrait"
          checkboxes={expatsTrait}
          persistaction={storeQuoteExpatsTrait}
          selector={(state) => state.quote.expatstrait}
          checkedState={isExpatsChecked}
        />
        {isExpatsChecked && (
          <DropdownSelectorSubLayout
            title="How many (max 4)?"
            id="numberofexpats"
            datatestid="numberofexpats"
            options={additionalTraitValues}
            groupname="numberofexpats"
            persistAction={storeQuoteNumberOfExpats}
            selector={(store) => store.quote.remortgage.numberofexpats.value}
            errorMessage={(store) => store.quote.remortgage.numberofexpats.validation.message}
          />
        )}

        {(region === jurisdictions.SCOTLAND.value) && (
          <>
            <CheckboxSelector
              id="gifteddeposittrait-remortgage"
              datatestid="gifteddeposittrait-remortgage"
              checkboxes={giftedDepositTrait}
              persistaction={storeQuoteGiftedDepositTrait}
              storePath={storePath}
              checkedState={isGiftedDepositChecked}
            />
            {isGiftedDepositChecked && (
              <DropdownSelectorSubLayout
                title="How many (max 4)?"
                id="numberofgifteddeposits-remortgage"
                datatestid="numberofgifteddeposits-remortgage"
                options={additionalTraitValues}
                groupname="numberofgifteddeposits"
                persistAction={storeQuoteNumberOfGiftedDeposits}
                storePath={storePath}
                selector={(store) =>
                  getCurrentQuote(store).numberofgifteddeposits.value
                }
                errorMessage={(store) =>
                  getCurrentQuote(store).numberofgifteddeposits.validation.message
                }
              />
            )}          
          </>
        )}

        <CheckboxSelector
          id="helptobuytrait"
          datatestid="helptobuytrait"
          checkboxes={helpToBuyLoanRedemptionTrait}
          persistaction={storeQuoteHelpToBuyLoanTrait}
          storePath={storePath}
          checkedState={isHelpToBuyLoanChecked}
        />
        <CheckboxSelector
          id="hmotrait"
          datatestid="hmotrait"
          checkboxes={hmoTrait}
          persistaction={storeQuoteHMOTrait}
          storePath={storePath}
          checkedState={isHMOChecked}
        />
        {isHMOChecked && (
          <>
            <div className={css.infoText}>
              Note: Please check you have selected that this is not the
              customer&apos;s Main Residence and that it is a Buy To Let to get
              an accurate quote.
            </div>
            {!showGIFields && (
              <DropdownSelectorSubLayout
                title="How many bedrooms?"
                id="numberofhmo"
                datatestid="numberofhmo"
                options={numberOfBedrooms}
                groupname="numberofhmo"
                persistAction={storeQuoteNumberOfBedrooms}
                storePath={storePath}
                selector={(store) => store.quote[caseType].numberOfBedrooms.value}
                errorMessage={(store) => store.quote[caseType].numberOfBedrooms.validation.message}
              />
            )}
            {(region !== jurisdictions.SCOTLAND.value) && (
              <ButtonSelectorSubLayout
                title="Is this a student property?"
                id="studentproperty"
                datatestid="studentproperty"
                buttons={yesno}
                groupname="studentproperty"
                persistAction={storeQuoteStudentProperty}
                storePath={storePath}
                selector={(store) => store.quote[caseType].studentproperty.value}
                errorMessage={(store) => store.quote[caseType].studentproperty.validation.message}
              />
            )}
          </>
        )}
        
        {(region !== jurisdictions.SCOTLAND.value) && (
          <CheckboxSelector
            id="jointproprietortrait"
            datatestid="jointproprietortrait"
            checkboxes={jointProprietorTrait}
            persistaction={storeQuoteJointProprietorTrait}
            checkedState={isJointProprietorChecked}
          />
        )}

        {(region !== jurisdictions.SCOTLAND.value) && (
          <CheckboxSelector
            id="soleproprietortrait"
            datatestid="soleproprietortrait"
            checkboxes={soleProprietorTrait}
            persistaction={storeQuoteSoleProprietorTrait}
            checkedState={isSoleProprietorChecked}
          />
        )}

        <CheckboxSelector
          id="sharedownershiptrait"
          datatestid="sharedownershiptrait"
          checkboxes={sharedOwnershipTrait}
          persistaction={storeQuoteSharedOwnershipTrait}
          checkedState={isSharedOwnershipChecked}
        />
      </CheckboxApply>
    </>
  );
};

Remortgage.propTypes = {
  caseType: PropTypes.string,
  storePath: PropTypes.string,
  submitted: PropTypes.bool,
  validationHandler: PropTypes.func.isRequired,
};

Remortgage.defaultProps = {
  caseType: QuoteType.REMORTGAGE,
  storePath: null,
  submitted: false,
};

export default Remortgage;
