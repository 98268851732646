import * as insuranceActions from "../../../actions/instruct-actions/insurance";
import { isInsuranceProcessTypeValid } from "../validators";

const initialState = {
  processing: false
};

export default (state = initialState, action = {}) => {
  switch (action.type) {

    case insuranceActions.UPDATE_INSURANCE_PREFERENCE.type:
      return {
        ...state,
        processType: {
            ...state.processType,
            value: action.payload
        }
      };

      case insuranceActions.VALIDATE_INSURANCE_PREFERENCE.type:
          {
          return {
              ...state,
              processType: {
                  ...state.processType,
                  validationErrors: isInsuranceProcessTypeValid(state.processType?.value)
              }
          }
        }


    default:
      return state;
  }
};
