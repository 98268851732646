const generatePropChangeActionCreator = (type) => (payloadName) => {
  const actionCreator = (input) => {
    const action = {
      type: `SAVE_QUOTE_${type}`,
    };

    action[payloadName] = input;

    return action;
  };

  return {
    type: `SAVE_QUOTE_${type}`,
    action: actionCreator,
  };
};

export const UPDATE_QUOTE_TRANSACTIONS = generatePropChangeActionCreator(
  "UPDATE_QUOTE_TRANSACTIONS"
)("transactions");


export const UPDATE_CUSTOMER_FIRST_NAME = generatePropChangeActionCreator(
  "UPDATE_CUSTOMER_FIRST_NAME"
)("customerFirstName");

export const UPDATE_CUSTOMER_SURNAME = generatePropChangeActionCreator(
  "UPDATE_CUSTOMER_SURNAME"
)("customerSurname");

export const UPDATE_CUSTOMER_EMAIL = generatePropChangeActionCreator(
  "UPDATE_CUSTOMER_EMAIL"
)("customerEmailAddress");

export const UPDATE_CUSTOMER_EMAIL_MESSAGE = generatePropChangeActionCreator(
  "UPDATE_CUSTOMER_EMAIL_MESSAGE"
)("customerEmailMessage");

export const UPDATE_CORRESPONDENCE_ADDRESS = generatePropChangeActionCreator(
  "UPDATE_CORRESPONDENCE_ADDRESS"
)("correspondenceAddress");

export const UPDATE_QUOTE_NAME = generatePropChangeActionCreator(
  "UPDATE_QUOTE_NAME"
)("quoteName");

export const UPDATE_QUOTE_NOTES = generatePropChangeActionCreator(
  "UPDATE_QUOTE_NOTES"
)("quoteNotes");

export const VALIDATE_SAVE_QUOTE = generatePropChangeActionCreator(
  "VALIDATE_SAVE_QUOTE"
)("validateSaveQuote");

export const SUBMIT_SAVE_QUOTE = generatePropChangeActionCreator(
  "SUBMIT_SAVE_QUOTE"
)("submitSaveQuote");

export const VALIDATE_SEND_QUOTE = generatePropChangeActionCreator(
  "VALIDATE_SEND_QUOTE"
)("validateSendQuote");

export const SUBMIT_SEND_QUOTE = generatePropChangeActionCreator(
  "SUBMIT_SEND_QUOTE"
)("submitSendQuote");

export const CLEAR_VALIDATION = generatePropChangeActionCreator(
  "CLEAR_VALIDATION"
)("clearValidation")

export const SEND_QUOTE_REQUEST_COMPLETE = generatePropChangeActionCreator(
  "SEND_QUOTE_REQUEST_COMPLETE"
)("sendQuoteComplete")

export const SEND_SAVED_QUOTE_WITH_ID = generatePropChangeActionCreator(
  "SEND_SAVED_QUOTE_WITH_ID"
)("quoteId");

