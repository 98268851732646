import React from 'react';
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import css from "./index.module.css";
import GenericModal from "../../modals/GenericModal/GenericModal";


const NewUserPreferenceMessage = ({ show, onHide }) => {
  const history = useHistory();

  return (
    <GenericModal
      show={show}
      onHide={onHide}
      heading="LET'S MAKE SELECT WORK BEST FOR YOU"
      primaryButtonText="CONTROL PANEL"
      secondaryButtonText="LATER"
      onClickValue={() => history.push("/preferences")}
      contentClassName={css["dialog-content"]}
      dialogClassName={css["dialog"]}
    >
      <div className={css.message}>
        <p>
          As a new user we recommend you take a look at our control panel, where
          you can:
        </p>

        <ul>
          <li>Change your broker referral fee</li>
          <li>Check your contact details</li>
          <li>Set your default jurisdiction</li>
        </ul>

        <p>
          <span className={css.emphasis}>PLUS</span> We now offer the ability to
          get home insurance quotes too!
        </p>
      </div>
    </GenericModal>
  );
};

NewUserPreferenceMessage.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
};

export default NewUserPreferenceMessage;
