import { call, put, takeLatest, fork, select } from "redux-saga/effects";
import * as types from "../../actions/types"
import putRequest from "../../../services/putRequest"
import getRequest from "../../../services/getRequest";
import {
    getSelectedQuoteReference,
    getClients
} from '../utils/uinsure';
import _ from 'lodash';

function* updateUinsureClient() {
    yield put({ type: types.SITE_BUSY, payload: true });

    try {
        const selectedUinsureReference = yield getSelectedQuoteReference();
        const quoteGroupId = yield select((store) => store.instruct.instructProcessState?.savedQuote?.data?.quoteGroupId);

        if (!selectedUinsureReference) {
            yield put({ type: types.SITE_BUSY, payload: false });
            return;
        }

        const clients = yield getClients();
        const endpoint = `${window.config.apiBaseUrl}/quotes/${quoteGroupId}/insurance/${selectedUinsureReference}`;

        const clientsEqual = (existingClient, newClient) => {
            _.isEqual(existingClient, newClient);
        }

        const updateRequired = (existingQuote, updatedClients) => {
            return !clientsEqual(existingQuote.data?.client1, updatedClients.Client1)
                || !clientsEqual(existingQuote.data?.client2, updatedClients.Client2);
        }

        const request = {
            Client1: clients[0] || null,
            Client2: clients[1] || null
        }

        const existingQuote = yield call(getRequest, endpoint);

        if (updateRequired(existingQuote, request)) {
            yield call(putRequest, endpoint, request);
        }
    }
    catch (e) {;
        // Don't stop if this fails
    }

    yield put({ type: types.SITE_BUSY, payload: false });
}

function* saga() {
    yield takeLatest(types.UPDATE_UINSURE_CLIENT, updateUinsureClient);
}

const updateUinsureClientSaga = fork(saga);

export { updateUinsureClientSaga as default, updateUinsureClient, saga };