import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { InputGroup, FormControl, DropdownButton, Dropdown, Button, Col, Form } from 'react-bootstrap';
import { Search } from '../shared/imgs';
import './searchBar.css';

const SearchBar = (props) => {
  const { onChange, displayDropdownItems, type, placeholder } = props;
  const initialDisplayValue = 'Select a category';
  const [searchInput, setSearchInput] = useState('');
  const [queryType, setQueryType] = useState(null);
  const [displayValue, setDisplayValue] = useState(initialDisplayValue);

  const handleDropDown = (item) => {
    setQueryType(item.queryValue);
    setDisplayValue(item.displayValue);
  }

  const handleSearch = (evt) => {
    evt.preventDefault();
    if (searchInput !== '' && displayValue !== initialDisplayValue) {
      onChange({ type: queryType, value: searchInput })
    } else onChange(null);
  }

  return (
    <Form onSubmit={handleSearch}>
      <Form.Row>
        <Form.Group as={Col} md="12">
          <InputGroup className="searchBar">
            <DropdownButton
              as={InputGroup.Prepend}
              variant="outline-secondary"
              title={displayValue}
              id={`input-group-dropdown-${type}`}
            >
              {displayDropdownItems.map((item) => (
                <Dropdown.Item
                  key={item.id}
                  onClick={() => handleDropDown(item)}
                >
                  {item.displayValue}
                </Dropdown.Item>
              ))}
            </DropdownButton>
            <FormControl
              type="text"
              value={searchInput}
              onChange={(evt) => setSearchInput(evt.target.value)}
              placeholder={placeholder}
              aria-label={placeholder}
            />
            <InputGroup.Append>
              <Button
                variant="outline-primary"
                type="submit"
              >
                <Search className="searchIcon" />
              </Button>
            </InputGroup.Append>
          </InputGroup>
        </Form.Group>
      </Form.Row>
    </Form>
  );
}

SearchBar.propTypes = {
  displayDropdownItems: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    displayValue: PropTypes.string,
    queryValue: PropTypes.string,
  })).isRequired,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
}

export default SearchBar;