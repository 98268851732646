import React from 'react';
import Error from '../../pages/error';
import { withRouter } from 'react-router-dom';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false};
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, hasReset: false, error: error };
  }

  reset = () => {
    const { redirect, history } = this.props;
    if (redirect && history) {
      if (history) history.push('/dashboard');
    }

    this.setState({ hasError: false});
  }

  render() {
    if (this.state.hasError) {
      if (this.props.FallbackComponent) {
        return <React.Fragment key={this.state.hasError}>
          <this.props.FallbackComponent reset={this.reset} />
        </React.Fragment>
      }
      else {
        return <React.Fragment key={this.state.hasError}>
          <Error reset={this.reset} error={this.state.error} />
        </React.Fragment>
      }
    }

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);