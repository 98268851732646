import React from "react";
import PropTypes from "prop-types";
import ButtonSelector from "../../../selectors/button/buttonselector";
import { preferredSortOrder } from "../../../shared/data";
import { storePreferencePreferredSortOrder } from "../../../../redux/actions/actions";
import css from "./sortordercard.module.css";
import { useSelector } from "react-redux";

const SortOrderCard = ({ id, datatestid }) => {
  return (
    <fieldset>
      <div id={id} data-testid={datatestid} className={css.sortorderbutton}>
        <ButtonSelector
          id={`${id}-sortorder`}
          datatestid={`${datatestid}-sortorder`}
          buttons={preferredSortOrder}
          groupname="sortorder"
          persistAction={storePreferencePreferredSortOrder}
          selector={(store) => store.preferences.preferredSortOrder.value}
          errorMessage={(store) =>
            store.preferences.preferredSortOrder.validation.message
          }
        />
      </div>
    </fieldset>
  );
};

SortOrderCard.propTypes = {
  id: PropTypes.string.isRequired,
  datatestid: PropTypes.string.isRequired,
};

export default SortOrderCard;
