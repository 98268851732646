import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom' 
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import AuthCheck from "../../components/authcheck/authCheck";
import authState from "../../components/authcheck/authState";
import Spinner from "../../components/spinner"
import AllSections from "./components/sections-block"
import commonCss from "../common/styles.module.css";
import css from "./instructQuote.module.css";
import * as getSavedQuoteActions from "../../redux/actions/get-saved-quotes-actions";
import * as siteWideActions from "../../redux/actions/site-wide-actions"
import * as notifications from "../../data/notifications"
import { getLenders } from "../../redux/actions/actions";
import { QuoteType } from "../../data/quote-type";
import { useSelectApi } from "../../hooks/selectApi";
import { populateInstructStore } from "./utils";
import { equalIgnoreCase } from '@lms/react-common-helpers/strings';
import Layout from '../../layout';

const InstructQuote = ({ getAccessToken, isTest = false, pathSlugs }) => {
  const dispatch = useDispatch();
  const history = useHistory()
  const { getQuoteGroup, getInsuranceQuote } = useSelectApi();
  const quoteGroupId = pathSlugs?.params?.quoteGroupId
  const hasSavedQuote = true;
  const currentQuote = useSelector(store => store.quote.quotesReceived)
  const quoteData = useSelector(store => store.saveQuote?.Body?.QuoteDetails)
  const lenders = useSelector((store) => store.lenders.data);
  const hasCurrentQuote = currentQuote ? true : false
  const [isLoading, setIsLoading] = useState(true)
  const [hasRelatedQuote, setHasRelatedQuote] = useState(false)
  const [savedData, setSavedData] = useState(null)
  const [sectionComplete, setSectionComplete] = useState(0)
  const [retrievedCurrent, setRetrievedCurrent] = useState(false)
  const relatedSection = useRef(null)

  useEffect(() => {
    async function load() {
      const result = await getQuoteGroup({ quoteGroupId });
      let insuranceResult = null;
      if (result.insuranceDetails?.quoteReference) {
        insuranceResult = await getInsuranceQuote({ quoteGroupId, insuranceReference: result.insuranceDetails.quoteReference });
      }
      populateInstructStore(result, insuranceResult, dispatch);
    }
    load();
  }, [quoteGroupId])

  const saveQuoteRetreived = useSelector(
    (store) => store.instruct.instructProcessState.savedQuote
  );
  
  useEffect(() => {
    if(saveQuoteRetreived) {
      setSavedData(saveQuoteRetreived.data)
      setHasRelatedQuote(saveQuoteRetreived.data.relatedQuote ? true : false)
      setIsLoading(false)
    }
  }, [saveQuoteRetreived])

  
  useEffect(() => {
    if(!hasSavedQuote && hasCurrentQuote && !retrievedCurrent) {
      dispatch(getSavedQuoteActions.MAP_CURRENT_QUOTE.action())
      setRetrievedCurrent(true)
    }else if(!hasSavedQuote && !hasCurrentQuote){
      dispatch(siteWideActions.NOTIFICATION_SHOW.action({show: true, message: notifications.getInstructQuote.negative, type: "negative"}))
      if (!isTest) history.push('/')
    }
  },[quoteData])

  useEffect(() => {
    if (!lenders || lenders.length < 1) {
      getAccessToken().then(() => {
        dispatch(getLenders());
      });
    }
  }, [lenders])

  const doSectionComplete = () => {
    setSectionComplete(sectionComplete +1)
  }

  const unsetSectionComplete = () => {
    setSectionComplete(sectionComplete - 1);
  }

  const canInstruct = hasRelatedQuote ? sectionComplete === 2 : sectionComplete === 1
  const instructQuote = () => {
    history.push(`/legalTerms/${quoteGroupId || ''}`)
  };

  const title = equalIgnoreCase(saveQuoteRetreived?.data?.quoteType, QuoteType.SALE) ? "Property for sale" : "Property being purchased" || null
  const relatedQuoteTitle = equalIgnoreCase(saveQuoteRetreived?.data?.relatedQuote?.quoteType, QuoteType.SALE) ? "Property for sale" : "Property being purchased" || null

  return (
    <Layout classname={css.container}>
      <AuthCheck requiredAuthState={authState.AUTHENTICATED} />
      <Row>
        <Col md={1} lg={1} />
        <Col md={11} lg={11}>
          <Row>
            <h1 className={`${commonCss.pageHeading} ${css.instructHeading}`}>
              <span className={css.headingHighlight}>NEARLY DONE...</span>
              WE JUST NEED A BIT MORE INFORMATION
            </h1>
          </Row>
          {isLoading ? (
            <Row>
              <div className={css.loading}>
                <Spinner variant={css.spinner}/> Loading quotes...
              </div>
            </Row>
          ):  (
                <>
                  <Row>
                  {
                    hasRelatedQuote ? 
                    <>
                      <AllSections 
                        data={savedData} 
                        isRelatedQuote={false} 
                        title={title} 
                        caseTypeIdx={0} 
                        addSectionComplete={() => doSectionComplete()}
                        unsetSectionComplete={() => unsetSectionComplete()}
                        isTest={isTest}
                        scrollToNextSection={() => relatedSection.current.scrollIntoView()}
                      />
                      <div ref={relatedSection}></div>
                      <AllSections 
                        data={savedData.relatedQuote} 
                        isRelatedQuote={true} 
                        title={relatedQuoteTitle} 
                        caseTypeIdx={1} 
                        addSectionComplete={() => doSectionComplete()}
                        unsetSectionComplete={() => unsetSectionComplete()}
                        isTest={isTest}
                        canInstruct={canInstruct}
                        instructAction={instructQuote}
                        
                      />
                    </>
                    :
                      <AllSections 
                        data={savedData} 
                        isRelatedQuote={false} 
                        caseTypeIdx={0} 
                        addSectionComplete={() => doSectionComplete()}
                        unsetSectionComplete={() => unsetSectionComplete()}
                        isTest={isTest}
                        canInstruct={canInstruct}
                        instructAction={instructQuote}
                      />
                  }
                  </Row>
                </>
              )
          }
        </Col>
      </Row>
    </Layout>
  );
};

InstructQuote.propTypes = {
  isTest: PropTypes.bool,
  pathSlugs: PropTypes.shape({
    params: PropTypes.shape({
      quoteGroupId: PropTypes.string,
    }),
  }),
  getAccessToken: PropTypes.func,
};

export default InstructQuote;
