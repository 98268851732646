import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownMenu from "react-bootstrap/DropdownMenu";
import getCountries from "@lms/react-reference-data";
import { AuthContext } from "../../../../utils/authProvider";
import NumberFormat from "react-number-format";
import Form from "react-bootstrap/Form";
import css from "./index.module.css";
import commonCss from "../../styles.module.css";

const uri = window?.config?.referenceDataUrl;

const InternationalPhoneNumberInput = ({
  label,
  callingCode,
  onCallingCodeChange,
  phoneNumber,
  onChangePhoneNumber,
  phoneNumberPlaceholder,
  errorMessages = []
}) => {

  const { getAccessToken } = useContext(AuthContext);
  const [callingCodes, setCallingCodes] = useState([]);
  const [callingCodeLoading, setCallingCodeLoading] = useState(true);

  useEffect(() => {
    const fetchCountries = async () => {
      const data = await getCountries({ getAccessToken: getAccessToken, uri: uri });
      const mappedResults = data
        .filter(item => item.callingCode)
        .map(item => ({ name: item.name, value: `+${item.callingCode}` }));

      setCallingCodes(mappedResults);
      setCallingCodeLoading(false)
    };

    fetchCountries();
  }, []);

  useEffect(() => {
    if (callingCodes.length > 0) {
      if (!callingCode) {
        onCallingCodeChange("+44");
      }
    }
  }, [callingCodes, callingCode]);

  return (
    <React.Fragment>
      {
        !callingCodeLoading &&
        <React.Fragment>
          <Form.Label className={css.formLabel}>
            {label}
          </Form.Label>
          <div className={css.dropDownContainer}>
            <div className={css.dropDownContainerChild}>
              <Dropdown>
                <Dropdown.Toggle
                  style={{
                    backgroundColor: "transparent",
                    color: "#495057",
                    borderRadius: 5,
                    borderColor: "#ced4da"
                  }}>
                  {callingCode}
                </Dropdown.Toggle>
                <DropdownMenu
                  style={{
                    maxHeight: 300,
                    overflowY: "scroll"
                  }}>
                  {
                    callingCodes.map((c, index) => {
                      return (
                        <Dropdown.Item key={index} onClick={() => onCallingCodeChange(c.value)}>{c.name}</Dropdown.Item>
                      )
                    })
                  }
                </DropdownMenu>
              </Dropdown>
            </div>
            <div className={css.phoneNumberContainer}>
              <Form.Control
                as={NumberFormat}
                type={"text"}
                placeholder={phoneNumberPlaceholder}
                onChange={(e) => onChangePhoneNumber(e.target.value)}
                value={phoneNumber}
                format="###########"
                className={css.phoneNumberControl}
              />
            </div>
          </div>
          <div className={css.errorMessages}>
            {errorMessages?.map((e, idx) => (
              <div key={idx} className={commonCss.error}>{`* ${e}`}</div>
            ))}
          </div>
        </React.Fragment>
      }
    </React.Fragment>
  );
};

InternationalPhoneNumberInput.propTypes = {
  label: PropTypes.string,
  onChangeCallingCode: PropTypes.func,
  callingCode: PropTypes.string,
  phoneNumber: PropTypes.string,
  onChangePhoneNumber: PropTypes.func,
  phoneNumberPlaceholder: PropTypes.string,
};

export default InternationalPhoneNumberInput;
