import React, { useState, useEffect } from 'react';
import Applications from '@lms/react-applications';
import FeatureToggler from '../feature-toggler';
import { AuthContext } from "../../utils/authProvider";
import { Role } from '../../utils/role';
import { userHasRole } from '@lms/react-common-helpers/user';
import { UserType } from '../../enum/UserType';

const AppSwitcher = () => {
  const [userType, setUserType] = useState();
  const { getUser } = React.useContext(AuthContext);
  const applicationsApiBaseAddress = window?.config?.applicationsApiBaseAddress;

  useEffect(() => {
    let unmounted = false;
    async function init() {
      const user = await getUser();
      if (!unmounted) {
        let tempUserType = null;
        if (userHasRole(user, [Role.LMSUser])) {
          tempUserType = UserType.LMS;
        } else if (userHasRole(user, [Role.Lawfirm])) {
          tempUserType = UserType.LawFirm;
        } else if (userHasRole(user, [Role.Broker])) {
          tempUserType = UserType.Broker;
        }
        setUserType(tempUserType);
      }
    }
    init();
    return () => {
      unmounted = true;
    };
  }, []);

  if (!userType) return null;

  return (
    <FeatureToggler featureName="AppSwitcherEnabled">
      <Applications
        uri={applicationsApiBaseAddress}
        userType={userType}
      />
    </FeatureToggler>
  );
};

export default AppSwitcher;
