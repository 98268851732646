const isNotEmpty = (field) => field && !/^\s*$/.test(field);

export const ADDRESS_VALIDATION_MESSAGES = {
  automaticEntry: {
    invalid: "You must supply a property address",
    incomplete: "This address is incomplete. Please use manual entry below.",
  },
  houseNameNumber: {
    empty: "You must supply a house name/number",
  },
  streetName: {
    empty: "You must supply street name",
  },
  postcode: {
    empty: "You must supply a postcode",
  },
  country: {
    empty: "You must supply a country",
  },
  postTown: {
    empty: "You must supply a town",
  },
};

export const CUSTOMER_VALIDATION_MESSAGES = {
  title: {
    empty: "You must provide a title",
  },
  firstName: {
    empty: "You must provide a first name(s)",
  },
  surname: {
    empty: "You must provide a surname",
  },
  email: {
    invalid: "If you supply an email it must be in a valid format",
  },
  phoneNumber: {
    empty: "You must provide at least one valid phone number",
    invalid:
      "All phone numbers provided must be 28 characters or less in length",
  },
  mobile: {
    invalid:
      "All phone numbers provided must be 28 characters or less in length",
  },
  workPhoneNumber: {
    invalid:
      "All phone numbers provided must be 28 characters or less in length",
  },
  homePhoneNumber: {
    invalid:
      "All phone numbers provided must be 28 characters or less in length",
  },
};

export const InsuranceValidationErrors = {
  processType: {
    invalid: "Please select an option"
  }
}

export const getPropertyAddressValidationErrors = (propertyAddress) => {
  const addressToValidate =
    (propertyAddress?.usedManualAddressEntry
      ? propertyAddress?.manualEntry
      : propertyAddress?.automaticEntry) ?? {};
  const validationErrors = ["streetName", "country"].reduce(
    (errors, toValidate) => {
      if (isNotEmpty(addressToValidate[toValidate])) return errors;
      return [
        ...errors,
        {
          id: toValidate,
          errors: [ADDRESS_VALIDATION_MESSAGES[toValidate].empty],
        },
      ];
    },
    []
  );
  const postCodeIsValid =
    isNotEmpty(addressToValidate.postcode1) &&
    isNotEmpty(addressToValidate.postcode2);

  if (!postCodeIsValid) {
    validationErrors.push({
      id: "postcode",
      errors: [ADDRESS_VALIDATION_MESSAGES.postcode.empty],
    });
  }

  const postTownIsValid =
    isNotEmpty(addressToValidate.postTown);

  if (!postTownIsValid) {
    validationErrors.push({
      id: "postTown",
      errors: [ADDRESS_VALIDATION_MESSAGES.postTown.empty],
    });
  }

  if(propertyAddress?.usedManualAddressEntry && !isNotEmpty(addressToValidate.houseNumber) && !isNotEmpty(addressToValidate.houseName)){
    validationErrors.push({
      id: "houseNumber",
      errors: [ADDRESS_VALIDATION_MESSAGES.houseNameNumber.empty],
    });
    validationErrors.push({
      id: "houseName",
      errors: [ADDRESS_VALIDATION_MESSAGES.houseNameNumber.empty],
    });
  }

  if (!propertyAddress?.usedManualAddressEntry) {
    if (!propertyAddress?.automaticAddressLabel) {
      return [
        {
          id: "automaticEntry",
          errors: [ADDRESS_VALIDATION_MESSAGES.automaticEntry.invalid],
        },
      ];
    }
    if (propertyAddress?.automaticAddressLabel && validationErrors.length > 0) {
      return [
        {
          id: "automaticEntry",
          errors: [ADDRESS_VALIDATION_MESSAGES.automaticEntry.incomplete],
        },
      ];
    }
  }

  return validationErrors;
};

export const isValidEmail = (email) =>
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );

export const isCustomerValid = (customer) => {
  const newErrors = {};

  if (!customer.title) {
    newErrors.title = [CUSTOMER_VALIDATION_MESSAGES.title.empty];
  }

  if (!customer.firstName) {
    newErrors.firstName = [CUSTOMER_VALIDATION_MESSAGES.firstName.empty];
  }

  if (!customer.surname) {
    newErrors.surname = [CUSTOMER_VALIDATION_MESSAGES.surname.empty];
  }

  if (!isValidEmail(customer.email) && customer.email) {
    newErrors.email = [CUSTOMER_VALIDATION_MESSAGES.email.invalid];
  }

  if (
    !customer.mobile &&
    !customer.workPhoneNumber &&
    !customer.homePhoneNumber
  ) {
    newErrors.mobile = [CUSTOMER_VALIDATION_MESSAGES.phoneNumber.empty];
    newErrors.workPhoneNumber = [
      CUSTOMER_VALIDATION_MESSAGES.phoneNumber.empty,
    ];
    newErrors.homePhoneNumber = [
      CUSTOMER_VALIDATION_MESSAGES.phoneNumber.empty,
    ];
  }

  if (customer.mobile && customer.mobile.length > 28) {
    newErrors.mobile = [CUSTOMER_VALIDATION_MESSAGES.mobile.invalid];
  }

  if (customer.workPhoneNumber && customer.workPhoneNumber.length > 28) {
    newErrors.workPhoneNumber = [
      CUSTOMER_VALIDATION_MESSAGES.workPhoneNumber.invalid,
    ];
  }

  if (customer.homePhoneNumber && customer.homePhoneNumber.length > 28) {
    newErrors.homePhoneNumber = [
      CUSTOMER_VALIDATION_MESSAGES.homePhoneNumber.invalid,
    ];
  }

  return newErrors;
};

export const isInsuranceProcessTypeValid = (value) => {
  if (value === "SendLead" || value ==="UserComplete")
    return null;

  return InsuranceValidationErrors.processType.invalid;
}
