import React from "react";
import PropTypes from "prop-types";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";

import css from "./noteTable.module.css";

const NoteTable = ({ note }) => (
  <Row>
    <Col>
      <Row>
        <Col>
          <div className={css.tableHeader}>
            <h2 className={css.tableHeaderText}>Notes</h2>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={css.tableBody}>
            <Table borderless>
              <tbody>
                <tr>
                  <td>{note}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    </Col>
  </Row>
);

NoteTable.propTypes = {
  note: PropTypes.string,
}

NoteTable.defaultProps = {
  note: null,
}

export default NoteTable;