import React, { useState } from "react";
import classnames from "classnames";
import css from "./jurisdiction.module.css";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { isFeatureEnabled } from '../../../utils/features';
import ToggleButton from "react-bootstrap/ToggleButton";
import { useSelector, useDispatch } from "react-redux";
import { England, Wales, Scotland, NoCountry } from "../../shared/imgs/index";
import { jurisdictionsWithNone as jurisdictionsEnum } from "../../shared/data";
import PropTypes from "prop-types";

const Jurisdiction = ({
  id,
  datatestid,
  storelocation,
  persistAction,
  subsection,
  isPreferenceScreen
}) => {
  const dispatch = useDispatch();
  const storeLocation = storelocation ? useSelector(storelocation) : useSelector((store) => store.preferences.jurisdiction.value);
  const buttonList = { ...jurisdictionsEnum }
  if(!isPreferenceScreen) delete buttonList.NONE 

  const isScotlandEnabled = isFeatureEnabled('ScotlandEnabled');

  const setValue = (value) => dispatch(persistAction(value, subsection));
  const [unsupportedRegionSelected, setUnsupportedRegionSelected] = useState(false);
  const error =
    useSelector(
      (store) =>
        store.quote[store.quote.type.value].jurisdiction.validation.message
    ) || null;

  function clicked(evt) {
    let w = evt.target.getBoundingClientRect().width;
    let h = evt.target.getBoundingClientRect().height;

    // Check the width of the rectangle in the SVG to determine the country map clicked.
    // Warning - may need to change if the SVG container is ever resized.
    if (w < 50) {
      validateRegion(jurisdictionsEnum.WALES.value);
    } else if (w > 80 && w < 160) {
      validateRegion(jurisdictionsEnum.ENGLAND.value);
    } else if (h > 100 && h < 200) {      
      validateRegion(jurisdictionsEnum.SCOTLAND.value);
    }
  }

  const validateRegion = (value, event = null) => {
    if (value === jurisdictionsEnum.SCOTLAND.value && !isScotlandEnabled) {
      setUnsupportedRegionSelected(true);
      // Remove focus from the element to prevent style change to clicked Toggle
      if (event) {
        event.target.blur();
      }
    } else {
      setUnsupportedRegionSelected(false);
      setValue(value);
    }
  };

  return (
    <>
      <div className={css.flexcontainer}>
        <div className={classnames(css.flexitem)}>
          {storeLocation === jurisdictionsEnum.ENGLAND.value && (
            <England onClick={(e) => clicked(e)} />
          )}
          {storeLocation === jurisdictionsEnum.WALES.value && (
            <Wales onClick={(e) => clicked(e)} />
          )}
          {storeLocation === jurisdictionsEnum.SCOTLAND.value && (
            <Scotland onClick={(e) => clicked(e)} />
          )}
          {(storeLocation === null ||
            storeLocation === jurisdictionsEnum.NONE.value) && <NoCountry />}
        </div>
        <span className={css.spacer}></span>
        <ButtonGroup
          toggle
          className={classnames(css.flexitem, "btn-group-vertical")}
        >
          {Object.entries(buttonList).map(
            ([enumKey, { displayName, value }], idx) =>  (
              <ToggleButton
                className={css.toggleButton}
                key={idx}
                id={`${id}-${enumKey}`}
                data-testid={`${datatestid}-${displayName}`}
                type="radio"
                variant="secondary"
                name="region"
                value={value}
                checked={storeLocation === value}
                onChange={(e) => validateRegion(e.currentTarget.value, e)}
              >
                {displayName}
              </ToggleButton>
            )
          )}
        </ButtonGroup>
        <span className={css.spacer}></span>
        {unsupportedRegionSelected && 
        <div className={classnames(css.flexitem)}>
          <p>This option is coming soon. Please contact Sales Support for help with calculating a quote for a property in Scotland.</p> 
          <p><a href="mailto:salessupport@lms.com">salessupport@lms.com</a></p>
        </div>
        }
      </div>
      {error && <div className={css.error}>{error}</div>}
    </>
  );
};

Jurisdiction.propTypes = {
  id: PropTypes.string.isRequired,
  datatestid: PropTypes.string.isRequired,
  storelocation: PropTypes.func.isRequired,
  persistAction: PropTypes.func.isRequired,
  subsection: PropTypes.string,
  isPreferenceScreen: PropTypes.bool
};

export default Jurisdiction;
