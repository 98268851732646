import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import GenericModal from "../../../modals/GenericModal/GenericModal";
import css from "./index.module.css";
import LabelledTextInput from "../../common/components/labelled-text-input";
import LabelledCheckboxInput from "../../common/components/labelled-checkbox-input"
import { useSelector, useDispatch } from "react-redux";
import formsCss from "../../../components/shared/forms.module.css";
import { linkUinsure, storeUinsureValidation } from "../../../redux/actions/actions";
import Button from "../../../components/button/button";

const LinkUinsureModal = ({
  getAccessToken,
  storePath = null,
  show,
  onHide,
  actionButtonText = "LINK ACCOUNT",
}) => {

  const dispatch = useDispatch()
  const isLoading = useSelector(store => store.loaders.linkUinsureRequestBusy) || false
  const linkError = useSelector((store) => store.preferences.uinsure.linkError) || "";
  const emailValidation = useSelector((store) => store.preferences.uinsure.validation?.email) || "";
  const passwordValidation = useSelector((store) => store.preferences.uinsure.validation?.password) || "";
  const termsValidation = useSelector((store) => store.preferences.uinsure.validation?.terms) || "";
  const uinsureConnected = useSelector((store) => store.preferences.uinsure.uinsureLinked?.value) ?? false;
  const linkComplete = useSelector((store) => store.preferences.uinsure.linkComplete) ?? false;

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {
      emailAddress: e.target[0].value,
      password: e.target[1].value,
      termsAccepted: e.target[2].checked
    }

    if (validate(data)) {
      getAccessToken().then(() => {
        dispatch(linkUinsure(data))
      });
    }
  }

  const validate = (data) => {
    const errorMsg = "We need this information to link your account";

    let validation = {
      email: data.emailAddress ? "" : errorMsg,
      password: data.password ? "" : errorMsg,
      terms: data.termsAccepted ? "" : errorMsg
    }

    dispatch(storeUinsureValidation(validation));
    return !validation.email && !validation.password && !validation.terms
  }

  useEffect(() => {
    if (linkComplete) onHide()
  }, [linkComplete])

  return (
    <GenericModal
      show={show}
      onHide={onHide}
      heading={uinsureConnected ? "UPDATE YOUR UINSURE ACCOUNT" : "CONNECT YOUR UINSURE ACCOUNT"}
      contentClassName={css["dialog-content"]}
      dialogClassName={css["dialog"]}
      isActionLoading={isLoading}
      onClickValue={() => handleSubmit()}
    >
      <Row className={css["dialog-body"]}>
        <Col>
          <Row>
            <Form className={css["link-uinsure-form"]} onSubmit={handleSubmit}>
              <Form.Group controlId="email">
                <LabelledTextInput
                  label="Uinsure email"
                  placeholder="Email"
                  errorMessages={emailValidation ? [emailValidation] : []}
                  onChange={() => { }}
                  uncontrolled
                />
              </Form.Group>
              <Form.Group controlId="password">
                <LabelledTextInput
                  type="password"
                  label="Uinsure password"
                  placeholder="Password"
                  errorMessages={passwordValidation ? [passwordValidation] : []}
                  onChange={() => { }}
                  uncontrolled
                />
              </Form.Group>
              <Form.Group controlId="terms">
                <LabelledCheckboxInput
                  label="I authorise LMS to use my details to connect to Uinsure"
                  errorMessages={termsValidation ? [termsValidation] : []}
                />

                <Row>
                  <div>
                    <p><strong>How will LMS use my details?</strong></p>
                    <p>We will use this to create Home Insurance quotes on Select on your behalf and link them to your Uinsure account. This means that quotes created via Select will be displayed when you sign into Uinsure.</p>
                  </div>
                </Row>
                <Row>
                  <p className={formsCss.error}>{linkError}</p>
                </Row>
              </Form.Group>
              <Form.Group controlId="buttons" className={css.container}>
                <Button
                  id="modal-secondary-button"
                  datatestid="modal-secondary-button"
                  btnClass={css.tertiarybutton}
                  variant="tertiary"
                  size="lg"
                  type="button"
                  onClick={onHide}
                  text="CANCEL"
                />

                <Button
                  id="modal-primary-button"
                  datatestid="modal-primary-button"
                  btnClass={css.primarybutton}
                  size="lg"
                  type="submit"
                  isLoading={isLoading}
                  text={uinsureConnected ? "SAVE CHANGES" : "LINK ACCOUNT"}
                  disabled={isLoading}
                />
              </Form.Group>
            </Form>
          </Row>
        </Col>
      </Row>
    </GenericModal>
  );
};

LinkUinsureModal.propTypes = {
  getAccessToken: PropTypes.func.isRequired,
  show: PropTypes.bool,
  onHide: PropTypes.func,
  storePath: PropTypes.string,
  actionButtonText: PropTypes.string
};

export default LinkUinsureModal;
