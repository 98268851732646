import React, { useMemo } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import css from './pagination.module.css';

const Pagination = (props) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const lastPage = paginationRange[paginationRange.length - 1];
  return (
    <div className={classnames(css.paginationContainer, { [className]: className })}>
      <button
        key="btn-page-one"
        type="button"
        disabled={currentPage === 1}
        className={classnames(css.paginationItem, currentPage === 1 ? css.disabled : null)}
        onClick={onPrevious}
      >
        <div className={classnames(css.arrow, css.arrowLeft)} />
      </button>
      {paginationRange.map((pageNumber, idx) => {
        if (pageNumber === DOTS) {
          return <button key={`btn-dots-${idx}`} type="button" className={classnames(css.paginationItem, css.dots)}>&#8230;</button>;
        }

        return (
          <button
            key={`btn-page-${pageNumber}`}
            type="button"
            className={classnames(css.paginationItem, pageNumber === currentPage ? css.selected : null)}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </button>
        );
      })}
      <button
        key="btn-page-last"
        type="button"
        disabled={currentPage === lastPage}
        className={classnames(css.paginationItem, currentPage === lastPage ? css.disabled : null)}
        onClick={onNext}
      >
        <div className={classnames(css.arrow, css.arrowRight)} />
      </button>
    </div>
  );
};

Pagination.propTypes = {
  totalCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  siblingCount: PropTypes.number,
  className: PropTypes.string,
};

Pagination.defaultProps = {
  siblingCount: 1,
  className: 'pagination-bar',
};

const DOTS = '...';

const range = (start, end) => {
  const length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};

const usePagination = ({
  totalCount,
  pageSize,
  siblingCount = 1,
  currentPage,
}) => {
  const paginationRange = useMemo(() => {
    const totalPageCount = Math.ceil(totalCount / pageSize);

    // Pages count = siblingCount + firstPage + lastPage + currentPage + 2*DOTS
    const totalPageNumbers = siblingCount + 5;

    /*
      If number of pages is less than page numbers we want to show,
      return the range [1..totalPageCount]
    */
    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(
      currentPage + siblingCount,
      totalPageCount,
    );

    /*
      Don't show dots if there is only one position left after/before the left/right page count
      as that would lead to a change in Pagination component size which is undersirable
    */
    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = 3 + 2 * siblingCount;
      const leftRange = range(1, leftItemCount);

      return [...leftRange, DOTS, totalPageCount];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightItemCount = 3 + 2 * siblingCount;
      const rightRange = range(
        totalPageCount - rightItemCount + 1,
        totalPageCount,
      );
      return [firstPageIndex, DOTS, ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      const middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }

    return 0;
  }, [totalCount, pageSize, siblingCount, currentPage]);

  return paginationRange;
};

export default Pagination;
