import React from 'react';
import PropTypes from 'prop-types';
import { Accordion, Card } from 'react-bootstrap';
import classnames from 'classnames';
import css from './index.module.css';
import AccordionIconToggle from './AccordionIconToggle';

export default function AccordionItems(props) {
  const { id, cardClassName, eventKey, headerText, iconClassName, headerClassName, toggleClassName, variant, lengthToDisplay, children } = props;

  const renderIcon = () => {
    if (variant === 'caseDetail') {
      return (
        <span className={iconClassName || css.iconToggle}>
          <AccordionIconToggle eventKey={eventKey} />
        </span>
      )
    }
    if (variant === 'cases') {
      return (
        <span className={iconClassName || classnames(css.caseCount, css[id])}>
          {lengthToDisplay}
          <AccordionIconToggle eventKey={eventKey} />
        </span>
      )
    }
    return null;
  }

  return (
    <Card id={id} className={cardClassName || css.card}>
      <Accordion.Toggle
        className={toggleClassName || css.card}
        as={Card.Header}
        eventKey={eventKey}
      >
        <span className={headerClassName || css.headerText}>
          {headerText}
        </span>
        {renderIcon()}
      </Accordion.Toggle>
      {children}
    </Card>
  );
}

AccordionItems.propTypes = {
  id: PropTypes.string,
  cardClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  headerText: PropTypes.string,
  toggleClassName: PropTypes.string,
  eventKey: PropTypes.string,
  iconClassName: PropTypes.string,
  lengthToDisplay: PropTypes.number,
  variant: PropTypes.oneOf(['cases', 'caseDetail']).isRequired,
  children: PropTypes.node.isRequired,
}