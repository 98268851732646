import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import css from "./legalTerms.module.css";
import commonCss from "../common/styles.module.css";
import AuthCheck from "../../components/authcheck/authCheck";
import authState from "../../components/authcheck/authState";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import classnames from "classnames";
import { ReactComponent as LegalIcon } from "../../components/shared/imgs/legal-icon.svg";
import Button from "../../components/button/button";
import * as instructProcessActions from "../../redux/actions/instruct-actions/instruct-process";
import Layout from '../../layout';

const LegalTerms = ({ getAccessToken, pathSlugs }) => {
  const history = useHistory();
  const [submitted, setSubmitted] = useState(false);

  const dispatch = useDispatch();
  const instructionBusy = useSelector(store => store.instruct.instructProcessState.processing)
  const instructQuote = () => {
    setSubmitted(true);
    dispatch(instructProcessActions.INSTRUCT_REQUEST_BUSY.action(true))
    getAccessToken().then(() => {
      dispatch(
        instructProcessActions.INSTRUCT_QUOTE.action({
          quoteId: pathSlugs?.params?.quoteId || null,
          history
        })
      );
    });
  };

  useEffect(() => {
    if (!instructionBusy && submitted) {
      setSubmitted(false);
    }
  }, [instructionBusy]);

  return (
    <Layout fluid>
      <AuthCheck requiredAuthState={authState.AUTHENTICATED} />

      <Row className={css.bottompad2x}>
        <Col md={1} xs={12}></Col>
        <Col md={10} xs={12}>
          <Row>
            <Col md={1} xs={4}>
              <LegalIcon className={css.legalTermsIcon} />
            </Col>
            <Col md={3} xs={8}>
              <div className={classnames(css.headerText, css.leftalign)}>
                THE LEGAL STUFF
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className={css.subheader}>
                Please note indemnity and liability provisions below - you should only use this site and service if you accept these provisions.
              </div>
              <div className={css.contentText}>
                <div className={css.sectionText}>
                  The following terms and conditions (<span className={css.sectionBold}>Terms</span>) shall apply between us (<span className={css.sectionBold}>Legal Marketing Services Ltd/LMS</span>) and you,
                  in relation to your use of the following site and services:<br />
                  <a className={css.sectionLink} href="https://cloud.lms.com/select/" rel="noreferrer" target="_blank">(LMS Marketplace- Conveyancing Quotation and Instruction Page (‘Marketplace’))</a>.
                </div>
                <div className={css.sectionText}>
                  Please note our general <a className={css.sectionLink} rel="noreferrer" target="_blank" href="https://www.lms.com/terms/terms-of-use.html">Website Terms</a> will also apply. In the event of any conflict or consistency, these Terms shall take priority.
                </div>
                <div className={css.sectionText}>
                  For the purposes of these Terms, Marketplace shall be referred to as the site.
                </div>

                <div className={css.sectionHeader}>1. Intended Use</div>
                <div className={css.sectionText}>
                  The site is intended for use by brokers, estate agents or intermediaries (<span className={css.sectionBold}>Permitted User</span>) who have registered with LMS, and any other user shall be known as an <span className={css.sectionBold}>Unauthorised User</span>. If you are an Unauthorised User then you should stop using this site immediately.
                </div>

                <div className={css.sectionHeader}>2. Registration</div>
                <div className={css.sectionText}>
                  You will only be able to register as a Permitted User if you, or your network, have also entered a separate Introduction Agreement with us to provide broker services and introductions (<span className={css.sectionBold}>Introduction Agreement</span>).
                </div>
                <div className={css.sectionText}>
                  To become a Permitted User and access the services available on the site, you must complete our ‘Broker Registration’ process as made available on the site. We will conduct such due diligence as is determined by LMS to be reasonable in order to ensure the security of the site, and to minimise risks of fraud and money laundering. We reserve the right to conduct additional or different due diligence at any time on notice to you, as a reflection of the need to ensure our compliance processes constantly evolve and adapt.
                </div>
                <div className={css.sectionText}>
                  Any refusal to complete the due diligence requested, provision of false information, and/or failure to successfully pass the due diligence checks to LMS’ satisfaction, will result on your registration or account for this site being refused or suspended, as applicable. Any change in your status which would result in a failure of the checks,  (e.g. loss of FCA registration), will result in the same outcome.
                </div>

                <div className={css.sectionHeader}>3. Access to the site and login details</div>
                <div className={css.sectionText}>
                  Once you have registered  for your Account, subject to these Terms we will set you up as a new user by the end of the next working day and you will be provided with log on details, which will be required  whenever you instruct a  case.
                </div>
                <div className={css.sectionText}>
                  You are responsible for the security and confidentiality of any usernames, passwords, personalised link or other information needed to access or use the site or any of our services. You must not allow others to access the site or any services via your username or personalised link.
                </div>
                <div className={css.sectionText}>
                  You are only permitted to make twelve attempts to log on to the service, should you exceed these attempts your account may be suspended and you may be unable to use the site. Should this happen you would need to contact us directly at <a className={css.sectionLink} rel="noreferrer" target="_blank" href="mailto: helpdesk@lms.com">helpdesk@lms.com</a> in order to request further access to the service.
                </div>
                <div className={css.sectionText}>
                  Should you lose or misplace your access details you must promptly notify us at <a className={css.sectionLink} rel="noreferrer" target="_blank" href="mailto: helpdesk@lms.com">helpdesk@lms.com</a>
                </div>
                <div className={css.sectionText}>
                  We have the right to disable any user access, identification code or password at any time, if in our reasonable opinion there is a risk of fraud; a threat to security of the site; or you have failed to comply with any of the provisions of these Terms. If you know or suspect that anyone other than you have accessed the service, knows your user identification code or password, you must promptly notify us at <a className={css.sectionLink} rel="noreferrer" target="_blank" href="mailto: helpdesk@lms.com">helpdesk@lms.com</a>.
                </div>
                <div className={css.sectionText}>
                  You are required to ensure that any employee or other personnel authorised to access this site your behalf, complies with these Terms at all times. Any failure by such individuals to do so, shall be treated as a failure and breach by you under these Terms.
                </div>

                <div className={css.sectionHeader}>4. Customer Referrals</div>
                <div className={css.sectionText}>
                  This site is made available to Permitted Users so you may obtain quotes for conveyancing services from one of our Panel Firms on behalf of one of your customers. Any quote provided by a Panel Firm via this site is binding for a period of 14 days from the date of creation. If a quote is accepted by a Permitted User’s customer then this should be notified to the Panel Firm within this time limit, using this site. The Panel Firm will then communicate directly with the customer to confirm the instruction and establish the terms on which any conveyancing services will be provided. We/the Panel Firm will also communicate with the customer by letter and confirm the service LMS has provided through this site and any applicable referral fees - for you and LMS. The customer will then be requested to confirm any instruction directly with the Panel Firm, on the basis of this communication.
                </div>
                <div className={css.sectionText}>
                  LMS shall take reasonable steps to, but shall be under no obligation to provide the Permitted User with a quote or other information a Permitted User requests on behalf of a customer via the site, nor shall LMS incur any liability for refusing or failing to do so.
                </div>
                <div className={css.sectionText}>
                  Subject to the preceding in this paragraph 4, we make no commitments and accept no liability as to the provision of conveyancing services by a Panel Firm to a customer, as arranged through provision of the Marketplace service.
                </div>

                <div className={css.sectionHeader}>5. Quotes and fees</div>
                <div className={css.sectionText}>
                  <span className={css.sectionBold}>No completion, no legal or broker fee</span><br />
                  If for any reason your customer’s transaction falls through before they have contractually committed, they won’t have to pay any of the legal costs.  However, if any third-party costs e.g. property searches, have already been incurred of their behalf they will need to pay for these.
                </div>
                <div className={css.sectionText}>
                  Please note, in this event you will also not receive any agreed fee for broker services.
                </div>
                <div className={css.sectionText}>
                  <span className={css.sectionBold}>The fixed fee quote</span><br />
                  The fee you have been quoted for conveyancing services, is a fixed fee covering the standard legal work required.  Should any further work be carried out on your customer’s behalf at their request, there may be additional fees payable.  These fees will be agreed between the customer and the Panel Firm before work commences.
                </div>
                <div className={css.sectionText}>
                  This quote shall remain open for acceptance by you for a period of 30 days.
                </div>
                <div className={css.sectionText}>
                  <span className={css.sectionBold}>Your Broker/Referral fee</span><br />
                  When requesting a quote via Marketplace, you will be required to provide details of the fee which you have agreed with your customer for the provision of your broker services.  Such fee will then be paid to you in accordance with the Introduction Agreement.
                </div>
                <div className={css.sectionText}>
                  <span className={css.sectionBold}>Case tracking</span><br />
                  You can track the progress of your case within Marketplace,  which provides you with the key milestone updates; ability to view documents; contact details for the Panel Firm case handler as well as LMS and frequently asked questions.
                </div>
                <div className={css.sectionText}>
                  <span className={css.sectionBold}>Other important information</span><br />
                  This quote has been prepared on the details provided and the Land Registry fees used have been based upon the fees for registered properties.  This is subject to change as at quotation stage the legal title has not been checked by the panel firm.  You should be aware that it is not possible to cover every individual scenario which can mean, on occasion, unavoidable disbursements, additional legal work or more complex matters may occur.  In this case, the law firm will confirm once the legal title has been checked and you will be contacted before they proceed.  The law firm act in the interest of you, your customer (their client) and the mortgage lender (if applicable).
                </div>
                <div className={css.sectionText}>
                  <span className={css.sectionBold}>Local Search Indemnity Insurance (LSI)</span><br />
                  For remortgage cases, LMS have included LSI on the quotation as this is accepted by the majority of UK mortgage lenders.  We must make your aware that some lenders do not accept LSI.  In these circumstances and dependent upon the type of transaction, the lender may request the full searches are undertaken.  This will be fully determined by the acting law firm from the UK Finance Lenders Handbook or through special conditions within the lenders mortgage offer.  If searches are required, the law firm will confirm the additional costs with you and the customer as such costs vary with each local authority.
                </div>
                <div className={css.sectionText}>
                  <span className={css.sectionBold}>Searches</span><br />
                  Additional searches may be required due to the property’s location which may incur a fee.
                </div>
                <div className={css.sectionText}>
                  <span className={css.sectionBold}>Leasehold</span><br />
                  When selling/buying or remortgaging a leasehold property, the freeholder and/or managing agent may charge fees for releasing information.  These fees can vary and the law firm will confirm the charges that apply in each case.
                </div>
                <div className={css.sectionText}>
                  <span className={css.sectionBold}>Stamp Duty</span><br />
                  The applicable Stamp Duty Land Tax (England) Land Transaction Tax (Wales) or Land & Buildings Transaction Tax (Scotland) will be confirmed by your appointed law firm.
                </div>
                <div className={css.sectionText}>
                  <span className={css.sectionBold}>Disbursements</span><br />
                  The quote provided includes a guide as to the most common disbursements for this transaction.  The appointed law firm will write to the customer/client and provide a full guide of disbursements applicable to their transaction as these can vary depending on the mortgage lender, the property and where additional work is required.
                </div>

                <div className={css.sectionHeader}>6. Indemnity - Sharing of information and personal data</div>
                <div className={css.sectionText}>
                  The Permitted User is required to ensure they have the necessary permissions, authority and/or legal bases pursuant to which they may share both confidential and personal data relating to a customer, as required by the UK GDPR, the  Data Protection Act 2018, and any other applicable laws and regulations in the UK from time to time.
                </div>
                <div className={css.sectionText}>
                  Furthermore, the Permitted User acknowledges and agrees that LMS is reliant on the Permitted User to ensure they have the authority to provide the above information, to obtain  a quote and/or confirm a quote on behalf of a Customer via this site, and to obtain an insurance quote and service from UInsure, which includes the sharing of information and personal data with the Panel Firms and UInsure, pursuant to which the Permitted User confirms and undertakes they have the authority to do the preceding on behalf of the customer.
                </div>
                <div className={css.sectionText}>
                  LMS will process and retain any personal data provided by a Permitted User in accordance with the Introduction Agreement and its <a className={css.sectionLink} href="https://www.lms.com/privacy.html">Privacy Notice</a> at all times.
                </div>
                <div className={css.sectionText}>
                  A Permitted User shall have no authority, and shall not hold yourself out, or permit any person to hold themselves out, or otherwise create the impression that you are authorised to bind LMS or the Panel Firms in any way, and shall not do any act which might reasonably create the impression that you are authorised to do so.
                </div>
                <div className={css.sectionText}>
                  <span className={css.sectionBold}>Indemnity</span>. The Permitted User shall indemnify LMS against all claims and proceedings and all liability, loss, costs and expenses incurred by LMS as a result of any claim made or brought by a customer, Lender Client or other legal person in respect of any loss, damage or distress caused to them as a result of any breach by the Permitted User of the obligations and warranty set out in this paragraph “Authority and Indemnity”, provided that LMS gives to a Permitted User prompt notice of such claim, full information about the circumstances giving rise to it, reasonable assistance in dealing with the claim and sole authority to manage, defend and/or settle it.
                </div>

                <div className={css.sectionHeader}>7. Marketing/Panel Management Fee</div>
                <div className={css.sectionText}>
                  LMS is required to comply with the Code of Conduct published by the Solicitors Regulation Authority, or equivalent standards as required by the Council of Licensed Conveyancers, Law Societies of Scotland and Northern Ireland and the requirements of Trading Standards when making any referral.  Please note, any Panel Firm whom LMS may refer your customer to is an independent professional from whom your customer will receive impartial and confidential advice. The advice and service of the Panel Firm will remain independent and subject to your and your customer’s instructions. You and the customer are always free to choose another solicitor until instruction is confirmed directly with the Panel Firm at which point any withdrawal of instruction will be subject to that Panel Firm’s terms of engagement.
                </div>
                <div className={css.sectionText}>
                  LMS are required to inform you and your customer as part of the Code of Conduct issued by the Solicitors Regulation Authority and equivalent bodies, as above, that govern Panel Firms acting in the United Kingdom that in accordance with usual professional practice, the Panel Firm that you instruct on behalf of your customer will pay us a fee and your customer shall be made aware of this fee and our service in the instruction letter sent to them. This letter will also explain any commission paid to you as well.
                </div>
                <div className={css.sectionText}>
                  <span className={css.sectionBold}>Please note that no fee will be payable to us, nor commission/Referral Fee to you, unless the customer’s conveyance completes.
                  </span>
                </div>
                <div className={css.sectionText}>
                  In the event a Panel Firm uses our automatic case updating system they will also pay us an administration fee. These costs are used to contribute towards the ongoing quality control, audit, training, support and general commercial development of the Panel Firms including information technology development.
                </div>

                <div className={css.sectionHeader}>8. UInsure</div>
                <div className={css.sectionText}>
                  LMS offer the opportunity to obtain home insurance from UInsure Limited, via the site. To do this, you will need to follow the provided link to the UInsure website and register with them. Please note that such website, registration, obtaining of quotes and insurance products will all be subject to UInsure’s Intermediary Terms of Business and Privacy Notice (or such other documentation made available from time to time).
                </div>
                <div className={css.sectionText}>
                  Once registered with UInsure, you will be able to generate a quote for insurance for your customer, which is then made available via the site. Such quotes will be valid for 30 days. To generate the quote we will need to share information relating to your customer and property with UInsure, namely the mortgaged property address, property type, year of construction, number of bedrooms customer date of birth (all parties). Such sharing is via a secure UI authentication key.
                </div>
                <div className={css.sectionText}>
                  If your customer decides to process with a UInsure quote we will then also share the customer’s name, current/correspondence address, telephone and email address.
                </div>

                <div className={css.sectionHeader}>Disclaimer</div>
                <div className={css.sectionText}>
                  Unsure is a separate business and service to LMS, and we only assist you by providing an integrated means to obtain a quote and insurance for your customers. All registration and access to UInsure products is determined by UInsure in accordance with their Intermediary terms of Business and any other requirements as set by UInsure. <span className={css.sectionBold}>LMS hereby excludes all liability related to all acts and/or omissions of UInsure and/or claims relating to products supplied by UInsure</span>.
                </div>

                <div className={css.sectionHeader}>9. Downloading items</div>
                <div className={css.sectionText}>
                  You may download the Self Billing Agreement and Support Guide, subject to these Terms.
                </div>
                <div className={css.sectionText}>
                  Although we make reasonable efforts to update the information on our site, we make no representations, warranties or guarantees, whether express or implied, that the content on our site is accurate, complete or up to date.
                </div>

                <div className={css.sectionHeader}>10. Limitation of Liability</div>
                <div className={css.sectionText}>
                  We exclude all liability for losses arising out of your reliance on Marketplace when there have been errors or inaccuracies in the information provided by you on Marketplace and when you do not have the authority to provide such information.
                </div>
                <div className={css.sectionText}>
                  If you are a Permitted User, our total liability to you and your total liability to us, whether in contract, tort (including negligence), for breach of statutory duty, or otherwise, arising under or in connection with this site shall be limited in accordance with the Introduction Agreement between us (or with your network body if applicable).
                </div>
                <div className={css.sectionText}>
                  If you are an Unauthorised User you agree not to use this site and we have no liability to you for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with:
                </div>
                <div className={css.sectionText}>
                  <ul>
                    <li>use of, or inability to use, the site; or</li>
                    <li>use of or reliance on any content displayed on the site.</li>
                  </ul>
                </div>

                <div className={css.sectionHeader}>11. Varying terms</div>
                <div className={css.sectionText}>
                  We may vary these terms at any time by providing you with 30 days’ prior notice to the proposed date of change, as well as a link to, or copy of, the updated Terms. Such notice may be provided via Marketplace when you log-in or use the site, or otherwise sent to the email address provided on registration. You continued use of the site after the 30 days will demonstrate your acceptance of the updated Terms, which shall apply from the date of change.
                </div>

                <div className={css.sectionHeader}>12. Law and Jurisdiction</div>
                <div className={css.sectionText}>
                  These Terms are subject to the laws of England and Wales, as well as the exclusive jurisdiction of the Courts of England and Wales.
                </div>
              </div>
            </Col>
          </Row>


          {/* buttons */}
          <Row>
            <Col md={2} xs={12} className={css.buttonAlignment}>
              <Button
                id="InstructButton"
                datatestid="InstructButton"
                btnClass={commonCss.backButton}
                onClick={(e) => {
                  e.preventDefault();
                  history.goBack();
                }}
                text="BACK TO INSTRUCTION"
              />
            </Col>
            <Col md={2} xs={12} className={css.buttonAlignment}>
              <Button
                id="InstructButton"
                datatestid="InstructButton"
                btnClass={commonCss.backButton}
                onClick={instructQuote}
                text="I AGREE"
                isLoading={instructionBusy}
                disabled={submitted}
              />
            </Col>
          </Row>
        </Col>
        <Col md={1} xs={12}></Col>
      </Row>
    </Layout >
  );
};

LegalTerms.propTypes = {
  pathSlugs: PropTypes.shape({
    params: PropTypes.shape({
      quoteId: PropTypes.string,
    }),
  }),
  getAccessToken: PropTypes.func,
};

export default LegalTerms;
