import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";

import AuthCheck from "../../components/authcheck/authCheck";
import authState from "../../components/authcheck/authState";
import Button from "../../components/button/button";
import FeesTable from "../../components/quoteBreakdown/components/feestable";
import Totals from "../../components/quoteBreakdown/components/totals";
import ViewQuoteBanner from "./components/viewquotebanner";
import Layout from '../../layout';

import css from "./viewquote.module.css";
import commonCss from "../common/styles.module.css";

import formatter from "../../utils/formatter";
import { generateNotes, removeDuplicateObjectFromArray } from "../../utils/quoteBreakdownUtils";

import SaveQuoteModal from "./components/savequotemodal"
import SendQuoteModal from "./components/sendquotemodal"
import * as saveQuoteActions from "../../redux/actions/quoteActions/save";

import { ReactComponent as PrintIcon } from "../../components/shared/imgs/print-icon.svg"
import CopyForPrint from "../../components/general-text"
import { printQuoteCopy } from "../../data/print-quote-terms";
import {
  findReceivedQuote,
  sumReceivedQuotes,
  getReceivedQuotesDateGenerated,
  toQuoteTypeDisplay
} from "../../utils/jsutils";
import { resetInstructData } from "../../redux/actions/actions";
import { useSelectApi } from "../../hooks/selectApi";

const ViewQuote = () => {
  const dispatch = useDispatch()
  const { preferQuote } = useSelectApi();
  const history = useHistory();
  const [quoteResults, setQuoteResults] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const quoteIds = useSelector((store) => store.quote.selectedQuotes);
  const quotes = useSelector((store) => store.quote.quotesReceived);
  const selectedInsurance = useSelector((store) => store.uinsure);
  const { quoteGroupId } = quotes;

  useEffect(() => {
    const receivedQuotes = [];
    quoteIds.map((id) => receivedQuotes.push(findReceivedQuote(quotes, id)));
    const combinedTotals = sumReceivedQuotes(receivedQuotes);
    setQuoteResults({ quotes: receivedQuotes, ...combinedTotals });
  }, [quotes])

  let notes = [];

  quoteResults.quotes?.forEach((quote) => {
    generateNotes(quote.costBreakdown).forEach((item) => notes.push(item));
    notes = removeDuplicateObjectFromArray(notes, 'symbol');
  });

  const dateGenerated = getReceivedQuotesDateGenerated(quotes);

  const selectedGi = useSelector((store) => store.uinsure?.selectedQuote);
  const quote = useSelector((store) => store.quote);

  const [showSaveQuoteModal, setShowSaveQuoteModal] = useState(false)
  const [showSendQuoteModal, setShowSendQuoteModal] = useState(false)

  const uinsure = useSelector((store) => store.uinsure);

  const {
    selectedQuote: selectedGiQuoteReference = undefined,
    selectedPremium: selectedGiPremiumReference = undefined,
    quote: giQuote = undefined } = uinsure || {}

  let selectedGiQuote;
  let selectedGiPremium;
  if (selectedGiQuoteReference) {
    const { buildings, buildingsAndContents } = giQuote;
    selectedGiQuote = selectedGiQuoteReference == buildings.quoteReference ? buildings : buildingsAndContents;
    selectedGiPremium = selectedGiQuote.premiumListItems.filter(item => item.premiumId === selectedGiPremiumReference)[0];
  }

  const instructUrl = "/instruct-quote"

  const clearValidationErrors = () => {
    dispatch(saveQuoteActions.CLEAR_VALIDATION.action(true))
  }

  const handleSaveQuoteModal = () => {
    setShowSaveQuoteModal(true)
  }

  const handleHideSaveQuoteModal = () => {
    clearValidationErrors()
    setShowSaveQuoteModal(false)
  }

  const handleSendQuoteModal = () => {
    setShowSendQuoteModal(true)
  }

  const handleHideSendQuoteModal = () => {
    clearValidationErrors()
    setShowSendQuoteModal(false)
  }

  const handleInstructClick = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    await preferQuote({ quoteGroupId, quoteId: quoteIds[0], insuranceReference: selectedGiQuoteReference, insurancePremium: selectedGiPremiumReference })
    if (quoteIds.length > 1) {
      await preferQuote({ quoteGroupId, quoteId: quoteIds[1], insuranceReference: selectedGiQuoteReference, insurancePremium: selectedGiPremiumReference });
    }
    dispatch(resetInstructData());
    history.push(`${instructUrl}/${quoteGroupId}`);
    setSubmitting(false);
  }

  const mapQuoteType = (type) => {
    return toQuoteTypeDisplay(type).toUpperCase();
  }

  return (
    <Layout classname={css.viewQuoteContainer}>
      <AuthCheck requiredAuthState={authState.AUTHENTICATED} />
      <Row>
        <Col md={1} lg={1} />
        <Col md={10} lg={10}>
          <Row>
            <Col md={12} lg={12}>
              <Button
                variant="tertiary"
                id="PrintButton"
                datatestid="PrintButton"
                btnClass={css.printButton}
                onClick={() => window.print()}
                iconLeft={PrintIcon}
                text="Print"
              />
              <ViewQuoteBanner quoteType={'marketplace'} />
            </Col>
          </Row>
          <Row>
            <Col>
              <div className={css.quoteDetailsContainer}>
                <Table borderless className={css.quoteDetailsTable}>
                  <tbody>
                    <tr>
                      <td>Customer ref/postcode</td>
                      <td>Save this quote to generate</td>
                    </tr>
                    <tr>
                      <td>Quote Generated</td>
                      <td>
                        {formatter.formatDate(new Date(dateGenerated))} (valid
                        for 14 days)
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
          {quoteResults.quotes?.map((t, idx) => (
            <React.Fragment key={idx}>
              <Row>
                <Col md={12} lg={12}>
                  <h1 className={commonCss.pageHeading}>
                    YOUR{" "}
                    {mapQuoteType(t.quoteType)}{" "}
                    QUOTE
                  </h1>
                </Col>
              </Row>
              {idx === 0 && (
                <Row>
                  <Col>
                    <div className={css.vatText}>
                      All prices are subject to VAT unless otherwise stated.
                    </div>
                  </Col>
                </Row>
              )}
              <div className={css.feesContainer}>
                <FeesTable fees={t.costBreakdown} notes={generateNotes(t.costBreakdown)} />
              </div>
              <Totals
                subTotal={t.costSummary.subtotalCost}
                vatTotal={t.costSummary.vat}
                totalCost={t.costSummary.totalCost}
              />
            </React.Fragment>
          ))}
          {quoteResults.quotes?.length > 1 && (
            <>
              <Row>
                <Col md={12} lg={12}>
                  <div className={css.grandTotalHeading}>
                    <h1 className={commonCss.pageHeading}>COMBINED QUOTE</h1>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Totals
                    subTotal={quoteResults.subTotal}
                    vatTotal={quoteResults.vat}
                    totalCost={quoteResults.total}
                    totalText="GRAND TOTAL"
                  />
                </Col>
              </Row>
            </>
          )}
          <Row>
            <Col>
              {notes.map((n, idx) => (
                <div className={css.noteFooter} key={idx}>
                  {`${n.symbol} ${n.noteDescription}`}
                </div>
              ))}
            </Col>
          </Row>
          {selectedGiPremium && (
            <Row>
              <Col md={12} lg={12}>
                <ViewQuoteBanner quoteType={"homeinsurance"} />
              </Col>
            </Row>
          )}
          {selectedGiPremium && (
            <Row>
              <Col>
                <Totals
                  totalCost={selectedGiPremium.totalMonthlyPremium}
                  totalText="Total"
                  totalHint={selectedGiQuote.includesContents ? "Buildings and Contents" : "Buildings only"}
                  showTotalOnly
                />
              </Col>
            </Row>
          )}
          {selectedGiPremium && (
            <Row>
              <Col>
                <div className={css.uinsureFooter}>
                  <div>* 12 x monthly installments of {formatter.formatCurrency(selectedGiPremium.totalMonthlyPremium)} (including {selectedGiPremium.iptPercent}% fixed interest rate)</div>
                </div>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <div className={`${commonCss.buttonContainer} ${css.buttonRow}`}>
                <Button
                  id="ViewQuoteSaveQuote"
                  datatestid="ViewQuoteSaveQuote"
                  type="button"
                  btnClass={commonCss.button}
                  size="lg"
                  variant="primary"
                  onClick={() => handleSaveQuoteModal()}
                  text="SAVE QUOTE"
                />
                <SaveQuoteModal
                  quoteIds={quoteIds}
                  quoteGroupId={quoteGroupId}
                  show={showSaveQuoteModal}
                  onHide={() => handleHideSaveQuoteModal()}
                  actionButtonText="SAVE QUOTE"
                  insuranceReference={selectedInsurance?.selectedQuote}
                  insurancePremium={selectedInsurance?.selectedPremium}
                />
                <Button
                  id="ViewQuoteSendQuote"
                  datatestid="ViewQuoteSendQuote"
                  type="button"
                  btnClass={commonCss.button}
                  size="lg"
                  variant="primary"
                  onClick={() => handleSendQuoteModal()}
                  text="SEND QUOTE"
                />
                <SendQuoteModal
                  show={showSendQuoteModal}
                  onHide={() => handleHideSendQuoteModal()}
                  actionButtonText="SEND QUOTE"
                  quoteGroupId={quoteGroupId}
                  quoteIds={quoteIds}
                  requiresSave={true}
                  insuranceReference={selectedInsurance?.selectedQuote}
                  insurancePremium={selectedInsurance?.selectedPremium}
                />
                <Button
                  id="ViewQuoteInstructQuote"
                  datatestid="ViewQuoteInstructQuote"
                  type="button"
                  btnClass={commonCss.button}
                  size="lg"
                  variant="primary"
                  onClick={(e) => handleInstructClick(e)}
                  text="INSTRUCT QUOTE"
                  disabled={submitting}
                  isLoading={submitting}
                />
              </div>
              <CopyForPrint copy={printQuoteCopy} />
            </Col>
          </Row>
        </Col>
      </Row>
      <Col md={1} lg={1} />
    </Layout>
  );
};

export default ViewQuote;
