import React from "react";
import PropTypes from "prop-types";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "../../components/button/button";
import { ReactComponent as Banner } from "../../components/shared/imgs/landing-banner.svg";
import { ReactComponent as Uinsure } from "../../components/shared/imgs/uinsure-logo.svg";
import classnames from "classnames";
import css from "./Landing.module.css";
import AuthCheck from "../../components/authcheck/authCheck";
import authState from "../../components/authcheck/authState";
import Layout from '../../layout';

const Landing = ({ signinRedirect }) => {

  return (
    <Layout>
      <AuthCheck requiredAuthState={authState.UNAUTHENTICATED} />

      <Row className={css.bottompad2x}>
        <Col md={1} xs={12}></Col>
        <Col md={7} xs={12}>
          <Row className={css.bottompad2x}>
            <Col md={12} xs={12} className={css.leftalign}>
              <Banner id="banner" data-testid="banner" />
            </Col>
          </Row>
          <Row className={css.bottompad2x}>
            <Col
              md={12}
              xs={12}
              data-testid="main-text"
              className={classnames(css.leftalign, css.landingtitle)}
            >
              Making conveyancing easy for the broker community
            </Col>
          </Row>
          <Row className={css.bottompad2x}>
            <Col
              md={12}
              xs={12}
              data-testid="secondary-text"
              className={classnames(css.leftalign, css.landingtext)}
            >
              Get quotes from our panel of hand-picked law firms. Send the quote
              to your customer, instruct the case and keep an eye on its
              progress, all in one place.
            </Col>
          </Row>
          <Row className={css.bottompad2x}>
            <Col
              md={12}
              xs={12}
              className={classnames(css.leftalign, css.inlineflex)}
            >
              <Button
                id="sign_in"
                datatestid="sign-in"
                btnClass={css.signin}
                onClick={() => signinRedirect()}
                type="button"
                text="SIGN IN"
              />
            </Col>
          </Row>
        </Col>
        <Col md={3} xs={12}>
          <Row className={classnames(css.bottompad2x, css.videosection)}>
            <Col md={12} xs={12} className={css.leftalign}>
              <Row className={css.bottompad1x}>
              WANT TO KNOW MORE?
              </Row>
              <Row className={css.bottompad1x}>Watch our videos and 'how to' guides</Row>
              <Row className={classnames(css.bottompad2x, css.videolink)}>
              <video width="100%" controls >
                <source src="https://www.lms.com/lmsselect_videos/lmsselect.mp4" type="video/mp4" data-testid="lms-video-mp4"/>
                <source src="https://www.lms.com/lmsselect_videos/lmsselect.ogv" type="video/ogv" data-testid="lms-video-ogv"/>
              </video>
              </Row>
              <Row className={classnames(css.uinsurelogo)}>
                <Uinsure id="uinsure-logo" data-testid="uinsure-logo" width="100px"/>
              </Row>
              <Row className={css.videolink}><a href="https://www.lms.com/lmsselect_videos/uinsure-how-to-connect.mp4" target="_blank" data-testid="uinsure-1">How to connect my Uinsure account &gt;</a></Row>
              <Row className={css.videolink}><a href="https://www.lms.com/lmsselect_videos/uinsure-how-to-generate-a-quote.mp4" target="_blank" data-testid="uinsure-2">How to generate a Uinsure quote &gt;</a></Row>
              <Row className={css.videolink}><a href="https://www.lms.com/lmsselect_videos/uinsure-selling-insurance-myself.mp4" target="_blank" data-testid="uinsure-3">Selling Uinsure home insurance myself &gt;</a></Row>
              <Row className={css.videolink}><a href="https://www.lms.com/lmsselect_videos/uinsure-completing-on-my-behalf.mp4" target="_blank" data-testid="uinsure-4">Asking Uinsure to complete on my behalf &gt;</a></Row>
            </Col>
          </Row>
        </Col>
        <Col md={1} xs={12}></Col>
      </Row>
    </Layout>
  );
};

Landing.propTypes = {
  signinRedirect: PropTypes.func,
};

export default Landing;
