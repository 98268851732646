import React, { useState } from "react";
import { useDispatch } from "react-redux"
import { resetQuoteData } from "../../redux/actions/actions"
import { slide as Menu } from "react-burger-menu";
import css from "./menu.module.css";
import { ReactComponent as Hamburger } from "../../components/shared/imgs/hamburger.svg";
import { ReactComponent as Close } from "../../components/shared/imgs/close.svg";
import { ReactComponent as IconArrow } from "../../components/shared/imgs/link-closed.svg";
import Button from "../../components/button/button";
import { useHistory } from "react-router-dom";

const Navigation = () => {
  const dispatch = useDispatch()
  const history = useHistory();

  const [isOpen, setIsOpen] = useState(false)

  const handleMenuItemClick = (location) => {
    setIsOpen(false)
    history.push(location)
  }

  const handleStateChange = (state) => {
    setIsOpen(state.isOpen)
  }

  const clearQuoteData = () => {
    dispatch(resetQuoteData())
  }

  const handleNewQuoteClick = () => {
    clearQuoteData()
    handleMenuItemClick("/quote")
  }

  const handleSavedQuoteClick = () => {
    clearQuoteData()
    handleMenuItemClick("/view-quotes")
  }

  const handleViewCasesClick = () => {
    clearQuoteData()
    handleMenuItemClick("/case-progress")
  }

  return (
    <Menu
      id="menu"
      data-testid="menu"
      menuClassName={css.bmMenu}
      burgerButtonClassName={css.bmBurgerButton}
      crossButtonClassName={css.bmCrossButton}
      className={css.outerMenuContainer}
      disableAutoFocus
      customBurgerIcon={<Hamburger className={css.burgerIcon} />}
      customCrossIcon={<Close className={css.crossIcon} />}
      width={"375px"}
      isOpen={isOpen}
      onStateChange={(state) => handleStateChange(state)}
    >
      <div className={css.menuContent}>
        <div className={css.spacer}></div>

        <div className={css.menuItem}>
          <Button
            id="new_quote"
            datatestid="dashboard_menu"
            btnClass={css.menuItemText}
            btnTextClass={css.buttonText}
            btnIconClass={css.buttonIcon}
            onClick={() => handleMenuItemClick("/")}
            type="button"
            text="RETURN TO DASHBOARD"
            icon={IconArrow}
          />
        </div>
        <div className={css.spacer}></div>

        <div className={css.subHeader}>YOUR ACTIONS</div>


        <div className={css.menuItem}>
          <Button
            id="new_quote"
            datatestid="new_quote"
            btnClass={css.menuItemText}
            btnTextClass={css.buttonText}
            btnIconClass={css.buttonIcon}
            onClick={() => handleNewQuoteClick()}
            type="button"
            text="START A NEW QUOTE"
            icon={IconArrow}
          />
        </div>

        <div className={css.menuItem}>
          <Button
            id="instruct_quote"
            datatestid="instruct_quote"
            btnClass={css.menuItemText}
            btnTextClass={css.buttonText}
            btnIconClass={css.buttonIcon}
            onClick={() => handleSavedQuoteClick()}
            type="button"
            text="INSTRUCT A SAVED QUOTE"
            icon={IconArrow}
          />
        </div>

        <div className={css.menuItem}>
          <Button
            id="check_case"
            datatestid="check_case"
            btnClass={css.menuItemText}
            btnTextClass={css.buttonText}
            btnIconClass={css.buttonIcon}
            onClick={() => handleViewCasesClick()}
            type="button"
            text="CHECK CASE PROGRESS"
            icon={IconArrow}
          />
        </div>

        <div className={css.spacer}></div>
        <div className={css.subHeader}>YOUR PREFERENCES</div>
        <div className={css.menuItem}>
          <Button
            id="preferences"
            datatestid="preferences"
            btnClass={css.menuItemText}
            btnTextClass={css.buttonText}
            btnIconClass={css.buttonIcon}
            onClick={() => handleMenuItemClick("/preferences")}
            type="button"
            text="CONTROL PANEL"
            icon={IconArrow}
          />
        </div>
      </div>
    </Menu>
  );
};

export default Navigation;
