import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router"
import { Row, Col} from "react-bootstrap"

import DateInput from "../../components/dateInput"
import css from "./index.module.css"
import Button from "../../components/button/button"
import DropdownSelectorLayout from "../../pages/quote/components/dropdown-selector-layout";

import { QuoteType } from "../../data/quote-type"
import Layout from '../../layout';

import { 
    propertyType, 
    giYearBuilt, 
    numberOfBedrooms 
} from "../../components/shared/data";

import {
    storeDateOfBirth,
    storeQuotePropertyType,
    storeQuoteNumberOfBedrooms,
    storeQuoteYearBuilt,
    validateUinsureForm,
  } from "../../redux/actions/actions";

const GeneralInsuranceFormDetails = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const caseType = useSelector(store => store.quote.type.value)
    const currentStore = useSelector(store => store.quote[caseType])
    const relevantStore = caseType === QuoteType.SALE_AND_PURCHASE ? currentStore.purchase : currentStore
    const storePath = caseType === QuoteType.SALE_AND_PURCHASE ? "saleAndPurchase.purchase" : null
    const homeOwnersCount = relevantStore.owners.value
        
    const isValid = useSelector(store => store.uinsure.requredDetailsCollected)
    const [isSubmitted, setSubmitted] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault()
        setSubmitted(true);
        dispatch(validateUinsureForm())
    }

    const getDateOfBirthCollection = (store) =>
        caseType == QuoteType.SALE_AND_PURCHASE
            ? store.quote[caseType].purchase.DateOfBirth.value
            : store.quote[caseType].DateOfBirth.value;

    const getQuote = (store) =>
        caseType == QuoteType.SALE_AND_PURCHASE
            ? store.quote[caseType].purchase
            : store.quote[caseType]

    useEffect(() => {
        if(isValid && isSubmitted) history.push('/quote-insurance')
        else if (!isValid) setSubmitted(false);
    }, [isValid, isSubmitted])

    return (
        <Layout classname={css.container}>
            <Row>
                <Col md={1} xs={1}></Col>
                <Col md={10} xs={10}>
                    <h1 className={css.h1}>We just need a few more details from the home insurance quote</h1>
                    <DropdownSelectorLayout
                        title="Type of Property"
                        id="property-type"
                        datatestid="property-type"
                        options={propertyType}
                        groupname="propertytype"
                        persistAction={storeQuotePropertyType}
                        storePath={storePath}
                        selector={caseType === QuoteType.SALE_AND_PURCHASE ? (store) => store.quote[caseType].purchase.propertyType.value : (store) => store.quote[caseType].propertyType.value}
                        errorMessage={caseType === QuoteType.SALE_AND_PURCHASE ? (store) => store.quote[caseType].purchase.propertyType.validation.message : (store) => store.quote[caseType].propertyType.validation.message}
                    />

                    <DropdownSelectorLayout
                        title="Number of Bedrooms"
                        id="number-bedrooms"
                        datatestid="number-bedrooms"
                        options={numberOfBedrooms}
                        groupname="NumberBedrooms"
                        persistAction={storeQuoteNumberOfBedrooms}
                        storePath={storePath}
                        selector={caseType === QuoteType.SALE_AND_PURCHASE ? (store) => store.quote[caseType].purchase.numberOfBedrooms.value : (store) => store.quote[caseType].numberOfBedrooms.value}
                        errorMessage={caseType === QuoteType.SALE_AND_PURCHASE ? (store) => store.quote[caseType].purchase.numberOfBedrooms.validation.message : (store) => store.quote[caseType].numberOfBedrooms.validation.message}
                    />

                    <DropdownSelectorLayout
                        title="Year Built"
                        id="year-built"
                        datatestid="year-built"
                        options={giYearBuilt}
                        groupname="YearBuilt"
                        persistAction={storeQuoteYearBuilt}
                        storePath={storePath}
                        selector={caseType === QuoteType.SALE_AND_PURCHASE ? (store) => store.quote[caseType].purchase.yearBuilt.value : (store) => store.quote[caseType].yearBuilt.value}
                        errorMessage={caseType === QuoteType.SALE_AND_PURCHASE ? (store) => store.quote[caseType].purchase.yearBuilt.validation.message : (store) => store.quote[caseType].yearBuilt.validation.message}
                    />

                    {/* <DateInput
                        id="dob"
                        datatestid="dob"
                        collectionValues={caseType === 'saleAndPurchase' ? (store) => store.quote[caseType].purchase.DateOfBirth.value : (store) => store.quote[caseType].DateOfBirth.value}
                        storeModel = {caseType === 'saleAndPurchase' ? (store) => store.quote[caseType].purchase : (store) => store.quote[caseType]}
                        persistAction={storeDateOfBirth}
                        storePath={storePath}
                        homeOwnersCount={homeOwnersCount}
                        errorclass="error"
                        className={css.dob}
                    /> */}

                    <DateInput
                        id="dob"
                        datatestid="dob"
                        collectionValues={getDateOfBirthCollection}
                        storeModel={getQuote}
                        persistAction={storeDateOfBirth}
                        storePath={storePath}
                        homeOwnersCount={homeOwnersCount}
                        errorclass={css.error}
                    />
                    <Row>
                        <Col>
                            <div className={css.actionContainer}>
                                <Button
                                    id="giskipbuton"
                                    datatestid="giskipbutton"
                                    text="Back"
                                    type="button"
                                    variant="tertiary"
                                    btnClass={css.firstButton}
                                    onClick={() => history.go(-1)}
                                />
                                <Button
                                    id="ginextbuton"
                                    datatestid="ginextbutton"
                                    text="Next"
                                    type="button"
                                    onClick={(e) => handleSubmit(e)}
                                />
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col md={1} xs={1}></Col>
            </Row>
        </Layout>
    )
}

export default GeneralInsuranceFormDetails