import { UserManager, WebStorageStateStore } from "oidc-client";
import { setAuthHeader } from '../../utils/axiosHeaders'

export class AuthService {
    UserManager;
    postLoginUrl;


    constructor(baseUrl, { authority, clientId, logoutUri }) {

        const config = {
            authority: authority,
            client_id: clientId,
            redirect_uri: `${baseUrl}/signin-oidc`,
            response_type: "code",
            scope: "openid profile",
            automaticSilentRenew: false,
            loadUserInfo: true,
            silent_redirect_uri: `${baseUrl}/renew-oidc`,
            post_logout_redirect_uri: logoutUri,
        }

        this.postLoginUrl = `${baseUrl}/dashboard`;

        this.UserManager = new UserManager({
            ...config,
            userStore: new WebStorageStateStore({ store: window.sessionStorage })
        });
    }

    signinRedirectCallback = () => {
        this.UserManager.signinRedirectCallback().then(() => {
            this.redirectToRequestedUri();
        });
    };

    signinRedirect = () => {
        this.persistRequestedUri();
        this.UserManager.signinRedirect({});
    };

    getUser = async () => {
        const user = await this.UserManager.getUser();
        return user;
    };

    isAuthenticated = async () => {
        const user = await this.UserManager.getUser();
        return !!user && !!user.access_token;
    };

    getAccessToken = async () => {
        let user = await this.UserManager.getUser();

        if (!user || !user.access_token || user.expired || user.expires_in < 10) {
            try {
                user = await this.UserManager.signinSilent();
            }
            catch (e) {
                this.signinRedirect();
                return null;
            }
        }

        setAuthHeader(user.access_token);
        this.redirectToRequestedUri();
        return user.access_token;
    }

    signinSilentCallback = () => {
        this.UserManager.signinSilentCallback();
    };

    logout = () => {
        this.UserManager.clearStaleState();
        this.UserManager.removeUser();
        this.UserManager.signoutRedirect();
    };

    signoutRedirectCallback = () => {
        this.UserManager.signoutRedirectCallback().then(() => {
            this.UserManager.clearStaleState();
            this.UserManager.removeUser();
        });
    };

    onSilentRenewError = (fn) => {
        this.UserManager.events.addSilentRenewError((e) => {
            fn(e);
        });
    }

    persistRequestedUri = () => {
        if (!window.sessionStorage.getItem("requested-uri")) {
            window.sessionStorage.setItem("requested-uri", window.location);
        }
    }

    redirectToRequestedUri = () => {
        const requestedUri = window.sessionStorage.getItem("requested-uri");

        if (requestedUri) {
            window.sessionStorage.removeItem("requested-uri");
            window.location.replace(requestedUri);
        }
    }
}

export default AuthService;