import React from 'react'
import PropTypes from 'prop-types'
import css from './index.module.css'

const SummaryDetails = ({
    title,
    value
}) => {
    return (
        <dl className={css.dl}>
            <dt className={css.dt}>{title}</dt>
            <dd className={css.dd} data-summary-value={title}>{value}</dd>
        </dl>
    )
}

SummaryDetails.propTypes = {
    title: PropTypes.string,
    value: PropTypes.string
}

export default SummaryDetails