import React from "react";
import PropType from "prop-types";

import css from "./viewquotebanner.module.css";
import {
  MarketplacePricing,
  SelectPricing,
} from "../../../../components/shared/imgs";
import { ReactComponent as UInsureLogo } from "../../../../components/shared/imgs/UInsure.svg";

const CONTENT = {
  marketplace: {
    text: "MARKETPLACE CONVEYANCING",
    icon: <MarketplacePricing />,
  },
  select: {
    text: "FIXED PRICE CONVEYANCING",
    icon: <SelectPricing />,
  },
  homeinsurance: {
    text: "HOME INSURANCE",
    icon: <UInsureLogo />
  }
};

const ViewQuoteBanner = ({ quoteType }) => (
  <div className={css.quoteTypeBanner}>
    <div className={css.quoteTypeBannerIcon}>{CONTENT[quoteType].icon}</div>
    <div data-testid="quoteTypeBannerText">{CONTENT[quoteType].text}</div>
  </div>
);

ViewQuoteBanner.propTypes = {
  quoteType: PropType.oneOf(["marketplace", "select", "homeinsurance"]),
};

export default ViewQuoteBanner;
