import React from "react";
import { useHistory } from "react-router-dom";
import Button from "../../../components/button/button";
import css from "../dashboard.module.css";
import { ReactComponent as CaseProgressIcon } from "../../../components/shared/imgs/case-progress-icon.svg";
import { ReactComponent as IconArrow } from "../../../components/shared/imgs/link-closed.svg";

const CaseProgress = () => {
  const history = useHistory();

  return (
    <>
      <div className={css.checkProgressCard}>
        <div className={css.actionSectionContent}>
          <div className={css.actionSectionIcon}>
            <CaseProgressIcon />
          </div>
          <div className={css.actionSectionText}>
            See how your cases are doing?
          </div>
          <div className={css.actionSectionLink}>
            <Button
              id="case-progress"
              datatestid="case-progress"
              btnClass={css.actionSectionLinkText}
              onClick={() => history.push("/case-progress")}
              type="button"
              text="VIEW YOUR CASES"
              icon={IconArrow}
              variant="link-arrow"
            />
          </div>
        </div>
        <div className={css.checkProgressBottomBorder}></div>
      </div>
    </>
  );
};

export default CaseProgress;
