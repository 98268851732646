import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as Placeholder } from "../shared/imgs/Placeholder.svg";
import { ReactComponent as select } from '../shared/imgs/select.svg'


const Logo = ({ name, className, logos, logotype }) => {
  const logosObj = { ...logos };
  const upperName = name.toUpperCase().replace(/\s/g, "");
  let matched = "";
  Object.keys(logosObj).forEach((key) => {
    if (
      upperName.includes(key.toUpperCase()) &&
      (matched === "" || key.length < matched.length)
    ) {
      matched = key;
    }
  });

  if (matched === "" && (logotype === "lawfirm" || logotype === "insurance")) {
    const Logo = Placeholder;
    return <Logo className={className} />;
  }
  else if (matched === "" && logotype === "network") {
    const Logo = select;
    return <Logo className={className} />;
  }
  else if (matched !== "") {
    const Logo = logosObj[Object.keys(logosObj).find((key) => key === matched)];
    return <Logo className={className} />;
  }
  else {
    const Logo = Placeholder;
    return <Logo className={className} />;
  }
};

Logo.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  logos: PropTypes.object.isRequired,
  logotype: PropTypes.string.isRequired
};

export default Logo;
