import * as actions from "../../../actions/instruct-actions/lender";

const validateSale = (state) => {
  const errors = {};

  if (
    state.existingMortgageNumber?.length !== 0 &&
    state.existingMortgageNumber?.length > 32
  ) {
    errors.existingMortgageNumber = [
      "You must supply an existing mortgage number no longer than 32 characters.",
    ];
  }
  return errors;
};

const getValidationErrors = (quoteType, state) => {
  let errors = {};
  if (quoteType.toLowerCase() === "sale") {
    errors = {
      ...errors,
      ...validateSale(state),
    };
  } else {
    errors = {
      ...errors,
      ...validateSale(state),
    };
  }
  return errors;
};

const updateState = (state, action, actionProp, prop, ) => {
  if(action.isRelatedQuote) {
    return {
      ...state,
      relatedQuote: {
        ...state.relatedQuote,
        [prop]: action[actionProp]
      }
    }
  }

  return {
    ...state,
    [prop]: action[actionProp]
  };

}

export default (state = {}, action) => {
  switch (action.type) {
    case actions.UPDATE_EXISTING_LENDER.type:
      return updateState(state, action, 'newExistingLender', 'existingLender')

    case actions.UPDATE_EXISTING_MORTGAGE_NUMBER.type:
      return updateState(state, action, 'newExistingMortgageNumber', 'existingMortgageNumber')

    case actions.UPDATE_NEW_LENDER.type:
      return  updateState(state, action, 'newLender', 'newLender')

    case actions.VALIDATE_LENDER_INFO.type:
      return {
        ...state,
        validationErrors: getValidationErrors(action.quoteType, state),
      };
  }

  return state;
};
