import React from "react";
import PropTypes from "prop-types";
import FormLabel from "react-bootstrap/FormLabel";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownMenu from "react-bootstrap/DropdownMenu";
import css from "./title-selector.module.css";
import commonCss from "../../../../pages/common/styles.module.css";

const TITLES = ["Mr", "Mrs", "Ms", "Miss", "Mx"];

const TitleSelector = ({ onChange = () => {}, errorMessages = [], value }) => {
  const doDropdownChange = (newTitle) => {
    onChange(newTitle);
  };

  return (
    <div className={css.container}>
      <div className={css.dropdownContainer}>
        <FormLabel>Title</FormLabel>
        <Dropdown className={css.dropdown}>
          <Dropdown.Toggle className={css.dropdownToggle}>
            {value}
          </Dropdown.Toggle>
          <DropdownMenu className={css.dropdownDrawer}>
            {TITLES.map((t, idx) => (
              <Dropdown.Item
                data-testid={`${t.toLowerCase()}-item`}
                key={idx}
                onClick={() => doDropdownChange(t)}
              >
                {t}
              </Dropdown.Item>
            ))}
          </DropdownMenu>
        </Dropdown>
        {errorMessages.map((e, idx) => (
          <div key={idx} className={commonCss.error}>{`* ${e}`}</div>
        ))}
      </div>
    </div>
  );
};

TitleSelector.propTypes = {
  onChange: PropTypes.func,
  errorMessages: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.string,
};

export default TitleSelector;
