import React from 'react'
import { Accordion, Button } from 'react-bootstrap';
import css from './index.module.css'
import AccordionItemWrapper from '../../components/AccordionItems/AccordionItemWrapper';

const Error = ({ reset, error }) => {
    const errorIcon = `${window.config?.cdnUrl}/icons/error.svg`;

    const handleReset = () => { if (reset) reset() };

    return <div data-testid='default-error'>
        <div>
            <img className={css.icon} src={errorIcon}></img>
        </div>
        
            <h2 className={css.header}>Something's gone wrong</h2>
            <h3 className={css.subheader}>Please go back and try again</h3>
            <div>
            <div className={css.details}>
            <Accordion className={css.accordion}>
                <AccordionItemWrapper
                    id="diag-info"
                    headerText="Error details"
                    eventKey="0"
                    variant="caseDetail"
                    className={css.accordion}
                >
                    <Accordion.Collapse eventKey="0"><p className={css.subheader}>{error?.toString() ?? 'Unknown'}</p></Accordion.Collapse>
                </AccordionItemWrapper>
            </Accordion>
            </div>
            <Button className={css.button} onClick={handleReset}>Back</Button>
        </div>
    </div>
}

export default Error