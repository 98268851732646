import React from "react";

// presentation
import classnames from "classnames";
import css from "../quote.module.css";
import { Row, Col } from "react-bootstrap";

//components
import ButtonSelector from "../../../components/selectors/button/buttonselector";

const ButtonSelectorSubLayout = ({
  title,
  id,
  datatestid,
  buttons,
  groupname,
  persistAction,
  selector,
  errorMessage,
  storePath,
}) => {
  return (
    <>
      <Row className={css.traitLabel}>
        <Col
          md={12}
          xs={12}
          className={classnames(css.leftalign, css.leftpad2x)}
        >
          {title}
        </Col>
      </Row>

      <Row className={css.traitField}>
        <Col
          md={12}
          xs={12}
          className={classnames(css.leftalign, css.leftpad2x)}
        >
          <ButtonSelector
            id={id}
            datatestid={datatestid}
            buttons={buttons}
            groupname={groupname}
            persistAction={persistAction}
            selector={selector}
            storePath={storePath}
            errorMessage={errorMessage}
          />
        </Col>
      </Row>
    </>
  );
};

export default ButtonSelectorSubLayout;
