import React from "react";
import Form from "react-bootstrap/Form";
import PropTypes from "prop-types";
import css from "./labelled-textarea.module.css";
import commonCss from "../../styles.module.css";

const LabelledTextarea = ({ datatestid, label, placeholder, infoText, value, onChange, errorMessages = [] }) => {
  return (
    <div className={css.container}>
      <div className={css["label-container"]}>
        <Form.Label>{label}</Form.Label>
        <Form.Text
          className={css["info-text"]}
          data-testid={`${datatestid}-info-text`}
        >
          {infoText}
        </Form.Text>
      </div>
      <Form.Control as="textarea" rows={4} placeholder={placeholder} onChange={(e) => onChange(e.target.value)} value={value} />
      {errorMessages?.map((e, idx) => (
        <div key={idx} className={commonCss.error}>{`* ${e}`}</div>
      ))}
    </div>
  );
};

LabelledTextarea.propTypes = {
  datatestid: PropTypes.string,
  infoText: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  errorMessages: PropTypes.arrayOf(PropTypes.string)
};

export default LabelledTextarea;
