import { DateTime } from "luxon";
import { useSelector } from "react-redux";
import { QuoteType, QuoteTypeDisplay } from "../data/quote-type";
import { equalIgnoreCase } from '@lms/react-common-helpers/strings';
import _ from "lodash";

export const isChecked = (array) => {
  if (!array) return false;
  return array ? array[0].checked : false;
};

export const getCheckedState = (caseType, prop) => {
  const caseTypeArr = caseType.split(".");
  if (caseTypeArr.length > 1) {
    return isChecked(
      useSelector(
        (store) => store.quote[caseTypeArr[0]][caseTypeArr[1]][prop].value
      )
    );
  }
  return isChecked(useSelector((store) => store.quote[caseType][prop].value));
};

export const trimNewLines = (str) => {
  return str.replace(/(\r\n|\n|\r)/gm, ", ")
}

export const parseAddress = (address) => {
  if (!address || address === undefined || Object.entries(address)?.length <= 0) return ""
  const { houseName, houseNumber, streetName, postTown, county, country, postcode1, postcode2 } = address;
  const array = [houseName, `${houseNumber ?? ''} ${streetName ?? ''}`, postTown, county, country, `${postcode1 ?? ''} ${postcode2 ?? ''}`].filter((item) => item && item.length !== 0);
  return array.join(', ').replace(/\s+/g, ' ');
}

export const isBlank = (str) => {
  return !str || /^\s*$/.test(str);
}

export const isFieldInvalid = (field) => {
  if (!field) return false;
  if (field.value === null || isBlank(field.value) || field.value < 0)
    return true;
  if (typeof field.value === "object" && field?.value.hasOwnProperty("0")) {
    return field.value[0].checked ? false : true;
  } else {
    return false;
  }
}

export const getQuote = (store, caseType, storePath) => {
  if (storePath) {
    const arr = storePath.split('.');
    return store.quote[arr[0]][arr[1]];
  } else {
    return store.quote[caseType];
  }
}

export const getJurisdiction = (store, caseType, storePath) => {
  if (storePath) { 
    const arr = storePath.split('.');
    return store.quote[arr[0]].jurisdiction.value; 
  } else {
    return store.quote[caseType].jurisdiction.value;
  }
};


export const findReceivedQuote = (quotes, quoteId) => {
  return quotes.quotes.find((quote) => quote.quoteId === quoteId);
}

export const sumReceivedQuotes = (quotes) => {
  let subTotal = 0;
  let vat = 0;
  let total = 0;

  quotes.map((quote) => {
    subTotal = sumCurrency(subTotal, quote.costSummary.subtotalCost);
    vat = sumCurrency(vat, quote.costSummary.vat);
    total = sumCurrency(total, quote.costSummary.totalCost);
  })

  return { subTotal, vat, total };
}

export const sumCurrency = (a, b) => parseFloat((parseFloat(a) + parseFloat(b)).toFixed(2));

export const getReceivedQuotesDateGenerated = (quotes) => quotes.dateTimeGenerated;

export const toQuoteTypeDisplay = (quoteType) => {
  const stripped = quoteType?.replace(/\s/g, "");
  if (equalIgnoreCase(stripped, QuoteType.SALE)) return QuoteTypeDisplay.SALE;
  else if (equalIgnoreCase(stripped, QuoteType.PURCHASE)) return QuoteTypeDisplay.PURCHASE;
  else if (equalIgnoreCase(stripped, QuoteType.SALE_AND_PURCHASE)) return QuoteTypeDisplay.SALE_AND_PURCHASE;
  else if (equalIgnoreCase(stripped, QuoteType.REMORTGAGE)) return QuoteTypeDisplay.REMORTGAGE;
  else if (equalIgnoreCase(stripped, QuoteType.TRANSFER_OF_EQUITY)) return QuoteTypeDisplay.TRANSFER_OF_EQUITY;
  else if (equalIgnoreCase(stripped, 'toere-mortgage')) return QuoteTypeDisplay.TRANSFER_OF_EQUITY_WITH_REMO;
  else if (equalIgnoreCase(stripped, 'toewithremortgage')) return QuoteTypeDisplay.TRANSFER_OF_EQUITY_WITH_REMO;
  else if (equalIgnoreCase(stripped, 're-mortgage')) return QuoteTypeDisplay.REMORTGAGE;
  return QuoteTypeDisplay.SALE;
}

export const toQuoteType = (quoteType) => {
  const stripped = quoteType?.replace(/\s/g, "");
  if (equalIgnoreCase(stripped, QuoteType.SALE)) return QuoteType.SALE;
  else if (equalIgnoreCase(stripped, QuoteType.PURCHASE)) return QuoteType.PURCHASE;
  else if (equalIgnoreCase(stripped, QuoteType.REMORTGAGE)) return QuoteType.REMORTGAGE;
  else if (equalIgnoreCase(stripped, QuoteType.TRANSFER_OF_EQUITY)) return QuoteType.TRANSFER_OF_EQUITY;
  return QuoteType.SALE;
}

/**
 * Gets the difference in days between 2 dates, ignoring the time.
 * E.g. Friday 23:59 and Saturday 00:00 will return 1.
 * @param {DateTime} the first date
 * @param {DateTime} the second date
 */
export const getDifferenceInDays = (firstDate, secondDate) => {
  const dateOne = DateTime.fromObject({ year: firstDate.year, month: firstDate.month, day: firstDate.day })
  const dateTwo = DateTime.fromObject({ year: secondDate.year, month: secondDate.month, day: secondDate.day })
  const result = Math.floor(dateOne.diff(dateTwo, ["days"]).days);
  return result >= 0 ? result : result * -1;
}

/**
 * Parse an ISO date string into a DateTime, respecting the time zone.
 * @param {input} the first date
 */
export const parseISODate = (input) => DateTime.fromISO(input, { setZone: true });

/**
 * Filter a given quote group and return the group with only preferred quotes.
 * @param {any} quoteGroup the quote group to filter
 */
 export const filterPreferredQuotes = (quoteGroup) => {
  const clonedGroup = _.cloneDeep(quoteGroup);
  clonedGroup.conveyancyQuotes = clonedGroup.conveyancyQuotes.filter((quote) => quote.preferred);
  return clonedGroup;
}