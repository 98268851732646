export const MISSING_INFORMATION =
  "We need this information in order to give you an accurate price. Please check your input and try again.";
export const MISSING_TRAIT_INFORMATION =
  "We need this information in order to give \n you an accurate price. Please check  your \n input and try again.";
export const INVALID_EMAIL =
  "The provided email is invalid. Please check your input and try again.";
export const INVALID_MOBILE =
  "The provided mobile is invalid. Please check your input and try again.";
export const MISSING_CONTACT_METHOD =
  "You must have at least one contact method saved.";
