import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import css from "./index.module.css";

//components
import { Form } from "react-bootstrap";
import * as logos from "../shared/imgs/insurer-logos/index"
import Logo from "../logo/logo";
import Spinner from "../../components/spinner"

const GiResultsTable = ({
  data,
  onQuoteSelect,
  isLoading,
  error,
  errorMessage
}) => {
  const [tableData, setTableData] = useState([]);

  const mapTableData = (data) => {
    return Object.keys(data).map((key) => {
      return data[key].premiumListItems.map((premium) => ({
        provider: getProviderDetails(premium),
        monthlyPrice: getMonthlyPrice(premium),
        coverType: getCoverType(data[key]),
        defaqtoRating: getDefaqtoRating(premium),
        select: getRadio(data[key], premium)
      }));
    }).flat();
  }

  useEffect(() => {
    if (!error && !isLoading) {
      const entries = mapTableData(data);
      setTableData(entries);
    }
  }, [data]);

  const rowHeaders = [
    "Provider",
    "Monthly price",
    "Cover type",
    "Defaqto rating",
    ""
  ];

  const renderHeaders = (title, index) => {
    return (
      <th key={`th-${index}`} className={css.th}>
        {title}
      </th>
    );
  };

  const renderItems = (cell, index) => {
    const key = `td-${index}`;
    return (
      <td key={key} data-test-id={key} className={css.td}>
        {cell}
      </td>
    );
  };

  const renderBody = (row, index) => {
    return (
      <tr key={`row-${index}`} className={css.tr}>
        {Object.keys(row).map((cell, idx) => renderItems(row[cell], idx))}
      </tr>
    );
  };

  const convertToCurrency = (val) => {
    return `£${(Math.round(val * 100 + Number.EPSILON) / 100).toFixed(2)}`;
  };

  const getRadio = (item, premium) => {
    const { quoteReference } = item;
    const { premiumId } = premium;
    const id = `gi/*/${premiumId}`;
    return (
      <Form.Check
        className={css.checkbox}
        key={id}
        id={id}
        name="giQuoteSelect"
        type="radio"
        onChange={() => onQuoteSelect(quoteReference, premiumId)}
      />
    );
  };

  const getMonthlyPrice = (item) => {
    const monthlyPrice = convertToCurrency(item.totalMonthlyPremium);
    return (
      <>
        <span className={css.totalCost}>{monthlyPrice}</span>
      </>
    );
  };

  const getCoverType = (key) => {
    let type = "";
    if (key.includesBuildings && key.includesContents) {
      type = "Buildings and contents";
    } else {
      type = "Buildings only"
    }

    return (
      <>
        <span className={css.totalCost}>{type}</span>
      </>
    );
  };

  const getDefaqtoRating = (item) => {
    return (
      <>
        <span className={css.totalCost}>5</span>
      </>
    );
  }

  const getProviderDetails = (item) => {
    return (
      <div className={css.lawFirmPlacement}>
        <div className={css.logo}>
          <Logo logos={logos} name={item.provider} logotype="insurance" className={css.lawFirmLogo} />
        </div>
      </div>
    );
  };

  if (!error && isLoading) {
    return (
      <div className={css.loading}>
        <Spinner variant={css.spinner} /> Searching the market...
      </div>
    )
  }
  if (!isLoading && error) {
    if (errorMessage) {
    return (
      <div data-testid="error" className={css.leftpad4x}>
        {errorMessage}
      </div>
      )
    }

    return (
      <div data-testid="error" className={css.leftpad4x}>
        Sorry, we can&apos;t quote for this transaction at the moment
      </div>
      )
  }

  return (
    <table data-testid="result-table" className={css.table}>
      <thead className={css.header}>
        <tr>
          {rowHeaders.map((title, index) => renderHeaders(title, index))}
        </tr>
      </thead>
      <tbody>{tableData.map((row, index) => renderBody(row, index))}</tbody>
    </table>
  );
};

GiResultsTable.propTypes = {
  data: PropTypes.object.isRequired,
  onQuoteSelect: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.bool,
  errorMessage: PropTypes.string
};

export default GiResultsTable;
