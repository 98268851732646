const generatePropChangeActionCreator = (type) => (payloadName) => {
    const actionCreator = (input, isRelatedQuote) => {
      const action = {
        type: `INSTRUCT_ADDITIONAL_INFO_${type}`,
        isRelatedQuote,
      };
  
      action[payloadName] = input;
  
      return action;
    };
  
    return {
      type: `INSTRUCT_ADDITIONAL_INFO_${type}`,
      action: actionCreator,
    };
  };
  
  export const UPDATE_ADDITIONAL_INFO = generatePropChangeActionCreator(
    "UPDATE_ADDITIONAL_INFO"
  )("additionalInfo")
  