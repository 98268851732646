import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom" 
import PropTypes from "prop-types";
import GenericModal from "../../../modals/GenericModal/GenericModal";
import ReferralFeeUpdate from "../referralfeeupdate/referralfeeupdate";
import {
  storePreferenceDefaultReferralFee,
  storeQuoteBespokeReferralFee,
  validateQuote,
  validatePreferences
} from "../../../redux/actions/actions";
import { useSelector, useDispatch } from "react-redux";

function ReferralFeeModal({ id, datatestid, show, onHide, getAccessToken }) {
  const dispatch = useDispatch()
  const history = useHistory()
  const caseType = useSelector((store) => store.quote.type.value);
  
  const isDefaultUpdated = useSelector(
    (store) =>
      (store.quote[caseType].updateDefaultReferralFee &&
        store.quote[caseType].updateDefaultReferralFee.value) || [
        {
          checked: false,
        },
      ]
  );

  const [newReferralFee, setNewReferralFee] = useState();
  const getNewReferralFee = (val) => {
    setNewReferralFee(val);
  };

  const isInvalid =
    useSelector(
      (store) => store.quote[caseType].bespokeReferralFee.validation.message
    ) !== null;

  const bespokeReferralFee = useSelector(store => store.quote[caseType].bespokeReferralFee.value)
  const bespokeReferralFeeCurrent = useRef(bespokeReferralFee)
  useEffect(() => {
    if(bespokeReferralFeeCurrent.current !== bespokeReferralFee) {
      bespokeReferralFeeCurrent.current = bespokeReferralFee
      dispatch(validateQuote(history));
    }
  }, [bespokeReferralFee])

  return (
    <>
      <div>
        <GenericModal
          show={show}
          onHide={onHide}
          heading="WHAT IS YOUR FEE?"
          bodyContent={
            <ReferralFeeUpdate
              id={id}
              datatestid={datatestid}
              getNewReferralFee={(val) => getNewReferralFee(val)}
            />
          }
          secondaryButtonText="CANCEL"
          primaryButtonText="SAVE CHANGES"
          onClickValue={async () => {
            await getAccessToken()
            if (isInvalid) return;
            if (isDefaultUpdated[0].checked) {
              dispatch(
                storePreferenceDefaultReferralFee(
                  Number(newReferralFee),
                  caseType
                )
              );
              dispatch(
                storeQuoteBespokeReferralFee(Number(newReferralFee), caseType)
              );
              dispatch(validatePreferences())
            } else {
              dispatch(
                storeQuoteBespokeReferralFee(Number(newReferralFee), caseType)
              );
            }
          }}
        />
      </div>
    </>
  );
}

ReferralFeeModal.propTypes = {
  id: PropTypes.string.isRequired,
  datatestid: PropTypes.string.isRequired,
  getAccessToken: PropTypes.func.isRequired,
  show: PropTypes.bool,
  onHide: PropTypes.func
};

export default ReferralFeeModal;
