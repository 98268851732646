export const printQuoteCopy = [
    {
        title: {
            text: `No completion, no legal fee`
        },
        text: [
        `If for any reason your transaction falls through before you have contractually committed you won’t have to pay any of the legal costs.
        However, if any third party costs, e.g. searches, have already been incurred on your behalf you will need to pay for these.`
        ]
    },
    {
        title: {
            text: `Your fixed fee`
        },
        text: [
        `The fee you have been quoted is a fixed fee covering the standard legal work required. Should any further work be carried out on your behalf,
        at your request, there may be additional fees payable. These fees will be agreed between you and the conveyancer before work commences.`
        ]
    },
    {
        title: {
            text: `Case tracking`
        },
        text: [
        `You can track the progress of your case online which provides you with key milestone updates; ability to view documents; contact details 
        for the law firm case handler as well as LMS and frequently asked questions. You can also monitor the progress of your case by registering for our email and text update services.`
        ]
    },
    {
        title: {
            text: `In capable hands`
        },
        text: [
        `LMS has enabled in excess of 365,000 transactions and £52bn loans in 2018. We have over 25 years of experience in providing outsourced
        legal services to lenders, estate agents and brokers.`
        ]
    },
    {
        title: {
            text: `Other Important information`
        },
        text: [
        `This quote has been prepared based on the details provided and Land Registry fees have been used for registered properties. This is subject
        to change as at quotation stage the legal title has not been checked by the law firm. You should be aware that it is not possible to cover every
        individual scenario which can mean, on occasion, unavoidable disbursements, additional legal work or more complex matters may occur. In
        this case, the law firm will confirm once legal title has been checked and you will be contacted before they proceed. The law firm act in the
        interest of you, the client and the mortgage lender (if applicable).`
        ]
    },
    {
        title: {
            type: 'h3',
            text: `Local Search Indemnity (LSI)`
        },
        text: [
        `For remortgage cases, LMS have included LSI on the quotation as this is accepted by the majority of UK mortgage lenders. This is quoted on the assumption 
        that the policy is purchased through LMS.  We must make you aware that some lenders do not accept LSI. In these circumstances and dependent upon the type 
        of transaction, the lender may request that full searches are carried out. This will be fully determined by the acting law firm from the UK Finance Mortgage 
        Lenders Handbook or through the special conditions within the Lenders Mortgage Offer. If searches are required, the law firm will confirm the additional costs 
        with you as such costs vary with each Local Authority.`
        ]
    },
    {
        title: {
            type: 'h3',
            text: `Searches`
        },
        text: [
        `Additional searches may be required due to the property’s location.`
        ]
    },
    {
        title: {
            type: 'h3',
            text: `Leasehold`
        },
        text: [
        `When selling/buying or remortgaging a leasehold property, the freeholder and/or managing agent may charge fees for releasing information.
        These fees can vary and your law firm will confirm the charges that apply to your case.`
        ]
    },
    {
        title: {
            type: 'h3',
            text: `Stamp Duty`
        },
        text: [
        `The applicable Stamp Duty/Land and Buildings Tax/Land Transaction Tax will be confirmed by your law firm.`
        ]
    },
    {
        text: [
        `The quote provided includes a guide as to the most common disbursements for this transaction. The law firm will write to the customer and
        provide a full guide of disbursements applicable in their transaction as these can vary depending on the mortgage lender, the property and
        where additional work is required.`
        ]
    },
    {
        text: [
        `<note>LMS is required to inform you that in accordance with usual professional practice the law firm that you will instruct will pay LMS a marketing fee from their own funds which will
        be agreed on instruction. This fee is used to contribute towards the ongoing quality control, audit, training, support and general commercial development of the law firm.</note>`
        ]
    }
]