import React, { useEffect } from "react";
import PropTypes from "prop-types";

import Accordion from "react-bootstrap/Accordion";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";
import Card from "react-bootstrap/Card";
import {
  Completed,
  Pending,
  TableFilterArrow,
} from "../../../../components/shared/imgs";

import css from "./summary-section.module.css";

const SummarySection = ({
  children,
  completed,
  completedIconClassName,
  heading,
  show,
}) => {
  return (
    <div className={css.summarySectionContainer}>
      <Card.Header className={css.headingContainer}>
        <div className={css.headingTextAndIconContainer}>
          <div className={css.completedIcon}>
            {completed ? (
              <Completed
                data-testid="Completed"
                className={completedIconClassName}
              />
            ) : (
              <Pending data-testid="Pending" />
            )}
          </div>
          <div className={css.heading}>{heading}</div>
        </div>
        {/* <div className={css.caret}>
          {!show || <TableFilterArrow data-testid="summarySectionCaret" />}
        </div> */}
      </Card.Header>
      {!show || (
        <Card.Body className={css.summarySectionBody}>{children}</Card.Body>
      )}
    </div>
  );
};

SummarySection.propTypes = {
  children: PropTypes.node,
  completed: PropTypes.bool,
  completedIconClassName: PropTypes.string,
  heading: PropTypes.string,
  show: PropTypes.bool,
};

export default SummarySection;
