import React from 'react';
import PropTypes from 'prop-types';
import css from './button.module.css';
import classnames from 'classnames'
import Spinner from '../spinner'
import { Button } from 'react-bootstrap'

const CustomButton = ({
    id,
    datatestid,
    btnClass,
    btnTextClass,
    btnCountClass,
    btnIconClass,
    type,
    text,
    onClick,
    icon,
    iconLeft,
    buttonCount,
    isLoading,
    disabled,
    variant,
    size,
}) => {

    const Icon = icon
    const IconLeft = iconLeft

    return (
        <Button
            id={id}
            className={classnames(btnClass, css.button)}
            data-testid={datatestid}
            onClick={onClick}
            type={type}
            disabled={disabled}
            size={size}
            variant={variant}
        >
            {iconLeft &&
                <span className={classnames(css.iconLeft, { [btnIconClass]: btnIconClass })} data-testid="button-icon-left"><IconLeft /></span>
            }
            <span className={classnames(css.text, { [btnTextClass]: btnTextClass, [`${variant}--text`]: variant })}>{text}</span>
            {buttonCount &&
                <span className={classnames(css.count, { [btnCountClass]: btnCountClass })} data-testid="button-count">{buttonCount}</span>
            }
            {icon &&
                <span className={classnames(css.icon, { [btnIconClass]: btnIconClass })} data-testid="button-icon"><Icon /></span>
            }
            {isLoading &&
                <Spinner variant={css.spinner} datatestid={`${datatestid}-spinner`} />
            }
        </Button>
    )
}

CustomButton.propTypes = {
    id: PropTypes.string.isRequired,
    datatestid: PropTypes.string.isRequired,
    type: PropTypes.string,
    btnClass: PropTypes.string,
    btnTextClass: PropTypes.string,
    btnCountClass: PropTypes.string,
    btnIconClass: PropTypes.string,
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    icon: PropTypes.object,
    iconLeft: PropTypes.object,
    buttonCount: PropTypes.string,
    isLoading: PropTypes.bool,
    disabled: PropTypes.bool,
    variant: PropTypes.string,
    size: PropTypes.string
};

export default CustomButton;
