import React from "react";

import { Route } from "react-router-dom";
import { AuthConsumer } from "./authProvider";
import { FeatureFlagsConsumer } from '@lms/react-configuration/context/feature-flags';
import Loading from '../components/loading';

const AuthAwareRoute = ({ component, ...rest }) => {
    const renderFn = (Component) => (props) => (
        <AuthConsumer>
            {({ getUser, logout, isAuthenticated, signoutRedirectCallback, signinRedirect, getAccessToken }) => {
                return (  
                    <FeatureFlagsConsumer
                        loading={<Loading />}
                    >                
                        <Component getUser = {getUser} logout={logout} isAuthenticated={isAuthenticated} signinRedirect={signinRedirect} signoutRedirectCallback={signoutRedirectCallback} getAccessToken={getAccessToken} {...props} />
                    </FeatureFlagsConsumer>   
                );
            }}
        </AuthConsumer>
    );

    return <Route {...rest} component={renderFn(component)} />;
};

export default AuthAwareRoute;