import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import css from "./index.module.css"
import GenericModal from "../../modals/GenericModal/GenericModal";
import { Table } from "react-bootstrap"
import formatter from "../../utils/formatter";


const MiniBreakdown = ({
    show,
    onHide,
    data
}) => {

    const formatDisplayCurrency = (val) => {
        return val > 0 ? formatter.formatCurrency(val) : "-"
    }

    return (
        <GenericModal
            show={show}
            onHide={onHide}
            heading="Price breakdown"
            contentClassName={css["dialog-content"]}
            dialogClassName={css["dialog"]}
            secondaryButtonText="CLOSE"
        >
            <Table borderless className={css.table}>
                <tbody>
                    <tr className={css.row}>
                        <th className={css.th}>Legal Fees</th>
                        <td className={css.td} data-fee="legal">{formatDisplayCurrency(data.legalFees)}</td>
                    </tr>
                    <tr className={css.row}>
                        <th className={css.th}>Standard disbursements</th>
                        <td className={css.td} data-fee="disbursements">{formatDisplayCurrency(data.disbursements)}</td>
                    </tr>
                    <tr className={css.row}>
                        <th className={css.th}>Special disbursements</th>
                        <td className={css.td} data-fee="specialDisbursements">{formatDisplayCurrency(data.specialDisbursements)}</td>
                    </tr>
                    <tr className={css.row}>
                        <th className={css.th}>Registration fees and taxes</th>
                        <td className={css.td} data-fee="government">{formatDisplayCurrency(data.governmentFees)}</td>
                    </tr>
                    <tr className={css.row}>
                        <th className={css.th}>VAT</th>
                        <td className={css.td} data-fee="vat">{formatDisplayCurrency(data.vat)}</td>
                    </tr>
                    <tr className={classnames(css.row, css.total)}>
                        <th className={classnames(css.th, css.total)}>Total <span className={css.note}>(includes VAT where applicable)</span></th>
                        <td className={classnames(css.td, css.total)} data-fee="total">{formatDisplayCurrency(data.totalCost)}</td>
                    </tr>
                </tbody>
            </Table>
        </GenericModal>
    )
}

MiniBreakdown.propTypes = {
    data: PropTypes.shape({
        legalFees: PropTypes.number,
        disbursements: PropTypes.number,
        specialDisbursements: PropTypes.number,
        governmentFees: PropTypes.number,
        vat: PropTypes.number,
        totalCost: PropTypes.number
    }).isRequired,
    show: PropTypes.bool,
    onHide: PropTypes.func
}

export default MiniBreakdown