import { equalIgnoreCase } from '@lms/react-common-helpers/strings';

const formatCurrency = new Intl.NumberFormat("en-GB", {
  style: "currency",
  currency: "GBP",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const formatDate = (date) => {
  const d = date.getDate();
  const M = date.getMonth() + 1;
  const y = date.getFullYear();
  const h = date.getHours();
  const m = date.getMinutes();

  const padZeros = (val) => (val <= 9 ? `0${val}` : val);

  return `${padZeros(d)}-${padZeros(M)}-${y} ${padZeros(h)}:${padZeros(m)}`;
};

const formatBoolean = (theBool) => (theBool ? "Yes" : "No");

const formatFeeType = (feeType) => {
  if (equalIgnoreCase(feeType, 'specialdisbursement')) {
    return 'Special Disbursements';
  }

  return feeType;
}

export default {
  formatBoolean,
  formatCurrency: formatCurrency.format,
  formatDate,
  formatFeeType,
};
