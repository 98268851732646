import React from "react";

// presentation
import classnames from "classnames";
import css from "../quote.module.css";
import { Row, Col } from "react-bootstrap";

//components
import DropdownSelector from "../../../components/selectors/dropdown/dropdownselector";

const DropdownSelectorLayout = ({
  title,
  id,
  datatestid,
  options,
  groupname,
  persistAction,
  selector,
  errorMessage,
  storePath,
}) => {
  return (
    <>
      <Row className={css.label}>
        <Col
          md={12}
          xs={12}
          className={classnames(css.leftalign, css.leftpad2x)}
        >
          {title}
        </Col>
      </Row>
      <Row>
        <Col
          md={12}
          xs={12}
          className={classnames(css.leftalign, css.leftpad2x)}
        >
          <DropdownSelector
            title={title}
            id={id}
            datatestid={datatestid}
            options={options}
            groupname={groupname}
            persistAction={persistAction}
            selector={selector}
            errorMessage={errorMessage}
            storePath={storePath}
          />
        </Col>
      </Row>
    </>
  );
};

export default DropdownSelectorLayout;
