import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Accordion, Card, Row, Col, Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import css from '../index.module.css';
import Button from '../../../components/button/button';
import { storeUinsureSelectedQuote, getUinsureRedirect } from '../../../redux/actions/actions';
import * as getSavedQuoteActions from "../../../redux/actions/get-saved-quotes-actions";
import formatter from '../../../utils/formatter';

export default function AccordionItemContent(props) {
  const { item, eventKey, index } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  const quote = useSelector((s) => s.instruct.instructProcessState?.savedQuote?.data);
  const [giInstructed, setGiInstructed] = useState(false);

  const { houseNumber, houseName, postcode1, postcode2, streetName } = item.propertyAddress
  const { caseType, surname, generalInsuranceReference, quoteId, referralFee } = item;
  
  let houseNameString = '';  
  if (houseName)
    houseNameString = `${houseName},`;
    
  const houseNameNumber = `${houseNameString} ${!houseNumber ? '' : houseNumber}`;
  const displayString = `${surname} - ${houseNameNumber} ${streetName}, ${postcode1} ${postcode2} - ${caseType} - ${formatter.formatCurrency(referralFee)}`;

  const handleViewClick = () => {
    history.push(`/caseDetail/${item.caseId}`);
  };

  useEffect(() => {
    if (quote && giInstructed) {
      setGiInstructed(false);
      dispatch(getUinsureRedirect());
    }
  }, [quote]);

  const handleInstructClick = (e, reference, quoteId) => {
    e.preventDefault();
    setGiInstructed(true);
    dispatch(storeUinsureSelectedQuote(reference));
    dispatch(
      getSavedQuoteActions.GET_SAVED_QUOTE.action(quoteId)
    );
  }

  return (
    <Accordion.Collapse key={item.caseId} eventKey={eventKey}>
      <Card.Body className={index === 0 ? css.cardBodyFirst : css.cardBody}>
        <Container fluid>
          <Row className={css.itemContentRow}>
            <Col id={`item-${index}`} className={css.itemContentColumn}>
              {displayString}
            </Col>
            <Col xs="auto">
              {/* {generalInsuranceReference &&
                <Button
                  btnClass={css.button}
                  id="cases-table-instruct-button"
                  datatestid="cases-table-instruct-button"
                  variant="tertiary"
                  text="INSTRUCT GENERAL INSURANCE"
                  size="md"
                  onClick={(e) => handleInstructClick(e, generalInsuranceReference, quoteId)}
                />
              } */}
              <Button
                btnClass={css.button}
                id="cases-table-view-button"
                datatestid="cases-table-view-button"
                variant="tertiary"
                text="VIEW"
                size="md"
                onClick={handleViewClick}
              />
            </Col>
          </Row>
        </Container>
        <hr />
      </Card.Body>
    </Accordion.Collapse>
  )
}

AccordionItemContent.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    caseClients: PropTypes.arrayOf(PropTypes.shape({
      address: PropTypes.shape({
        houseNumber: PropTypes.string,
        houseName: PropTypes.string,
        postcode1: PropTypes.string,
        postcode2: PropTypes.string,
        streetName: PropTypes.string,
      }),
    })),
    caseType: PropTypes.string,
    surname: PropTypes.string,
    generalInsuranceReference: PropTypes.string,
    caseId: PropTypes.number,
    quoteId: PropTypes.string,
    referralFee: PropTypes.string,
  }).isRequired,
  eventKey: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
}