import { createStore, applyMiddleware, compose } from 'redux';
import persistStore from 'redux-persist/es/persistStore';
import createSagaMiddleware from 'redux-saga'
import rootReducer from './reducers'
import sagas from './sagas'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const initialState = {}

const sagaMiddleware = createSagaMiddleware()

const store = createStore(
  rootReducer,
  initialState,
  composeEnhancers(applyMiddleware(sagaMiddleware)) 
)

export const persistor = persistStore(store);

sagaMiddleware.run(sagas)

export default store;