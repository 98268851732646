import React from "react";
import { useHistory } from "react-router-dom";
import Button from "../../../components/button/button";
import css from "../dashboard.module.css";
import { ReactComponent as PricingIcon } from "../../../components/shared/imgs/lawfirm-pricing-icon.svg";
import { ReactComponent as Link } from "../../../components/shared/imgs/link-closed.svg";

const Pricing = () => {
  const history = useHistory();

  return (
      <div className={css.pricingSectionNav}>
        <div className={css.pricingSectionIcon}>
          <PricingIcon />
        </div>
        <div className={css.pricingSectionLink}>
          <Button
            id="instruct_quote"
            datatestid="pricing"
            btnClass={css.actionSectionLinkText}
            onClick={() => history.push("/pricing")}
            type="button"
            text="MANAGE YOUR PRICING"
            icon={Link}
            variant="link-arrow"
          />
        </div>
      </div>
  );
};

export default Pricing;
