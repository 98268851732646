import {
    GET_EMULATE_USER, FINISHED_GETTING_USER, POPULATE_EMULATE_USER, START_EMULATING_USER, STOP_EMULATING_USER, START_EMULATING_USER_SAGA, STOP_EMULATING_USER_SAGA,
} from '../types';

export function getUserAction(payload) {
    return {
        type: GET_EMULATE_USER,
        payload,
    };
}

export function getUserFinishedLoading(payload) {
    return {
        type: FINISHED_GETTING_USER,
        payload,
    };
}

export function getUserPopulateStore(payload) {
    return {
        type: POPULATE_EMULATE_USER,
        payload,
    };
}

export function startEmulatingUserSaga() {
    return {
        type: START_EMULATING_USER_SAGA,
    }
}

export function startEmulatingUser(payload) {
    return {
        type: START_EMULATING_USER,
        payload,
    }
}

export function stopEmulatingUserSaga() {
    return {
        type: STOP_EMULATING_USER_SAGA,
    }
}

export function stopEmulatingUser() {
    return {
        type: STOP_EMULATING_USER,
    }
}
