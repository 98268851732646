import React from "react";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import css from "./buttonselector.module.css";
import { useSelector } from "react-redux";
import store from "../../../redux/store";
import classnames from "classnames";
import PropTypes from "prop-types";

const ButtonSelector = ({
  id,
  datatestid,
  buttons,
  groupname,
  persistAction,
  selector,
  errorMessage,
  storePath,
  disabled
}) => {
  const buttonValue = useSelector(selector);
  const error = useSelector(errorMessage);
  const setValue = (value) => store.dispatch(persistAction(value, storePath));

  return (
    <>
      <ButtonGroup toggle className={classnames(css.flexcontainer)}>
        {buttons.map((button, idx) => (
          <ToggleButton
            className={classnames(css.flexitem, css.toggleButton)}
            id={`${id}-${button.name}`}
            data-testid={`${datatestid}-${button.name}`}
            key={idx}
            type="radio"
            variant="secondary"
            name={groupname}
            value={button.value}
            disabled={disabled || button.disabled}
            checked={buttonValue === button.value}
            onChange={(e) => setValue(e.currentTarget.value)}
          >
            {button.name}
          </ToggleButton>
        ))}
      </ButtonGroup>
      <span className={css.error}> {error}</span>
    </>
  );
};

ButtonSelector.propTypes = {
  id: PropTypes.string.isRequired,
  datatestid: PropTypes.string.isRequired,
  buttons: PropTypes.arrayOf(PropTypes.object),
  groupname: PropTypes.string,
  persistAction: PropTypes.func,
  selector: PropTypes.func,
  errorMessage: PropTypes.func,
};

export default ButtonSelector;
