import React from 'react';
import { AuthService } from '../services/authService/AuthService'
import PropTypes from 'prop-types';

export const AuthContext = React.createContext({
    signinRedirectCallback: () => ({}),
    logout: () => ({}),
    getUser: () => ({}),
    signoutRedirectCallback: () => ({}),
    isAuthenticated: () => ({}),
    signinRedirect: () => ({}),
    signinSilentCallback: () => ({}),
    getAccessToken: () => ({}),
    onSilentRenewError: () => ({})
});

export const AuthConsumer = AuthContext.Consumer;

const AuthProvider = ({children}) => {
    const authService = new AuthService(window.config.baseUrl, window.config.oidc);
    return <AuthContext.Provider value={authService}>{children}</AuthContext.Provider>
}

AuthProvider.propTypes = {
    children: PropTypes.any
  };

export default AuthProvider;