import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import classnames from 'classnames'

//presentation
import css from './index.module.css'

//components
import { FormControl, FormLabel, Row, Col } from 'react-bootstrap'
import InputMask from 'react-input-mask'
import { beforeMaskedValueChange } from './mask-conditions'


const DateInput = ({
    mask='99/99/9999',
    alwaysShowMask=true,
    maskChar=null,
    placeholder='dd/mm/yyyy',
    label="Borrower",
    id,
    datatestid,
    persistAction,
    storePath,
    homeOwnersCount='0',
    collectionValues,
    storeModel,
    className,
}) => {
    
    let fields = []
    
    const dispatch = useDispatch()
    const storeDates = useSelector(collectionValues)
    const [dateArray, setDataArray] = useState(storeDates)
    const currentStore = useSelector(storeModel)
    let currentValue = useRef([])

    const updateStore = () => {
        let newDateArray = []

        if(+homeOwnersCount < dateArray.length){
            dateArray.splice(+homeOwnersCount, (dateArray.length - +homeOwnersCount))
        }

        for(let i = dateArray.length; i < homeOwnersCount; i++){
            const dateObj = { id: i, value: null, validation: { message: null }}
            newDateArray.push(dateObj)
        }

        dispatch(persistAction([...dateArray, ...newDateArray], storePath))
    }

    const validate = (e, i) => {
        currentValue.current[i] = e.target.value
        const date = e.target.value.split('/').reverse().join('/')
        if(dateArray[i]) {
            dateArray[i].value = date
            dateArray[i].validation.message = null
        }

        updateStore()
    }

    useEffect(() => {
        for(let i = 0; i < homeOwnersCount; i++){
            const date = currentStore.DateOfBirth.value[i]?.value
            currentValue.current.push(date ? date.split('/').reverse().join('/') : '')
        }
    }, [])

    useEffect(() => {
        setDataArray(storeDates)
    }, [storeDates])

    for(let i = 0; i < homeOwnersCount; i++){
        fields.push(
            <div className={classnames(css.label, {[className]: className })} key={`${id}-${i}`}>
                <Row>
                    <Col md={12} xs={12} className={classnames(css.leftalign, css.leftpad2x)}>
                        <FormLabel htmlFor={`${id}-${i}`}>{`${label} ${i+1} date of birth`}</FormLabel>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} xs={12} className={classnames(css.leftalign, css.leftpad2x)}> 
                        <div className={css.flexcontainer}>
                            <FormControl
                                as={InputMask} 
                                mask={mask} 
                                alwaysShowMask={alwaysShowMask} 
                                maskChar={maskChar} 
                                placeholder={placeholder}
                                id={`${id}-${i}`}
                                data-testid={datatestid}
                                onBlur={(e) => validate(e, i)}
                                onChange={(e) => validate(e, i)}
                                value={currentValue.current[i] || ''}
                                beforeMaskedValueChange={beforeMaskedValueChange}
                                autoComplete="off"
                            />
                        </div> 
                        <span className={css.error}>{currentStore.DateOfBirth.value[i]?.validation.message}</span>
                    </Col>
                </Row>
            </div>
        )
    }

    useEffect(() => {
        updateStore()
    }, [homeOwnersCount])

    return homeOwnersCount > 0 ? fields : ''
}

DateInput.propTypes = {
    mask: PropTypes.string,
    alwaysShowMask: PropTypes.bool,
    maskChar: PropTypes.any,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    id: PropTypes.string,
    datatestid: PropTypes.string,
    persistAction: PropTypes.func,
    storePath: PropTypes.string,
    homeOwnersCount: PropTypes.string,
    collectionValues: PropTypes.func,
    storeModel: PropTypes.func,
    className: PropTypes.string,
}

export default DateInput