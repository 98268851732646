import { filterPreferredQuotes } from "../../utils/jsutils"
import * as getSavedQuoteActions from "../../redux/actions/get-saved-quotes-actions";
import * as customerActions from "../../redux/actions/instruct-actions/customer";
import * as lenderActions from "../../redux/actions/instruct-actions/lender";
import { storeUinsureSelectedQuote } from "../../redux/actions/actions";

const mapAddress = (address) => ({
    ...address,
    postCode: address.postcode1 && address.postcode2 ? `${address.postcode1} ${address.postcode2}` : null
});

const mapToStore = (quoteGroup) => {
    if (!quoteGroup) {
        return {
            data: {}
        }
    }

    const { conveyancyQuotes } = quoteGroup;
    
    const mapPropertyPrice = (requestData) => {
        return requestData?.salePrice 
        || requestData?.purchasePrice 
        || requestData?.considerationAmount 
        || requestData.mortgageValue;
    }
    const mapMortgage = (requestData) => {
        return requestData?.outStandingMortgage 
        || requestData?.customerObtainingMortgage;
    }

    const result = { data: {}};

    const getData = (idx) => {
        const quote = conveyancyQuotes[idx];
        const requestData = quoteGroup.requestData.filter((item) => item.quoteType.toLowerCase() !== 'uinsure')[idx];

        return {
            quoteId: quote.quoteId,
            quoteGroupId: quoteGroup.id,
            quoteType: quote.quoteType,
            lawFirmName: quote.lawFirm.name,
            jurisdiction: requestData?.jurisdiction,
            propertyAddress: requestData?.propertyAddress ? mapAddress(requestData.propertyAddress) : null,
            propertyPrice: requestData ? mapPropertyPrice(requestData) : null,
            mortgageInvolved: requestData ? mapMortgage(requestData) : null,
            firstTimeBuyer: requestData?.firstTimeBuyers ?? false,
            numberOfBedrooms: requestData?.numberOfBedrooms ?? null,
            yearBuilt: requestData?.yearBuilt ?? null,
            propertyType: requestData?.propertyType ?? null
        }
    }

    result.data =  { 
        ...result.data, 
        ...getData(0)
    };

    if (conveyancyQuotes.length > 1) {
        result.data.relatedQuote = {
            ...getData(1)
        }
    }

    return result;
}

export const populateInstructStore = (quoteGroup, insuranceResult, dispatch) => {
    const filtered = filterPreferredQuotes(quoteGroup);
    const storeModel = mapToStore(filtered);
    const uinsureQuote = filtered.requestData.find((item) => item.quoteType.toLowerCase() === 'uinsure');

    dispatch(getSavedQuoteActions.SET_RETRIEVED_SAVED_QUOTE.action(storeModel));

    if (quoteGroup.customerDetails) {
        dispatch(customerActions.UPDATE_ADDRESS.action(0, mapAddress(quoteGroup.customerDetails?.correspondenceAddress)));
        dispatch(customerActions.UPDATE_FIRST_NAME.action(0, quoteGroup.customerDetails?.forename));
        dispatch(customerActions.UPDATE_SURNAME.action(0, quoteGroup.customerDetails?.surname));
    }

    if (filtered.conveyancyQuotes.length == 2) {
        dispatch(customerActions.UPDATE_ADDRESS.action(0, mapAddress(quoteGroup.customerDetails?.correspondenceAddress), true));
        dispatch(customerActions.UPDATE_FIRST_NAME.action(0, quoteGroup.customerDetails?.forename, true));
        dispatch(customerActions.UPDATE_SURNAME.action(0, quoteGroup.customerDetails?.surname, true));

        if (uinsureQuote) {
            dispatch(customerActions.UPDATE_DATE_OF_BIRTH.action(0, uinsureQuote.dateOfBirthClient1));
        }
    }

    if (quoteGroup.insuranceDetails) {
        dispatch(storeUinsureSelectedQuote({ quote: quoteGroup.insuranceDetails.quoteReference, premium: quoteGroup.insuranceDetails.selectedPremium }))
    }

    if (insuranceResult) {
        if (insuranceResult?.client1?.dateOfBirth) dispatch(customerActions.UPDATE_DATE_OF_BIRTH.action(0, insuranceResult.client1?.dateOfBirth));
    }

    filtered.requestData.map((item, idx) => {
        if (item.newLenderId) dispatch(lenderActions.UPDATE_NEW_LENDER.action(`${item.newLenderId}`, idx > 0));
        if (item.existingLenderId) dispatch(lenderActions.UPDATE_EXISTING_LENDER.action(`${item.existingLenderId}`, idx > 0));
    })
}