export default function generateStringFromAddress(oneCase) {
  const { houseName, houseNumber, postTown, county, streetName, districtName, postcode1, postcode2 } = oneCase;
  const arrayOfItems = [houseName, houseNumber, streetName, districtName, postTown, county, postcode1 + ' ' + postcode2];
  const arrayForString = [];
  arrayOfItems.forEach((item) => {
    if (item && item.length > 0) {
      arrayForString.push(item);
    }
  });
  return arrayForString.join(', ');
}