import React from "react";
import { QuoteType } from "../../../data/quote-type";
import css from "../quote.module.css";
import Purchase from "./purchase";
import Sale from "./sale";

const SaleAndPurchase = ({ submitted, validationHandler }) => {
  const storePathSale = `${QuoteType.SALE_AND_PURCHASE}.${QuoteType.SALE}`;
  const storePathPurchase = `${QuoteType.SALE_AND_PURCHASE}.${QuoteType.PURCHASE}`;

  return (
    <>
      <h2 className={css.caseTypeTitle}>Property being sold</h2>
      <div className={css.toppad2x}></div>
      <Sale submitted={submitted} validationHandler={validationHandler} storePath={storePathSale} />

      <h2 className={css.caseTypeTitle}>Property being purchased</h2>
      <div className={css.toppad2x}></div>
      <Purchase submitted={submitted} validationHandler={validationHandler} storePath={storePathPurchase} />

    </>
  );
};

export default SaleAndPurchase;
