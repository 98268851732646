import { SET_LENDERS } from '../actions/types'

const initialState = {
  data: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_LENDERS:
        return {
          data: [...action.payload]
        }
    default:
      return state
  }
}