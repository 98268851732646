export const QuoteType = {
    SALE: "sale",
    PURCHASE: "purchase",
    SALE_AND_PURCHASE: "saleAndPurchase",
    REMORTGAGE: "remortgage",
    TRANSFER_OF_EQUITY: "transferOfEquity",
    INSURANCE: 'Uinsure'
}

export const QuoteTypeDisplay = {
    SALE: "Sale",
    PURCHASE: "Purchase",
    SALE_AND_PURCHASE: "Sale And Purchase",
    REMORTGAGE: "Remortgage",
    TRANSFER_OF_EQUITY: "Transfer Of Equity",
    TRANSFER_OF_EQUITY_WITH_REMO: "Transfer of Equity with Remortgage"
}

