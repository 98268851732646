import React from "react";
import classnames from 'classnames';
import css from './index.module.css';

import MortgageBrainLogo from './mortgage-brain.png';
import { ReactComponent as Select } from '../select.svg';
import { Col } from "react-bootstrap";

export { ReactComponent as HLPClear } from "./clearthinking.svg";
export { ReactComponent as Fairstone } from "./fairstone.svg";
export { ReactComponent as FairstoneCITY } from "./fairstone.svg";
export { ReactComponent as FairstoneMOR } from "./fairstone.svg";
export { ReactComponent as HomeLoanPartnership } from "./hlp.svg";
export { ReactComponent as HLPIMA } from "./ima.svg";
export { ReactComponent as Intrinsic } from "./quilter.svg";
export { ReactComponent as HLPMorgan } from "./morgan.svg";
export { ReactComponent as PurpleCow } from "./purplecow.svg";
export { ReactComponent as QuilterFinancialPlanning } from "./quilter.svg";
export { ReactComponent as QuilterFinancialPlanningexistingclient } from "./quilter.svg";
export { ReactComponent as TheRightMortgageProtectionNetwork } from "./the-right-mortgage.svg";
export { ReactComponent as WoodshiresConveyancing } from "./woodshires.svg";
export { Select as None };

const PartnerLogo = ({ logo, height }) => (
  <span className={css.partnershipInnerContainer}>
    <span className={css.partnershipContent}>
      <span className={css.partnershipText}>
        In partnership with
      </span>
      <span>
        <img src={logo} height={height} />
      </span>
    </span>
  </span>
);

export const MortgageBrain = ({ className }) => (
  <span className={classnames(css.flex, css.partnershipContainer)}>
    <Col xs={12} md={6}>
      <Select className={className} />
    </Col>
    <Col xs={12} md={6}>
      <PartnerLogo logo={MortgageBrainLogo} />
    </Col>
  </span>
)