const generateActionCreator = (type) => (payloadName) => {
  const actionCreator = (input) => {
    const action = {
      type: `GET_SAVED_QUOTE_${type}`,
    };

    action[payloadName] = input;

    return action;
  };

  return {
    type: `GET_SAVED_QUOTE_${type}`,
    action: actionCreator,
  };
};

export const GET_SAVED_QUOTE = generateActionCreator("GET_SAVED_QUOTE")(
  "quoteId"
);

export const SET_RETRIEVED_SAVED_QUOTE = generateActionCreator(
  "SET_RETRIEVED_SAVED_QUOTE"
)("retrievedQuote");

export const MAP_CURRENT_QUOTE = generateActionCreator(
  "MAP_CURRENT_QUOTE"
)("currentQuote");
