import { QuoteType } from "../../data/quote-type";
import { 
    storeDateOfBirth, 
    storeQuoteArmedForcesTrait, 
    storeQuoteAuctionTrait, 
    storeQuoteBridgingFinance, 
    storeQuoteBridgingFinanceRepresentation, 
    storeQuoteDeedOfPostponementTrait, 
    storeQuoteExpatsTrait, 
    storeQuoteFirstTimeBuyer, 
    storeQuoteGiftedDepositTrait, 
    storeQuoteGiftedEquityTrait, 
    storeQuoteHelpToBuyLoanTrait, 
    storeQuoteHMOTrait, 
    storeQuoteJointProprietorTrait, 
    storeQuoteLtdCompany, 
    storeQuoteMainResidence, 
    storeQuoteMortgage, 
    storeQuoteNewBuildTrait, 
    storeQuoteNewLender, 
    storeQuoteNumberOfBedrooms, 
    storeQuoteNumberOfExpats, 
    storeQuoteNumberOfGiftedDeposits, 
    storeQuoteObtainMortgage, 
    storeQuoteOtherResidence, 
    storeQuoteOwners, 
    storeQuoteOwnersAfterTransfer, 
    storeQuotePropertyAddress, 
    storeQuotePropertyType, 
    storeQuoteRegion, 
    storeQuoteRightToBuyTrait, 
    storeQuoteSharedOwnershipTrait, 
    storeQuoteFirstRegistrationTrait,
    storeQuoteSoleProprietorTrait, 
    storeQuoteTenure, 
    storeQuoteTransactionAmount, 
    storeQuoteTransferRequired, 
    storeQuoteType, 
    storeQuoteYearBuilt 
} from "../../redux/actions/actions";
import { useDispatch } from 'react-redux';
import { parseISODate, toQuoteType } from "../../utils/jsutils";
import { equalIgnoreCase } from '@lms/react-common-helpers/strings';
import { tenures } from '../../components/shared/data';
import _ from "lodash";

export function useEditMapper() {
    const dispatch = useDispatch();

    const updateStore = (action, value, storePath = null) => {
        try {
            if (value !== null && value !== undefined) {
                dispatch(action(value, storePath));
            }
        } catch (e) {
            // Ignore it, failure to update one store value shouldn't stop the user continuing to edit.
        }
    }

    const mapPropertyPrice = (quote) => {
        return quote?.salePrice 
        || quote?.purchasePrice 
        || quote?.considerationAmount 
        || quote.mortgageValue;
    }

    const mapTenure = (tenure) => {
        const result = tenures.find(o => {
            return o.name.toLowerCase() === tenure.toLowerCase();
        })
    
        return result?.value
    }

    const isLimitedCompany = ({ ltdCompanySale, ltdCompanyPurchase, ltdCompanyRemortgage }) => {
        return !!ltdCompanySale 
        || !!ltdCompanyPurchase 
        || !!ltdCompanyRemortgage;
    }

    const mapMortgage = ({ outStandingMortgage, customerObtainingMortgage}) =>
        !!outStandingMortgage || !!customerObtainingMortgage;

    const mapNumberOfOwnders = ({ numberOfOwners, numberOfCurrentOwners }) => numberOfOwners || numberOfCurrentOwners;

    const mapTrueFalse = (value) => {
        return value ? "1" : "2"
    }

    const mapTrait = (value) => {
        return [ { checked: value ? true : false } ]
    }

    const mapAddress = ({ propertyAddress }) => {
        const mapped = _.cloneDeep(propertyAddress);
        mapped.postCode = mapped.postcode1 + ' ' + mapped.postcode2;
        return mapped;
    }

    const populate = (quote, storePath, insuranceQuote) => {
        const quoteType = toQuoteType(quote.quoteType);
        updateStore(storeQuoteRegion, quote.jurisdiction, storePath);
        updateStore(storeQuotePropertyAddress, mapAddress(quote), storePath);
        updateStore(storeQuoteTransactionAmount, mapPropertyPrice(quote).toString(), storePath);
        updateStore(storeQuoteTenure, mapTenure(quote.tenure), storePath);
        updateStore(storeQuoteLtdCompany, mapTrueFalse(isLimitedCompany(quote)), storePath);
        updateStore(storeQuoteOwners, mapNumberOfOwnders(quote)?.toString(), storePath);
        updateStore(storeQuoteNumberOfBedrooms, quote.numberOfBedrooms, storePath);
        updateStore(storeQuoteExpatsTrait, mapTrait(quote.numberOfExpats > 0), storePath)
        updateStore(storeQuoteNumberOfExpats, quote.numberOfExpats, storePath);

        if (quoteType == QuoteType.SALE 
            || quoteType == QuoteType.REMORTGAGE) {
                updateStore(storeQuoteMortgage, mapTrueFalse(mapMortgage(quote)), storePath);
                updateStore(storeQuoteHelpToBuyLoanTrait, mapTrait(quote.helpToBuyEquityLoan), storePath);
        } else if (quoteType == QuoteType.PURCHASE) {
            updateStore(storeQuoteObtainMortgage, mapTrueFalse(mapMortgage(quote)), storePath);
            updateStore(storeQuoteFirstTimeBuyer, mapTrueFalse(quote.firstTimeBuyers), storePath);           
            updateStore(storeQuoteRightToBuyTrait, mapTrait(quote.rightToBuy), storePath);
            updateStore(storeQuoteNewBuildTrait, mapTrait(quote.newBuild), storePath);
            updateStore(storeQuoteGiftedEquityTrait, mapTrait(quote.giftedEquity), storePath);            
            updateStore(storeQuoteGiftedDepositTrait, mapTrait(quote.numberOfGiftedDeposits > 0), storePath);
            updateStore(storeQuoteNumberOfGiftedDeposits, quote.numberOfGiftedDeposits, storePath);
            updateStore(storeQuoteAuctionTrait, mapTrait(quote.auction), storePath);
            updateStore(storeQuoteArmedForcesTrait, mapTrait(quote.armedForcesHelpToBuy), storePath);
            updateStore(storeQuoteBridgingFinance, mapTrait(quote.bridgingFinance), storePath)
            updateStore(storeQuoteBridgingFinanceRepresentation, quote.bridgingFinanceRepresentation, storePath);
            updateStore(storeQuoteFirstRegistrationTrait, mapTrait(quote.firstRegistration), storePath);
        }   
        if (quoteType == QuoteType.SALE) {
            updateStore(storeQuoteGiftedEquityTrait, mapTrait(quote.saleAtUndervalue), storePath);
            updateStore(storeQuoteNewLender, quote.existingLenderId?.toString(), storePath);
            updateStore(storeQuoteFirstRegistrationTrait, mapTrait(quote.firstRegistration), storePath);
        }
        if (quoteType === QuoteType.PURCHASE ||
            quoteType == QuoteType.REMORTGAGE) {
                updateStore(storeQuoteMainResidence, mapTrueFalse(quote.mainResidence), storePath);
                updateStore(storeQuoteOtherResidence, quote.secondaryUsage, storePath);
                updateStore(storeQuoteSoleProprietorTrait, mapTrait(quote.soleProprietorJointMortgagor), storePath);
                updateStore(storeQuoteJointProprietorTrait, mapTrait(quote.jointProprietorSoleMortgagor), storePath)
                updateStore(storeQuoteNewLender, quote.newLenderId?.toString(), storePath);
                updateStore(storeQuoteDeedOfPostponementTrait, mapTrait(quote.deedOfPostponement), storePath);
                updateStore(storeQuoteGiftedDepositTrait, mapTrait(quote.numberOfGiftedDeposits > 0), storePath);
                updateStore(storeQuoteNumberOfGiftedDeposits, quote.numberOfGiftedDeposits, storePath);
        }
        if (quoteType !== QuoteType.TRANSFER_OF_EQUITY) {
            updateStore(storeQuoteHMOTrait, mapTrait(quote.multipleOccupancy), storePath);
            updateStore(storeQuoteSharedOwnershipTrait, mapTrait(quote.sharedOwnership), storePath);
        }
        if (quoteType === QuoteType.TRANSFER_OF_EQUITY || quoteType === QuoteType.REMORTGAGE) {
            updateStore(storeQuoteOwnersAfterTransfer, quote.postTransferNumberOfOwners?.toString(), storePath);
        }
        if (quoteType === QuoteType.TRANSFER_OF_EQUITY) {
            updateStore(storeQuoteOwners, quote.preTransferNumberOfOwners?.toString(), storePath);
        }
        if (quoteType === QuoteType.REMORTGAGE) {
            updateStore(storeQuoteTransferRequired, mapTrueFalse(quote.postTransferNumberOfOwners > 0), storePath)
            updateStore(storeQuoteOwners, quote.numberOfOwners?.toString(), storePath);
        }
        if (insuranceQuote && quoteType !== QuoteType.SALE) {
            updateStore(storeQuotePropertyType, insuranceQuote.propertyType, storePath);
            updateStore(storeQuoteYearBuilt, insuranceQuote.yearBuilt, storePath);

            const dobs = [];
            if (insuranceQuote.client1) {
                const parsed = parseISODate(insuranceQuote.client1.dateOfBirth)?.toFormat("yyyy/MM/dd");
                dobs.push({ id: 1, value: parsed, validation: { message: null }})
            }

            if (insuranceQuote.client2) {
                const parsed = parseISODate(insuranceQuote.client2.dateOfBirth)?.toFormat("yyyy/MM/dd");
                dobs.push({ id: 2, value: parsed, validation: { message: null }})
            }

            if (insuranceQuote.client3) {
                const parsed = parseISODate(insuranceQuote.client3.dateOfBirth)?.toFormat("yyyy/MM/dd");
                dobs.push({ id: 3, value: parsed, validation: { message: null }})
            }

            if (insuranceQuote.client4) {
                const parsed = parseISODate(insuranceQuote.client4.dateOfBirth)?.toFormat("yyyy/MM/dd");
                dobs.push({ id: 4, value: parsed, validation: { message: null }})
            }
            
            if (dobs.length > 0) updateStore(storeDateOfBirth, dobs, storePath);
        }
    }

    const populateQuoteStore = ({ requestData, insuranceQuote }) => {
        if (!requestData || requestData.length < 1) return;
        const filteredRequestData = requestData.filter((item) => item !== null && !equalIgnoreCase(item.quoteType, QuoteType.INSURANCE));

        if (!filteredRequestData || filteredRequestData.length < 1) return;

        // Set the quote type
        if (filteredRequestData.length > 1) {
            updateStore(storeQuoteType, QuoteType.SALE_AND_PURCHASE);
        }
        else {
            updateStore(storeQuoteType, toQuoteType(filteredRequestData[0].quoteType));
        }

        if (filteredRequestData.length > 1) {
            populate(filteredRequestData[0], `saleAndPurchase.${filteredRequestData[0].quoteType.toLowerCase()}`, insuranceQuote);
            populate(filteredRequestData[1], `saleAndPurchase.${filteredRequestData[1].quoteType.toLowerCase()}`, insuranceQuote);
        } else {
            populate(filteredRequestData[0], null, insuranceQuote);
        }
    }

    return {
        populateQuoteStore
    }
};
