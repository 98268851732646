import { put } from "redux-saga/effects";
import * as siteWideActions from "../../actions/site-wide-actions"

function* showError(apiResponse) {
    try {
        const { response } = apiResponse;
        const { data } = response;

        const { extensions, detail } = data;
        const { errorCode } = extensions;

        const errorMessage = `${detail ? detail : "An error occurred"} (${errorCode ? errorCode : 0})`;

        yield put(siteWideActions.NOTIFICATION_SHOW.action({ show: true, message: errorMessage, type: "negative" }));
    }
    catch {
        yield put(siteWideActions.NOTIFICATION_SHOW.action({ show: true, message: "An error occurred", type: "negative" }));
    }
}

export default showError;
