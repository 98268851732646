import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames'
import css from './index.module.css'
import _ from 'lodash'
import { ReactComponent as SortArrow } from '../shared/imgs/table-filter-arrow.svg'

const SortColumn = ({
    data,
    setData,
    column,
    sortDirection,
    children
}) => {

    const [label, setLabel] = useState(sortDirection.desc)

    const sort = () => {
        return _.orderBy(data, [column], [Object.keys(sortDirection).find(key => sortDirection[key] === label)])
    }
    
    const handleClick = (evt) => {
        evt.preventDefault()
        setLabel(label === sortDirection.desc ? sortDirection.asc : sortDirection.desc)
        setData(sort())
    }

    return (
        <button className={css.button} onClick={(evt) => handleClick(evt)}>
            {children}
            <span className={classnames(css.icon, [{[css.iconAsc]: label===sortDirection.asc}])} data-test-id="sort-icon" title={label}><SortArrow /></span>
        </button>
    )
};

SortColumn.propTypes = {
    data: PropTypes.array.isRequired,
    setData: PropTypes.func.isRequired,
    sortDirection: PropTypes.object.isRequired
};

export default SortColumn;
