import React, { useState, useRef } from "react";
import PropTypes from "prop-types";

import Button from "../../../../components/button/button";

import TitleSelector from "../title-selector";

import commonCss from "../../../common/styles.module.css";
import css from "./customer-form.module.css";
import LabelledTextInput from "../../../common/components/labelled-text-input";
import ValidationGroup from "../../../../components/validation-group";
import Address from "../../../../components/address";
import InternationalPhoneNumberInput from "../../../common/components/international-phone-number-input";

// import PhoneCapture from "./components/phone-capture";

const CustomerForm = ({
  id,
  caseTypeIdx,
  onTitleChange,
  onFirstNameChange,
  onSurnameChange,
  onAddressChange,
  onEmailChange,
  onMobileCallingCodeChange,
  onMobileChange,
  onWorkPhoneNumberCallingCodeChange,
  onWorkPhoneNumberChange,
  onHomePhoneNumberCallingCodeChange,
  onHomePhoneNumberChange,
  canRemoveCustomer,
  onRemoveCustomer,
  onComplete = () => {},
  errors = {},
  customer,
}) => {

  const [formSubmitted, setFormSubmitted] = useState(false);
  const childValid = useRef(false);

  const validationHandler = (isValid) => childValid.current = isValid;

  const onSubmit = () => {
    setFormSubmitted(true);
    if (childValid.current) onComplete();
  }
  
  return (
    <div className={css.customerFormContainer}>
      <div>
        <TitleSelector
          onChange={onTitleChange}
          errorMessages={errors.title}
          value={customer.title || "Please select"}
        />
      </div>
      <div>
        <LabelledTextInput
          id={`${caseTypeIdx}${id}firstName`}
          datatestid="first-name"
          label="First name(s)"
          errorMessages={errors.firstName}
          placeholder="First name(s)"
          onChange={onFirstNameChange}
          value={customer.firstName}
        />
      </div>
      <div>
        <LabelledTextInput
          id={`${caseTypeIdx}${id}surname`}
          datatestid="surname"
          label="Surname"
          errorMessages={errors.surname}
          placeholder="Surname"
          onChange={onSurnameChange}
          value={customer.surname}
        />
      </div>
      <div>
        <ValidationGroup submitted={formSubmitted} validationHandler={validationHandler}>
          <Address 
          label="Correspondence address"
            id={`${caseTypeIdx}${id}CorrespondenceAddress`}
            onChange={onAddressChange}
            initialState={customer.correspondenceAddress}
          />
        </ValidationGroup>
      </div>
      <div>
        <LabelledTextInput
          id={`${caseTypeIdx}${id}email`}
          datatestid="email"
          label="Email"
          infoText="(Optional)"
          errorMessages={errors.email}
          placeholder="Email"
          onChange={onEmailChange}
          value={customer.email}
        />
      </div>
      <div className={css.infoText}>We require at Least ONE phone number</div>

      <div>
        <InternationalPhoneNumberInput 
          label="Mobile number"
          callingCode={customer.mobileCallingCode} 
          onCallingCodeChange={onMobileCallingCodeChange}
          phoneNumber={customer.mobile}
          onChangePhoneNumber={onMobileChange}
          phoneNumberPlaceholder="Mobile Number"
          errorMessages={errors.mobile}
        />
      </div> 
      <div>
        <InternationalPhoneNumberInput 
          label="Work phone number"
          callingCode={customer.workPhoneNumberCallingCode} 
          onCallingCodeChange={onWorkPhoneNumberCallingCodeChange}
          phoneNumber={customer.workPhoneNumber}
          onChangePhoneNumber={onWorkPhoneNumberChange}
          phoneNumberPlaceholder="Work Number"
          errorMessages={errors.workPhoneNumber}
        />
      </div>     
      <div>
        <InternationalPhoneNumberInput 
          label="Home number"
          callingCode={customer.homePhoneNumberCallingCode} 
          onCallingCodeChange={onHomePhoneNumberCallingCodeChange}
          phoneNumber={customer.homePhoneNumber}
          onChangePhoneNumber={onHomePhoneNumberChange}
          phoneNumberPlaceholder="Home Number"
          errorMessages={errors.homePhoneNumber}
        />
      </div>
      <div className={css.addButtonContainer}>
        <Button
          id={`${caseTypeIdx}${id}addNewCustomer`}
          datatestid={`${id}addNewCustomer`}
          btnClass={`${commonCss.buttonMd} ${css.addButton}`}
          onClick={onSubmit}
          text="ADD THIS CUSTOMER"
        />
        {canRemoveCustomer && (
          <Button
            id={`${caseTypeIdx}${id}removeCustomer`}
            datatestid={`${id}removeCustomer`}
            btnClass={`${commonCss.buttonMd} ${css.removeButton}`}
            onClick={onRemoveCustomer}
            text="REMOVE THIS CUSTOMER"
          />
        )}
      </div>
    </div>
  );
};

CustomerForm.propTypes = {
  id: PropTypes.string,
  caseTypeIdx:  PropTypes.number,
  onTitleChange: PropTypes.func,
  onFirstNameChange: PropTypes.func,
  onSurnameChange: PropTypes.func,
  onAddressChange: PropTypes.func,
  onEmailChange: PropTypes.func,
  onMobileCallingCodeChange: PropTypes.func,
  onMobileChange: PropTypes.func,
  onWorkPhoneNumberCallingCodeChange: PropTypes.func,
  onWorkPhoneNumberChange: PropTypes.func,
  onHomePhoneNumberCallingCodeChange: PropTypes.func,
  onHomePhoneNumberChange: PropTypes.func,
  canRemoveCustomer: PropTypes.func,
  onRemoveCustomer: PropTypes.func,
  onComplete: PropTypes.func,
  errors: PropTypes.object,
  customer: PropTypes.object,
}
export default CustomerForm;
