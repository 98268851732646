import React from "react";
import { useHistory } from "react-router-dom";
import Button from "../../../components/button/button";
import css from "../dashboard.module.css";
import { ReactComponent as SavedQuoteIcon } from "../../../components/shared/imgs/saved-quotes-icon.svg";
import { ReactComponent as IconArrow } from "../../../components/shared/imgs/link-closed.svg";

const SavedQuotes = () => {
  const history = useHistory();

  return (
    <>
      <div className={css.savedQuoteCard}>
        <div className={css.actionSectionContent}>
          <div className={css.actionSectionIcon}>
            <SavedQuoteIcon />
          </div>
          <div className={css.actionSectionText}>
            Want to proceed with a case?
          </div>
          <div className={css.actionSectionLink}>
            <Button
              id="instruct_quote"
              datatestid="instruct-quote"
              btnClass={css.actionSectionLinkText}
              onClick={() => history.push("/view-quotes")}
              type="button"
              text="INSTRUCT A SAVED QUOTE"
              icon={IconArrow}
              variant="link-arrow"
            />
          </div>
        </div>
        <div className={css.savedQuoteBottomBorder}></div>
      </div>
    </>
  );
};

export default SavedQuotes;
