import { useContext } from "react";
import getRequest from "../services/getRequest";
import patchRequest from "../services/patchRequest";
import postRequest from "../services/postRequest";
import { AuthContext } from "../utils/authProvider";
import * as notifications from "../data/notifications"
import { DateTime } from "luxon";
import { useNotifications } from "./notifications";

export function useSelectApi() {
    const authContext = useContext(AuthContext);
    const { showError } = useNotifications();

    const getErrorMessage = (apiResponse, fallbackMessage) => {
        const { response } = apiResponse;

        if (response) {
            const { data } = response;
            const { errorCode, message } = data;
            if (message || errorCode) {
                return `${message ? message : "An error occurred"} (${errorCode ? errorCode : 0})`;
            }
        }

        const { message } = apiResponse;

        if (message) {
            return `${"An error occurred"} (${message})`;
        }

        if (fallbackMessage) {
            return `${fallbackMessage}`;
        } else {
            return "An unknown error occurred (0)";
        }
    }

    const notifyError = (apiResponse, fallbackMessage) =>
        showError(getErrorMessage(apiResponse, fallbackMessage));

    const execute = async (fn, fallbackErrorMessage, fallbackValue) => {
        try {
            await authContext.getAccessToken();
            return await fn();
        }
        catch (e) {
            notifyError(e, fallbackErrorMessage);
            return fallbackValue;
        }
    }

    const getSavedQuotes = async ({ pageSize, page, createdAfter, searchCriteria, hasPreferred }) => {
        return execute(async () => {
            let minCreatedDate = createdAfter;
            if (!minCreatedDate) minCreatedDate = DateTime.now()
                .minus({ years: 1 })
                .toUTC()
                .toISODate()

            let url = `${window.config.apiBaseUrl}/quotes/?minCreatedOn=${encodeURIComponent(minCreatedDate)}`;

            if (pageSize && page)
                url = `${url}&pageSize=${pageSize}&pageNumber=${page}`

            if (searchCriteria){
                const { type, value } = searchCriteria;
                if (type && value) url = `${url}&${encodeURIComponent(searchCriteria.type)}=${encodeURIComponent(searchCriteria.value)}`
            }

            if (hasPreferred)
                url = `${url}&hasPreferred=true`

            const { data } = await getRequest(url)

            return data;
        }, notifications.getSavedQuotes.negative, null);
    };

    const getQuoteGroup = async({ quoteGroupId }) => {
        return execute(async () => {
            let url = `${window.config.apiBaseUrl}/quotes/${quoteGroupId}`;
            const { data } = await getRequest(url);
            return data;
        }, notifications.getSavedQuote.negative, null);
    }

    const sendQuotes = async ({ quoteGroupId, emailAddress, message, quoteIds }) => {
        return execute(async () => {
            const request = {
                emailAddress,
                message,
                quoteIds
            };
            const url = `${window.config.apiBaseUrl}/quotes/${quoteGroupId}/send`
            await postRequest(url, request);
            return true;
        }, notifications.sendQuote.negative, false)
    }

    const preferQuote = async({ quoteGroupId, quoteId, insuranceReference, insurancePremium }) => {
        return execute(async () => {
            const preferRequest = getPatchRequest("/Preferred", true)

            const updateRequest = [].concat(
                getPatchRequest('/InsuranceDetails/QuoteReference', insuranceReference),
                getPatchRequest('/InsuranceDetails/SelectedPremium', insurancePremium)
            )
            
            let preferUrl = `${window.config.apiBaseUrl}/quotes/${quoteGroupId}/${quoteId}`;
            let updateUrl = `${window.config.apiBaseUrl}/quotes/${quoteGroupId}`;
            
            await patchRequest(preferUrl, preferRequest);

            if (updateRequest.length > 0) await patchRequest(updateUrl, updateRequest);
            
            return true;
        }, notifications.saveQuote.negative, false); 
    };

    const getInsuranceQuote = async({ quoteGroupId, insuranceReference }) => {
        return execute(async () => {
            const url = `${window.config.apiBaseUrl}/quotes/${quoteGroupId}/insurance/${insuranceReference}`
            const { data } = await getRequest(url);
            return data;
        });
    };

    const saveQuote = async ({ quoteId, quoteGroupId, firstName, surname, correspondenceAddress, quoteName, notes, insuranceReference, insurancePremium }) => {
        return execute(async () => {
            const preferRequest = getPatchRequest("/Preferred", true);
            const updateRequest = [].concat(
                getPatchRequest("/CustomerDetails", {
                    Forename: firstName,
                    Surname: surname,
                    CorrespondenceAddress: correspondenceAddress
                }),
                getPatchRequest('/QuoteName', quoteName),
                getPatchRequest('/Notes', notes),
                getPatchRequest('/InsuranceDetails/QuoteReference', insuranceReference),
                getPatchRequest('/InsuranceDetails/SelectedPremium', insurancePremium)
            );

            let preferUrl = `${window.config.apiBaseUrl}/quotes/${quoteGroupId}/${quoteId}`;
            let updateUrl = `${window.config.apiBaseUrl}/quotes/${quoteGroupId}`;

            await patchRequest(preferUrl, preferRequest);
            await patchRequest(updateUrl, updateRequest);

            return true;
        }, notifications.saveQuote.negative, false)
    };

    const getActiveCases = async({ pageSize, page, searchCriteria }) => {
        return execute(async () => {
            let url = `${window.config.apiBaseUrl}/cases?active=true&delayed=true&hold=true&pending=true&cancelled=false&closed=false&complete=false`;

            if (pageSize && pageSize > 0) url = `${url}&pageSize=${pageSize}`
            if (page && page > 0) url = `${url}&pageNumber=${page}`

            if (searchCriteria){
                const { type, value } = searchCriteria;
                if (type && value) url = `${url}&${encodeURIComponent(searchCriteria.type)}=${encodeURIComponent(searchCriteria.value)}`
            }

            const result = await getRequest(url);
            const { data } = result;
            return data;
        });
    };

    const getClosedCases = async({ pageSize, page, searchCriteria }) => {
        return execute(async () => {
            let url = `${window.config.apiBaseUrl}/cases?active=false&delayed=false&hold=false&pending=false&cancelled=true&closed=true&complete=true`;

            if (pageSize && pageSize > 0) url = `${url}&pageSize=${pageSize}`
            if (page && page > 0) url = `${url}&pageNumber=${page}`

            if (searchCriteria){
                const { type, value } = searchCriteria;
                if (type && value) url = `${url}&${encodeURIComponent(searchCriteria.type)}=${encodeURIComponent(searchCriteria.value)}`
            }

            const result = await getRequest(url);
            const { data } = result;
            return data;
        });
    }

    const getCase = async({ caseId }) => {
        return execute(async () => {
            const caseUrl = `${window.config.apiBaseUrl}/cases/${caseId}`;
            const clientsUrl = `${window.config.apiBaseUrl}/cases/${caseId}/clients`;
            const caseTasksUrl = `${window.config.apiBaseUrl}/cases/${caseId}/tasks?pageSize=250&pageNumber=1`;
            const caseNotesUrl = `${window.config.apiBaseUrl}/cases/${caseId}/notes?pageSize=250&pageNumber=1`;

            const caseResult = await getRequest(caseUrl);
            const clientsResult = await getRequest(clientsUrl);
            const caseTasksResult = await getRequest(caseTasksUrl);
            const caseNotesResult = await getRequest(caseNotesUrl);

            const { data: caseData } = caseResult;
            const { data: clientsData } = clientsResult;
            const { data: caseTasksData } = caseTasksResult;
            const { data: caseNotesData } = caseNotesResult;

            let quoteGroup = null;
            if (caseData?.quoteGroupId && caseData?.quoteId) {
                quoteGroup = await getQuoteGroup({ quoteGroupId: caseData.quoteGroupId });
                quoteGroup.conveyancyQuotes = quoteGroup?.conveyancyQuotes.filter((item) => item.quoteId == caseData?.quoteId);
            }
            
            return {
                ...caseData,
                clients: clientsData?.items,
                casePlanEntries: caseTasksData?.items,
                caseNotes: caseNotesData?.items,
                quoteGroup
            }
        })
    }

    const getPatchRequest = (path, value) => (path && value ? [{
        op: "replace",
        path: path,
        value: value
    }] : []);

    return {
        getSavedQuotes,
        saveQuote,
        sendQuotes,
        getQuoteGroup,
        preferQuote,
        getActiveCases,
        getClosedCases,
        getCase,
        getInsuranceQuote
    }
}