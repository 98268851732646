export const UPDATE_INSURANCE_PREFERENCE = {
    type: "UPDATE_INSURANCE_PREFERENCE",
    action: (payload) => ({
        type: "UPDATE_INSURANCE_PREFERENCE",
        payload
    })
}

export const VALIDATE_INSURANCE_PREFERENCE = {
    type: "VALIDATE_INSURANCE_PREFERENCE",
    action: () => ({
        type: "VALIDATE_INSURANCE_PREFERENCE"
    })
};