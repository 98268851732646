export const INSTRUCT_QUOTE = {
  type: "INSTRUCT_PROCESS_INSTRUCT_QUOTE",
  action: ({ quoteId, history }) => ({
    type: "INSTRUCT_PROCESS_INSTRUCT_QUOTE",
    quoteId,
    history
  }),
};

export const INSTRUCT_REQUEST_BUSY = {
  type: "INSTRUCT_PROCESS_INSTRUCT_REQUEST_BUSY",
  action: (payload) => ({
    type: "INSTRUCT_PROCESS_INSTRUCT_REQUEST_BUSY",
    payload
  })
}

export const SAVE_INSTRUCT_RESPONSE = {
  type: "INSTRUCT_PROCESS_SAVE_INSTRUCT_RESPONSE",
  action: (payload) => ({
    type: "INSTRUCT_PROCESS_SAVE_INSTRUCT_RESPONSE",
    payload
  })
}

/* these will move */
export const UPDATE_TYPE_OF_PROPERTY = {
  type: "UPDATE_TYPE_OF_PROPERTY",
  action: (payload) => ({
    type: "UPDATE_TYPE_OF_PROPERTY",
    payload
  })
}