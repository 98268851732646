import React, { useEffect, useState } from "react";
import { tenures, jurisdictions } from "../../../components/shared/data";
import ButtonSelectorLayout from "../components/button-selector-layout";
import store from "../../../redux/store";
import { useSelector } from "react-redux";

const Tenure = ({ region, persistAction, storePath, selector, errorMessage }) => { 

    const freeholdTenure = tenures.find(tenure => tenure.name === "Freehold");
    const currentSelection =  useSelector(selector);

    const isScotlandQuote = region ===  jurisdictions.SCOTLAND.value;
    const isFreeholdSelected = currentSelection === freeholdTenure.value;

    const setValue = (value) => store.dispatch(persistAction(value, storePath));

    const [showControl, setShowControl] = useState(false);

    useEffect(() => {
        if (isScotlandQuote) {
            if (!isFreeholdSelected) {
                setValue(freeholdTenure.value);
            }
            setShowControl(false);
        } else {
            setShowControl(true);
        }
    }, [region]);

    if (!showControl) return null;

    return (
        <ButtonSelectorLayout
            title="Tenure"
            id="tenure-buttons"
            datatestid="tenure-buttons"
            buttons={tenures}
            groupname="tenure"
            persistAction={persistAction}
            storePath={storePath}
            selector={selector}
            errorMessage={errorMessage}
        />
    );
};

export default Tenure;