const POSTCODE_REGEX = /[a-zA-Z]{1,2}[0-9Rr][0-9a-zA-Z]? ?[0-9](?:a-zA-Z-245|[^CIKMOVcikmov]){2}$/
const DEFAULT_REGEX = /\S/;

const defaultValidatorOptions = {
    houseName: {required: ['houseName','houseNumber'], message: 'We need the house name and/or number to give you an accurate price. Please check your input and try again.'},
    houseNumber: {required: ['houseName','houseNumber'], message: 'We need the house name and/or number to give you an accurate price. Please check your input and try again.'},
    streetName: true,
    postTown: true,
    postCode: {required: ['postCode'], regex: POSTCODE_REGEX},
    country: true
}

const VALIDATION_ERROR = "We need this information to give you an accurate price. Please check your input and try again."

const validateProperty = (prop, regex) => {
    if (prop === null || prop === undefined) return false;
    return !!prop && regex ? regex.test(prop) : DEFAULT_REGEX.test(prop)
};

const validate = (state, { validationOptions }) => {
    let isValid = true;
    if (!state) return state;
    const validatedState = { ...state };
    const { data, validation } = validatedState;

    const validatorOptions = { ...defaultValidatorOptions, ...validationOptions };
    Object.keys(validatorOptions).map(item => {
        const { required, regex, message } = validatorOptions[item];
        if (required) {
            if (required.some(element => validateProperty(data[element], regex))) {
                validation[item] = null
            } else {
                validation[item] = message ? message : VALIDATION_ERROR
                isValid = false;
            }
        }
        else {
            if (!validateProperty(data[item])) {
                validation[item] = message ? message : VALIDATION_ERROR
                isValid = false;
            } else {
                validation[item] = null;
            }
        };
    })

    return isValid;
}

export default validate;