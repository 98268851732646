import React from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import css from '../../dashboard.module.css';

export default function EmulateResultRow(props) {
  const { label, value, id } = props;
  return (
    <Form.Group as={Row} controlId={id} className={css.emulateResultRow}>
      <Form.Label column xs={4} className={css.emulateResult}>
        {label}
      </Form.Label>
      <Col xs="auto">
        <Form.Control plaintext readOnly defaultValue={value} className={css.displayValueWidth} />
      </Col>
    </Form.Group>
  );
}

EmulateResultRow.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
}