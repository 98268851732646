import React from "react";
import Form from "react-bootstrap/Form";
import PropTypes from "prop-types";
import css from "./labelled-text-input.module.css";
import commonCss from "../../styles.module.css";

const LabelledTextInput = ({
  id,
  datatestid,
  type = "text",
  label,
  placeholder,
  infoText,
  onChange,
  errorMessages = [],
  value = "",
  uncontrolled = false,
  maxLength = null
}) => {

  return (
    <div className={css.container}>
      <div className={css.labelContainer}>
        <Form.Label htmlFor={id} data-testid={`${datatestid}-label`}>
          {label}
        </Form.Label>
        <Form.Text
          className={css.infoText}
          data-testid={`${datatestid}-info-text`}
        >
          {infoText}
        </Form.Text>
      </div>
      {!uncontrolled &&
        <Form.Control
          id={id}
          type={type}
          placeholder={placeholder}
          data-testid={`${datatestid}-input`}
          onChange={(e) => onChange(e.target.value)}
          value={value}
          maxLength={maxLength}
        />
      }
      {uncontrolled &&
        <Form.Control
          id={id}
          type={type}
          placeholder={placeholder}
          data-testid={`${datatestid}-input`}
          onChange={(e) => onChange(e.target.value)}
          maxLength={maxLength}
        />
      }
      {errorMessages?.map((e, idx) => (
        <div key={idx} className={commonCss.error}>{`* ${e}`}</div>
      ))}
    </div>
  );
};

LabelledTextInput.propTypes = {
  id: PropTypes.string,
  datatestid: PropTypes.string,
  errorMessages: PropTypes.arrayOf(PropTypes.string),
  infoText: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.oneOf(["text", "number", "email", "password"]),
  maxLength: PropTypes.number
};

export default LabelledTextInput;
