import React from "react";
import PropTypes from "prop-types";
import CurrencyInput from "../../../currencyinput/currencyinput";
import { storePreferenceDefaultReferralFee } from "../../../../redux/actions/actions";
import { useSelector } from "react-redux";
import FormLabel from "react-bootstrap/FormLabel";
import css from "./pricingcard.module.css";

const PricingCard = ({
  id,
  datatestid,
  inputclass,
  placeholder,
  label,
  errormessage,
  errorclass,
}) => {
  const defaultvalue = useSelector(
    (store) => store.preferences.defaultReferralFee.value
  );

  return (
    <fieldset>
      <FormLabel htmlFor={`${id}-email`} className={css.label}>
        {label}
      </FormLabel>

      <div id={id} data-testid={datatestid}>
        <CurrencyInput
          id={"${id}-amount"}
          datatestid={"${datatestid}-amount"}
          cssclass={inputclass}
          placeholder={placeholder}
          persistaction={storePreferenceDefaultReferralFee}
          errorMessage={errormessage}
          value={defaultvalue}
          isallowed={(values) => {
            const { floatValue } = values;
            return (
              floatValue == null ||
              (Number.isInteger(floatValue) && floatValue <= 400.01)
            );
          }}
          format="###"
        />
        <div className={errorclass}>
          {useSelector(
            (store) => store.preferences.defaultReferralFee.validation.message
          )}
        </div>
      </div>
    </fieldset>
  );
};

PricingCard.propTypes = {
  id: PropTypes.string.isRequired,
  datatestid: PropTypes.string.isRequired,
  inputclass: PropTypes.string,
  placeholder: PropTypes.string,
  errormessage: PropTypes.func,
  errorclass: PropTypes.string,
};

export default PricingCard;
