import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import FormControl from "react-bootstrap/FormControl";
import FormLabel from "react-bootstrap/FormLabel";
import FormGroup from "react-bootstrap/FormGroup";
import css from "./contactcard.module.css";
import classnames from "classnames";
import {
  storePreferenceContactEmail,
  storePreferenceContactMobile,
} from "../../../../redux/actions/actions";
import store from "../../../../redux/store";
import NumberFormat from "react-number-format";

const ContactCard = ({
  id,
  datatestid,
  inputclass,
  placeholder,
  errorclass,
}) => {
  const setEmailValue = (value) =>
    store.dispatch(storePreferenceContactEmail(value));
  const setMobileValue = (value) =>
    store.dispatch(storePreferenceContactMobile(value));

  const mobile = useSelector(
    (store) => store.preferences.contactMobile.value
  );

  const email = useSelector(
    (store) => store.preferences.contactEmail.value
  );

  return (
    <fieldset>
      <legend className={css.legend}>
      These are the contact details held on file for you, if you want to change please contact salessupport@lms.com
      </legend>

      <div id={id} datatestid={datatestid}>
        <FormGroup>
          <FormLabel htmlFor={`${id}-email`} className={css.label}>
            Email
          </FormLabel>
          <FormControl
            id={`${id}-email`}
            data-testid={`${datatestid}-email`}
            // JAS-5635 - Remove class to make field appear disabled
            //className={classnames(inputclass, css.email)}
            placeholder={placeholder[0]}
            defaultValue={email}
            onChange={(e) => setEmailValue(e.currentTarget.value)}
            disabled
          />
          <div className={errorclass}>
            {useSelector(
              (store) => store.preferences.contactEmail.validation.message
            )}
          </div>
        </FormGroup>

        <FormGroup>
          <FormLabel htmlFor={`${id}-mobile`} className={css.label}>
            Mobile
          </FormLabel>
          <FormControl
            as={NumberFormat}
            type="tel"
            id={`${id}-mobile`}
            data-testid={`${datatestid}-mobile`}
            // JAS-5635 - Remove class to make field appear disabled
            //className={classnames(inputclass, css.mobile)}
            placeholder={placeholder[1]}
            // use value here, defaultValue doesn't trigger a rerender when useSelector returns null rather than the number on first mount
            // https://github.com/s-yadav/react-number-format/blob/cd8efbbd1f936dd52ba03bc4f638e7b5a2182ba8/src/number_format.js#L117
            // only looks at props.value, not defaultValue
            value={mobile}
            onChange={(e) => setMobileValue(e.currentTarget.value)}
            format="##### ######"
            disabled
          />
          <div className={errorclass}>
            {useSelector(
              (store) => store.preferences.contactMobile.validation.message
            )}
          </div>
        </FormGroup>
      </div>
    </fieldset>
  );
};

ContactCard.propTypes = {
  id: PropTypes.string.isRequired,
  datatestid: PropTypes.string.isRequired,
  inputclass: PropTypes.string,
  placeholder: PropTypes.array,
  errorclass: PropTypes.string,
};

export default ContactCard;
