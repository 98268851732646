import _ from "lodash";
import {
  STORE_UINSURE_QUOTE,
  STORE_UINSURE_SELECTED_QUOTE,
  STORE_UINSURE_INSTRUCT,
  STORE_UINSURE_VALIDATION_SUCCESS
} from "../actions/types";

const initialState = {
  quote: {},
  requredDetailsCollected: false,
  isLoading: true
};

export default function (state = initialState, action) {
  switch (action.type) {
    case STORE_UINSURE_QUOTE:
      {
        const { buildings, buildingsAndContents, error, errorMessage, isLoading } = action.payload;
        const hasQuote = (buildingsAndContents || buildings) ? true : false
        
        return {
          ...state,
          quote: {
            buildings,
            buildingsAndContents
          },
          error,
          hasQuote,
          errorMessage,
          isLoading
        };
      }

    case STORE_UINSURE_SELECTED_QUOTE:
      return {
        ...state,
        selectedQuote: action.payload.quote,
        selectedPremium: action.payload.premium
      };

    case STORE_UINSURE_INSTRUCT:
      return {
        ...state,
        instruct: action.payload
      }

    case STORE_UINSURE_VALIDATION_SUCCESS:
      return {
        ...state,
        requredDetailsCollected: action.payload
      }

    default:
      return state;
  }
}
