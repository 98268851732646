export const general = {
    positive: "Success",
    negative: "There has been an error"
}

export const saveQuote = {
    positive: "Your quote has been successfully saved",
    negative: "There has been an error saving your quote please try again"
}

export const sendQuote = {
    positive: "Your quote has been successfully sent",
    negative: "There has been an error sending your quote please try again"
}

export const getQuote = {
    negative: "There has been an error sending your quote please try again"
}

export const getSavedQuote = {
    negative: "There has been an error retrieving your saved quote. Please check your reference ID"
}

export const getSavedQuotes = {
    negative: "There has been an error retrieving your saved quotes"
}

export const getInstructQuote = {
    negative: "There has been an error retrieving your quote, please supply your quote ID or start a new quote"
}

export const instructQuote = {
    negative: "There has been an error instructing your quote, please check your details and try again"
}

export const preferences = {
    positive: "Your preferences have been successfully saved",
    negative: "There has been an error saving your preferences please try again"
}

export const getPreferences = {
    negative: "There has been an error retrieving your preferences"
}

export const linkUinsure = {
    positive: "Your Uinsure account has been successfully linked"
}

export const searchQuotesQuery = {
    negative: 'There has been an error searching for your quotes. Please try again',
}

export const getAllCases = {
    negative: 'There has been an error retrieving your cases. Please try again',
}

export const searchCases = {
    negative: 'There has been an error searching for your cases. Please try again',
}

export const getUser = {
    negative: 'There has been an error searching for this user. Please try again',
}

export const startEmulatingUser = {
    negative: 'There has been an error when starting to emulate this user. Please try again',
}

export const stopEmulatingUser = {
    negative: 'There has been an error when stopping emulation of this user. Please try again',
}