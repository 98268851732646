import React from 'react'
import { useSelector } from 'react-redux'
import Form from 'react-bootstrap/Form'
import css from './radioselector.module.css';
import PropTypes from 'prop-types'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import { QuestionCircleFill } from 'react-bootstrap-icons';
import store from '../../../redux/store';

const RadioSelector = ({ radios, persistaction, datatestid, name, storelocation, errorMessage, disabled = false }) => {

    const setValue = (id) => { store.dispatch(persistaction(id)); };
    const value = useSelector(storelocation)

    let error = null;
    if (errorMessage)
        error = useSelector(errorMessage);

    return (
        <>
            <Form>
                <Form.Group className={css.flexcontainer}>
                    {radios.map((radio) => (
                        <div key={radio.value} className={css.flexitem}>
                            <Form.Check
                                className={css.checkbox}
                                data-testid={`${datatestid}-${radio.value}`}
                                key={radio.value}
                                id={radio.value}
                                name={name}
                                type="radio"
                                checked={value == radio.value}
                                label={radio.name}
                                disabled={disabled}
                                onChange={(e) => setValue(e.currentTarget.id)} />

                            {radio.overlay === true &&

                                <OverlayTrigger key='top' placement='top'
                                    overlay={
                                        <Popover id="popover-basic">
                                            <Popover.Content>
                                                {radio.overlaytext}
                                            </Popover.Content>
                                        </Popover>
                                    }
                                >
                                    <QuestionCircleFill />
                                </OverlayTrigger>}
                        </div>))}
                </Form.Group>
            </Form>
            <span className={css.error}> {error}</span>
        </>
    )
}

RadioSelector.propTypes = {
    radios: PropTypes.arrayOf(PropTypes.object),
    persistaction: PropTypes.func.isRequired,
    datatestid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    storelocation: PropTypes.func.isRequired,
    errorMessage: PropTypes.func
}

export default RadioSelector