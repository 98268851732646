import { SET_SETTINGS } from '../actions/types'

const initialState = {
  settings: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_SETTINGS:
        return {
          ...action.payload
        }
    default:
      return state
  }
}