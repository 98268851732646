import React from "react";
import PropTypes from "prop-types";
import RadioSelector from "../../../selectors/radio/radioselector";
import { uinsureoptions } from "../../../shared/data";
import { storePreferenceUinsureQuotingType } from "../../../../redux/actions/actions";

const UinsureOptionsCard = ({ id, connected, datatestid, disabled }) => {

  return (
    <fieldset>
      <div id={id} data-testid={datatestid}>
        <RadioSelector
          id={`${id}-uinsure-quote-type`}
          datatestid={`${datatestid}-uinsure-quote-type`}
          persistaction={storePreferenceUinsureQuotingType}
          storelocation={(store) => store.preferences.uinsure.quotingType.value}
          name={`${id}-uinsure-quote-type`}
          radios={uinsureoptions}
          disabled={!connected || disabled}
        />
      </div>
    </fieldset>
  );
};

UinsureOptionsCard.propTypes = {
  id: PropTypes.string.isRequired,
  datatestid: PropTypes.string.isRequired,
};

export default UinsureOptionsCard;
