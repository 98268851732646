import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import AuthCheck from '../../components/authcheck/authCheck';
import authState from '../../components/authcheck/authState';
import QuoteBreakdown from '../../components/quoteBreakdown';
import Spinner from '../../components/spinner';
import css from './index.module.css';
import { useSelectApi } from '../../hooks/selectApi';
import Layout from '../../layout';

const ViewSavedQuote = () => {
  const { quoteGroupId } = useParams();
  const { getQuoteGroup } = useSelectApi();
  const history = useHistory();

  const [quoteGroup, setQuoteGroup] = useState();
  const [quotes, setQuotes] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getGroup() {
      const result = await getQuoteGroup({ quoteGroupId });
      if (result && result.hasPreferred) {
        const filtered = result.conveyancyQuotes.filter((quote) => quote.preferred)
        setQuotes(filtered);
        setQuoteGroup(result);
      } else {
        history.push('/view-quotes');
      }
      
      setLoading(false);
    }
    getGroup();
  }, [])

  if (loading) {
    return (
      <Layout classname={css.viewCaseSummaryContainer}>
        <AuthCheck requiredAuthState={authState.AUTHENTICATED} />
        <Row>
          <Col md={1} xs={1}></Col>
          <Col md={10} xs={10} className={css.loading}>
            <Spinner variant={css.spinner} /> Loading quote details...
          </Col>
          <Col md={1} xs={1}></Col>
        </Row>
      </Layout>
    );
  }

  if (!quotes) {
    return null;
  }

  return (
    <Layout classname={css.viewCaseSummaryContainer}>
      <AuthCheck requiredAuthState={authState.AUTHENTICATED} />
      <Row>
        <Col md={{ span: 10, offset: 1 }} xs={{ span: 10, offset: 1 }} className={css.pageHeader}>
          VIEW YOUR SAVED QUOTE
        </Col>
      </Row>
      <Row noGutters>
        <Col md={{ span: 10, offset: 1 }} xs={{ span: 10, offset: 1 }}>
          <QuoteBreakdown quoteGroup={quoteGroup} quotes={quotes} />
        </Col>
      </Row>
    </Layout>
  );
}

export default ViewSavedQuote;