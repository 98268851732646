import React from "react"
import css from "../index.module.css"
import { Form } from "react-bootstrap"

const AddressNotFound = ({ id, onChange }) => {
    return <div className={css.flexcontainer}>
        <div className={css.flexitem}>
            <Form.Check
                className={css.checkbox}
                id={`${id}-address-not-found`}
                type="checkbox"
                label="Tick if property address not found or not correct"
                onChange={(e) => onChange(e.target.checked)}
            >
            </Form.Check>
        </div>
    </div>
}

export default AddressNotFound;