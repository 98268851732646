import React from 'react';
import Spinner from '../spinner';
import css from './index.module.css';

const Loading = () => (
  <div className={css.loading}>
    <Spinner variant={css.loadingSpinner} />
  </div>
);

export default Loading;
