import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import * as conveyedAddressActions from "../../../../redux/actions/instruct-actions/conveyance-address";

import Button from "react-bootstrap/Button";

import commonCss from "../../../common/styles.module.css";
import css from "./address-section.module.css";
import ValidationGroup from "../../../../components/validation-group";
import Address from "../../../../components/address";

const AddressSection = ({
  completeCallback,
  caseTypeIdx,
  isRelatedQuote }) => {
  const dispatch = useDispatch();
  const [formSubmitted, setformSubmitted] = useState(false);
  const childrenValid = useRef(false);

  const caseAddress = useSelector((store) => isRelatedQuote
    ? store.instruct.instructProcessState?.savedQuote?.data?.relatedQuote?.propertyAddress
    : store.instruct.instructProcessState?.savedQuote?.data?.propertyAddress);

  const submit = () => {
    setformSubmitted(true);
    if (childrenValid.current) completeCallback();
  };

  const validationHandler = (isValid) => { childrenValid.current = isValid };


  return (
    <>
      <div className={css.container}>
        <ValidationGroup submitted={formSubmitted} validationHandler={validationHandler}>
          <Address
            id={`${caseTypeIdx}-conveyed-address`}
            label="Conveyed property full address"
            onChange={(address) => {
              dispatch(conveyedAddressActions.UPDATE_CONVEYED_ADDRESS.action(address, isRelatedQuote))
            }}
            initialState={caseAddress}
          />
        </ValidationGroup>

        <Button
          id="addressSectionNext"
          className={commonCss.buttonMd}
          onClick={submit}
        >
          NEXT SECTION
        </Button>
      </div>
    </>
  );
};

AddressSection.propTypes = {
  completeCallback: PropTypes.func,
  value: PropTypes.object,
  caseTypeIdx: PropTypes.number,
  isRelatedQuote: PropTypes.bool
};

export default AddressSection;
