import React from "react";
import PropTypes from "prop-types";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import css from "./totals.module.css";
import Table from "react-bootstrap/Table";
import formatter from "../../../../utils/formatter";

const Totals = ({
  subTotal,
  vatTotal,
  totalCost,
  totalText = "Total",
  totalHint,
  showTotalOnly
}) => {
  return (
    <>
    {!showTotalOnly && (
        <Row>
          <Col>
            <div className={css.subtotalsContainer}>
              <Table className={`table-borderless ${css.subtotalsTable}`}>
                <tbody>
                  {[
                    {
                      description: "Subtotal",
                      value: subTotal,
                    },
                    { description: "VAT", value: vatTotal },
                  ].map(({ description, value }, idx) => (
                    <tr key={idx}>
                      <td>{description}</td>
                      <td className={css.totalsTableValueColumn}>
                        {formatter.formatCurrency(value)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <div className={css.totalContainer}>
            <div>{totalText}</div>
            {totalHint && (
              <div className={css.totalHint}>{totalHint}</div>
            )}
            <div>{formatter.formatCurrency(totalCost)}</div>
          </div>
        </Col>
      </Row>
    </>
  );
};
Totals.propTypes = {
  subTotal: PropTypes.number,
  vatTotal: PropTypes.number,
  totalCost: PropTypes.number,
  totalText: PropTypes.string,
  totalHint: PropTypes.string,
  showTotalOnly: PropTypes.bool,
};

export default Totals;
