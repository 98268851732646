import React from "react";
import { AuthConsumer } from "../utils/authProvider";

const SignoutOidc = () => (
    <AuthConsumer>
        {({ logout }) => {
            logout();
            return  <div>Logging Out...</div>;
        }}
    </AuthConsumer>
);

export default SignoutOidc;
