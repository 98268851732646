import * as actions from "../../../actions/instruct-actions/additional-info";

const updateState = (state, action, actionProp, prop,) => {
  if (action.isRelatedQuote) {
    return {
      ...state,
      relatedQuote: {
        ...state.relatedQuote,
        [prop]: action[actionProp]
      }
    }
  }

  return {
    ...state,
    [prop]: action[actionProp]
  };

}

export default (state = {}, action) => {
  switch (action.type) {
    case actions.UPDATE_ADDITIONAL_INFO.type:
      return updateState(state, action, 'additionalInfo', 'notes');
  }

  return state;
};
