import _ from 'lodash';
import { IQuote } from '../../types/quote';

export const rollupPricing = (quotes: IQuote[], caseType: string) => {
  const aggregate: IQuote[] = [];
  quotes.forEach((quote) => {
    const { costSummary, lawFirm, quoteId } = quote;

    if (lawFirm) {
      const { officeId } = lawFirm;
      const {
        disbursements,
        specialDisbursements,
        governmentFees,
        legalFees,
        subtotalCost,
        totalCost,
        vat,
      } = costSummary;

      const existing = aggregate.find((i) => i.lawFirm.officeId === officeId);

      if (existing) {
        const { costSummary: existingCostSummary } = existing;
        existingCostSummary.disbursements = (existingCostSummary.disbursements + disbursements);
        existingCostSummary.governmentFees = (existingCostSummary.governmentFees + governmentFees);
        existingCostSummary.legalFees = (existingCostSummary.legalFees + legalFees);
        existingCostSummary.specialDisbursements = (existingCostSummary.specialDisbursements + specialDisbursements);
        existingCostSummary.subtotalCost = (existingCostSummary.subtotalCost + subtotalCost);
        existingCostSummary.totalCost = (existingCostSummary.totalCost + totalCost);
        existingCostSummary.vat = (existingCostSummary.vat + vat);
        existing.count++;
        existing.quoteIds.push(quoteId);
      } else {
        aggregate.push({
          ..._.cloneDeep(quote),
          count: 1,
          quoteIds: [quoteId]
        });
      }
    }
  });

  const expectedQuotes = caseType === 'saleAndPurchase' ? 2 : 1;
  return aggregate.filter((quote) => quote.count === expectedQuotes);
}