import React from "react";
import Button from "../../../components/button/button";
import { useHistory } from "react-router-dom";
import css from "../dashboard.module.css";
import { ReactComponent as PrefsIcon } from "../../../components/shared/imgs/control-panel-icon.svg";
import { ReactComponent as IconArrow } from "../../../components/shared/imgs/link-closed.svg";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const ControlPanel = () => {
  const history = useHistory();

  return (
    <>
      <Row>
        <Col md={4} xs={12}>
          <div className={css.prefsSectionNav}>
            <div className={css.prefsSectionIcon}>
              <PrefsIcon />
            </div>
            <div className={css.prefsSectionLink}>
              <div>
                <Button
                  id="preferences"
                  datatestid="preferences"
                  btnClass={css.actionSectionLinkText}
                  onClick={() => history.push("/preferences")}
                  type="button"
                  text="CONTROL PANEL"
                  icon={IconArrow}
                  variant="link-arrow"
                />
              </div>
            </div>
          </div>
        </Col>
        <Col md={8} xs={12}>
          <div className={css.prefsSectionContent}>
            <p>Complete flexibility managed by you:</p>
            <ul>
              <li>Change your broker referral fee</li>
              <li>Check your contact details</li>
              <li>Set your default jurisdiction</li>
            </ul>
            <p>PLUS we now offer the ability to get home insurance quotes too!</p>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ControlPanel;
