import { call, put, takeLatest, fork, select } from 'redux-saga/effects';
import _ from 'lodash';
import * as siteWideActions from '../../actions/site-wide-actions';
import * as instructProcessActions from '../../actions/instruct-actions/instruct-process';
import postRequest from '../../../services/postRequest';
import { sendUinsureLead } from "../uinsureSagas/sendUinsureLeadSaga";
import { updateUinsureClient } from '../uinsureSagas/updateUinsureClientSaga';
import showError from "../utils/error";
import { getPostcodeArray } from '../../../mappers/quote';
import { QuoteType } from '../../../data/quote-type';

function* instructQuote(action) {
  const history = action.history

  function forwardTo(location) {
    if (!history.send) return history.replace(location, '', location)
    history.push(location)
  }

  yield put(siteWideActions.SITE_BUSY.action(true));

  try {
    yield put(instructProcessActions.INSTRUCT_REQUEST_BUSY.action(true))

    const instructStoreValues = yield select((s) => s.instruct);
    const instructSavedQuote = yield select((s) => s.instruct.instructProcessState.savedQuote.data)
    const hasRelatedQuote = instructSavedQuote.relatedQuote ? true : false
    const preferences = yield select((s) => s.preferences);
    const insuranceProcessType = yield select((s) => s.instruct.insurance?.processType?.value);
    const additionalInfo = yield select((s) => s.instruct.additionalInfo);
    const giQuoteReference = yield select((s) => s.uinsure?.selectedQuote);


    const getCorrespondenceAddress = (isRelatedQuote) => {
      let result = {};
      if (isRelatedQuote) {
        result = {
          ...(instructStoreValues.customers.relatedQuote[0].correspondenceAddress),

        }
      } else {
        result = {
          ...(instructStoreValues.customers.quote[0].correspondenceAddress)
        }
      }

      const postcodeArray = getPostcodeArray(result.postCode);
      result.postcode1 = postcodeArray[0];
      result.postcode2 = postcodeArray[1];

      return result;
    }

    const getQuoteType = (isRelatedQuote) => isRelatedQuote ? instructSavedQuote.relatedQuote.quoteType : instructSavedQuote.quoteType;

    const getPropertyAddress = (isRelatedQuote) => {

      let result = {};

      if (isRelatedQuote) {
        result = {
          ...(instructStoreValues.propertyAddress.relatedQuote)
        }
      } else {
        result = {
          ...(instructStoreValues.propertyAddress.quote)
        }
      }

      const postcodeArray = getPostcodeArray(result.postCode);
      result.postcode1 = postcodeArray[0];
      result.postcode2 = postcodeArray[1];

      return result;
    }

    const getPhoneNumber = (phoneNumber, callingCode) => {
      if (!phoneNumber) return { phoneNumber: '', callingCode: '' };
      if (!callingCode) return { phoneNumber, callingCode: '+44' };
      return { phoneNumber, callingCode };
    };

    const mapClient = (customers) => {
      return customers.map((c, idx) => {
        const postcodeArray = getPostcodeArray(c.correspondenceAddress.postCode);
        const customerAddress = c.correspondenceAddress;

        const homePhone = getPhoneNumber(c.homePhoneNumber, c.homePhoneNumberCallingCode);
        const workPhone = getPhoneNumber(c.workPhoneNumber, c.workPhoneNumberCallingCode);
        const mobilePhone = getPhoneNumber(c.mobile, c.mobileCallingCode);

        return {
          Title: c.title ?? 106, // Map to the correct number, if they've used Other then omit the title using the blank title option
          Surname: c.surname,
          Forenames: c.firstName,
          Address: {
            HouseNumber: customerAddress.houseNumber,
            HouseName: customerAddress.houseName,
            StreetName: customerAddress.streetName,
            DistrictName: customerAddress.districtName,
            PostTown: customerAddress.postTown,
            County: customerAddress.county,
            Postcode1: postcodeArray[0],
            Postcode2: postcodeArray[1],
            Country: customerAddress.country
          },
          Email: c.email,
          HomePhoneNumberCallingCode: homePhone.callingCode,
          HomePhoneNumber: homePhone.phoneNumber,
          WorkPhoneNumberCallingCode: workPhone.callingCode,
          WorkPhoneNumber: workPhone.phoneNumber,
          MobileNumberCallingCode: mobilePhone.callingCode,
          MobileNumber: mobilePhone.phoneNumber,
          DateOfBirth: c.dateOfBirth ?? "2000-12-20T00:00:00"
        };
      })
    }

    const getQuoteDetails = (isRelatedQuote) => {
      const quoteType = getQuoteType(isRelatedQuote).toLowerCase();
      
      const result = {
        CorrespondenceAddress: getCorrespondenceAddress(isRelatedQuote),
        PropertyAddress: getPropertyAddress(isRelatedQuote),
      };

      switch(quoteType) {
        case QuoteType.SALE:
          result.ExistingAccountNum = isRelatedQuote ? instructStoreValues.lender?.relatedQuote?.existingMortgageNumber : instructStoreValues.lender?.existingMortgageNumber;
          break;
        case QuoteType.REMORTGAGE:
          result.ExistingAccountNum = isRelatedQuote ? instructStoreValues.lender?.relatedQuote?.existingMortgageNumber : instructStoreValues.lender?.existingMortgageNumber;
          result.ExistingLenderId = isRelatedQuote ? instructStoreValues.lender?.relatedQuote?.existingLender : instructStoreValues.lender?.existingLender
      }

      return result;
    }

    const instructModel = {
      ...getQuoteDetails(),
      clients: mapClient(instructStoreValues.customers.quote),
      casenotes: additionalInfo?.notes
    }

    const quoteId = instructStoreValues.instructProcessState.savedQuote.data.quoteId;
    const quoteGroupId = instructStoreValues.instructProcessState.savedQuote.data.quoteGroupId;

    const instructResponse = [];
    const res = yield call(
      postRequest,
      `${window.config.apiBaseUrl}/quotes/${quoteGroupId}/${quoteId}/instruct`,
      instructModel
    );

    let { data: payload, status } = res;

    if (status === 200) {
      instructResponse.push({
        ...payload,
        quoteGroupId,
        quoteId
      });
    }

    if (hasRelatedQuote && status === 200) {
      const relatedInstructModel = {
        ...getQuoteDetails(true),
        clients: mapClient(instructStoreValues.customers.relatedQuote),
        casenotes: additionalInfo?.relatedQuote?.notes
      }

      const relatedQuoteId = instructStoreValues.instructProcessState.savedQuote.data.relatedQuote.quoteId;
      const relatedQuoteGroupId = instructStoreValues.instructProcessState.savedQuote.data.quoteGroupId;

      const res = yield call(
        postRequest,
        `${window.config.apiBaseUrl}/quotes/${relatedQuoteGroupId}/${relatedQuoteId}/instruct`,
        relatedInstructModel
      );

      ({ data: payload, status } = res);

      if (status === 200) {
        instructResponse.push({
          ...payload,
          quoteGroupId,
          quoteId
        });
      }
    }

    if (status === 200) {
      if (preferences.uinsure.quotingType.value === "PassOnLeadWithoutQuote"
        || insuranceProcessType === "SendLead") {
        yield call(sendUinsureLead);
      } else if (giQuoteReference) {
        yield call(updateUinsureClient);
      }
      yield put(instructProcessActions.INSTRUCT_REQUEST_BUSY.action(false))
      yield put(instructProcessActions.SAVE_INSTRUCT_RESPONSE.action(instructResponse))
      yield call(forwardTo, '/instruct-confirmation')
    } else {
      yield put(siteWideActions.SITE_ERROR.action(res));
      yield put(instructProcessActions.INSTRUCT_REQUEST_BUSY.action(false))
      yield showError(res);
    }

  } catch (e) {
    yield put(instructProcessActions.INSTRUCT_REQUEST_BUSY.action(false))
    yield put(siteWideActions.SITE_ERROR.action(e));
    yield showError(e);
  }

  yield put(siteWideActions.SITE_BUSY.action(false));
}

function* saga() {
  yield takeLatest(instructProcessActions.INSTRUCT_QUOTE.type, instructQuote);
}

const instructQuoteSaga = fork(saga);

export { instructQuoteSaga as default, instructQuote, saga };
