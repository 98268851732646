import React from "react";
// import { useSelector } from "react-redux";
import PropTypes from 'prop-types';
import { Accordion } from "react-bootstrap";
import css from '../index.module.css';
import QuoteBreakdown from "../../../components/quoteBreakdown";

export default function AccordionQuoteBreakdownItem(props) {
  const { oneCase, eventKey } = props;
  const { quoteGroup } = oneCase;
  const { conveyancyQuotes } = quoteGroup ?? {};

  if (quoteGroup && conveyancyQuotes) {
    return (
      <Accordion.Collapse eventKey={eventKey}>
        <div className={css.quoteSectionContent}>
          <QuoteBreakdown quoteGroup={quoteGroup} quotes={conveyancyQuotes} />
        </div>
      </Accordion.Collapse>
    );
  }
  else {
    return (
      <Accordion.Collapse eventKey={eventKey}>
        <div className={css.sectionContent}>
          <p>The breakdown for this quote isn&apos;t available</p>
        </div>
      </Accordion.Collapse>
    );
  }
}

AccordionQuoteBreakdownItem.propTypes = {
  oneCase: PropTypes.shape({
    quoteId: PropTypes.string,
  }).isRequired,
  eventKey: PropTypes.string.isRequired,
}