/* eslint-disable no-param-reassign */
import {
  select,
  put,
  takeLatest,
  fork,
} from 'redux-saga/effects';
import { equalIgnoreCase } from '@lms/react-common-helpers/strings';
import * as types from '../../actions/types';
import {
  MISSING_INFORMATION,
  MISSING_TRAIT_INFORMATION,
} from '../shared/errormessages';

import { isBlank, isFieldInvalid } from '../../../utils/jsutils';
import { QuoteType } from '../../../data/quote-type';
import { jurisdictionsWithNone as jurisdictionsEnum } from "../../../components/shared/data";

function* validatePreferences() {
  const payload = yield select((s) => s.preferences);
  let errors = false;

  if (isBlank(payload.defaultReferralFee.value)) {
    errors = true;
    payload.defaultReferralFee.validation.message = MISSING_INFORMATION;
  }

  if (errors) {
    yield put({ type: types.PREFERENCES_VALIDATION_FAILURE, payload });
  } else {
    yield put({ type: types.PREFERENCES_VALIDATION_SUCCESS });
  }
}

function* validateQuote(history) {
  yield put({ type: types.QUOTE_REQUEST_BUSY, payload: true });
  const caseType = yield select((s) => s.quote.type.value);
  const payload = yield select((s) => s.quote[caseType]);
  const preferences = yield select((s) => s.preferences);

  const payloadType = yield select((s) => s.quote.type);

  const errors = [];

  const validate = (collection, quoteType) => {
    if (isFieldInvalid(payloadType)) {
      errors.push('type');
      payloadType.validation.message = MISSING_INFORMATION;
    }

    if (
      isFieldInvalid(collection.propertyType)
      && payload.jurisdiction
      && equalIgnoreCase(payload.jurisdiction.value, jurisdictionsEnum.SCOTLAND.value)
    ) {
      errors.push('propertyType');
      collection.propertyType.validation.message = MISSING_INFORMATION;
    }

    if (isFieldInvalid(collection.transactionAmount)) {
      errors.push('transactionAmount');
      collection.transactionAmount.validation.message = MISSING_INFORMATION;
    }

    if (!equalIgnoreCase(payload.jurisdiction.value, jurisdictionsEnum.SCOTLAND.value)
      && isFieldInvalid(collection.tenure)) {
      errors.push('tenure');
      collection.tenure.validation.message = MISSING_INFORMATION;
    }

    if (isFieldInvalid(collection.mortgage)) {
      errors.push('mortgage');
      collection.mortgage.validation.message = MISSING_INFORMATION;
    }

    if (isFieldInvalid(collection.obtainmortgage)) {
      errors.push('obtainmortgage');
      collection.obtainmortgage.validation.message = MISSING_INFORMATION;
    }

    if (isFieldInvalid(collection.ltdcompany)) {
      errors.push('ltdcompany');
      collection.ltdcompany.validation.message = MISSING_INFORMATION;
    }

    if (isFieldInvalid(collection.firsttimebuyer)) {
      errors.push('firsttimebuyer');
      collection.firsttimebuyer.validation.message = MISSING_INFORMATION;
    }

    if (isFieldInvalid(collection.transferrequired)) {
      errors.push('transferrequired');
      collection.transferrequired.validation.message = MISSING_INFORMATION;
    }

    if (isFieldInvalid(collection.mainresidence)) {
      errors.push('mainresidence');
      collection.mainresidence.validation.message = MISSING_INFORMATION;
    }

    if (
      collection.mainresidence
      && collection.mainresidence.value === '2'
      && isFieldInvalid(collection.otherresidence)
    ) {
      errors.push('otherresidence');
      collection.otherresidence.validation.message = MISSING_INFORMATION;
    }

    if (
      isFieldInvalid(collection.owners)
      && (!collection.ltdcompany || collection.ltdcompany.value === '2')
    ) {
      errors.push('owners');
      collection.owners.validation.message = MISSING_INFORMATION;
    }

    if (!isFieldInvalid(collection.owners)) {
      const populateErrorsArr = (i) => {
        errors.push(`DateOfBirth ${i}`);
        collection.DateOfBirth.value.forEach((item) => {
          if (item.id === i) item.validation.message = MISSING_INFORMATION;
        });
      };
      for (let i = 0; i < collection.DateOfBirth.value?.length; i += 1) {
        if (collection.DateOfBirth.value[i]?.value?.length !== 10
          || isFieldInvalid(collection.DateOfBirth.value[i])
        ) {
          populateErrorsArr(collection.DateOfBirth.value[i].id);
        }
      }
    }

    if (
      ((caseType === 'remortgage' && collection.transferrequired.value === '1')
        || (caseType !== 'remortgage'))
      && isFieldInvalid(collection.ownersaftertransfer)
    ) {
      errors.push('ownersaftertransfer');
      collection.ownersaftertransfer.validation.message = MISSING_INFORMATION;
    }

    if (caseType === 'sale' || quoteType === 'sale') {
      if (collection.mortgage.value === '1' && isFieldInvalid(collection.newLender)) {
        errors.push('newLender');
        collection.newLender.validation.message = MISSING_INFORMATION;
      }
    } else if (caseType !== 'sale' && quoteType !== 'sale' && isFieldInvalid(collection.newLender)) {
      errors.push('newLender');
      collection.newLender.validation.message = MISSING_INFORMATION;
    }

    if (
      !isFieldInvalid(collection.expatstrait)
      && isFieldInvalid(collection.numberofexpats)
    ) {
      errors.push('numberofexpats');
      collection.numberofexpats.validation.message = MISSING_TRAIT_INFORMATION;
    }

    if (
      isFieldInvalid(collection.numberofgifteddeposits)
      && !isFieldInvalid(collection.gifteddeposittrait)
    ) {
      errors.push('numberofgifteddeposits');
      collection.numberofgifteddeposits.validation.message = MISSING_TRAIT_INFORMATION;
    }

    if (
      isFieldInvalid(collection.numberofhelptobuy)
      && !isFieldInvalid(collection.helptobuytrait)
    ) {
      errors.push('numberofhelptobuy');
      collection.numberofhelptobuy.validation.message = MISSING_INFORMATION;
    }

    if (
      caseType !== 'sale'
      && isFieldInvalid(collection.numberOfBedrooms)
      && !isFieldInvalid(collection.hmotrait)
      && collection.hmotrait !== undefined
    ) {
      errors.push('numberOfBedrooms');
      collection.numberOfBedrooms.validation.message = MISSING_TRAIT_INFORMATION;
    }

    if (
      !equalIgnoreCase(payload.jurisdiction.value, jurisdictionsEnum.SCOTLAND.value)
      && isFieldInvalid(collection.studentproperty)
      && !isFieldInvalid(collection.hmotrait)
    ) {
      errors.push('studentproperty');
      collection.studentproperty.validation.message = MISSING_TRAIT_INFORMATION;
    }

    // jurisdiction sits at top level regardless of caseType
    if (isFieldInvalid(payload.jurisdiction) || payload.jurisdiction.value === jurisdictionsEnum.NONE.value) {
      errors.push('jurisdiction');
      collection.jurisdiction.validation.message = MISSING_INFORMATION;
    }

    if (
      !isFieldInvalid(collection.bridgingfinance)
      && isFieldInvalid(collection.bridgingfinancerepresentation)
    ) {
      errors.push('bridgingfinancerepresentation');
      collection.bridgingfinancerepresentation.validation.message = MISSING_INFORMATION;
    }
  };

  function validateGi(collection) {
    if (preferences.uinsure.quotingType.value === 'AlwaysIncludeGeneralInsuranceQuote'
      || preferences.uinsure.quotingType.value === 'PassOnLeadWithoutQuote') {
      if (isFieldInvalid(collection.propertyType)) {
        errors.push('propertytype');
        collection.propertyType.validation.message = MISSING_INFORMATION;
      }

      if (isFieldInvalid(collection.numberOfBedrooms)) {
        errors.push('numberOfBedrooms');
        collection.numberOfBedrooms.validation.message = MISSING_INFORMATION;
      }

      if (isFieldInvalid(collection.yearBuilt)) {
        errors.push('yearBuilt');
        collection.yearBuilt.validation.message = MISSING_INFORMATION;
      }
    }
  }

  if (caseType === QuoteType.SALE_AND_PURCHASE) {
    validate(payload.sale, QuoteType.SALE);
    validate(payload.purchase, QuoteType.PURCHASE);
    validateGi(payload.purchase);
  } else if (caseType !== QuoteType.SALE) {
    validate(payload);
    validateGi(payload);
  } else {
    validate(payload);
  }
  if (errors.length > 0) {
    yield put({ type: types.QUOTE_VALIDATION_FAILURE, payload, caseType });
    yield put({ type: types.QUOTE_REQUEST_BUSY, payload: false });
  } else {
    yield put({ type: types.QUOTE_VALIDATION_SUCCESS, history });
    yield put({ type: types.QUOTE_REQUEST_BUSY, payload: true });
  }
}

function* validateBespokeReferralFee(action) {
  const { payload } = action;
  const caseType = yield select((s) => s.quote.type.value);
  const collection = yield select((s) => s.quote[caseType]);
  const errors = [];

  const validate = (coll) => {
    if (payload.length <= 0) {
      coll.bespokeReferralFee.validation.message = MISSING_INFORMATION;
      errors.push('bespokeReferralFee');
    } else {
      coll.bespokeReferralFee.validation.message = null;
    }
  };

  validate(collection);

  if (errors.length > 0) {
    yield put({
      type: types.BESPOKE_REFERRAL_FEE_VALIDATION_FAILURE,
      payload: collection,
      caseType,
    });
  } else {
    yield put({
      type: types.BESPOKE_REFERRAL_FEE_VALIDATION_SUCCESS,
    });
  }
}

function* validateUinsureForm() {
  const errors = [];
  const caseType = yield select((s) => s.quote.type.value);
  const payload = yield select((s) => s.quote[caseType]);

  const validate = (collection) => {
    if (isFieldInvalid(collection.propertyType) || collection.propertyType.value === 'Please select') {
      errors.push('propertyType');
      collection.propertyType.validation.message = MISSING_INFORMATION;
    }
    if (isFieldInvalid(collection.numberOfBedrooms) || collection.numberOfBedrooms.value === 'Please select') {
      errors.push('number of bedrooms');
      collection.numberOfBedrooms.validation.message = MISSING_INFORMATION;
    }
    if (isFieldInvalid(collection.yearBuilt) || collection.yearBuilt.value === 'Please select') {
      errors.push('number of bedrooms');
      collection.yearBuilt.validation.message = MISSING_INFORMATION;
    }

    const dobCollection = [...collection.DateOfBirth.value];

    const populateErrorsArr = (i) => {
      errors.push(`DateOfBirth ${i}`);
      dobCollection.find((item) => item.id === i).validation.message = MISSING_INFORMATION;
    };

    for (let i = 0; i < dobCollection.length; i += 1) {
      if (dobCollection[i].value?.length !== 10 || isFieldInvalid(!dobCollection[i].value)) {
        populateErrorsArr(dobCollection[i]?.id);
      }
    }

    collection.DateOfBirth.value = dobCollection;
  };

  if (caseType === QuoteType.SALE_AND_PURCHASE) {
    validate(payload.purchase);
  } else {
    validate(payload);
  }

  if (errors.length > 0) {
    yield put({
      type: types.STORE_UINSURE_VALIDATION_SUCCESS,
      payload: false,
    });
  } else {
    yield put({
      type: types.STORE_UINSURE_VALIDATION_SUCCESS,
      payload: true,
    });
  }
}

function* saga() {
  yield takeLatest(types.VALIDATE_QUOTE, validateQuote);
  yield takeLatest(types.VALIDATE_PREFERENCES, validatePreferences);
  yield takeLatest(types.VALIDATE_UINSURE_FORM, validateUinsureForm);
  yield takeLatest(
    types.VALIDATE_BESPOKE_REFERRAL_FEE,
    validateBespokeReferralFee,
  );
}

const validationSaga = fork(saga);

export { validationSaga as default, validateQuote, saga };
