import { call, put, takeLatest, fork, select } from "redux-saga/effects";
import * as types from "../../actions/types"
import { updateUinsureClient } from "./updateUinsureClientSaga"
import * as uinsureUtils from '../utils/uinsure';
import getResourceFromApi from "../../../services/getRequest"

function* getUinsureRedirect() {
    yield put({ type: types.SITE_BUSY, payload: true });

    try {
        const giQuoteReference = yield uinsureUtils.getSelectedQuoteReference();
        const quoteGroupId = yield select((store) => store.instruct.instructProcessState?.savedQuote?.data?.quoteGroupId);

        if (giQuoteReference) {
            const response = yield call(getResourceFromApi,
                `${window.config.apiBaseUrl}/quotes/${quoteGroupId}/insurance/${giQuoteReference}/redirect`);

            const { status, data } = response;

            if (status === 200) {
                window.open(data.directUrl);
            }
        }
    }
    catch (e) {
        // Don't stop if this fails
    }

    yield put({ type: types.SITE_BUSY, payload: false });
}

function* saga() {
    yield takeLatest(types.GET_UINSURE_REDIRECT, getUinsureRedirect);
}

const getUinsureRedirectSaga = fork(saga);

export { getUinsureRedirectSaga as default, getUinsureRedirect, saga };