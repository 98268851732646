import React from 'react';
import PropTypes from 'prop-types';
import { FeatureFlagsProvider } from '@lms/react-configuration/context/feature-flags';
import { AuthContext } from "../../utils/authProvider";

const FeatureFlagWrapper = ({ children }) => {
  const { getAccessToken, isAuthenticated } = React.useContext(AuthContext);
  const configurationApiBaseAddress = window?.config?.configurationApiBaseAddress;

  const tryGetAccessToken = async () => {
    const userAuthed = await isAuthenticated();
    if (!userAuthed) {
      return null;
    }
    return getAccessToken();
  }

  return (
    <FeatureFlagsProvider
      url={configurationApiBaseAddress}
      getAccessToken={tryGetAccessToken}
      featurePrefix="Select.UI."
    >
      {children}
    </FeatureFlagsProvider>
  );
};

FeatureFlagWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FeatureFlagWrapper;
