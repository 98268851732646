export const beforeMaskedValueChange = (newState, oldState, userInput) => {
    var { value, selection } = newState;

    if(userInput === '/' && selection?.start === 1 && value.charAt(value.length-1) !== "0"){
        value = `0${value}/`
        selection = { start: 3, end: 3 }
    }

    if(userInput === '/' && selection?.start === 4 && value.charAt(value.length-1) !== "0"){
        value = `${[value.slice(0,value.length-1), "0", value.slice(value.length-1)].join("")}/`
        selection = { start: 6, end: 6 }
    }

    if(selection?.start === 1 && userInput >= 4) {
        value = `0${userInput}/`
        selection = { start: 3, end: 3 }
    } else if(selection?.start === 4 && userInput >= 2) {
        value = `${value.slice(0, value.length-1)}0${userInput}/`
        selection = { start: 6, end: 6 }
    }

    if(selection?.start === 3 && parseInt(value.charAt(0)) === 3 && parseInt(userInput) > 1) {
        return {...oldState}
    }
 
    return {
      value,
      selection
    };
  }