import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { useHistory, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import _ from 'lodash';

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Accordion from 'react-bootstrap/Accordion';
import Layout from '../../layout';

// actions
import {
  storeUinsureSelectedQuote,
  setSelectedQuotes,
  setEditingQuote
} from "../../redux/actions/actions";


// components
import AuthCheck from "../../components/authcheck/authCheck";
import ReferralFeeModal from "../../components/bespokereferralfee/referralfeemodal/referralfeemodal";
import ResultsTable from "../../components/resultsTable";
import GiResultsTable from "../../components/giResultsTable";
import authState from "../../components/authcheck/authState";
import MiniBreakdown from "../../components/miniBreakdown";
import LabelledCheckboxInput from "../common/components/labelled-checkbox-input"
import AccordionItemWrapper from "../../components/AccordionItems/AccordionItemWrapper";

//svgs
import {
  MarketplacePricing,
  SelectPricing,
  UInsure,
} from "../../components/shared/imgs";
//data
import { toeReferralFee } from "../../components/shared/data";

import commonCss from "../common/styles.module.css";
import css from "./quoteresults.module.css";
import { rollupPricing } from './utils';
import { arrayHasItems } from '@lms/react-common-helpers/arrays';
import { equalIgnoreCase } from '@lms/react-common-helpers/strings';
import { PricingModel } from '../../enum/PricingModel';

function QuoteResults({ getAccessToken }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const quotesReceived = useSelector((store) => store.quote.quotesReceived);
  const caseType = useSelector((store) => store.quote.type.value);

  const [showDistance, setShowDistance] = useState(false);
  const [selectLawFirms, setSelectLawFirms] = useState([]);
  const [marketplaceLawFirms, setMarketplaceLawFirms] = useState([]);
  const [selectedQuote, setSelectedQuote] = useState(false);
  const selectedGiQuote = useSelector((store) => store.uinsure?.selectedQuote);
  const [referralFeeShow, setReferralFeeShow] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const handleClose = () => setReferralFeeShow(false);
  const handleShow = () => setReferralFeeShow(true);

  const handleSelectQuote = (quotes) => {
    dispatch(setSelectedQuotes(quotes));
    setSelectedQuote(true);
  };

  useEffect(() => {
    const marketplaceQuotes = quotesReceived.quotes?.filter((item) => equalIgnoreCase(item.product.pricingModel, PricingModel.Marketplace)) ?? [];
    var marketplace = rollupPricing(marketplaceQuotes, caseType)?.sort((a, b) => a.costSummary.totalCost - b.costSummary.totalCost);
    setMarketplaceLawFirms(marketplace);

    const fixedPriceQuotes = quotesReceived.quotes?.filter((item) => equalIgnoreCase(item.product.pricingModel, PricingModel.FixedPrice)) ?? [];
    var fixedPrice = rollupPricing(fixedPriceQuotes, caseType)?.sort((a, b) => a.costSummary.totalCost - b.costSummary.totalCost);
    setSelectLawFirms(fixedPrice);
  }, [quotesReceived])

  useEffect(() => {
    if (!arrayHasItems(marketplaceLawFirms) && !arrayHasItems(selectLawFirms)) {
      setNoResults(true);
    }
    else {
      setNoResults(false);
    }
  }, [marketplaceLawFirms, selectLawFirms])

  const [isToE, setIsToE] = useState(false);

  useEffect(() => {
    setIsToE(caseType === "transferOfEquity");
  }, []);

  const [miniBreakdownData, setMiniBreakdownData] = useState({});
  const [miniBreakdownShow, setMiniBreakdownShow] = useState(false);

  const showMiniBreakdown = (breakdownData) => {
    setMiniBreakdownData(breakdownData);
    setMiniBreakdownShow(true);
  };

  const handleMiniBreakdownHide = () => {
    setMiniBreakdownShow(false);
  };

  const defaultReferralFee = useSelector(
    (store) => store.preferences.defaultReferralFee.value
  );
  const bespokeReferralFee = useSelector(
    (store) =>
      store.quote[caseType].bespokeReferralFee &&
      store.quote[caseType].bespokeReferralFee.value
  );
  const referralFee = bespokeReferralFee || defaultReferralFee;

  const generalInsurancePreference = useSelector(
    (store) => store.preferences.uinsure.quotingType.value
  );

  const preferredPricingModel = useSelector(
    (store) => store.preferences.preferredPricingModel.value
  );

  let [isSelectPreferred, setisSelectPreferred] = useState(true);

  useEffect(() => {
    if (preferredPricingModel === "FixedPrice") {
      setisSelectPreferred(true);
    } else {
      setisSelectPreferred(false);
    }
  }, []);

  const handleNextStep = () => {
    const url = isGInsure ? "/quote-further-details/" : "/view-quote/"
    history.push(`${url}`)
  }

  let [distanceRequired, setdistanceRequired] = useState(false);

  const [isGInsure, setIsGInsure] = useState(false)
  const handleGinsurePreference = (e) => {
    setIsGInsure(e.target.checked)
  }
  const giQuote = useSelector((store) => store.uinsure);

  const isLoading = useSelector((store) => store.uinsure.isLoading);

  return (
    <>
      <Layout>
        <AuthCheck requiredAuthState={authState.AUTHENTICATED} />
        <Row>
          <Col md={1} xs={1}></Col>
          <Col md={10} xs={10}>
            <div className={css.intro}>TAKE A LOOK AT YOUR QUOTES</div>
          </Col>
          <Col md={1} xs={1}></Col>
        </Row>

        {/* Quote section */}

        {noResults ? (
          <Row
            className={classnames(css.quoteBackground, css.toppad2x)}
            data-no-results
          >
            <Col md={1} xs={1}></Col>
            <Col md={10} xs={10}>
              <Row
                className={classnames(
                  css.fixedpricerow,
                  css.leftalign,
                  css.toppad2x,
                  css.bottompad2x
                )}
              >
                <Col>
                  <MarketplacePricing className={css.marketplaceimage} />{" "}
                  <span className={css.rowtitle}>No Results</span>
                  <p className={css.noresultsmessage}>
                    No suitable products could be found for the values entered. Please go BACK and try again.</p>
                </Col>
              </Row>
            </Col>
          </Row>
        ) : (
          <>
            <MiniBreakdown
              show={miniBreakdownShow}
              onHide={() => handleMiniBreakdownHide()}
              data={miniBreakdownData}
            />
            <Row className={classnames(css.quoteBackground, css.toppad2x)}>
              <Col md={1} xs={1}></Col>
              <Col md={10} xs={10}>
                {arrayHasItems(selectLawFirms) && (
                  <>
                    <Row
                      className={classnames(
                        css.marketplacerow,
                        css.leftalign,
                        css.toppad2x,
                        css.bottompad2x
                      )}
                    >
                      <Col md={8} xs={8}>
                        <SelectPricing className={css.marketplaceimage} />{" "}
                        <span className={css.rowtitle}>Select</span>
                      </Col>
                    </Row>
                    <Row
                      className={css.quoteResultsCard}
                      data-quote-results-table='Select'
                    >
                      <ResultsTable
                        data={selectLawFirms}
                        onQuoteSelect={handleSelectQuote}
                        handleMiniBreakdown={(breakDownData, show) =>
                          showMiniBreakdown(breakDownData, show)
                        }
                        caseType={caseType}
                        distanceRequired={distanceRequired}
                      />
                    </Row>
                  </>
                )}
                {arrayHasItems(marketplaceLawFirms) && (
                  <>
                    <Row
                      className={classnames(
                        css.marketplacerow,
                        css.leftalign,
                        css.toppad2x,
                        css.bottompad2x
                      )}
                    >
                      <Col md={8} xs={8}>
                        <MarketplacePricing className={css.marketplaceimage} />{" "}
                        <span className={css.rowtitle}>Marketplace</span>
                      </Col>
                    </Row>
                    <Row
                      className={css.quoteResultsCard}
                      data-quote-results-table='Marketplace'
                    >
                      <ResultsTable
                        data={marketplaceLawFirms}
                        onQuoteSelect={handleSelectQuote}
                        handleMiniBreakdown={(breakDownData, show) =>
                          showMiniBreakdown(breakDownData, show)
                        }
                        caseType={caseType}
                        distanceRequired={distanceRequired}
                      />
                    </Row>
                  </>
                )}

                {/* Referral Fee section */}
                <Row>
                  <Accordion className={classnames(css.accordion, css.toppad2x)}>
                    <AccordionItemWrapper
                      id="quoteBreakdown"
                      headerText="These prices include"
                      eventKey="0"
                      variant="caseDetail"
                    >
                      <Accordion.Collapse eventKey="0">
                        <Row
                          className={classnames(
                            css.referralFeeCard,
                            css.leftalign,
                            css.leftpad1x,
                            css.toppad2x,
                            css.bottompad2x
                          )}
                        >
                          <Col md={2} xs={2} className={css.toppad03x}>
                            Your fee
                          </Col>

                          {!isToE ? (
                            <Col md={1} xs={1} className={css.toppad03x}>
                              &pound;
                              {Math.round(referralFee * 100 + Number.EPSILON) / 100}
                            </Col>
                          ) : (
                            <Col md={1} xs={1} className={css.toppad03x}>
                              &pound;
                              {Math.round(toeReferralFee * 100 + Number.EPSILON) / 100}
                            </Col>
                          )}

                          <Col md={6} xs={6}>
                            {!isToE ? (
                              <>
                                <Button
                                  className={css.change}
                                  size="sm"
                                  variant="primary"
                                  onClick={handleShow}
                                >
                                  CHANGE
                                </Button>

                                <ReferralFeeModal
                                  id="referralfeemodal"
                                  datatestid="referralfeemodal"
                                  show={referralFeeShow}
                                  onHide={handleClose}
                                  getAccessToken={getAccessToken}
                                />
                              </>
                            ) : (
                              <div className={css.toppad03x}>
                                Your referral fee is fixed at £50 for Transfer Of Equity
                                quotes.
                              </div>
                            )}
                          </Col>
                          <Col
                            md={3}
                            xs={3}
                            className={classnames(
                              css.rightalign,
                              css.rightpad2x,
                              css.toppad03x
                            )}
                          >
                            Price includes VAT
                          </Col>
                        </Row>
                      </Accordion.Collapse>
                    </AccordionItemWrapper>
                  </Accordion>
                </Row>
                {generalInsurancePreference === "PromptForGeneralInsuranceQuote" && caseType !== "sale" && (
                  <>
                    <Row
                      className={classnames(
                        css.referralFeeHeader,
                        css.leftalign,
                        css.toppad2x,
                        css.bottompad1x
                      )}
                    >
                      <Col md={12} xs={12}>
                        These prices don&apos;t include:
                      </Col>
                    </Row>
                    <Row
                      className={classnames(
                        css.referralFeeCard,
                        css.leftalign,
                        css.leftpad1x,
                        css.toppad1x,
                        css.bottompad1x
                      )}
                    >
                      <Col md={1} xs={1}>
                        <UInsure className={css.uinsureimagesmall} />
                      </Col>
                      <Col md={2} xs={2} className={css.toppad1x}>
                        <div>
                          Home Insurance
                        </div>
                      </Col>
                      <Col
                        className={classnames(
                          css.rightalign,
                          css.rightpad2x,
                          css.toppad1x
                        )}
                      >
                        <div className={css.optincontainer}>
                          <fieldset>
                            <LabelledCheckboxInput
                              id="ginsurePreference"
                              label="I would like to include Home Insurance"
                              onChange={(e) => handleGinsurePreference(e)}
                              className={classnames("form-check-custom", css.gicheckbox)}
                            />
                          </fieldset>
                        </div>
                      </Col>
                    </Row>
                  </>
                )}
              </Col>
              <Col md={1} xs={1}></Col>
            </Row>

            {/* GI section */}
            {generalInsurancePreference ===
              "AlwaysIncludeGeneralInsuranceQuote" && caseType !== "sale" && (
                <Row className={css.controlrow}>
                  <Col md={1} xs={1}></Col>
                  <Col
                    md={10}
                    xs={1}
                    className={classnames(css.generalInsurance)}
                  >
                    <Row>
                      <Col md={6} xs={6} className={css.uinsureheader}>
                        <UInsure className={css.uinsureimage} />
                        <span className={css.rowtitle}>Home Insurance</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} className={css.giSubheader}>We have automatically searched the <b>whole of the market</b> to find you the <strong>cheapest 5-star</strong> rates Home Insurance Products</Col>
                    </Row>
                    <Row className={css.quoteResultsCard}>
                      <GiResultsTable
                        data={giQuote.quote}
                        onQuoteSelect={(quote, premium) => dispatch(storeUinsureSelectedQuote({ quote, premium }))}
                        isLoading={isLoading}
                        error={giQuote.error}
                        errorMessage={giQuote.errorMessage}
                      />
                    </Row>
                  </Col>
                  <Col md={1} xs={1}></Col>
                </Row>
              )}
          </>
        )}

        <Row>
          <Col md={1} xs={1}></Col>
          <Col className={css.buttonFooter}>
            <div className={commonCss.buttonContainer}>
              <Link
                className={commonCss.backButton}
                size="lg"
                variant="primary"
                to=""
                onClick={(e) => {
                  dispatch(setEditingQuote());
                  e.preventDefault();
                  history.goBack();
                }}
              >
                BACK
              </Link>
              {!noResults && (
                <Button
                  data-testid="next-btn"
                  className={commonCss.button}
                  size="lg"
                  variant="primary"
                  disabled={!selectedQuote || (generalInsurancePreference === 'AlwaysIncludeGeneralInsuranceQuote' && giQuote.hasQuote && !selectedGiQuote)}
                  onClick={handleNextStep}
                >
                  NEXT
                </Button>
              )}
            </div>
          </Col>
          <Col md={1} xs={1}></Col>
        </Row>
      </Layout>
    </>
  );
}

QuoteResults.propTypes = {
  getAccessToken: PropTypes.func.isRequired,
};

export default QuoteResults;
