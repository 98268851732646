import React from "react";
import PropTypes from 'prop-types';
import css from '../index.module.css';
import generateStringFromAddress from '../utils';
import { toQuoteTypeDisplay } from "../../../utils/jsutils";

export default function CaseSummaryCard(props) {
  const { oneCase } = props;

  const corrAddress = oneCase.clients[0].address;
  
  const propertyAddress = oneCase.propertyAddress;

  const stringToDisplayProperty = generateStringFromAddress(propertyAddress);
  const stringToDisplayCorrespondence = generateStringFromAddress(corrAddress);

  return (
    <div className={css.summaryCard} id="caseSummaryCard">
      <div className={css.summaryInfo}>
        <div className={css.label} id="header1">Case reference</div>
        <div className={css.response}>
          <div id="number">
            {oneCase.caseId}
          </div>
        </div>
      </div>

      <div className={css.summaryInfo}>
        <div className={css.label} id="header2">Customer(s)</div>
        <div className={css.response}>
          <div key="customers" className={css.clientNames}>
            <div id="customer1">
              {oneCase.clients[0]?.forenames}{" "}
              {oneCase.clients[0]?.surname}
            </div>
            <div id="customer2">
              {oneCase.clients[1]?.forenames}{" "}
              {oneCase.clients[1]?.surname}
            </div>
            <div id="customer3">
              {oneCase.clients[2]?.forenames}{" "}
              {oneCase.clients[2]?.surname}
            </div>
            <div id="customer4">
              {oneCase.clients[3]?.forenames}{" "}
              {oneCase.clients[3]?.surname}
            </div>
          </div>
        </div>
      </div>

      <div className={css.summaryInfo}>
        <div className={css.label} id="header3">Correspondence address</div>
        <div className={css.response}>
          <div id="corrAddress">
            {stringToDisplayCorrespondence}
          </div>
        </div>
      </div>

      <div className={css.summaryInfo}>
        <div className={css.label} id="header4">Property address</div>
        <div className={css.response}>
          <div id="propertyAddress">
            {stringToDisplayProperty}
          </div>
        </div>
      </div>

      <div className={css.summaryInfo}>
        <div className={css.label} id="header5">Product</div>
        <div className={css.response}>
          <div id="caseType">{toQuoteTypeDisplay(oneCase.caseType)}</div>
        </div>
      </div>

      <div className={css.summaryInfo}>
        <div className={css.label} id="header6">Broker fee</div>
        <div className={css.response}>
          <div id="referralFee">
            &pound;
            {Math.round(
              oneCase.referralFee * 100 +
              Number.EPSILON
            ) / 100}
          </div>
        </div>
      </div>
    </div>
  );
}

CaseSummaryCard.propTypes = {
  oneCase: PropTypes.shape({
    caseId: PropTypes.number,
    referralFee: PropTypes.string,
    propertyAddress: PropTypes.shape({
      address: PropTypes.string,
      streetName: PropTypes.string,
      districtName: PropTypes.string,
      postTown: PropTypes.string,
      county: PropTypes.string,
      postcode1: PropTypes.string,
      postcode2: PropTypes.string,
    }),
    caseType: PropTypes.string,
    caseClients: PropTypes.arrayOf(PropTypes.shape({
      address: PropTypes.shape({
        address: PropTypes.string,
        streetName: PropTypes.string,
        districtName: PropTypes.string,
        postTown: PropTypes.string,
        county: PropTypes.string,
        postcode1: PropTypes.string,
        postcode2: PropTypes.string,
      })
    }))
  }).isRequired,
}