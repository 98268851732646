import React, { useEffect, useState } from "react";

const ValidationGroup = ({ id, children, validationHandler, submitted }) => {

    const [validationState, setValidationState] = useState({});
    const [childComponents, setChildComponents] = useState();

    useEffect(() => {
        let isValid = true;
        Object.keys(validationState).map(item => isValid = isValid && validationState[item]);
        validationHandler(isValid);
    }, [validationState]);

    useEffect(() => {
        let count = 0;
        var newChildren = React.Children.map(children, function (child) {
            if (child) {
                // Only inject the validation handler etc to non-react dom components
                if (typeof child.type !== 'string') {
                    const arg = count;
                    count++;
                    return React.cloneElement(child, { submitted: submitted, validationHandler: (isValid) => setValidationState({ ...validationState, [arg]: isValid }) })
                }
            }

            return child;
        });

        setChildComponents(newChildren);
    }, [submitted, children])

    return childComponents ?? null;
}

export default ValidationGroup;