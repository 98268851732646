import { all, call, put, takeLatest, fork, select } from "redux-saga/effects";
import * as types from "../../actions/types"
import { storeUinsureQuote, storeUinsureSelectedQuote } from "../../actions/actions";

import postRequest from "../../../services/postRequest"
import { QuoteType } from "../../../data/quote-type";

function* reset() {
    yield put(storeUinsureQuote({
        buildings: null,
        buildingsAndContents: null,
        requiredDetailsCollected: false,
        hasQuote: false,
        isLoading: true
    }));

    yield put(storeUinsureSelectedQuote({
        selectedQuote: null,
        selectedPremium: null
    }));
}

function* getUinsureQuote({ quoteGroupId }) {
    yield put({ type: types.SITE_BUSY, payload: true });
    yield reset();
    const caseType = yield select((s) => s.quote.type.value)
    const currentCaseTypeDetails = yield select((s) => s.quote[caseType])    

    if ((caseType === QuoteType.SALE_AND_PURCHASE && currentCaseTypeDetails.purchase.otherresidence?.value === 'BuyToLet')
    || (caseType !== QuoteType.SALE && currentCaseTypeDetails.otherresidence?.value === 'BuyToLet')) {
        yield put(storeUinsureQuote({
            buildings: null,
            buildingsAndContents: null,
            error: true,
            errorMessage: 'We can\'t quote for Buy to Let properties',
            isLoading: false
        }));

        return;
    }

    const getUpdatedDetails = () => {
        const collection = caseType === QuoteType.SALE_AND_PURCHASE ? currentCaseTypeDetails.purchase : currentCaseTypeDetails
        return {
            dateOfBirthClient1: collection.DateOfBirth.value[0]?.value,
            dateOfBirthClient2: collection.DateOfBirth.value[1]?.value,
            dateOfBirthClient3: collection.DateOfBirth.value[2]?.value,
            dateOfBirthClient4: collection.DateOfBirth.value[3]?.value,
            riskAddressPropertyType: collection.propertyType?.value,
            yearBuilt: collection.yearBuilt?.value,
            numberOfBedrooms: collection.numberOfBedrooms?.value
        }
    } 

    const propertyAddress = caseType === QuoteType.SALE_AND_PURCHASE
        ? currentCaseTypeDetails.purchase.propertyAddress.value 
        : currentCaseTypeDetails.propertyAddress.value;
        
    const uinsureRequest = {
        quoteGroupId,
        riskDataId: "lms-test",
        ...getUpdatedDetails(),
        riskAddressHouseNameNumber: propertyAddress.houseName ? propertyAddress.houseName : propertyAddress.houseNumber,
        riskAddressStreetName: propertyAddress.streetName,
        riskAddressTownCity: propertyAddress.postTown,
        riskAddressDistrict: propertyAddress.districtName,
        riskAddressCounty: propertyAddress.county,
        riskAddressPostcode: propertyAddress.postCode
    }

    const buildingsAndContents = {
        ...uinsureRequest,
        includeBuildings: true,
        includeContents: true
    };

    const buildings = {
        ...buildingsAndContents,
        includeContents: false
    };

    const filterPremiums = (premiums) => {
        return premiums.sort((a, b) => a.totalMonthlyPremium - b.totalMonthlyPremium).slice(0, 1);
    }

    try {
        const [buildingsAndContentsResult, buildingsResult] = yield all([
            call(postRequest, `${window.config.apiBaseUrl}/quotes/${quoteGroupId}/insurance`, buildingsAndContents),
            call(postRequest, `${window.config.apiBaseUrl}/quotes/${quoteGroupId}/insurance`, buildings)
        ])

        const { status: buildingsAndContentsStatus, data: buildingsAndContentsData } = buildingsAndContentsResult;
        const { status: buildingsStatus, data: buildingsData } = buildingsResult;

        if (buildingsAndContentsStatus === 200 && buildingsStatus === 200) {

            const { premiumListItems: buildingsAndContentsPremiums } = buildingsAndContentsData;
            const { premiumListItems: buildingsPremiums } = buildingsData;

            const buildingsAndContentsQuote = {
                ...buildingsAndContentsData,
                premiumListItems: filterPremiums(buildingsAndContentsPremiums)
            }

            const buildingsQuote = {
                ...buildingsData,
                premiumListItems: filterPremiums(buildingsPremiums)
            }

            yield put(storeUinsureQuote({
                buildings: buildingsQuote,
                buildingsAndContents: buildingsAndContentsQuote,
                error: false,
                isLoading: false
            }));
        }
    } catch (e) {
        yield put(storeUinsureQuote({
            buildings: null,
            buildingsAndContents: null,
            error: true,
            isLoading: false
        }));
    }

    yield put({ type: types.SITE_BUSY, payload: false });
}

function* saga() {
    yield takeLatest(types.GET_UINSURE_QUOTE, getUinsureQuote);
}

const getUinsureQuoteSaga = fork(saga);

export { getUinsureQuoteSaga as default, getUinsureQuote, saga };