import _ from "lodash";
import * as actions from "../../../actions/instruct-actions/customer";
import { isCustomerValid } from "../validators";

const updateCustomerProp = ({ state, idx, isRelatedQuote }) => ({ prop, newValue }) => {
  const baseProp = isRelatedQuote ? "relatedQuote" : "quote"
  return _.flow(_.cloneDeep, (newState) =>
    _.set(newState, `[${baseProp}][${idx}][${prop}]`, newValue || "")
  )(state);

};

const initialState = {
  quote: [
    {
      correspondenceAddress: {}
    }
  ],
  relatedQuote: [
    {
      correspondenceAddress: {}
    }
  ]
};

export default (state = initialState, action) => {
  const isRelatedQuote = action.isRelatedQuote
  const doUpdateCustomerProp = updateCustomerProp({ state, idx: action.idx, isRelatedQuote });

  switch (action.type) {
    case actions.UPDATE_TITLE.type:
      return doUpdateCustomerProp({ prop: "title", newValue: action.newTitle });

    case actions.UPDATE_FIRST_NAME.type:
      return doUpdateCustomerProp({
        prop: "firstName",
        newValue: action.newFirstName,
      });

    case actions.UPDATE_SURNAME.type:
      return doUpdateCustomerProp({
        prop: "surname",
        newValue: action.newSurname,
      });

    case actions.UPDATE_ADDRESS.type:
      return doUpdateCustomerProp({
        prop: "correspondenceAddress",
        newValue: action.newAddress
      })

    case actions.UPDATE_DATE_OF_BIRTH.type:
      return doUpdateCustomerProp({
        prop: "dateOfBirth",
        newValue: action.newDateOfBirth
      })

    case actions.UPDATE_EMAIL.type:
      return doUpdateCustomerProp({ prop: "email", newValue: action.newEmail });

    case actions.UPDATE_MOBILE_NUMBER_CALLING_CODE.type:
      return doUpdateCustomerProp({
        prop: "mobileCallingCode",
        newValue: action.newMobileCallingCode,
      });

    case actions.UPDATE_MOBILE_NUMBER.type:
      return doUpdateCustomerProp({
        prop: "mobile",
        newValue: action.newMobile,
      });

    case actions.UPDATE_HOME_NUMBER_CALLING_CODE.type:
      return doUpdateCustomerProp({
        prop: "homePhoneNumberCallingCode",
        newValue: action.newHomePhoneNumberCallingCode
      });

    case actions.UPDATE_HOME_NUMBER.type:
      return doUpdateCustomerProp({
        prop: "homePhoneNumber",
        newValue: action.newHomePhoneNumber,
      });

    case actions.UPDATE_WORK_NUMBER.type:
      return doUpdateCustomerProp({
        prop: "workPhoneNumber",
        newValue: action.newWorkPhoneNumber,
      });

    case actions.UPDATE_WORK_NUMBER_CALLING_CODE.type:
      return doUpdateCustomerProp({
        prop: "workPhoneNumberCallingCode",
        newValue: action.newWorkPhoneNumberCallingCode
      });


    case actions.UPDATE_RELATED_TITLE.type:
      return doUpdateCustomerProp({
        prop: "title",
        newValue: action.newTitle
      });

    case actions.UPDATE_RELATED_FIRST_NAME.type:
      return doUpdateCustomerProp({
        prop: "firstName",
        newValue: action.newFirstName,
      });

    case actions.UPDATE_RELATED_SURNAME.type:
      return doUpdateCustomerProp({
        prop: "surname",
        newValue: action.newSurname,
      });


    case actions.SUBMIT_CUSTOMER.type:
      return _.flow(
        _.cloneDeep,
        (newState) =>
          updateCustomerProp({ state: newState, idx: action.idx, isRelatedQuote: action.isRelatedQuote })({
            prop: "errors",
            newValue: isCustomerValid(action.isRelatedQuote ? newState.relatedQuote[action.idx] : newState.quote[action.idx]),
          }),
        (newState) => {
          if (Object.keys(action.isRelatedQuote ? newState.relatedQuote[action.idx].errors : newState.quote[action.idx].errors).length === 0) {
            return updateCustomerProp({ state: newState, idx: action.idx, isRelatedQuote: action.isRelatedQuote })({
              prop: "submitted",
              newValue: true,
            });
          }

          return newState;
        }
      )(state);

    case actions.UNSUBMIT_CUSTOMER.type:
      return doUpdateCustomerProp({ prop: "submitted", newValue: false });

    case actions.ADD_NEW_CUSTOMER.type:

      if (action.isRelatedQuote) {
        return {
          ...state,
          relatedQuote: state.relatedQuote.concat(initialState.relatedQuote)
        }
      } else {
        return {
          ...state,
          quote: state.quote.concat(initialState.quote)
        }
      }

    case actions.REMOVE_CUSTOMER.type:
      if (action.isRelatedQuote) {
        return {
          ...state,
          relatedQuote: state.relatedQuote.filter((c, idx) => idx !== action.idx)
        }
      } else {
        return {
          ...state,
          quote: state.quote.filter((c, idx) => idx !== action.idx)
        }
      }
  }

  return state;
};
