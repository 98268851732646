import React from "react";
import PropTypes from 'prop-types';
import { Accordion } from "react-bootstrap";
import css from '../index.module.css';
import { equalIgnoreCase } from '@lms/react-common-helpers/strings';

export default function AccordionLawFirmItem(props) {
  const { oneCase, eventKey } = props;
  const { lawFirmBranchAddress } = oneCase;

  const getLawFirmAddressLabel = () => {
    const parts = [
      lawFirmBranchAddress?.houseNumber,
      lawFirmBranchAddress?.houseName,
      lawFirmBranchAddress?.streetName,
      lawFirmBranchAddress?.districtName,
      lawFirmBranchAddress?.postTown,
      lawFirmBranchAddress?.county,
      lawFirmBranchAddress?.postcode1 + lawFirmBranchAddress?.postcode2,
      lawFirmBranchAddress?.country];

    return parts.filter((item => item && item.length > 0 && !equalIgnoreCase(item, "none"))).join(', ');
  }

  return (
    <Accordion.Collapse eventKey={eventKey}>
      <div className={css.sectionContent}>
        <div className={css.summaryInfo}>
          <div className={css.label} id="header1">Law firm name</div>
          <div className={css.response}>
            <div id="name">
              {oneCase.lawFirmName}
            </div>
          </div>
        </div>

        <div className={css.summaryInfo}>
          <div className={css.label} id="header2">Branch address</div>
          <div className={css.response}>
            <div id="address">
              {getLawFirmAddressLabel()}
            </div>

          </div>
        </div>

        <div className={css.summaryInfo}>
          <div className={css.label} id="header3">Branch phone</div>
          <div className={css.response}>
            <div id="phone">
              {oneCase.lawFirmBranchPhone}
            </div>
          </div>
        </div>

        <div className={css.summaryInfo}>
          <div className={css.label} id="header4">Fee earner email</div>
          <div className={css.response}>
            <div id="email">
              {oneCase.lawFirmFeeEarnerEmail}
            </div>
          </div>
        </div>

        <div className={css.summaryInfo}>
          <div className={css.label} id="header5">Fee earner</div>
          <div className={css.response}>
            <div id="feeEarner">
              {oneCase.lawFirmFeeEarner}
            </div>
          </div>
        </div>
      </div>
    </Accordion.Collapse>
  );
}

AccordionLawFirmItem.propTypes = {
  oneCase: PropTypes.shape({
    lawFirmName: PropTypes.string,
    lawFirmBranchAddress: PropTypes.string,
    lawFirmBranchPhone: PropTypes.string,
    lawFirmBranchEmail: PropTypes.string,
    lawFirmFeeEarner: PropTypes.string,
  }).isRequired,
  eventKey: PropTypes.string.isRequired,
}