import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import css from "./index.module.css";
//images
import FavouriteSelected from "../shared/imgs/favourite-selected.svg";
import FavouriteUnselected from "../shared/imgs/favourite-unselected.svg";
//components
import { Form } from "react-bootstrap";
import SortColumn from "../sortColumn";
import * as logos from "../shared/imgs/lawfirm-logos/index"
import Logo from "../logo/logo";

const ResultsTable = ({
  data,
  onQuoteSelect,
  handleMiniBreakdown,
  distanceRequired,
}) => {
  const entries = [];
  const [tableData, setTableData] = useState(data);
  const sortDirection = { asc: "ascending", desc: "descending" };

  const handleSortedData = (sorted) => {
    setTableData(sorted);
  };

  useEffect(() => {
    setTableData(data);
  }, [data])

  const rowHeaders = [
    "",
    "Fixed conveyancing cost",
    "Special Disbursements",
    <SortColumn
      key=""
      data={tableData}
      column={"totalCost"}
      sortDirection={sortDirection}
      setData={(sorted) => handleSortedData(sorted)}
    >
      Total Cost
    </SortColumn>,
    "",
    "",
  ];

  const rowHeadersWithDistance = [
    "",
    "Fixed conveyancing cost",
    "Special Disbursements",
    <SortColumn
      key=""
      data={tableData}
      column={"totalCost"}
      sortDirection={sortDirection}
      setData={(sorted) => handleSortedData(sorted)}
    >
      Total Cost
    </SortColumn>,
    <SortColumn
      key=""
      data={tableData}
      column={"distance"}
      sortDirection={sortDirection}
      setData={(sorted) => handleSortedData(sorted)}
    >
      Distance
    </SortColumn>,
    "",
  ];

  const renderHeaders = (title, index) => {
    return (
      <th key={`th-${index}`} className={css.th}>
        {title}
      </th>
    );
  };

  const renderItems = (cell, index) => {
    const key = `td-${index}`;
    return (
      <td key={key} data-test-id={key} className={css.td}>
        {cell}
      </td>
    );
  };

  const renderBody = (row, index) => {
    return (
      <tr key={`row-${index}`} className={css.tr}>
        {Object.keys(row).map((cell, idx) => renderItems(row[cell], idx))}
      </tr>
    );
  };

  const convertToCurrency = (val) => {
    return `£${(Math.round(val * 100 + Number.EPSILON) / 100).toFixed(2)}`;
  };

  const getFavourite = (favourite) => {
    return favourite ? (
      <FavouriteSelected className={css.favouriteImage} />
    ) : (
      <FavouriteUnselected className={css.favouriteImage} />
    );
  };

  const getRadio = (quoteIds) => {
    return (
      <Form.Check
        className={css.checkbox}
        name="quoteselect"
        type="radio"
        onChange={() => onQuoteSelect(quoteIds)}
      />
    );
  };

  const openBreakdown = ({ legalFees, disbursements, specialDisbursements, governmentFees, vat, totalCost}) => {
    const breakdownData = {
      legalFees: legalFees,
      disbursements: disbursements,
      specialDisbursements: specialDisbursements,
      governmentFees: governmentFees,
      vat: vat,
      totalCost: totalCost,
    };
    handleMiniBreakdown(breakdownData, true);
  };

  const getTotalCostCell = ({costSummary}) => {
    const totalCost = convertToCurrency(costSummary.totalCost);
    return (
      <>
        <span className={css.totalCost}>{totalCost}</span>
        <span className={css.link} onClick={() => openBreakdown(costSummary)}>
          View Price breakdown
        </span>
      </>
    );
  };

  const getLawFirmDetails = ({lawFirm}) => {
    const lawFirmName = String(lawFirm.name);
    return (
      <div className={css.lawFirmPlacement}>
        <div className={css.logo}>
          <Logo logos={logos} name={lawFirmName} className={css.lawFirmLogo} logotype="lawfirm" />
        </div>
        <div className={css.name}>{lawFirm.name}</div>
        {(lawFirm.notificationText &&
          <div className={css.notificationText}>{lawFirm.notificationText.replace('Alert:', '')}</div>
        )}
      </div>
    );
  };

  if (distanceRequired) {
    tableData.forEach((item) => {
      const { costSummary, quoteIds } = item;
      const entry = {
        name: getLawFirmDetails(item),
        fixedCost: convertToCurrency(costSummary.legalFees),
        specialDisbursements:
        costSummary.specialDisbursements > 0
            ? convertToCurrency(costSummary.specialDisbursements)
            : "-",
        totalCost: getTotalCostCell(item),
        distance: item.distance !== null ? item.distance + " " + "miles" : "-",
        select: getRadio(quoteIds),
      };
      entries.push(entry);
    });
  } else {
    tableData.forEach((item) => {
      const { costSummary, quoteIds } = item;
      const entry = {
        name: getLawFirmDetails(item),
        fixedCost: convertToCurrency(costSummary.legalFees),
        specialDisbursements:
        costSummary.specialDisbursements > 0
            ? convertToCurrency(costSummary.specialDisbursements)
            : "-",
        totalCost: getTotalCostCell(item),
        distance: "",
        select: getRadio(quoteIds),
      };
      entries.push(entry);
    });
  }
  if (distanceRequired) {
    return (
      <table className={css.table}>
        <thead className={css.header}>
          <tr>
            {rowHeadersWithDistance.map((title, index) =>
              renderHeaders(title, index)
            )}
          </tr>
        </thead>
        <tbody>{entries.map((row, index) => renderBody(row, index))}</tbody>
      </table>
    );
  } else {
    return (
      <table className={css.table}>
        <thead className={css.header}>
          <tr>
            {rowHeaders.map((title, index) => renderHeaders(title, index))}
          </tr>
        </thead>
        <tbody>{entries.map((row, index) => renderBody(row, index))}</tbody>
      </table>
    );
  }
};

ResultsTable.propTypes = {
  data: PropTypes.array.isRequired,
  onQuoteSelect: PropTypes.func.isRequired,
  handleMiniBreakdown: PropTypes.func.isRequired,
  distanceRequired: PropTypes.bool.isRequired,
};

export default ResultsTable;
