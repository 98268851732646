import React, { useState } from 'react';
import AuthCheck from '../../components/authcheck/authCheck';
import authState from '../../components/authcheck/authState';
import { Row, Col, Container } from 'react-bootstrap';
import SearchBar from '../../components/SearchBar';
import css from './index.module.css';
import displayDropdownItems from './data';
import PreferredQuotes from './preferred-quotes';
import Layout from '../../layout';

const ViewSavedQuotes = () => {
  const [searchCriteria, setSearchCriteria] = useState();

  return (
    <Layout>
      <AuthCheck requiredAuthState={authState.AUTHENTICATED} />
      <Row className={css.bottompad2x}>
        <Col md={1} xs={1}></Col>
        <Col md={10} xs={10}>
          <h1 className={css.title}>Your saved quotes</h1>
          <table className={css.table}>
            <thead>
              <tr>
                <th colSpan="6" >
                  <SearchBar
                    onChange={setSearchCriteria}
                    type="quotes"
                    placeholder="Find a saved quote - Search postcode, your reference or law firm..."
                    displayDropdownItems={displayDropdownItems}
                  />
                </th>
              </tr>
            </thead>
          </table>
          <PreferredQuotes searchCriteria={searchCriteria}/>
        </Col>
      </Row>
    </Layout>
  )

}

export default ViewSavedQuotes